/* This code is defining a Recoil atom called `registration` which is used to manage the state of a
registration modal in a React application. The `atom` function is imported from the `recoil`
library. */
import { atom } from "recoil";

export const RegistraionModalTypes = {
  LOGIN: "LOGIN",
  SIGNUP: "SIGNUP",
  SIGNUPORGANIZER: "SIGNUPORGANIZER",
  FORGOT: "FORGOT",
  PASSWORD: "PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  ORGANIZER_DETAIL: "ORGANIZER_DETAIL",
  VERIFIER: "VERIFIER",
  EMAIL_VERIFICATION:"EMAIL_VERIFICATION",
  SIGNUP_VERIFICATION: "SIGNUP_VERIFICATION",
  LOGIN_VERIFICATION: "LOGIN_VERIFICATION",
  CHECK_EMAIL:"CHECK_EMAIL",
  LOGIN_EMAIL:"LOGIN_EMAIL",
  LOGIN_VERIFICATION_EMAIL:"LOGIN_VERIFICATION_EMAIL",
  GET_APP:"GET_APP",
  TICKETS_APP:"TICKETS_APP",
};

export const registration = atom({
  key: "registration",
  default: {
    openModal: false,
    modalType: "",
    userRole: "",
    userData: {},
    country:"",
  },
});