import { Box, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import axios from "../../../../../utils/api/axios";
import { theme } from "../../../../../styles/theme/base";

const DeleteListModal = ({ isOpen, onClose, listId, listName, onDeleteSuccess }) => {
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleDelete = async () => {
    try {
      setLoading(true);
      const response = await axios.delete(`/api/delete-list/${listId}`);

      if (response.status === 200) {
        toast({
          title: "Success",
          description: "List deleted successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onDeleteSuccess(listId); // Notify parent of the successful deletion
        onClose(); // Close the modal after success
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to delete the list.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent
        bg="black"
        border="1px solid #333333"
        borderColor="#333333"
        borderRadius="1.25rem"
      >
        <ModalHeader>Delete Subscriber List</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            Are you sure you want to delete the list <strong>{listName}</strong>? This action cannot be undone.
          </Text>
        </ModalBody>
        <ModalFooter>
        <Button
                onClick={
                    onClose
                }
                sx={theme.buttons.tercary.shape}
                bg={theme.buttons.tercary.bgColor}
                color={theme.buttons.tercary.color}
                _hover={{ bg: "red.500",
                    color: "black"
                 }}
                border="1px solid rgba(255, 255, 255, 0.20)"
                css={{
                width: "fit-content !important",
                padding: "1.25rem",
                marginRight: "10px",
                }}
            >
                Cancel
        </Button>
        <Button
                onClick={handleDelete}
                sx={theme.buttons.tercary.shape}
                bg={"red.500"}
                color={theme.buttons.tercary.color}
                _hover={{ bg: theme.buttons.primary.hover,
                    color: "black"
                }}
                border="1px solid rgba(255, 255, 255, 0.20)"
                css={{
                width: "fit-content !important",
                padding: "1.25rem",
                marginRight: "10px",
                }}
                isLoading={loading}
            >
                Delete
            </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteListModal;
