import {
    HStack,
    InputGroup,
    VStack,
    InputRightElement,
    Input as InputComponent,
    Box,
    useColorModeValue,
    Text,
    Switch,
    Flex,
  } from "@chakra-ui/react";
  import { Checkbox, Input, Select } from "antd";
  import {
    DateBox,
    InputBox,
    SelectBox,
    SwitchBox,
    TextBox,
    TimeBox,
  } from "./SharedModules";
  import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from "react-places-autocomplete";
  import { DownOutlined, UserOutlined } from "@ant-design/icons";
  import { Button as ABT, Dropdown, Space } from "antd";
  import { theme } from "../../../../styles/theme/base";
  import { useState, useEffect } from "react";
  import LocationAutocomplete from "../../../../utils/autocomplete";
  import secrets from "../../../../secrets";
  import { message } from "antd";
  import { useMediaQuery } from "../../../../utils/useMediaQuery";
  import languageIcon from "../../../../static/assets/images/language.svg";
  import { useLocation } from "react-router-dom";
  
  import "./styles.css";
  
  /**
   * This is a React component that renders a form for creating basic events, including fields for event
   * name, type, start and end dates and times, location, and description.
   * @param props - The `props` parameter is an object that contains various properties passed down from
   * the parent component to the `BasicEvents` component. These properties include `handleBlur`,
   * `handleChange`, `values`, and `setFieldValue`, which are likely functions or state variables used to
   * manage form input and state. The
   * @returns The BasicEvents component is being returned, which renders a form with input fields for
   * event name, event type, start and end dates and times, location, and description. The event type
   * field is a dropdown menu with options for Night Club, Music, Techno, and Conference. The location
   * field uses the LocationAutocomplete component to allow users to search for and select a location
   * using the Google Maps API
   */
  const Tags = (props) => {
    const isMobile = useMediaQuery("(max-width: 768px)");
    const { handleBlur, handleChange, values, setFieldValue } = props;
    const textValue = useColorModeValue(
      theme.colors.black[100],
      theme.colors.white[100]
    );
    const [selectedEvent, setSelectedEvent] = useState(values.eventType || []);
    const location = useLocation();
    const shouldShowContent = location.pathname.includes("/edit");
  
    const handleCheckboxChange = (checkedValues) => {
      if (checkedValues.length <= 5) {
        setFieldValue("eventType", checkedValues);
        setSelectedEvent(checkedValues);
      } else {
        // Display a message or take appropriate action when the limit is reached
        message.warning("You can select up to 5 values.");
      }
    };
    const tagsOptions = [
      {
        label: "Techno",
        value: "Techno",
      },
      {
        label: "Deep House",
        value: "Deep House",
      },
      {
        label: "Night Club",
        value: "Night Club",
      },
      {
        label: "Music",
        value: "Music",
      },
      {
        label: "House",
        value: "House",
      },
      {
        label: "Minimal House",
        value: "Minimal House",
      },
      {
        label: "Tech-House",
        value: "Tech-House",
      },
      {
        label: "Afro-House",
        value: "Afro-House",
      },
      {
        label: "Pop",
        value: "Pop",
      },
      {
        label: "Rock",
        value: "Rock",
      },
      {
        label: "Hip-Hop & R&B",
        value: "Hip-Hop & R&B",
      },
      {
        label: "Conference",
        value: "Conference",
      },
      {
        label: "Business",
        value: "Business",
      },
      {
        label: "Fashion Event",
        value: "Fashion Event",
      },
      {
        label: "Web3",
        value: "Web3",
      },
      {
        label: "Funk",
        value: "Funk",
      },
      {
        label: "Brasilidades",
        value: "Brasilidades",
      },
      {
        label: "Retro",
        value: "Retro",
      },
    ];
  
    useEffect(() => {
      setSelectedEvent(values.eventType || []);
    }, [values.eventType]);
  
    return (
      <VStack spacing={"2.19rem"} w="100%">

        
               <Box w={"100%"}>
          <Text
            fontSize={"1.3125rem"}
            mb="0.94rem"
            textTransform={"capitalize"}
            color={textValue}
            className="gordita600"
            fontWeight={500}
          >
            {"Tags"}
          </Text>
          <Text
            fontSize={"1rem"}
            color={"rgba(255, 255, 255, 0.60)"}
            className="gordita400"
            mb={"1.56rem"}
          >
            Click and select 1 to 5 tags maximum.
          </Text>
          <Checkbox.Group
            options={tagsOptions}
            value={selectedEvent}
            onChange={handleCheckboxChange}
            className="tags-container"
            style={{ width: "100%" }}
          />
        </Box>
        
      </VStack>
    );
  };
  
  export default Tags;
  