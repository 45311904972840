import {
    Box,
    Button,
    Divider,
    HStack,
    Text,
    VStack,
    Input,
    chakra,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
  } from "@chakra-ui/react";
  import { SignUpSchema } from "../../../utils/schema";
  import { Formik, Field, Form, ErrorMessage } from "formik";
  import { google } from "../../../static/assets/images";
  import {
    RegistraionModalTypes,
    registration,
  } from "../../../recoil/atoms/registration";
  import { useRecoilState } from "recoil";
  import { useNavigate, useParams } from "react-router-dom";
  import { userRoles } from "../../../utils/constants";
  import { useGoogleLogin } from "@react-oauth/google";
  import { user } from "../../../recoil/atoms/user";
  import { loginWithGoogle } from "../../../utils/actions/registration";
  import { useCookies } from "react-cookie";
  import PhoneInput from "react-phone-input-2/";
  import "react-phone-input-2/lib/bootstrap.css";
  import axios from "../../../utils/api/axios";
  import React, { useState, useEffect } from "react";
  import { useLocation } from "react-router-dom";
  import { LightLogo } from "../../../static/assets/images";
  import Login from "../Login";
  import SignUpVerification from "../SignUpVerification";
  import { useMediaQuery } from "../../../utils/useMediaQuery";
  import LoginVerification from "../LoginVerification";
  import backgroundPicture from "../../../static/assets/images/portalysvisionhero1.png";
  import { theme } from "../../../styles/theme/base";
  
  const initialValues = { phoneNumber: "", password: "", confirmPassword: "" };
  
  /**
   * The function handles the sign up process using Google login and saves the user's token and data to
   * local storage and state.
   */
  const CompleteRegistration = () => {
    const isMobile = useMediaQuery("(max-width: 768px)");
    const [_, setRegistrationModal] = useRecoilState(registration);
    const [u, setUser] = useRecoilState(user);
    const [cookies, setCookie] = useCookies(["x-auth-token"]);
    const location = useLocation();
    const params = useParams();
    const { email } = params;
    const [inputValue, setInputValue] = useState(""); // Initialize with an empty string
  
    const handleChange = (event) => {
      const newValue = event.target.value;
      setInputValue(newValue); // Update the state with the new value
    };
    const navigate = useNavigate();
  
    const [verificationStatus, setVerificationStatus] = useState("");
    const [verificationCheck, setVerificationCheck] = useState(false);
  
    const renderModal = () => {
      switch (_.modalType) {
        case RegistraionModalTypes.LOGIN:
          return <Login />;
        case RegistraionModalTypes.SIGNUP_VERIFICATION:
          return <SignUpVerification />;
        case RegistraionModalTypes.LOGIN_VERIFICATION:
          return <LoginVerification />;
      }
    };
  
    
  
    const handleSendOTP = async (phoneNumber) => {
      try {
        const response = await axios.post("/api/sendOTP", { phoneNumber });
        if (response.data.success === true) {
          setVerificationStatus(response.data.message);
          console.log("aaaaaaaaachcekoiin");
          setVerificationCheck(true);
          return true;
        } else {
          setVerificationStatus(response.data.message);
          setVerificationCheck(false);
          return false;
        }
      } catch (error) {
        console.error("Error sending OTP:", error);
        setVerificationStatus("Failed to send OTP");
        return false;
      }
    };
  

  
    /* The code is rendering a sign-up form using Chakra UI components and Formik library. The form
    includes input fields for email, password, and confirm password, and buttons to sign up with email
    or Google. The form also includes validation using Yup schema and error messages for invalid
    input. When the form is submitted, it calls a function to set the registration modal state with
    the user's email and open the password modal for further registration. The code also includes a
    link to the login modal and saves the user's token and data to local storage and state after
    successful registration. */
    return (
      <Box
        style={{
          background: `url(${backgroundPicture}), lightgray 50% / cover no-repeat`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          // backgroundColor: "#000000",
          width: "100vw",
          height: "100vh",
          flexShrink: 0,
        }}
      >
        <Box
          pos="relative"
          left={isMobile ? 0 : "7%"}
          display={"flex"}
          flexDir={"column"}
          justifyContent={"center"}
          alignItems={"flex-start"}
          width={isMobile ? "100%" : "25rem"}
          height={"100vh"}
          p={isMobile ? 20 : 0}
        >
          <Image
            cursor="pointer"
            onClick={() => navigate("/")}
            alt=""
            src={LightLogo}
            flex={1}
            width={120}
            height={70}
            margin={isMobile ? "-20px auto 20px" : 0}
          />
          <HStack flex={4} spacing={0} w="100%">
            <Box w="100vh" h="100%">
              <VStack>
                <Text
                  className="gordita700"
                  fontSize={{ base: 20, md: 24 }}
                  textAlign="center"
                  mb={isMobile ? 30 : 30}
                >
                  Ready to get back control of your tickets?
                </Text>
                <Formik
                  initialValues={initialValues}
                  onSubmit={async (values) => {
                    try {
                      // Attempt to send OTP
                      const response = await handleSendOTP(
                        "+" + values.phoneNumber
                      );
  
                      // Check if OTP sending was successful
                      if (response) {
                        // Proceed with setting the modal
                        setRegistrationModal((lp) => ({
                          ...lp,
                          openModal: true,
                          modalType: RegistraionModalTypes.SIGNUP_VERIFICATION,
                          userData: {
                            phoneNumber: values.phoneNumber,
                            email: params.email,            
                            isCompletingReg: true              
                          },
                        }));
                      }
                    } catch (error) {
                      console.error("Error sending OTP:", error);
                      setVerificationStatus("Failed to send OTP");
                      setVerificationCheck(false);
                    }
                  }}
                >
                  {(formik) => {
                    const { handleSubmit, errors, touched, setFieldValue } =
                      formik;
  
                    const handlePhoneNumberChange = (value) => {
                      // Check if the value is numeric or empty
                      if (/^\d*$/.test(value) || value === "") {
                        setFieldValue("phoneNumber", value);
                      }
                    };
  
                    return (
                      <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
                        <VStack alignItems="flex-start" marginBottom={15}>
                          <Text fontWeight={400} fontSize="0.75rem" pl={"1.5rem"}>
                            Email
                          </Text>
                          <Field
                            type="email"
                            name="email"
                            id="email"
                            render={({ field }) => (
                              <Input
                                placeholder="Enter Organizer Email"
                                fontSize={14}
                                value={email}
                                disabled={true}
                                border="1px solid"
                                borderRadius="6.25rem"
                                height="3.375rem"
                                borderColor="#FFFFFF"
                                color="#ffffff"
                                _placeholder={{
                                  color: "00000a",
                                }}
                                style={{
                                  marginTop: "0.30rem",
                                }}
                                h="48px"
                              />
                            )}
                          />
                        </VStack>
                        <VStack alignItems="flex-start">
                          <Text fontWeight={400} fontSize="0.75rem" pl={"1.5rem"}>
                            Phone Number
                          </Text>
                          <Field
                            type="phoneNumber"
                            name="phoneNumber"
                            id="phoneNumber"
                            render={({ field }) => (
                              <PhoneInput
                                inputProps={{
                                  name: "phoneNumber", // Specify the name for the input field
                                  placeholder: "Enter Your Phone Number",
                                  style: {
                                    width: "100%",
                                    border: "1px solid #FFFFFF",
                                    borderRadius: "6.25rem",
                                    height: "3.375rem",
                                    fontSize: "14px",
                                    color: "#FFFFFF",
                                    backgroundColor: "transparent",
                                  },
                                }}
                                style={{
                                  marginTop: "0.30rem",
                                }}
                                dropdownStyle={{ backgroundColor: "#000000" }}
                                country={"us"} // Set your default country
                                value={field.value} // Set the value explicitly
                                onChange={handlePhoneNumberChange} // Handle input changes
                              />
                            )}
                          />
  
                         
  
                          <p
                            style={{
                              textAlign: "center",
                              fontWeight: 700,
                              color: "red",
                            }}
                          >
                            {verificationStatus}
                          </p>
                        </VStack>
  
                        <Box mt={24} w="100%">
                          <Button
                            // color="white.100"
                            // bg="primary.100"
                            // type="submit"
                            // className="gordita700"
                            // w="100%"
                            // borderRadius="48px"
                            // h="65px"
                            // _hover={{
                            // bg: "primary.100",
                            // }}
                            type="submit"
                            className="gordita700"
                            sx={theme.buttons.primary.shape}
                            bg={theme.buttons.primary.default}
                            color={theme.buttons.primary.color}
                            css={{
                              width: "100%",
                            }}
                            _hover={{
                              bg: theme.buttons.primary.hover,
                              boxShadow: theme.buttons.primary.hoverBoxShadow,
                            }}
                            _active={{
                              boxShadow: theme.buttons.primary.activeBoxShadow,
                            }}
                          >
                            Receive SMS With The Code
                          </Button>
                        </Box>
  
                        <Box
                          className="gordita400"
                          fontSize={16}
                          px={{ base: 0, md: 36 }}
                          mt={24}
                          w="100%"
                          textAlign="center"
                        >
                          Have an account already?
                          <chakra.span
                            color="white"
                            cursor="pointer"
                            fontWeight="700"
                            onClick={() => navigate("/loginOrganizer")}

                          >
                            {" "}
                            Log In
                          </chakra.span>
                        </Box>
                      </Form>
                    );
                  }}
                </Formik>
              </VStack>
            </Box>
          </HStack>
        </Box>
  
        <div color="#FFFFFF" zIndex="100"></div>
        <Modal
          closeOnOverlayClick={false}
          isOpen={_.openModal}
          onOverlayClick={() => {
            setRegistrationModal((lp) => {
              return { ...lp, openModal: false, userRole: "" };
            });
          }}
          onClose={() => {
            setRegistrationModal((lp) => {
              return { ...lp, openModal: false, userRole: "" };
            });
          }}
          size={isMobile ? "full" : { base: "sm", md: "md", lg: "lg" }} // Set the size conditionally
          isCentered={true}
        >
          <ModalOverlay />
          <ModalContent
            mx={0}
            color="white.100"
            borderRadius="2.5rem"
            boxShadow={
              isMobile
                ? "none"
                : "0px 0px 100px 20px rgba(0, 0, 0, 0.90), -1px -1px 0px 0px rgba(255, 255, 255, 0.20) inset, 1px 1px 0px 0px rgba(255, 255, 255, 0.50) inset"
            }
            bg={"rgba(0, 0, 0, 0.50)"}
            backdropFilter={"blur(150px)"}
          >
            <ModalCloseButton />
            <ModalBody
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              p={isMobile ? 15 : "60px 10px"}
            >
              {renderModal()}
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    );
  };
  
  export default CompleteRegistration;
  