import {
  Box,
  HStack,
  Stack,
  Table,
  Thead,
  Tbody,
  Th,
  Td,
  TableContainer,
  Text,
  Image,
  Tr,
  useColorModeValue,
  Button,
  Heading,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal,
  useToast,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../../utils/api/axios";
import { useRecoilState } from "recoil";
import { user } from "../../../../recoil/atoms/user";
import userIcon from "../../../../static/assets/images/userIcon.svg";
import { getEvents } from "../../../../utils/actions/event";
import { isEmpty } from "lodash";
import { theme } from "../../../../styles/theme/base";
import filtericon from "../../../../static/assets/images/ic_round-filter-list.png";
import downloadIcon from "../../../../static/assets/images/downloadIcon.png";
import "../sharedAdminStyles.css";

const spacing = {
  gap: 0,
  spacing: 0,
  w: "100%",
};

/**
 * The UsersComponent function displays a table of attendees or organizers, with options to view
 * details or approve organizers if the user is an admin.
 * @returns The `UsersComponent` functional component is being returned.
 */
const UsersComponent = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [_, setUser] = useRecoilState(user);
  const colorValue = useColorModeValue("black.100", "gray.100");
  const [currentPage, setCurrentPage] = useState(1);
  const toast = useToast();
  const [events, setEvents] = useState([]);

  const [selectedEvent, setSelectedEvent] = useState("All Events");
  const [eventUuid, setEventUuid] = useState();
  const organizerId = _.userData?.organizerId;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen:isOpen2, onOpen:onOpen2, onClose:onClose2 } = useDisclosure();

  const [paymentIntent, setPaymentIntent] = useState("");
  const [subsidized, setSubsidized] = useState("");
  const [attendee, setAttendee] = useState(null);


  const getUsers = async () => {
    if (_?.token) {
      try {
        const response = await axios.get(`/api/getAllAttendees`, {
          headers: {
            "x-auth-token": "Bearer " + _?.token ,
          },
          params: {
            organizerId: organizerId,
            eventUuid,
          },
        });
        setData(response.data.user);
      } catch (error) {
        console.error("Error fetching NFTs:", error);
      }
    }
  };
  const init = async () => {
    const user = JSON.parse(localStorage.getItem("user_d"));
    if (_?.token) {
      if (user?.userRole === "ADMIN") {
        await axios
          .get("/api/events/admin", {
            headers: {
              "x-auth-token":"Bearer " + _?.token ,
            },
          })
          .then((res) => {
            const data = res.data;
            if (!isEmpty(data)) {
              const newEvents = [];
              Object.values(data).forEach((et) => {
                newEvents.push({ ...et });
              });
              console.log("newevents1", newEvents);
              setEvents(newEvents);
            }
          })
          .catch((err) => {
            console.log({ err });
          });
      } else {
        try {
          const val = await getEvents(organizerId);
          if (!isEmpty(val)) {
            console.log("checkpoint3");
            const newEvents = [];
            Object.values(val).forEach((et) => {
              newEvents.push({ ...et });
            });
            setEvents(newEvents);
          }
        } catch (error) {
          console.error("Error fetching NFTs:", error);
        }
      }
    }
  };
  useEffect(() => {
    init();
  }, []);
  useEffect(() => {
    getUsers();
  }, [_?.token, eventUuid]);
  const handleEventChange = (event) => {
    setSelectedEvent(event.eventName);
    setEventUuid(event.uuid);
  };

  const commonButtonStyle = {
    display: "flex",
    height: "45px",
    padding: "15px 25px",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    flex: "1 0 0",
    borderRadius: "100px",
  };

  const selectedButton = {
    ...commonButtonStyle,
    background: "var(--colors-shades-white-10, rgba(255, 255, 255, 0.10))",
    color: "white",
  };

  const notSelectedButton = {
    ...commonButtonStyle,
    background: "none",
    color: "#FFFFFF99",
  };

  const downloadCSV = () => {
    const csvData = arrayToCSV(data); // Assuming data is an array of objects
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (navigator.msSaveBlob) {
      // For Internet Explorer
      navigator.msSaveBlob(blob, "attendees.csv");
    } else {
      // For other browsers
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = "attendees.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  };
  const arrayToCSV = (array) => {
    // Extracting only the required columns: Full Name, Email, and Phone Number
    const filteredData = array.map((obj) => ({
      "Full Name": `${obj.firstName} ${obj.lastName}`,
      Email: obj.email || "",
      "Phone Number": obj.phoneNumber || "", // Assuming phoneNumber may be null or undefined
      "Ticket Type": obj.type || "",
      "Quantity of tickets": obj.ticketCount || "",
      "Total Paid with fees": obj.priceCount || "",
      "Date of Purchase": obj.createdAt || "",
    }));

    const header = Object.keys(filteredData[0])
      .map((key) => `"${key}"`)
      .join(",");
    const rows = filteredData.map((obj) =>
      Object.values(obj)
        .map((value) => `"${value}"`)
        .join(",")
    );

    return [header, ...rows].join("\n");
  };
  const refundPayment = async () => {
    const user = _?.userData;
    const payload = {
      organizerId: organizerId,
      paymentIntent: paymentIntent,
    };
    await axios
      .post("/api/refundPayment/", payload, {
        headers: {
          "x-auth-token": "Bearer " + _?.token ,
        },
      })
      .then(async (res) => {
        onClose();
        const indexesToRemove = Object.values(data)
          .map((entry, index) =>
            entry.paymentIntent === paymentIntent ? index : -1
          )
          .filter((index) => index !== -1);

        // Remove the entries from the data array
        indexesToRemove.forEach((index) => {
          data.splice(index, 1);
        });
        setPaymentIntent("");
        setSubsidized("");

        toast({
          title: "Payment Refunded",
          position: "bottom",
        });
      })
      .catch((err) => {
        console.log({ err });
        onClose();
      });
  };

  return (
    <Box w="100%">
      <Box width="100%" display={"flex"} justifyContent={"space-between"}>
        <Stack
          {...{ spacing }}
          flexDir="row"
          color="#FBFAFB"
          pos={"sticky"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={"10px"}
          flexWrap={"wrap"}
          top={0}
          p={"30px"}
          zIndex={10}
          w="100%"
          fontWeight={700}
          bg={"rgba(0, 0, 0, 0.50)"}
          backdropFilter={"blur(150px)"}
        >
          <Box fontSize={"32px"} className="control-header-component-title">
            Marketing
          </Box>

          <Box
            sx={{
              display: "flex",
              maxWidth: "634px",
              padding: "5px",
              alignItems: "flex-start",
              borderRadius: "100px",
              border:
                "1px solid var(--border-b-primary, rgba(255, 255, 255, 0.20))",
              background: "var(--surface-s-glass, rgba(0, 0, 0, 0.50))",
              backdropFilter: "blur(75px)",
            }}
            overflow={"scroll"}
            gap={"5px"}
            className="control-header-component-filters"
          >
            <Button sx={selectedButton} minW={"150px"} p={"5px 25px"}>
              {" "}
              Attendees
            </Button>
            <Button
              onClick={() => {
                navigate("/guestlist");
              }}
              sx={notSelectedButton}
              minW={"150px"}
              p={"5px 25px"}
            >
              {" "}
              Guest List
            </Button>
            <Button
              onClick={() => {
                navigate("/trackinglinks");
              }}
              sx={notSelectedButton}
              minW={"150px"}
              p={"5px 25px"}
            >
              {" "}
              Tracking Links
            </Button>
            <Button
              onClick={() => {
                navigate("/promocodes");
              }}
              sx={notSelectedButton}
              minW={"150px"}
              p={"5px 25px"}
            >
              {" "}
              Promo Codes
            </Button>
          </Box>
          <Box
            sx={theme.buttons.primary.shape}
            bg={theme.buttons.secondary.border}
            color={theme.buttons.secondary.color}
            border={theme.buttons.secondary.border}
            _hover={{ borderColor: theme.buttons.secondary.hoverBorderColor }}
            _active={{
              bg: theme.buttons.secondary.pressed,
            }}
            p="1"
            className="control-header-component-button"
          >
            <Image
              src={filtericon}
              width="1.5rem"
              height="1.5rem"
              borderRadius="var(--spacing-spacing-none, 0rem)"
            />
            <Select
              value={selectedEvent}
              border="none" // Added padding for better visual appearance
              onChange={(e) => {
                setSelectedEvent(e.target.value);
                const selectedEvent = events.find(
                  (event) => event.eventName === e.target.value
                );
                if (selectedEvent) {
                  handleEventChange(selectedEvent);
                } else {
                  setEventUuid();
                }
              }}
              width="100%" // Adjusted width to fill the entire box
            >
              <option value="All Events">All Events</option>
              {events?.map((event) => (
                <option key={event.eventUuid} value={event.eventName}>
                  {event.eventName}
                </option>
              ))}
            </Select>
          </Box>
          <Box display="flex" justifyContent="flex-end" style={{ order: 4 }}>
            <Button
              borderRadius="var(--Radius-radius-full, 100px)"
              border="1px solid rgba(255, 255, 255, 0.20)"
              display="inline-flex"
              height={"50px"}
              width={"50px"}
              justifyContent="center"
              alignItems="center"
              gap="5px"
              bg={"#000000"}
              onClick={downloadCSV}
            >
              <Image
                src={downloadIcon}
                borderRadius="var(--spacing-spacing-none, 0rem)"
              />
            </Button>
          </Box>
        </Stack>
      </Box>
      <Box w="100%" minH="80vh">
        <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
          <ModalOverlay />
          <ModalContent
            bg={"rgba(0, 0, 0, 0.50)"}
            backdropFilter={"blur(150px)"}
            borderRadius={"20px"}
            border={"1px solid #333333"}
          >
            <ModalHeader color="white.100">Cancel</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Are you sure you want to refund this ticket? The participant will
              receive {subsidized}$ deducted from your stripe account. The
              ticket(s) will be deleted but NOT added back to the event. If you
              want to add more tickets to your event to account for this refund,
              you will have to do it manually.
            </ModalBody>
            <ModalFooter>
              <Button
                mr={3}
                onClick={onClose}
                sx={theme.buttons.primary.shape}
                bg={theme.buttons.secondary.default}
                color={theme.buttons.secondary.color}
                style={{
                  width: "fit-content",
                }}
                _hover={{ bg: theme.buttons.secondary.hover }}
              >
                Cancel
              </Button>
              <Button
                sx={theme.buttons.primary.shape}
                bg={theme.buttons.primary.default}
                color={theme.buttons.primary.color}
                style={{
                  width: "5rem",
                  padding: "0",
                }}
                _hover={{
                  bg: theme.buttons.primary.hover,
                  boxShadow: theme.buttons.primary.hoverBoxShadow,
                }}
                _active={{
                  boxShadow: theme.buttons.primary.activeBoxShadow,
                }}
                onClick={refundPayment}
              >
                OK
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal isOpen={isOpen2} onClose={onClose2} isCentered={true}>
          <ModalOverlay />
          <ModalContent
            bg={"rgba(0, 0, 0, 0.50)"}
            backdropFilter={"blur(150px)"}
            borderRadius={"20px"}
            border={"1px solid #333333"}
          >
            <ModalHeader color="white.100"> {`${attendee?.firstName || ""} ${attendee?.lastName || ""}`}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>

            <Box marginTop="18px">
              <Text fontSize="20px" color="#FFFFFF" fontWeight="500">
              <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
      Email:
    </span>{" "} {attendee?.email}
              </Text>
            </Box>
            <Box marginTop="18px">
              <Text fontSize="20px" color="#FFFFFF" fontWeight="500">
              <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
      Phone Number:
    </span>{" "} {attendee?.phoneNumber}
              </Text>
            </Box>
            {attendee?.socialLinksObject?.instagram &&(
            <Box marginTop="18px">
              <Text fontSize="20px" color="#FFFFFF" fontWeight="500">
              <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
      Instagram
    </span>{" "}:<a
                            href={`https://www.instagram.com/${attendee?.socialLinksObject?.instagram}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              //textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          > @{attendee?.socialLinksObject?.instagram} {" "} &#8599;
                          </a>
              </Text>
            </Box>
            )}
            {attendee?.promoter && (
            <Box marginTop="18px">
              <Text fontSize="20px" color="#FFFFFF" fontWeight="500">
              <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
      Promoter:
    </span>{" "} {attendee?.promoter}
              </Text>
            </Box>
            )}
             
            </ModalBody>
            <ModalFooter>
              <Button
                sx={theme.buttons.primary.shape}
                bg={theme.buttons.primary.default}
                color={theme.buttons.primary.color}
                style={{
                  width: "5rem",
                  padding: "0",
                }}
                _hover={{
                  bg: theme.buttons.primary.hover,
                  boxShadow: theme.buttons.primary.hoverBoxShadow,
                }}
                _active={{
                  boxShadow: theme.buttons.primary.activeBoxShadow,
                }}
                onClick={onClose2}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Box w="100%" minH="80vh" px={"30px"}>
          {data ? (
            <TableContainer>
              <Table
                style={{
                  borderCollapse: "separate",
                  borderSpacing: "0 18px",
                  marginBottom: "30px",
                }}
                variant="unstyled"
              >
                <Thead
                  borderBottom="1px solid"
                  borderColor="rgba(153, 159, 187,0.2)"
                >
                  <Tr>
                    {[
                      "Name",
                      "Details",
                      "# of Tickets",
                      "Ticket Type",
                      "Actions",
                    ].map((column, index) => (
                      <Th
                        key={index}
                        paddingBottom={"20px"}
                        textTransform="capitalize"
                        color="var(--surface-surface-secondary, #959597)" // Added color
                        fontFamily="Gordita" // Added font family
                        fontSize="1rem" // Added font size
                        fontStyle="normal"
                        fontWeight={400} // Added font weight
                        lineHeight="150%" // Added line height
                        letterSpacing="-0.01rem" // Added letter spacing
                        position="relative"
                        textAlign={"center"}
                        border={"none"}
                        borderRadius="0rem" // Added border radius
                        borderRight="0px solid rgba(255, 255, 255, 0.10)" // Added border right
                        borderBottom="1px solid rgba(255, 255, 255, 0.10)" // Added border bottom
                        borderLeft="0px solid rgba(255, 255, 255, 0.10)" // Added border left
                        background="rgba(0, 0, 0, 0.50)" // Added background
                        backdropFilter="blur(75px)" // Added blur
                      >
                        {column}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody marginBottom={"30px"}>
                  {Object.values(data)?.map(
                    (
                      {
                        image,
                        firstName,
                        lastName,
                        email,
                        phoneNumber,
                        ticketCount,
                        type,
                        paymentIntent,
                        organizerShare,
                        socialLinks,
                        promoter,
                      },
                      index
                    ) => (
                      <Tr overflow="hidden" color="white.100" key={index}>
                        <Td
                          m={0}
                          py={0}
                          pl={20}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          borderLeft={"1px solid rgba(255, 255, 255, 0.10)"}
                          borderTopLeftRadius={"0.9375rem"}
                          borderBottomLeftRadius={"0.9375rem"}
                          overflow={"hidden"}
                          maxWidth={"15rem"}
                        >
                          <HStack py={0} spacing={10}>
                            <Image
                              w="57px"
                              h="57px"
                              alt=""
                              src={image || userIcon}
                            />
                            <Text
                              className="gordita400"
                              color="#FFF" // Added color
                              fontFeatureSettings="'ss08' on, 'ss04' on" // Added font feature settings
                              fontFamily="Gordita" // Added font family
                              fontSize="1.125rem" // Added font size
                              fontStyle="normal"
                              fontWeight={700} // Added font weight
                              lineHeight="110%" // Added line height
                              letterSpacing="-0.01125rem" // Added letter spacing
                            >
                              {`${firstName || ""} ${lastName || ""}`}
                            </Text>
                          </HStack>
                        </Td>
                       
                        <Td
                          m={0}
                          py={0}
                          textAlign={"center"}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          maxWidth={"14rem"}
                        >
                                                    <Button
                            cursor="pointer"
                            borderRadius={"3.125rem"}
                            border={"1px solid rgba(255, 255, 255, 0.20)"}
                            bg="transparent"
                            onClick={(event) => {
                                event.stopPropagation();
                                const socialLinksObject = JSON.parse(socialLinks);

                                setAttendee({
                                  firstName,
                                  lastName,
                                  email,
                                  phoneNumber,
                                  socialLinksObject,
                                  promoter
                                });
                                onOpen2();
                            }}
                          >
                            {"View Details"}
                           
                          </Button>
                        </Td>
                        <Td
                          m={0}
                          py={0}
                          textAlign={"center"}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          maxWidth={"14rem"}
                        >
                          <Text fontSize={14} className="gordita400">
                            {ticketCount || 0}
                          </Text>{" "}
                        </Td>
                        <Td
                          m={0}
                          py={0}
                          textAlign={"center"}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          maxWidth={"14rem"}
                        >
                          <Text fontSize={14} className="gordita400">
                            {type || ""}
                          </Text>{" "}
                        </Td>
                        <Td
                          m={0}
                          pl={0}
                          textAlign={"center"}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          borderTopRightRadius={"0.9375rem"}
                          borderBottomRightRadius={"0.9375rem"}
                        >
                          <Button
                            cursor="pointer"
                            borderRadius={"3.125rem"}
                            border={"1px solid rgba(255, 255, 255, 0.20)"}
                            bg="transparent"
                            onClick={(event) => {
                              if (paymentIntent) {
                                event.stopPropagation();
                                setPaymentIntent(paymentIntent);
                                setSubsidized(organizerShare * ticketCount);
                                onOpen();
                              }
                            }}
                          >
                            {" "}
                            {paymentIntent ? "Refund" : "Can't Refund"}{" "}
                          </Button>

                          
                        </Td>
                      </Tr>
                    )
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          ) : (
            ""
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default UsersComponent;
