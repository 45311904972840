import {
  Box,
  Flex,
  HStack,
  Text,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Img,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Image,
  IconButton,
  AccordionIcon,
  useMediaQuery,
} from '@chakra-ui/react';
import {
  DateBox,
  InputBox,
  SelectBox,
  SwitchBox,
  TextBox,
} from './SharedModules';
import RangeSlider from '../../Events/Create/rangeslider';
import { user } from '../../../../recoil/atoms/user';
import { useRecoilState } from 'recoil';
import { userRoles } from '../../../../utils/constants';
import { useEffect, useState, useRef } from 'react';
import { theme } from '../../../../styles/theme/base';
import deleteIcon from '../../../../static/assets/images/deleteIcon.svg';
import { DeleteIcon } from '@chakra-ui/icons';
import editIcon from '../../../../static/assets/images/editIcon.svg';
import dollarIcon from '../../../../static/assets/images/dollarIcon.svg';
import { Select } from 'antd';
import NFTMeta from './NFTMetaData';
import './styles.css';
import secrets from '../../../../secrets';
import { useLocation } from 'react-router-dom';
import frame3 from '../../../../static/assets/images/Frame 3.png';
import getSymbolFromCurrency from 'currency-symbol-map';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const TicktDetails = (props) => {
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const { handleBlur, handleChange, values, setFieldValue, isEditMode } = props;

  const [ticketTypes, setTicketTypes] = useState([]);
  const [selectedTicketType, setSelectedTicketType] = useState(null);
  const [editMode, setEditMode] = useState(false); // Edit mode state
  const [editingTicketIndex, setEditingTicketIndex] = useState(null);
  const [editingWaveIndex, setEditingWaveIndex] = useState(null);
  const [editMode2, setEditMode2] = useState(false); // Edit mode state
  const [waveCounter, setWaveCounter] = useState(0);
  const [wave, setWave] = useState({ isEditable: false, counter: null });
  const location = useLocation();
  const [isTicketWaveDialogOpened, setIsTicketWaveDialogOpened] =
    useState(false);

  const shouldShowContent = location.pathname.includes('/edit');

  const [soldoutIndex, setSoldoutIndex] = useState(null);
  const [showAdvanced, setShowAdvanced] = useState(false);

  const [dragging, setDragging] = useState(false);
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const dropRef = useRef(null);
  const [errorFields, setErrorFields] = useState();

  useEffect(() => {
    console.log('hhhh', values?.ticketTypes);

    if (isEditMode && values?.ticketTypes) {
      setTicketTypes([...values?.ticketTypes]);
    }
  }, [values.ticketTypes]);

  useEffect(() => {
    if (!isEditMode) {
      setTicketTypes([]);
    }
  }, [isEditMode]);

  const addTicketWave = (
    ticketTypeIndex,
    name,
    description,
    quantity,
    price,
    entries,
    password,
    limit,
    instagram,
    start,
    end
  ) => {
    const newTicketWave = {
      name: name,
      description: description,
      quantity: quantity,
      entries: entries,
      password: password,
      limit: limit,
      instagram: instagram,
      ticketRemainingSupply: Number(quantity),
      price,
      start,
      end,
    };

    const updatedTicketTypes = [...ticketTypes];
    updatedTicketTypes[ticketTypeIndex].ticketWaves = [
      ...updatedTicketTypes[ticketTypeIndex]?.ticketWaves,
      newTicketWave,
    ];
    // Update the state
    setTicketTypes(updatedTicketTypes);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isOpen3,
    onOpen: onOpen3,
    onClose: onClose3,
  } = useDisclosure();
  const {
    isOpen: isTicketWaveDialogOpen,
    onOpen: onOpenTicketWaveDialog,
    onClose: onCloseTicketWaveDialog,
  } = useDisclosure();

  const updateWave = (updatedWave) => {
    const updatedTicketTypes = [...ticketTypes];
    const updatedTicketWaves = [
      ...updatedTicketTypes[editingTicketIndex].ticketWaves,
    ];
    updatedTicketWaves[editingWaveIndex] = updatedWave;
    updatedTicketTypes[editingTicketIndex].ticketWaves = updatedTicketWaves;
    setTicketTypes([...updatedTicketTypes]);
  };

  const cancelTicketModel = () => {
    const updatedTicketTypes = [...ticketTypes];

    if (editMode) {
      updatedTicketTypes[editingTicketIndex] = selectedTicketType;
    } else {
      updatedTicketTypes.pop();
    }
    setTicketTypes(updatedTicketTypes);
    setFieldValue('ticketTypes', updatedTicketTypes);

    setEditMode(false);
    setEditingWaveIndex(null);
    setShowAdvanced(false);

    setFieldValue('ticketTypeName', '');
    setFieldValue('ticketName', '');
    setFieldValue('ticketDescription', '');
    setFieldValue('ticketQuantity', '');
    setFieldValue('ticketEntries', '');
    setFieldValue('ticketPassword', '');
    setFieldValue('limitQuantity', '');
    setFieldValue('instagram', '');
    setFieldValue('ticketPrice', '');
    setFieldValue('ticketStart', '');
    setFieldValue('ticketEnd', '');
    setErrorFields({
      ticketName: false,
      ticketQuantity: false,
      ticketPrice: false,
      ticketDescription: false,
    });
    onClose();
    onClose3();
  };

  const waveItem = (wave, index) => {
    return (
      <Box
        key={index}
        maxW={'36.875rem'}
        width={'100%'}
        height={isMobile ? 'auto' : '3.4375rem'}
        borderRadius={isMobile ? '15px' : '6.25rem'}
        border={'1px solid rgba(255, 255, 255, 0.10)'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={'0.31rem'}
        flexWrap={isMobile ? 'wrap' : 'inherit'}
        gap={'10px'}
      >
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          gap={'0.62rem'}
        >
          <Box
            width={'2.8125rem'}
            height={'2.8125rem'}
            bg="rgba(255, 255, 255, 0.05)"
            borderRadius={'50%'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {index + 1}
          </Box>
          <Text
            minW={isMobile ? '160px' : '185px'}
            className={'gordita700'}
            fontSize={'1rem'}
            lineHeight={'150%'}
            letterSpacing={'-0.01rem'}
            textTransform={'capitalize'}
            overflow={'hidden'}
            whiteSpace={'nowrap'}
            textOverflow={'ellipsis'}
          >
            {wave?.name}
          </Text>

          <Text
            className={'gordita400'}
            fontSize={'1rem'}
            lineHeight={'150%'}
            letterSpacing={'-0.01rem'}
          >
            {wave?.quantity}
          </Text>
        </Box>
        <Box
          display={'flex'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          gap={'20px'}
          margin={'auto 0.61rem'}
        >
          {shouldShowContent && (
            <Text
              className={'additionalTextClass'}
              fontSize={'1rem'}
              lineHeight={'150%'}
              letterSpacing={'-0.01rem'}
              w={'full'}
            >
              Remaining Quantity: {wave?.ticketRemainingSupply || 0}
            </Text>
          )}
          <Text
            className={'gordita600'}
            fontSize={'1rem'}
            lineHeight={'150%'}
            letterSpacing={'-0.01rem'}
          >
            {getSymbolFromCurrency(values.currency)}
            {wave?.price}
          </Text>
        </Box>
      </Box>
    );
  };

  const ticketItem = (ticket, index, ticketIndex) => {
    return (
      <Box
        height={'100%'}
        display={'flex'}
        justifyContent={'space-between'}
        padding={'0.94rem'}
        flexWrap={isMobile ? 'wrap' : 'inherit'}
        gap={'10px'}
      >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={'100%'}
          gap={'1'}
          marginRight={'1.56rem'}
          flexWrap={'wrap'}
        >
          <Text
            className={'gordita700'}
            fontSize={'1.1875rem'}
            lineHeight={'110%'}
            letterSpacing={'-0.01188rem'}
            overflow={'hidden'}
            whiteSpace={'nowrap'}
            textOverflow={'ellipsis'}
            maxWidth={'200px'} // Set a suitable max width
          >
            {ticket?.name}
          </Text>
          <Text
            className={'gordita400'}
            fontSize={'1.1875rem'}
            lineHeight={'110%'}
            letterSpacing={'-0.01188rem'}
          >
            {ticket?.quantity}u
          </Text>
          <Box
            display={'flex'}
            justifyContent={isMobile ? 'start' : 'flex-end'}
            alignItems={'center'}
            gap={isMobile ? '1' : '1.25rem'}
            flexWrap={'wrap'}
          >
            <Text
              className={'gordita400'}
              fontSize={'0.875rem'}
              lineHeight={'110%'}
              letterSpacing={'-0.01188rem'}
              whiteSpace={'nowrap'}
              textOverflow={'ellipsis'}
            >
              Remaining Quantity: {ticket?.ticketRemainingSupply || 0}
            </Text>
            <Text
              className={'gordita600'}
              fontSize={'1rem'}
              lineHeight={'110%'}
              letterSpacing={'-0.01188rem'}
            >
              {getSymbolFromCurrency(values.currency)}
              {ticket?.price}
            </Text>
          </Box>
        </Box>

        <Box
          display={'flex'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          gap={'0.94rem'}
          borderLeft={
            isMobile ? ' none' : '1px solid rgba(255, 255, 255, 0.10)'
          }
          paddingLeft={isMobile ? '0' : '1.56rem'}
        >
          <Button
            sx={theme.buttons.secondary.shape}
            bg={theme.buttons.secondary.bgColor}
            color={theme.buttons.secondary.color}
            _hover={{
              borderColor: theme.buttons.secondary.hoverBorderColor,
            }}
            _active={{
              bg: theme.buttons.secondary.pressed,
            }}
            style={{
              width: '55px',
              height: '55px',
              borderRadius: '50%',
            }}
            border={'1px solid #FFFFFF33'}
            onClick={(e) => {
              e.stopPropagation(); // Prevent the click event from propagating
              // Implement the logic to remove the wave from the ticketWaves array
              const updatedTicketTypes = [...ticketTypes];
              updatedTicketTypes[ticketIndex].ticketWaves.splice(index, 1);
              if (updatedTicketTypes[ticketIndex].ticketWaves.length) {
                setTicketTypes(updatedTicketTypes);
                setFieldValue('ticketTypes', updatedTicketTypes); // Update formik values
              } else {
                updatedTicketTypes.splice(ticketIndex, 1);
                setTicketTypes(updatedTicketTypes);
                setFieldValue('ticketTypes', updatedTicketTypes);
              }
            }}
          >
            <img
              src={deleteIcon}
              alt={'delete-icon'}
              style={{ width: '44px', height: '44px', fontSize: '40px' }}
            />
          </Button>
          <Button
            sx={theme.buttons.secondary.shape}
            bg={theme.buttons.secondary.bgColor}
            color={theme.buttons.secondary.color}
            _hover={{
              borderColor: theme.buttons.secondary.hoverBorderColor,
            }}
            _active={{
              bg: theme.buttons.secondary.pressed,
            }}
            style={{
              width: '55px',
              height: '55px',
              borderRadius: '50%',
            }}
            border={'1px solid #FFFFFF33'}
            onClick={(e) => {
              e.stopPropagation();
              // Set edit mode and the index of the wave being edited
              setEditMode(true);
              setEditingTicketIndex(ticketIndex);
              setEditingWaveIndex(index);
              setSelectedTicketType({
                ...ticketTypes.filter(
                  (ticket) => ticket.ticketTypeName === 'default'
                )[0],
              });

              // Populate the form fields with the data of the wave being edited
              setFieldValue('ticketName', ticket.name);
              setFieldValue('ticketDescription', ticket.description);
              setFieldValue('ticketQuantity', ticket.quantity);
              setFieldValue('ticketPrice', ticket.price);
              setFieldValue('ticketEntries', ticket.entries);
              setFieldValue('ticketPassword', ticket.password);
              setFieldValue('limitQuantity', ticket.limit);
              setFieldValue('instagram', ticket.instagram);
              setFieldValue('ticketStart', ticket.start);
              setFieldValue('ticketEnd', ticket.end);
              setShowAdvanced(false);

              // Open the modal
              onOpen3();
            }}
          >
            <img
              src={editIcon}
              alt={'edit-icon'}
              style={{ width: '44px', height: '44px', fontSize: '40px' }}
            />
          </Button>
        </Box>
      </Box>
    );
  };
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const createTicketWaves = () => {
    let newTicketType = {
      ticketTypeName: '',
      ticketWaves: [
        {
          name: '',
          description: '',
          quantity: '',
          price: '',
          ticketRemainingSupply: '',
          entries: '',
          password: '',
          limit: '',
          instagram: '',
          start: '',
          end: '',
        },
      ],
    };
    setTicketTypes([...ticketTypes, newTicketType]);
    setEditingTicketIndex(ticketTypes.length);
    setEditMode(false);
    setEditingWaveIndex(0);
    setFieldValue('ticketName', '');
    setFieldValue('ticketDescription', '');
    setFieldValue('ticketQuantity', '');
    setFieldValue('ticketPrice', '');
    setFieldValue('ticketEntries', '');
    setFieldValue('ticketPassword', '');
    setFieldValue('limitQuantity', '');
    setFieldValue('instagram', '');
    setFieldValue('ticketStart', '');
    setFieldValue('ticketEnd', '');
    setShowAdvanced(false);

    onOpen();
  };

  return (
    <Box w="100%">
      <Box
        w={'100%'}
        display={'flex'}
        alignItems={'start'}
        flexDirection={'column'}
        mb={'2.81rem'}
        // flexDir={isTablet ? "column" : "row"}
        // gap={isMobile ? 10 : 0}
      >
        <VStack mb={24}>
          <Box>
            <Text
              className="gordita600"
              fontSize={'1.3125rem'}
              textAlign="left"
              color="#FFFFFF"
              textTransform={'capitalize'}
              // width={"20%"}
            >
              Tickets
            </Text>
          </Box>
        </VStack>

        <Flex alignItems="center" marginRight={50}>
          <Box
            width={'12.375rem'}
            height={'3.4375rem'}
            borderRadius={'6.25rem'}
            border={'1px solid rgba(255, 255, 255, 0.20)'}
            padding={'0.31rem'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            pos={'relative'}
          >
            <Box
              width={'2.8125rem'}
              height={'2.8125rem'}
              bg={'rgba(255, 255, 255, 0.05)'}
              borderRadius={'50%'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <img
                src={dollarIcon}
                alt={'delete-icon'}
                style={{
                  width: '1.5rem',
                  height: '1.5rem',
                }}
              />
            </Box>
            <Select
              name="currency"
              id="currency"
              value={values.currency || 'USD'}
              style={{
                width: '100%',
                position: 'absolute',
              }}
              popupClassName="popup-currency-selector"
              className="currency-selector"
              onChange={(value) => setFieldValue('currency', value)}
              options={[
                {
                  value: 'USD',
                  label: 'USD',
                },
                {
                  value: 'EUR',
                  label: 'EUR',
                },
                {
                  value: 'GBP',
                  label: 'GBP',
                },
                {
                  value: 'CAD',
                  label: 'CAD',
                },
              ]}
            />
          </Box>
        </Flex>
      </Box>
      <VStack
        width={'100%'}
        display={'flex'}
        alignItems={'flex-start'}
        marginBottom={'3.13rem'}
      >
        <Box>
          <Text
            className="gordita600"
            fontSize={'1rem'}
            textAlign="left"
            w="100%"
            color="#FFFFFF"
            textTransform={'capitalize'}
          >
            Ticket Types
          </Text>
        </Box>

        {ticketTypes.filter((ticket) => ticket.ticketTypeName === 'default')
          ?.length > 0 ? (
          <Box
            w={'100%'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'start'}
            gap={'20px'}
            marginTop={0}
          >
            {ticketTypes
              .filter((ticket) => ticket.ticketTypeName === 'default')[0]
              .ticketWaves?.map((ticket, index) => (
                <Box
                  key={index}
                  border={'1px solid rgba(255, 255, 255, 0.10)'}
                  borderRadius={'1.25rem'}
                  cursor={'pointer'}
                  w={'100%'}
                  height={isMobile ? '100%' : '6.5625rem'}
                  p={'0.63rem'}
                >
                  {ticketItem(
                    ticket,
                    index,
                    ticketTypes.indexOf(
                      ticketTypes.filter(
                        (ticket) => ticket.ticketTypeName === 'default'
                      )[0]
                    )
                  )}
                </Box>
              ))}
          </Box>
        ) : (
          ``
        )}

        <Button
          disabled={waveCounter === 10}
          onClick={() => {
            const hasDefaultTicketType = ticketTypes.filter(
              (ticketType) => ticketType.ticketTypeName === 'default'
            );

            if (!!hasDefaultTicketType.length) {
              const index = ticketTypes.indexOf(hasDefaultTicketType[0]);
              const updatedTicketTypes = [...ticketTypes];
              const updatedTicketWaves = [
                ...updatedTicketTypes[index].ticketWaves,
                {
                  name: '',
                  description: '',
                  quantity: '',
                  price: '',
                  ticketRemainingSupply: '',
                  entries: '',
                  password: '',
                  limit: '',
                  instagram: '',
                  start: '',
                  end: '',
                },
              ];

              updatedTicketTypes[index].ticketWaves = updatedTicketWaves;
              setTicketTypes([...updatedTicketTypes]);
              setEditingTicketIndex(index);
              setEditingWaveIndex(updatedTicketWaves.length - 1);
            } else {
              let newTicketType = {
                ticketTypeName: 'default',
                ticketWaves: [
                  {
                    name: '',
                    description: '',
                    quantity: '',
                    price: '',
                    ticketRemainingSupply: '',
                    entries: '',
                    password: '',
                    limit: '',
                    instagram: '',
                    start: '',
                    end: '',
                  },
                ],
              };
              setTicketTypes([...ticketTypes, newTicketType]);
              setEditingTicketIndex(ticketTypes.length);
              setEditingWaveIndex(0);
            }

            setEditMode(false);
            setFieldValue('ticketName', '');
            setFieldValue('ticketDescription', '');
            setFieldValue('ticketQuantity', '');
            setFieldValue('ticketPrice', '');
            setFieldValue('ticketEntries', '');
            setFieldValue('ticketPassword', '');
            setFieldValue('limitQuantity', '');
            setFieldValue('instagram', '');
            setFieldValue('ticketStart', '');
            setFieldValue('ticketEnd', '');
            setShowAdvanced(false);

            onOpen3();
          }}
          sx={theme.buttons.secondary.shape}
          bg={theme.buttons.secondary.bgColor}
          color={theme.buttons.secondary.color}
          _hover={{
            borderColor: theme.buttons.secondary.hoverBorderColor,
          }}
          _active={{
            bg: theme.buttons.secondary.pressed,
          }}
          style={{
            width: '100%',
            marginTop: '1.56rem',
            textTransform: 'capitalize',
          }}
          className="gordita700"
        >
          Create a ticket Type
        </Button>
      </VStack>

      <VStack width={'100%'} display={'flex'} alignItems={'flex-start'}>
        <Box>
          <Text
            className="gordita600"
            fontSize={'1rem'}
            textAlign="left"
            w="100%"
            color="#FFFFFF"
            textTransform={'capitalize'}
          >
            Ticket Types with waves
            <span
              style={{
                marginLeft: '0.56rem',
                padding: '0.3125rem 0.625rem',
                borderRadius: '6.25rem',
                backgroundColor: '#fff',
                color: '#000',
                fontSize: '0.875rem',
                textTransform: 'capitalize',
                textAlign: 'center',
                lineHeight: '110%',
              }}
              className="gordita700"
            >
              New!
            </span>
          </Text>
        </Box>

        {ticketTypes.filter((ticket) => ticket.ticketTypeName !== 'default')
          ?.length > 0 ? (
          <Box
            w={'100%'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'start'}
            gap={'20px'}
            marginTop={'1.56rem !important'}
          >
            {ticketTypes
              .filter((ticket) => ticket.ticketTypeName !== 'default')
              .map((ticketType, index) => (
                <Box
                  key={index}
                  border={'1px solid rgba(255, 255, 255, 0.20)'}
                  borderRadius={'1.25rem'}
                  w={'100%'}
                  height={'auto'}
                  p={'1.56rem'}
                >
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    marginBottom={'1.44rem'}
                  >
                    <Text
                      className="gordita700"
                      fontSize={'1.1875rem'}
                      lineHeight={'110%'}
                      letterSpacing={'-0.01188rem'}
                      textTransform={'capitalize'}
                    >
                      {ticketType.ticketTypeName}
                    </Text>
                    <Box
                      display={'flex'}
                      justifyContent={'flex-end'}
                      alignItems={'center'}
                      gap={isMobile ? '5px' : '0.94rem'}
                    >
                      <Button
                        sx={theme.buttons.secondary.shape}
                        bg={theme.buttons.secondary.bgColor}
                        color={theme.buttons.secondary.color}
                        _hover={{
                          borderColor: theme.buttons.secondary.hoverBorderColor,
                        }}
                        _active={{
                          bg: theme.buttons.secondary.pressed,
                        }}
                        style={{
                          width: '55px',
                          height: '55px',
                          borderRadius: '50%',
                        }}
                        border={'1px solid #FFFFFF33'}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the click event from propagating
                          let index = ticketTypes.indexOf(ticketType);
                          const updatedTicketTypes = [...ticketTypes];
                          updatedTicketTypes.splice(index, 1);
                          setTicketTypes(updatedTicketTypes);
                          setFieldValue('ticketTypes', updatedTicketTypes); // Update formik values
                        }}
                      >
                        <img
                          src={deleteIcon}
                          alt={'delete-icon'}
                          style={{
                            width: '44px',
                            height: '44px',
                            fontSize: '40px',
                          }}
                        />
                      </Button>
                      <Button
                        sx={theme.buttons.secondary.shape}
                        bg={theme.buttons.secondary.bgColor}
                        color={theme.buttons.secondary.color}
                        _hover={{
                          borderColor: theme.buttons.secondary.hoverBorderColor,
                        }}
                        _active={{
                          bg: theme.buttons.secondary.pressed,
                        }}
                        style={{
                          width: '55px',
                          height: '55px',
                          borderRadius: '50%',
                        }}
                        border={'1px solid #FFFFFF33'}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the click event from propagating
                          let index = ticketTypes.indexOf(ticketType);

                          setEditingTicketIndex(index);
                          setEditingWaveIndex(0);
                          setSelectedTicketType({ ...ticketType });
                          setFieldValue(
                            'ticketTypeName',
                            ticketType.ticketTypeName
                          );

                          setFieldValue(
                            'ticketName',
                            ticketTypes[index]?.ticketWaves[0].name
                          );
                          setFieldValue(
                            'ticketDescription',
                            ticketTypes[index]?.ticketWaves[0].description
                          );
                          setFieldValue(
                            'ticketQuantity',
                            ticketTypes[index]?.ticketWaves[0].quantity
                          );
                          setFieldValue(
                            'ticketPrice',
                            ticketTypes[index]?.ticketWaves[0].price
                          );

                          setFieldValue(
                            'ticketEntries',
                            ticketTypes[index]?.ticketWaves[0].entries
                          );
                          setFieldValue(
                            'ticketPassword',
                            ticketTypes[index]?.ticketWaves[0].password
                          );
                          setFieldValue(
                            'limitQuantity',
                            ticketTypes[index]?.ticketWaves[0].limit
                          );
                          setFieldValue(
                            'instagram',
                            ticketTypes[index]?.ticketWaves[0].instagram
                          );
                          setFieldValue(
                            'ticketStart',
                            ticketTypes[index]?.ticketWaves[0].start
                          );
                          setFieldValue(
                            'ticketEnd',
                            ticketTypes[index]?.ticketWaves[0].end
                          );
                          setEditMode(true);
                          setShowAdvanced(false);

                          onOpen();
                        }}
                      >
                        <img
                          src={editIcon}
                          alt={'edit-icon'}
                          style={{
                            width: '44px',
                            height: '44px',
                            fontSize: '40px',
                          }}
                        />
                      </Button>
                    </Box>
                  </Box>
                  <Box display={'flex'} flexDir={'column'} gap={'0.62rem'}>
                    {ticketType.ticketWaves?.map((wave, index) =>
                      waveItem(wave, index)
                    )}
                  </Box>
                </Box>
              ))}
          </Box>
        ) : (
          ``
        )}

        <Button
          disabled={waveCounter === 10}
          onClick={() => {
            if (isTicketWaveDialogOpened) {
              createTicketWaves();
            } else {
              onOpenTicketWaveDialog();
              setIsTicketWaveDialogOpened(true);
            }
          }}
          sx={theme.buttons.secondary.shape}
          bg={theme.buttons.secondary.bgColor}
          color={theme.buttons.secondary.color}
          _hover={{
            borderColor: theme.buttons.secondary.hoverBorderColor,
          }}
          _active={{
            bg: theme.buttons.secondary.pressed,
          }}
          style={{
            width: '100%',
            marginTop: '1.56rem',
            textTransform: 'capitalize',
          }}
          className="gordita700"
        >
          Create a ticket Type with waves
        </Button>
        <Box>
          <Text
            marginTop={'1.56rem'}
            className="gordita400"
            fontSize={16}
            textAlign="left"
            w="100%"
            color="rgba(255, 255, 255, 0.60)"
          >
            Ticket waves refer to the same type of ticket offered at different
            prices. These follow a sequence based on availability, but you can
            override this by scheduling them according to specific dates.
          </Text>
        </Box>
      </VStack>

      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={function () {
          setEditMode(false);
          setEditingWaveIndex(null);
          // Clear form fields and close the modal
          setFieldValue('ticketName', '');
          setFieldValue('ticketDescription', '');
          setFieldValue('ticketQuantity', '');
          setFieldValue('ticketPrice', '');
          setFieldValue('ticketEntries', '');
          setFieldValue('ticketPassword', '');
          setFieldValue('limitQuantity', '');
          setFieldValue('instagram', '');
          setFieldValue('ticketStart', '');
          setFieldValue('ticketEnd', '');
          setShowAdvanced(false);

          onClose();
        }}
        size={'xl'}
      >
        <ModalOverlay />
        <ModalContent
          bg={'rgba(0, 0, 0, 0.50)'}
          // backdropFilter={"blur(150px)"}
          borderRadius={'1.5625rem'}
          border={'1px solid #333333'}
          boxShadow={'0px 0px 100px 150px rgba(0, 0, 0, 0.30)'}
          backdropFilter={'blur(75px)'}
          padding={'0.64rem 0'}
        >
          <ModalHeader>
            {editMode ? 'Update' : 'Create'} Ticket With Waves
          </ModalHeader>
          <ModalCloseButton
            top={'1.5rem'}
            right={'1.5rem'}
            onClick={cancelTicketModel}
          />
          <ModalBody>
            <>
              <Box mb={'1.87rem'}>
                <Text
                  fontSize={'1.125rem'}
                  className="gordita600"
                  textTransform={'capitalize'}
                  mb={'0.94rem'}
                >
                  Ticket Type name
                </Text>
                <InputBox
                  values={values}
                  handleBlur={handleBlur}
                  handleChange={(e) => {
                    const updatedTicketTypes = [...ticketTypes];
                    updatedTicketTypes[editingTicketIndex].ticketTypeName =
                      e.target.value;
                    setTicketTypes(updatedTicketTypes);
                    handleChange(e);
                    setErrorFields({
                      ...errorFields,
                      ticketTypeName: e.target.value ? false : true,
                    });
                  }}
                  placeholder={
                    'Only for internal use. Attendees won’t see this name.'
                  }
                  maxW="100%"
                  name="ticketTypeName"
                  error={errorFields?.ticketTypeName}
                />
              </Box>
              <Box>
                <Text
                  fontSize={'1.125rem'}
                  className="gordita600"
                  textTransform={'capitalize'}
                  mb={'0.94rem'}
                >
                  Ticket Waves
                </Text>
                {/* <Text fontWeight={"400"} fontSize={"1rem"} color={"#FFFFFF99"}>
                  Ticket waves refer to the same type of ticket offered at
                  different prices. These follow a sequence based on
                  availability, but you can override this by scheduling them
                  according to specific dates.
                </Text> */}
              </Box>

              <Box m={'0 0 1.25rem 0'}>
                <Accordion
                  allowToggle
                  index={editingWaveIndex}
                  defaultIndex={
                    editMode
                      ? []
                      : [
                          ticketTypes[editingTicketIndex]?.ticketWaves?.length -
                            1,
                        ]
                  }
                  maxHeight={'20rem'}
                  overflow={'scroll'}
                >
                  {ticketTypes[editingTicketIndex]?.ticketWaves?.map(
                    (wave, index) => (
                      <AccordionItem
                        key={index}
                        border={'1px solid rgba(255, 255, 255, 0.10)'}
                        borderRadius={
                          editingWaveIndex === index ? '1.5625rem' : '6.25rem'
                        }
                        mb={'1.25rem'}
                      >
                        {({ isExpanded }) => (
                          <>
                            <Box
                              display={'flex'}
                              justifyContent={'space-between'}
                              alignItems={'center'}
                            >
                              <Box
                                width={'100%'}
                                display={'flex'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                              >
                                <Box
                                  padding={'0.31rem'}
                                  as="span"
                                  flex={1}
                                  textAlign="left"
                                  display={'flex'}
                                  alignItems={'center'}
                                  gap={'0.62rem'}
                                >
                                  <Box
                                    width={'2.8125rem'}
                                    height={'2.8125rem'}
                                    bg="rgba(255, 255, 255, 0.05)"
                                    borderRadius={'50%'}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                  >
                                    {index + 1}
                                  </Box>
                                  <Text
                                    className="gordita700"
                                    fontSize={'1rem'}
                                    lineHeight={'150%'}
                                    letterSpacing={'-0.01rem'}
                                  >
                                    {[
                                      'First',
                                      'Second',
                                      'Third',
                                      'Fourth',
                                      'Fifth',
                                      'Sixth',
                                      'Seventh',
                                      'Eighth',
                                      'Ninth',
                                      'Tenth',
                                    ][index].concat(' Wave')}
                                  </Text>
                                </Box>
                                {shouldShowContent &&
                                  isExpanded &&
                                  (wave.ticketRemainingSupply === 0 ? (
                                    <Button
                                      size="sm"
                                      variant="outline"
                                      colorScheme="red"
                                      marginRight="10"
                                      onClick={(e) => {
                                        e.stopPropagation(); // Prevent the click event from propagating
                                        // Implement the logic to remove the wave from the ticketWaves array
                                        // updated;
                                      }}
                                    >
                                      Sold Out
                                    </Button>
                                  ) : (
                                    <Button
                                      size="sm"
                                      variant="outline"
                                      colorScheme="white"
                                      marginRight="10"
                                      onClick={(e) => {
                                        e.stopPropagation(); // Prevent the click event from propagating
                                        // Implement the logic to remove the wave from the ticketWaves array

                                        const updatedWave = {
                                          name: wave.name,
                                          description: wave.description,
                                          quantity: wave.quantity,
                                          price: wave.price,
                                          ticketRemainingSupply: 0,
                                          ticketId: wave.ticketId,
                                          entries: wave.entries,
                                          password: wave.password,
                                          limit: wave.limit,
                                          instagram: wave.instagram,
                                          start: wave.start,
                                          end: wave.end,
                                        };
                                        updateWave(updatedWave);
                                      }}
                                    >
                                      Mark as sold out
                                    </Button>
                                  ))}

                                {index > 0 && (
                                  <Button
                                    sx={theme.buttons.tercary.shape}
                                    bg={theme.buttons.tercary.bgColor}
                                    color={theme.buttons.tercary.color}
                                    _hover={{
                                      bg: theme.buttons.tercary.hoverBgColor,
                                    }}
                                    _active={{
                                      bg: theme.buttons.tercary.pressedBgColor,
                                    }}
                                    style={{
                                      width: 'fit-content',
                                      height: 'fit-content',
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevent the click event from propagating
                                      // Implement the logic to remove the wave from the ticketWaves array
                                      const updatedTicketTypes = [
                                        ...ticketTypes,
                                      ];
                                      updatedTicketTypes[
                                        editingTicketIndex
                                      ].ticketWaves.splice(index, 1);
                                      setTicketTypes(updatedTicketTypes);
                                    }}
                                  >
                                    <img
                                      src={deleteIcon}
                                      alt={'delete-icon'}
                                      style={{
                                        width: '1.5rem',
                                        height: '1.5rem',
                                        marginRight: '0.31rem',
                                      }}
                                    />
                                  </Button>
                                )}
                              </Box>

                              {ticketTypes[editingTicketIndex]?.ticketWaves
                                ?.length > 0 && (
                                <AccordionButton
                                  sx={theme.buttons.tercary.shape}
                                  bg={theme.buttons.tercary.bgColor}
                                  color={theme.buttons.tercary.color}
                                  _hover={{
                                    bg: theme.buttons.tercary.hoverBgColor,
                                  }}
                                  _active={{
                                    bg: theme.buttons.tercary.pressedBgColor,
                                  }}
                                  style={{
                                    width: 'fit-content',
                                    height: 'fit-content',
                                    display:
                                      isExpanded ||
                                      ticketTypes[editingTicketIndex]
                                        ?.ticketWaves?.length <= 0
                                        ? 'none'
                                        : 'unset',
                                    marginRight: '0.31rem',
                                  }}
                                  onClick={() => {
                                    setEditingWaveIndex(
                                      isExpanded ? null : index
                                    );
                                    setFieldValue(
                                      'ticketName',
                                      ticketTypes[editingTicketIndex]
                                        ?.ticketWaves[index].name
                                    );
                                    setFieldValue(
                                      'ticketDescription',
                                      ticketTypes[editingTicketIndex]
                                        ?.ticketWaves[index].description
                                    );
                                    setFieldValue(
                                      'ticketQuantity',
                                      ticketTypes[editingTicketIndex]
                                        ?.ticketWaves[index].quantity
                                    );
                                    setFieldValue(
                                      'ticketPrice',
                                      ticketTypes[editingTicketIndex]
                                        ?.ticketWaves[index].price
                                    );
                                    setFieldValue(
                                      'ticketEntries',
                                      ticketTypes[editingTicketIndex]
                                        ?.ticketWaves[index].entries
                                    );
                                    setFieldValue(
                                      'ticketPassword',
                                      ticketTypes[editingTicketIndex]
                                        ?.ticketWaves[index].password
                                    );
                                    setFieldValue(
                                      'limitQuantity',
                                      ticketTypes[editingTicketIndex]
                                        ?.ticketWaves[index].limit
                                    );
                                    setFieldValue(
                                      'instagram',
                                      ticketTypes[editingTicketIndex]
                                        ?.ticketWaves[index].instagram
                                    );
                                    setFieldValue(
                                      'ticketStart',
                                      ticketTypes[editingTicketIndex]
                                        ?.ticketWaves[index].start
                                    );
                                    setFieldValue(
                                      'ticketEnd',
                                      ticketTypes[editingTicketIndex]
                                        ?.ticketWaves[index].end
                                    );
                                    setShowAdvanced(false);
                                  }}
                                >
                                  <img
                                    src={editIcon}
                                    alt={'edit-icon'}
                                    style={{
                                      width: '1.5rem',
                                      height: '1.5rem',
                                    }}
                                  />
                                </AccordionButton>
                              )}
                            </Box>
                            <AccordionPanel p={'1rem 1rem 1rem 1rem'}>
                              <VStack marginBottom={'1.25rem'} w="100%">
                                <InputBox
                                  values={values}
                                  handleBlur={handleBlur}
                                  handleChange={(e) => {
                                    // Update the existing wave with the edited data
                                    const updatedWave = {
                                      name: e.target.value,
                                      description: wave.description,
                                      quantity: wave.quantity,
                                      price: wave.price,
                                      ticketRemainingSupply:
                                        wave.ticketRemainingSupply,
                                      ticketId: wave.ticketId,
                                      entries: wave.entries,
                                      password: wave.password,
                                      limit: wave.limit,
                                      instagram: wave.instagram,
                                      start: wave.start,
                                      end: wave.end,
                                    };
                                    updateWave(updatedWave);
                                    handleChange(e);
                                    setErrorFields({
                                      ...errorFields,
                                      ticketName: e.target.value ? false : true,
                                    });
                                  }}
                                  placeholder={'Name'}
                                  maxW="100%"
                                  name="ticketName"
                                  error={errorFields?.ticketName}
                                />
                              </VStack>
                              <InputBox
                                values={values}
                                handleBlur={handleBlur}
                                handleChange={(e) => {
                                  // Update the existing wave with the edited data
                                  const updatedWave = {
                                    name: wave.name,
                                    description: e.target.value,
                                    quantity: wave.quantity,
                                    price: wave.price,
                                    ticketRemainingSupply:
                                      wave.ticketRemainingSupply,
                                    ticketId: wave.ticketId,
                                    entries: wave.entries,
                                    password: wave.password,
                                    limit: wave.limit,
                                    instagram: wave.instagram,
                                    start: wave.start,
                                    end: wave.end,
                                  };

                                  updateWave(updatedWave);
                                  handleChange(e);
                                  setErrorFields({
                                    ...errorFields,
                                    ticketDescription: e.target.value
                                      ? false
                                      : true,
                                  });
                                }}
                                placeholder={'Description'}
                                maxW="100%"
                                name="ticketDescription"
                                error={errorFields?.ticketDescription}
                              />
                              <HStack mt={20} spacing={19} w="100%">
                                <InputBox
                                  values={values}
                                  handleBlur={handleBlur}
                                  handleChange={(e) => {
                                    // Update the existing wave with the edited data
                                    const updatedWave = {
                                      name: wave.name,
                                      description: wave.description,
                                      quantity: e.target.value,
                                      price: wave.price,
                                      ticketRemainingSupply:
                                        wave.ticketRemainingSupply +
                                        (Number(e.target.value) -
                                          wave.quantity),
                                      ticketId: wave.ticketId,
                                      entries: wave.entries,
                                      password: wave.password,
                                      limit: wave.limit,
                                      instagram: wave.instagram,
                                      start: wave.start,
                                      end: wave.end,
                                    };

                                    updateWave(updatedWave);
                                    handleChange(e);
                                    setErrorFields({
                                      ...errorFields,
                                      ticketQuantity: e.target.value
                                        ? false
                                        : true,
                                    });
                                  }}
                                  placeholder={'Quantity'}
                                  maxW="100%"
                                  name="ticketQuantity"
                                  type="number"
                                  error={errorFields?.ticketQuantity}
                                />
                                <InputBox
                                  values={values}
                                  handleBlur={handleBlur}
                                  handleChange={(e) => {
                                    // Update the existing wave with the edited data
                                    const updatedWave = {
                                      name: wave.name,
                                      description: wave.description,
                                      quantity: wave.quantity,
                                      price: e.target.value,
                                      ticketRemainingSupply:
                                        wave.ticketRemainingSupply,
                                      ticketId: wave.ticketId,
                                      entries: wave.entries,
                                      password: wave.password,
                                      limit: wave.limit,
                                      instagram: wave.instagram,
                                      start: wave.start,
                                      end: wave.end,
                                    };

                                    updateWave(updatedWave);
                                    handleChange(e);
                                    setErrorFields({
                                      ...errorFields,
                                      ticketPrice: e.target.value
                                        ? false
                                        : true,
                                    });
                                  }}
                                  placeholder={'Price'}
                                  maxW="100%"
                                  name="ticketPrice"
                                  type="number"
                                  error={errorFields?.ticketPrice}
                                />
                              </HStack>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'start',
                                  gap: '10px',
                                  marginTop: '10px',
                                }}
                              >
                                <input
                                  type="checkbox"
                                  id="showAdvanced"
                                  name="showAdvanced"
                                  onChange={(e) => {
                                    setShowAdvanced(e.target.checked);
                                  }}
                                  style={{
                                    cursor: 'pointer',
                                    marginTop: '2px',
                                    height: ' 1rem',
                                    color: '#FFFFFF',
                                  }}
                                />
                                <label
                                  htmlFor="showAdvanced"
                                  style={{
                                    color: '#FFFFFF',
                                    fontSize: '16px',
                                  }}
                                >
                                  Show Advanced Options
                                </label>
                              </div>
                              {showAdvanced && (
                                <>
                                  <HStack mt={20} spacing={19} width="100%">
                                    <Box w={'100%'}>
                                      <DatePicker
                                        selected={
                                          wave.start
                                            ? new Date(wave.start)
                                            : null
                                        }
                                        //selected={wave.start ? moment(wave.start, 'DD-MM-YYYY') : moment()}
                                        onChange={(date) => {
                                          // Update the existing wave with the edited data
                                          const updatedWave = {
                                            name: wave.name,
                                            description: wave.description,
                                            quantity: wave.quantity,
                                            price: wave.price,
                                            ticketRemainingSupply:
                                              wave.ticketRemainingSupply,
                                            ticketId: wave.ticketId,
                                            entries: wave.entries,
                                            password: wave.password,
                                            limit: wave.limit,
                                            instagram: wave.instagram,
                                            start: date, // Use the 'date' parameter directly
                                            end: wave.end,
                                          };
                                          updateWave(updatedWave);
                                          setErrorFields({
                                            ...errorFields,
                                            ticketStart: date ? false : true, // Check if 'date' is truthy to determine if the field is filled
                                          });
                                        }}
                                        showTimeSelect
                                        dateFormat="Pp"
                                        maxW="100%"
                                        placeholderText="Start Selling On (optional)"
                                        className="custom-date-picker"
                                      />
                                    </Box>
                                    <Box w={'100%'}>
                                      <DatePicker
                                        selected={
                                          wave.end ? new Date(wave.end) : null
                                        }
                                        onChange={(date) => {
                                          // Update the existing wave with the edited data
                                          const updatedWave = {
                                            name: wave.name,
                                            description: wave.description,
                                            quantity: wave.quantity,
                                            price: wave.price,
                                            ticketRemainingSupply:
                                              wave.ticketRemainingSupply,
                                            ticketId: wave.ticketId,
                                            entries: wave.entries,
                                            password: wave.password,
                                            limit: wave.limit,
                                            instagram: wave.instagram,
                                            start: wave.start, // Use the 'date' parameter directly
                                            end: date,
                                          };
                                          updateWave(updatedWave);
                                          setErrorFields({
                                            ...errorFields,
                                            ticketEnd: date ? false : true, // Check if 'date' is truthy to determine if the field is filled
                                          });
                                        }}
                                        showTimeSelect
                                        dateFormat="Pp"
                                        maxW="100%"
                                        placeholderText="Stop Selling On (optional)"
                                        className="custom-date-picker"
                                      />
                                    </Box>
                                  </HStack>
                                  <HStack mt={20} spacing={19} w="100%">
                                    <InputBox
                                      values={values}
                                      handleBlur={handleBlur}
                                      handleChange={(e) => {
                                        // Update the existing wave with the edited data
                                        const updatedWave = {
                                          name: wave.name,
                                          description: wave.description,
                                          quantity: wave.quantity,
                                          price: wave.price,
                                          ticketRemainingSupply:
                                            wave.ticketRemainingSupply,
                                          ticketId: wave.ticketId,
                                          entries: e.target.value,
                                          password: wave.password,
                                          limit: wave.limit,
                                          instagram: wave.instagram,
                                          start: wave.start,
                                          end: wave.end,
                                        };

                                        updateWave(updatedWave);
                                        handleChange(e);
                                        setErrorFields({
                                          ...errorFields,
                                          ticketEntries: e.target.value
                                            ? false
                                            : true,
                                        });
                                      }}
                                      placeholder={
                                        'Number of Entries for Group Tickets (default 1)'
                                      }
                                      maxW="100%"
                                      name="ticketEntries"
                                      type="number"
                                    />
                                  </HStack>
                                  <HStack mt={20} spacing={19} w="100%">
                                    <InputBox
                                      values={values}
                                      handleBlur={handleBlur}
                                      handleChange={(e) => {
                                        // Update the existing wave with the edited data
                                        const updatedWave = {
                                          name: wave.name,
                                          description: wave.description,
                                          quantity: wave.quantity,
                                          price: wave.price,
                                          ticketRemainingSupply:
                                            wave.ticketRemainingSupply,
                                          ticketId: wave.ticketId,
                                          entries: wave.entries,
                                          password: wave.password,
                                          limit: e.target.value,
                                          instagram: wave.instagram,
                                          start: wave.start,
                                          end: wave.end,
                                        };

                                        updateWave(updatedWave);
                                        handleChange(e);
                                        setErrorFields({
                                          ...errorFields,
                                          limitQuantity: e.target.value
                                            ? false
                                            : true,
                                        });
                                      }}
                                      placeholder={
                                        'Limit purchase quantity (default is 6, max is 10)'
                                      }
                                      maxW="100%"
                                      name="limitQuantity"
                                      type="number"
                                    />
                                  </HStack>
                                  <HStack mt={20} spacing={19} w="100%">
                                    <InputBox
                                      values={values}
                                      handleBlur={handleBlur}
                                      handleChange={(e) => {
                                        // Update the existing wave with the edited data
                                        const updatedWave = {
                                          name: wave.name,
                                          description: wave.description,
                                          quantity: wave.quantity,
                                          price: wave.price,
                                          ticketRemainingSupply:
                                            wave.ticketRemainingSupply,
                                          ticketId: wave.ticketId,
                                          entries: wave.entries,
                                          password: e.target.value,
                                          limit: wave.limit,
                                          instagram: wave.instagram,
                                          start: wave.start,
                                          end: wave.end,
                                        };

                                        updateWave(updatedWave);
                                        handleChange(e);
                                        setErrorFields({
                                          ...errorFields,
                                          ticketPassword: e.target.value
                                            ? false
                                            : true,
                                        });
                                      }}
                                      placeholder={
                                        "Add a password to restrict ticket purchases"
                                      }
                                      maxW="100%"
                                      name="ticketPassword"
                                      
                                    />
                                  </HStack>
                                  <HStack mt={20} spacing={19} w="100%">
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'start',
                                        gap: '10px',
                                        marginTop: '10px',
                                      }}
                                    >
                                      <input
                                        type="checkbox"
                                        id="instagram"
                                        name="instagram"
                                        checked={wave.instagram}
                                        onChange={(e) => {
                                          // Update the existing wave with the edited data
                                          const updatedWave = {
                                            name: wave.name,
                                            description: wave.description,
                                            quantity: wave.quantity,
                                            price: wave.price,
                                            ticketRemainingSupply:
                                              wave.ticketRemainingSupply,
                                            ticketId: wave.ticketId,
                                            entries: wave.entries,
                                            password: wave.password,
                                            limit: wave.limit,
                                            instagram: e.target.checked,
                                            start: wave.start,
                                            end: wave.end,
                                          };

                                          updateWave(updatedWave);
                                          handleChange(e);
                                          setErrorFields({
                                            ...errorFields,
                                            instagramQuantity: e.target.checked
                                              ? false
                                              : true,
                                          });
                                        }}
                                        style={{
                                          cursor: 'pointer',
                                          marginTop: '2px',
                                          height: ' 1rem',
                                          color: '#FFFFFF',
                                        }}
                                      />
                                      <label
                                        htmlFor="instagram"
                                        style={{
                                          color: '#FFFFFF',
                                          fontSize: '16px',
                                        }}
                                      >
                                        Ask For Attendee's Instagram
                                      </label>
                                    </div>
                                  </HStack>
                                </>
                              )}
                            </AccordionPanel>
                          </>
                        )}
                      </AccordionItem>
                    )
                  )}
                </Accordion>
              </Box>

              <Button
                sx={theme.buttons.secondary.shape}
                bg={theme.buttons.secondary.bgColor}
                color={theme.buttons.secondary.color}
                _hover={{
                  borderColor: theme.buttons.secondary.hoverBorderColor,
                }}
                _active={{
                  bg: theme.buttons.secondary.pressed,
                }}
                disabled={
                  ticketTypes[editingTicketIndex]?.ticketWaves.length >= 10 ||
                  !values.ticketName ||
                  //  !values.ticketDescription ||
                  !values.ticketPrice ||
                  !values.ticketQuantity
                    ? true
                    : false
                }
                style={{
                  width: '100%',
                  textTransform: 'capitalize',
                }}
                onClick={() => {
                  const {
                    ticketName,
                    ticketDescription,
                    ticketPrice,
                    ticketQuantity,
                    ticketEntries,
                    ticketPassword,
                    limitQuantity,
                    instagram,
                    ticketStart,
                    ticketEnd,
                  } = values;
                  if (
                    !ticketName &&
                    !ticketDescription &&
                    !ticketPrice &&
                    !ticketQuantity &&
                    !ticketEntries &&
                    !ticketPassword &&
                    !limitQuantity &&
                    !instagram &&
                    !ticketStart &&
                    !ticketEnd
                  )
                    return;
                  if (ticketTypes[editingTicketIndex]?.ticketWaves.length >= 10)
                    return;
                  addTicketWave(editingTicketIndex, '', '', '', '');
                  setEditingWaveIndex(
                    ticketTypes[editingTicketIndex]?.ticketWaves.length - 1
                  );
                  setFieldValue('ticketName', '');
                  setFieldValue('ticketDescription', '');
                  setFieldValue('ticketQuantity', '');
                  setFieldValue('ticketPrice', '');
                  setFieldValue('ticketEntries', '');
                  setFieldValue('ticketPassword', '');
                  setFieldValue('limitQuantity', '');
                  setFieldValue('instagram', '');
                  setFieldValue('ticketStart', '');
                  setFieldValue('ticketEnd', '');
                  setShowAdvanced(false);
                }}
              >
                Create Another Ticket Wave
              </Button>

              <div
                id="error-message"
                style={{ color: 'red', marginTop: '10px' }}
              >
                {/* Error message will be displayed here */}
              </div>
            </>
          </ModalBody>
          <ModalFooter paddingTop={0}>
            <Button
              mr={3}
              onClick={cancelTicketModel}
              sx={theme.buttons.tercary.shape}
              bg={theme.buttons.tercary.bgColor}
              color={theme.buttons.tercary.color}
              _hover={{
                bg: theme.buttons.tercary.hoverBgColor,
              }}
              _active={{
                bg: theme.buttons.tercary.pressedBgColor,
              }}
              style={{
                width: 'fit-content',
                minWidth: '6.25rem',
                padding: '0rem 1.25rem',
              }}
              className="gordita700"
            >
              Cancel
            </Button>
            <Button
              sx={theme.buttons.primary.shape}
              bg={theme.buttons.primary.default}
              color={theme.buttons.primary.color}
              _hover={{
                bg: theme.buttons.primary.hover,
                boxShadow: theme.buttons.primary.hoverBoxShadow,
              }}
              _active={{
                boxShadow: theme.buttons.primary.activeBoxShadow,
              }}
              onClick={() => {
                const {
                  ticketName,
                  ticketDescription,
                  ticketQuantity,
                  ticketPrice,
                  ticketTypeName,
                  ticketEntries,
                  ticketPassword,
                  limitQuantity,
                  instagram,
                } = values;

                // Check if any of the required fields is empty
                if (
                  !ticketTypeName ||
                  !ticketName ||
                  !ticketQuantity ||
                  ticketPrice === null ||
                  ticketPrice === undefined ||
                  ticketTypeName === 'default'
                ) {
                  setErrorFields({
                    ticketTypeName:
                      ticketTypeName && ticketTypeName !== 'default'
                        ? false
                        : true,
                    ticketName: ticketName ? false : true,
                    ticketQuantity: ticketQuantity ? false : true,
                    ticketPrice: ticketPrice ? false : true,

                    // ticketDescription: ticketDescription ? false : true,
                  });
                  // Set the error message
                  var errorMessage;
                  if (ticketTypeName === 'default') {
                    errorMessage =
                      'Please use another name for the ticket type name';
                  } else {
                    errorMessage = 'Please fill out all required fields';
                  }

                  document.getElementById('error-message').textContent =
                    errorMessage;
                  document.getElementById('error-message').style.color =
                    '#FF6B6B';
                  return; // Exit the function to prevent creating a ticket wave
                }
                setErrorFields({
                  ticketTypeName: false,
                  ticketName: false,
                  ticketQuantity: false,
                  ticketPrice: false,
                  ticketDescription: false,
                });

                setFieldValue('ticketTypes', [...ticketTypes]);
                setEditMode(false);
                setEditingTicketIndex(null);
                setEditingWaveIndex(null);

                // Clear form fields and close the modal
                setFieldValue('ticketTypeName', '');
                setFieldValue('ticketName', '');
                setFieldValue('ticketDescription', '');
                setFieldValue('ticketQuantity', '');
                setFieldValue('ticketEntries', '');
                setFieldValue('ticketPassword', '');
                setFieldValue('limitQuantity', '');
                setFieldValue('instagram', '');
                setFieldValue('ticketPrice', '');
                setFieldValue('ticketStart', '');
                setFieldValue('ticketEnd', '');
                setShowAdvanced(false);

                onClose();
              }}
              style={{ width: 'fit-content', textTransform: 'capitalize' }}
              className="gordita700"
            >
              {editMode ? 'Update' : 'Create'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isOpen3}
        onClose={() => {
          const updatedTicketTypes = [...ticketTypes];

          if (editMode) {
            onClose3();
            setEditMode(false);
          } else {
            // Check if 'default' ticket type exists
            const defaultTicketType = updatedTicketTypes.find(
              (type) => type.ticketTypeName === 'default'
            );

            if (defaultTicketType) {
              // Check if there are waves to remove
              if (defaultTicketType.ticketWaves.length > 0) {
                defaultTicketType.ticketWaves.pop(); // Remove the latest wave
              } else {
                // If no waves are left, remove the entire 'default' ticket type
                const defaultIndex =
                  updatedTicketTypes.indexOf(defaultTicketType);
                updatedTicketTypes.splice(defaultIndex, 1);
              }

              setTicketTypes(updatedTicketTypes);
              setFieldValue('ticketTypes', updatedTicketTypes);
            }

            onClose3();
          }
        }}
        size={'xl'}
      >
        <ModalOverlay />
        <ModalContent
          bg={'rgba(0, 0, 0, 0.50)'}
          // backdropFilter={"blur(150px)"}
          borderRadius={'1.5625rem'}
          border={'1px solid #333333'}
          boxShadow={'0px 0px 100px 150px rgba(0, 0, 0, 0.30)'}
          backdropFilter={'blur(75px)'}
          padding={'0.64rem 0'}
        >
          <ModalHeader textTransform={'capitalize'}>
            {editMode ? 'Update' : 'Create'} A Ticket type
          </ModalHeader>
          <ModalCloseButton
            top={'1.5rem'}
            right={'1.5rem'}
            onClick={cancelTicketModel}
          />
          <ModalBody>
            <>
              <Box>
                <Text
                  className={'gordita400'}
                  fontSize={'1rem'}
                  color={'rgba(255, 255, 255, 0.60)'}
                  mb={'1.56rem'}
                >
                  Ticket types are available from them moment you publish the
                  event till they are sold out. If you want to release ticket
                  waves with different prices go back and click on the{' '}
                  <span className="gordita700">
                    ‘Create a ticket Type with waves’
                  </span>{' '}
                  button.
                </Text>
              </Box>
              <Box marginBottom={'10px'}>
                {shouldShowContent &&
                  (ticketTypes[editingTicketIndex]?.ticketWaves[
                    editingWaveIndex
                  ]?.ticketRemainingSupply === 0 ? (
                    <Button
                      size="sm"
                      variant="outline"
                      colorScheme="red"
                      marginRight="10"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent the click event from propagating
                        // Implement the logic to remove the wave from the ticketWaves array
                        // updated;
                      }}
                    >
                      Sold Out
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      variant="outline"
                      colorScheme="white"
                      marginRight="10"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent the click event from propagating
                        // Implement the logic to remove the wave from the ticketWaves array

                        const updatedWave = {
                          name: ticketTypes[editingTicketIndex]?.ticketWaves[
                            editingWaveIndex
                          ].name,
                          description:
                            ticketTypes[editingTicketIndex]?.ticketWaves[
                              editingWaveIndex
                            ].description,
                          quantity:
                            ticketTypes[editingTicketIndex]?.ticketWaves[
                              editingWaveIndex
                            ].quantity,
                          price:
                            ticketTypes[editingTicketIndex]?.ticketWaves[
                              editingWaveIndex
                            ].price,
                          ticketRemainingSupply: 0,
                          entries:
                            ticketTypes[editingTicketIndex]?.ticketWaves[
                              editingWaveIndex
                            ].entries,
                          password:
                            ticketTypes[editingTicketIndex]?.ticketWaves[
                              editingWaveIndex
                            ].password,
                          limit:
                            ticketTypes[editingTicketIndex]?.ticketWaves[
                              editingWaveIndex
                            ].limit,
                          instagram:
                            ticketTypes[editingTicketIndex]?.ticketWaves[
                              editingWaveIndex
                            ].instagram,
                          ticketId:
                            ticketTypes[editingTicketIndex]?.ticketWaves[
                              editingWaveIndex
                            ].ticketId,
                        };
                        updateWave(updatedWave);
                      }}
                    >
                      Mark as sold out
                    </Button>
                  ))}
              </Box>

              <Box m={'0 0 1.25rem 0'}>
                <VStack marginBottom={'1.25rem'} w="100%">
                  <InputBox
                    values={values}
                    handleBlur={handleBlur}
                    handleChange={(e) => {
                      // Update the existing wave with the edited data
                      const updatedWave = {
                        name: e.target.value,
                        description:
                          ticketTypes[editingTicketIndex].ticketWaves[
                            editingWaveIndex
                          ].description,
                        quantity:
                          ticketTypes[editingTicketIndex].ticketWaves[
                            editingWaveIndex
                          ].quantity,
                        price:
                          ticketTypes[editingTicketIndex].ticketWaves[
                            editingWaveIndex
                          ].price,
                        ticketRemainingSupply:
                          ticketTypes[editingTicketIndex].ticketWaves[
                            editingWaveIndex
                          ].ticketRemainingSupply,
                        entries:
                          ticketTypes[editingTicketIndex].ticketWaves[
                            editingWaveIndex
                          ].entries,
                        password:
                          ticketTypes[editingTicketIndex].ticketWaves[
                            editingWaveIndex
                          ].password,
                        instagram:
                          ticketTypes[editingTicketIndex]?.ticketWaves[
                            editingWaveIndex
                          ].instagram,
                        ticketId:
                          ticketTypes[editingTicketIndex].ticketWaves[
                            editingWaveIndex
                          ].ticketId,
                      };
                      updateWave(updatedWave);
                      handleChange(e);
                      setErrorFields({
                        ...errorFields,
                        ticketName: e.target.value ? false : true,
                      });
                    }}
                    placeholder={'Name'}
                    maxW="100%"
                    name="ticketName"
                    error={errorFields?.ticketName}
                  />
                </VStack>
                <InputBox
                  values={values}
                  handleBlur={handleBlur}
                  handleChange={(e) => {
                    // Update the existing wave with the edited data
                    const updatedWave = {
                      name: ticketTypes[editingTicketIndex].ticketWaves[
                        editingWaveIndex
                      ].name,
                      description: e.target.value,
                      quantity:
                        ticketTypes[editingTicketIndex].ticketWaves[
                          editingWaveIndex
                        ].quantity,
                      price:
                        ticketTypes[editingTicketIndex].ticketWaves[
                          editingWaveIndex
                        ].price,
                      ticketRemainingSupply:
                        ticketTypes[editingTicketIndex].ticketWaves[
                          editingWaveIndex
                        ].ticketRemainingSupply,
                      ticketId:
                        ticketTypes[editingTicketIndex].ticketWaves[
                          editingWaveIndex
                        ].ticketId,
                      entries:
                        ticketTypes[editingTicketIndex].ticketWaves[
                          editingWaveIndex
                        ].entries,
                      password:
                        ticketTypes[editingTicketIndex].ticketWaves[
                          editingWaveIndex
                        ].password,
                      limit:
                        ticketTypes[editingTicketIndex]?.ticketWaves[
                          editingWaveIndex
                        ].limit,
                      instagram:
                        ticketTypes[editingTicketIndex]?.ticketWaves[
                          editingWaveIndex
                        ].instagram,
                    };

                    updateWave(updatedWave);
                    handleChange(e);
                    setErrorFields({
                      ...errorFields,
                      ticketDescription: e.target.value ? false : true,
                    });
                  }}
                  placeholder={'Description'}
                  maxW="100%"
                  name="ticketDescription"
                  error={errorFields?.ticketDescription}
                />
                <HStack mt={20} spacing={19} w="100%">
                  <InputBox
                    values={values}
                    handleBlur={handleBlur}
                    handleChange={(e) => {
                      // Update the existing wave with the edited data
                      const updatedWave = {
                        name: ticketTypes[editingTicketIndex].ticketWaves[
                          editingWaveIndex
                        ].name,
                        description:
                          ticketTypes[editingTicketIndex].ticketWaves[
                            editingWaveIndex
                          ].description,
                        quantity: e.target.value,
                        price:
                          ticketTypes[editingTicketIndex].ticketWaves[
                            editingWaveIndex
                          ].price,
                        ticketRemainingSupply:
                          Number(
                            ticketTypes[editingTicketIndex].ticketWaves[
                              editingWaveIndex
                            ].ticketRemainingSupply
                          ) +
                          Number(e.target.value) -
                          Number(
                            ticketTypes[editingTicketIndex].ticketWaves[
                              editingWaveIndex
                            ].quantity
                          ),
                        ticketId:
                          ticketTypes[editingTicketIndex].ticketWaves[
                            editingWaveIndex
                          ].ticketId,

                        entries:
                          ticketTypes[editingTicketIndex].ticketWaves[
                            editingWaveIndex
                          ].entries,

                        password:
                          ticketTypes[editingTicketIndex].ticketWaves[
                            editingWaveIndex
                          ].password,
                        limit:
                          ticketTypes[editingTicketIndex]?.ticketWaves[
                            editingWaveIndex
                          ].limit,
                        instagram:
                          ticketTypes[editingTicketIndex]?.ticketWaves[
                            editingWaveIndex
                          ].instagram,
                      };

                      updateWave(updatedWave);
                      handleChange(e);
                      setErrorFields({
                        ...errorFields,
                        ticketQuantity: e.target.value ? false : true,
                      });
                    }}
                    placeholder={'Quantity'}
                    maxW="100%"
                    name="ticketQuantity"
                    type="number"
                    error={errorFields?.ticketQuantity}
                  />
                  <InputBox
                    values={values}
                    handleBlur={handleBlur}
                    handleChange={(e) => {
                      // Update the existing wave with the edited data
                      const updatedWave = {
                        name: ticketTypes[editingTicketIndex].ticketWaves[
                          editingWaveIndex
                        ].name,
                        description:
                          ticketTypes[editingTicketIndex].ticketWaves[
                            editingWaveIndex
                          ].description,
                        quantity:
                          ticketTypes[editingTicketIndex].ticketWaves[
                            editingWaveIndex
                          ].quantity,
                        price: e.target.value,
                        ticketRemainingSupply:
                          ticketTypes[editingTicketIndex].ticketWaves[
                            editingWaveIndex
                          ].ticketRemainingSupply,
                        ticketId:
                          ticketTypes[editingTicketIndex].ticketWaves[
                            editingWaveIndex
                          ].ticketId,
                        entries:
                          ticketTypes[editingTicketIndex].ticketWaves[
                            editingWaveIndex
                          ].entries,
                        password:
                          ticketTypes[editingTicketIndex].ticketWaves[
                            editingWaveIndex
                          ].password,
                        limit:
                          ticketTypes[editingTicketIndex]?.ticketWaves[
                            editingWaveIndex
                          ].limit,
                        instagram:
                          ticketTypes[editingTicketIndex]?.ticketWaves[
                            editingWaveIndex
                          ].instagram,
                      };

                      updateWave(updatedWave);
                      handleChange(e);
                      setErrorFields({
                        ...errorFields,
                        ticketPrice: e.target.value ? false : true,
                      });
                    }}
                    placeholder={'Price'}
                    maxW="100%"
                    name="ticketPrice"
                    type="number"
                    error={errorFields?.ticketPrice}
                  />
                </HStack>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'start',
                    gap: '10px',
                    marginTop: '10px',
                  }}
                >
                  <input
                    type="checkbox"
                    id="showAdvanced"
                    name="showAdvanced"
                    onChange={(e) => {
                      setShowAdvanced(e.target.checked);
                    }}
                    style={{
                      cursor: 'pointer',
                      marginTop: '2px',
                      height: ' 1rem',
                      color: '#FFFFFF',
                    }}
                  />
                  <label
                    htmlFor="showAdvanced"
                    style={{
                      color: '#FFFFFF',
                      fontSize: '16px',
                    }}
                  >
                    Show Advanced Options
                  </label>
                </div>
                {showAdvanced && (
                  <>
                    <HStack mt={20} spacing={19} w="100%">
                      <InputBox
                        values={values}
                        handleBlur={handleBlur}
                        handleChange={(e) => {
                          // Update the existing wave with the edited data
                          const updatedWave = {
                            name: ticketTypes[editingTicketIndex].ticketWaves[
                              editingWaveIndex
                            ].name,
                            description:
                              ticketTypes[editingTicketIndex].ticketWaves[
                                editingWaveIndex
                              ].description,
                            quantity:
                              ticketTypes[editingTicketIndex].ticketWaves[
                                editingWaveIndex
                              ].quantity,
                            price:
                              ticketTypes[editingTicketIndex].ticketWaves[
                                editingWaveIndex
                              ].price,
                            ticketRemainingSupply:
                              ticketTypes[editingTicketIndex].ticketWaves[
                                editingWaveIndex
                              ].ticketRemainingSupply,
                            ticketId:
                              ticketTypes[editingTicketIndex].ticketWaves[
                                editingWaveIndex
                              ].ticketId,
                            entries: e.target.value,
                            password:
                              ticketTypes[editingTicketIndex].ticketWaves[
                                editingWaveIndex
                              ].password,
                            limit:
                              ticketTypes[editingTicketIndex]?.ticketWaves[
                                editingWaveIndex
                              ].limit,
                            instagram:
                              ticketTypes[editingTicketIndex]?.ticketWaves[
                                editingWaveIndex
                              ].instagram,
                          };

                          updateWave(updatedWave);
                          handleChange(e);
                        }}
                        placeholder={
                          'Number of Entries for Group Tickets (default 1)'
                        }
                        maxW="100%"
                        name="ticketEntries"
                        type="number"
                      />
                    </HStack>

                    <HStack mt={20} spacing={19} w="100%">
                      <InputBox
                        values={values}
                        handleBlur={handleBlur}
                        handleChange={(e) => {
                          // Update the existing wave with the edited data
                          const updatedWave = {
                            name: ticketTypes[editingTicketIndex].ticketWaves[
                              editingWaveIndex
                            ].name,
                            description:
                              ticketTypes[editingTicketIndex].ticketWaves[
                                editingWaveIndex
                              ].description,
                            quantity:
                              ticketTypes[editingTicketIndex].ticketWaves[
                                editingWaveIndex
                              ].quantity,
                            price:
                              ticketTypes[editingTicketIndex].ticketWaves[
                                editingWaveIndex
                              ].price,
                            ticketRemainingSupply:
                              ticketTypes[editingTicketIndex].ticketWaves[
                                editingWaveIndex
                              ].ticketRemainingSupply,
                            ticketId:
                              ticketTypes[editingTicketIndex].ticketWaves[
                                editingWaveIndex
                              ].ticketId,
                            entries:
                              ticketTypes[editingTicketIndex]?.ticketWaves[
                                editingWaveIndex
                              ].entries,
                            password:
                              ticketTypes[editingTicketIndex].ticketWaves[
                                editingWaveIndex
                              ].password,
                            limit: e.target.value,
                            instagram:
                              ticketTypes[editingTicketIndex]?.ticketWaves[
                                editingWaveIndex
                              ].instagram,
                          };

                          updateWave(updatedWave);
                          handleChange(e);
                        }}
                        placeholder={
                          'Limit purchase quantity (default is 6, max is 10)'
                        }
                        maxW="100%"
                        name="limitQuantity"
                        type="number"
                      />
                    </HStack>
                    <HStack mt={20} spacing={19} w="100%">
                      <InputBox
                        values={values}
                        handleBlur={handleBlur}
                        handleChange={(e) => {
                          // Update the existing wave with the edited data
                          const updatedWave = {
                            name: ticketTypes[editingTicketIndex].ticketWaves[
                              editingWaveIndex
                            ].name,
                            description:
                              ticketTypes[editingTicketIndex].ticketWaves[
                                editingWaveIndex
                              ].description,
                            quantity:
                              ticketTypes[editingTicketIndex].ticketWaves[
                                editingWaveIndex
                              ].quantity,
                            price:
                              ticketTypes[editingTicketIndex].ticketWaves[
                                editingWaveIndex
                              ].price,
                            ticketRemainingSupply:
                              ticketTypes[editingTicketIndex].ticketWaves[
                                editingWaveIndex
                              ].ticketRemainingSupply,
                            ticketId:
                              ticketTypes[editingTicketIndex].ticketWaves[
                                editingWaveIndex
                              ].ticketId,
                            entries: 
                              ticketTypes[editingTicketIndex]?.ticketWaves[
                                editingWaveIndex
                              ].entries,
                            password: e.target.value,
                            limit:
                              ticketTypes[editingTicketIndex]?.ticketWaves[
                                editingWaveIndex
                              ].limit,
                            instagram:
                              ticketTypes[editingTicketIndex]?.ticketWaves[
                                editingWaveIndex
                              ].instagram,
                          };

                          updateWave(updatedWave);
                          handleChange(e);
                        }}
                        placeholder={
                          'Add a password to restrict ticket purchases'
                        }
                        maxW="100%"
                        name="ticketPassword"
                       
                      />
                    </HStack>
                    <HStack mt={20} spacing={19} w="100%">
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'start',
                          gap: '10px',
                          marginTop: '10px',
                        }}
                      >
                        <input
                          type="checkbox"
                          id="instagram"
                          name="instagram"
                          checked={
                            ticketTypes[editingTicketIndex]?.ticketWaves[
                              editingWaveIndex
                            ].instagram
                          }
                          onChange={(e) => {
                            // Update the existing wave with the edited data
                            const updatedWave = {
                              name: ticketTypes[editingTicketIndex].ticketWaves[
                                editingWaveIndex
                              ].name,
                              description:
                                ticketTypes[editingTicketIndex].ticketWaves[
                                  editingWaveIndex
                                ].description,
                              quantity:
                                ticketTypes[editingTicketIndex].ticketWaves[
                                  editingWaveIndex
                                ].quantity,
                              price:
                                ticketTypes[editingTicketIndex].ticketWaves[
                                  editingWaveIndex
                                ].price,
                              ticketRemainingSupply:
                                ticketTypes[editingTicketIndex].ticketWaves[
                                  editingWaveIndex
                                ].ticketRemainingSupply,
                              ticketId:
                                ticketTypes[editingTicketIndex].ticketWaves[
                                  editingWaveIndex
                                ].ticketId,
                              entries:
                                ticketTypes[editingTicketIndex]?.ticketWaves[
                                  editingWaveIndex
                                ].entries,
                              password:
                                ticketTypes[editingTicketIndex].ticketWaves[
                                  editingWaveIndex
                                ].password,
                              limit:
                                ticketTypes[editingTicketIndex]?.ticketWaves[
                                  editingWaveIndex
                                ].limit,
                              instagram: e.target.checked,
                            };
                            updateWave(updatedWave);
                            handleChange(e);
                          }}
                          style={{
                            cursor: 'pointer',
                            marginTop: '2px',
                            height: ' 1rem',
                            color: '#FFFFFF',
                          }}
                        />
                        <label
                          htmlFor="instagram"
                          style={{
                            color: '#FFFFFF',
                            fontSize: '16px',
                          }}
                        >
                          Ask For Attendee's Instagram
                        </label>
                      </div>
                    </HStack>
                  </>
                )}
              </Box>

              <div
                id="error-message"
                style={{ color: 'red', marginTop: '10px' }}
              >
                {/* Error message will be displayed here */}
              </div>
            </>
          </ModalBody>
          <ModalFooter paddingTop={0}>
            <Button
              mr={3}
              onClick={() => {
                const updatedTicketTypes = [...ticketTypes];

                if (editMode) {
                  onClose3();
                  setEditMode(false);
                } else {
                  // Check if 'default' ticket type exists
                  const defaultTicketType = updatedTicketTypes.find(
                    (type) => type.ticketTypeName === 'default'
                  );

                  if (defaultTicketType) {
                    // Check if there are waves to remove
                    if (defaultTicketType.ticketWaves.length > 0) {
                      defaultTicketType.ticketWaves.pop(); // Remove the latest wave
                    } else {
                      // If no waves are left, remove the entire 'default' ticket type
                      const defaultIndex =
                        updatedTicketTypes.indexOf(defaultTicketType);
                      updatedTicketTypes.splice(defaultIndex, 1);
                    }

                    setTicketTypes(updatedTicketTypes);
                    setFieldValue('ticketTypes', updatedTicketTypes);
                  }

                  onClose3();
                }
              }}
              sx={theme.buttons.tercary.shape}
              bg={theme.buttons.tercary.bgColor}
              color={theme.buttons.tercary.color}
              _hover={{
                bg: theme.buttons.tercary.hoverBgColor,
              }}
              _active={{
                bg: theme.buttons.tercary.pressedBgColor,
              }}
              style={{
                width: 'fit-content',
                minWidth: '6.25rem',
                padding: '0rem 1.25rem',
              }}
              className="gordita700"
            >
              Cancel
            </Button>
            <Button
              sx={theme.buttons.primary.shape}
              bg={theme.buttons.primary.default}
              color={theme.buttons.primary.color}
              _hover={{
                bg: theme.buttons.primary.hover,
                boxShadow: theme.buttons.primary.hoverBoxShadow,
              }}
              _active={{
                boxShadow: theme.buttons.primary.activeBoxShadow,
              }}
              onClick={() => {
                const {
                  ticketName,
                  ticketDescription,
                  ticketQuantity,
                  ticketEntries,
                  ticketPassword,
                  limitQuantity,
                  instagram,
                  ticketPrice,
                } = values;

                // Check if any of the required fields is empty
                if (
                  !ticketName ||
                  !ticketQuantity ||
                  ticketPrice === null ||
                  ticketPrice === undefined
                ) {
                  setErrorFields({
                    ticketName: ticketName ? false : true,
                    ticketQuantity: ticketQuantity ? false : true,
                    ticketPrice: ticketPrice ? false : true,
                    // ticketDescription: ticketDescription ? false : true,
                  });
                  // Set the error message
                  const errorMessage = 'Please fill out all required fields';
                  document.getElementById('error-message').textContent =
                    errorMessage;
                  document.getElementById('error-message').style.color =
                    '#FF6B6B';
                  return; // Exit the function to prevent creating a ticket wave
                }
                setErrorFields({
                  ticketName: false,
                  ticketQuantity: false,
                  ticketPrice: false,
                  ticketDescription: false,
                });
                setFieldValue('ticketTypes', [...ticketTypes]);
                setEditMode(false);
                setEditingTicketIndex(null);
                setEditingWaveIndex(null);

                // Clear form fields and close the modal
                setFieldValue('ticketName', '');
                setFieldValue('ticketDescription', '');
                setFieldValue('ticketQuantity', '');
                setFieldValue('ticketPrice', '');
                setFieldValue('ticketEntries', '');
                setFieldValue('ticketPassword', '');
                setFieldValue('limitQuantity', '');
                setFieldValue('instagram', '');
                setShowAdvanced(false);

                onClose3();
              }}
              style={{ width: 'fit-content', textTransform: 'capitalize' }}
              className="gordita700"
            >
              {editMode ? 'Update' : 'Create'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isTicketWaveDialogOpen}
        onClose={() => onCloseTicketWaveDialog()}
        size={'xl'}
      >
        <ModalOverlay />
        <ModalContent
          bg={'rgba(0, 0, 0, 0.50)'}
          // backdropFilter={"blur(150px)"}
          borderRadius={'1.5625rem'}
          border={'1px solid #333333'}
          boxShadow={'0px 0px 100px 150px rgba(0, 0, 0, 0.30)'}
          backdropFilter={'blur(75px)'}
          padding={'0.525rem 0 0'}
          maxW={'28.5rem'}
          style={{
            width: '28.5rem !important',
          }}
        >
          <ModalCloseButton
            top={'1.5rem'}
            right={'1.5rem'}
            //onClick={cancelTicketModel}
          />
          <ModalBody
            display={'flex'}
            flexDir={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Box
              width={'26.625rem'}
              height={'20.4375rem'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              borderRadius={'0.625rem'}
              backgroundColor={'rgba(255, 255, 255, 0.10)'}
            >
              <img
                src={frame3} // Replace with your actual image URL
                alt="frame3"
                style={{ maxWidth: '100%', maxHeight: '100%' }}
              />
            </Box>
            <Box
              width={'100%'}
              display={'flex'}
              flexDir={'column'}
              justifyContent={'center'}
              alignItems={'flex-start'}
              padding={'1.56rem 0.94rem'}
            >
              <Text
                fontSize="1.25rem"
                className={'gordita600'}
                textTransform={'capitalize'}
              >
                Ticket waves
              </Text>
              <Text
                mt={'0.62rem'}
                fontSize={'1rem'}
                color={'rgba(255, 255, 255, 0.60)'}
                className="gordita400"
              >
                Ticket waves refer to the same type of ticket offered at
                different prices. These follow a sequence based on availability,
                but you can override this by scheduling them according to
                specific dates.
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter paddingTop={0}>
            <Button
              sx={theme.buttons.primary.shape}
              bg={theme.buttons.primary.default}
              color={theme.buttons.primary.color}
              _hover={{
                bg: theme.buttons.primary.hover,
                boxShadow: theme.buttons.primary.hoverBoxShadow,
              }}
              _active={{
                boxShadow: theme.buttons.primary.activeBoxShadow,
              }}
              onClick={() => {
                createTicketWaves();
                onCloseTicketWaveDialog();
              }}
              style={{
                width: 'fit-content',
                padding: '0rem 1.5625rem',
                textTransform: 'capitalize',
              }}
              className="gordita700"
            >
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* <NFTMeta
        {...{
          values,
          handleBlur,
          handleChange,
          setFieldValue,
        }}
      /> */}
    </Box>
  );
};

export default TicktDetails;
