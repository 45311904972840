import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Box, Image, HStack, Text, Flex, Icon, Button } from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import QRCode from "qrcode.react";

import { user } from "../../recoil/atoms/user";
import HomeIcon from "../../../src/static/assets/images/homeIcon.svg";
import localActivityIcon from "../../../src/static/assets/images/localActivityIcon.svg";
import favoriteIcon from "../../../src/static/assets/images/favoriteIcon.svg";
import qrcodeIcon from "../../../src/static/assets/images/qrcodeIcon.svg";
import qrcodeImage from "../../../src/static/assets/images/qrcodeImage.svg";
import closeIcon from "../../../src/static/assets/images/closeIcon.svg";
import defaultProfileImage from "../../../src/static/assets/images/defaultProfileImage.svg";
import { useMediaQuery } from "../../../src/utils/useMediaQuery";
import portalys from "../../../src/static/assets/images/Mark.png";
import axios from "../../utils/api/axios";
import { useLocation } from 'react-router-dom'; // Assuming you are using React Router
import { FaApple,FaGoogleWallet } from 'react-icons/fa';
import googleWallet from '../../../src/static/assets/images/enUS_add_to_google_wallet_add-wallet-badge.svg'
import appleWallet from "../../../src/static/assets/images/US-UK_Add_to_Apple_Wallet_RGB_101421.svg"

import "./style.css";
import { AutoComplete } from "antd";
import { theme } from "../../styles/theme/base";

function BottomNavbar({ children }) {
  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const [_, setUser] = useRecoilState(user);
  const [image, setImage] = useState("");
  const [showQRCode, setShowQRCode] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState(false);

  let pathname = window.location.pathname;

  useEffect(() => {
    setImage(_.userData?.image);
  }, [_.userData]);
  const location = useLocation();

  useEffect(() => {
    // Get the URL search params
    const searchParams = new URLSearchParams(location.search);

    // Check if 'qrcode' parameter exists and is set to 'true'
    if (searchParams.get('qrcode') === 'true') {
      if (_?.token && _?.userData.userId)
      {
         setShowQRCode(true);
      }
      // Call your renderQRCode function here
    }
  }, [location.search,_?.token]);

  useEffect(() => {
    // Add event listener to handle scrolling
    if (showQRCode) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = 'auto'; // Enable scrolling
    }

    // Clean up the effect
    return () => {
      document.body.style.overflow = 'auto'; // Reset overflow to default
    };
  }, [showQRCode]);

  useEffect(() => {
    const tokenVerify = searchParams.get("tokenVerify");

    if (tokenVerify) {
      async function verifyLink() {
        try {
          const response = await axios.get(`/api/verify/${tokenVerify}`);
          setVerificationStatus(response.data.success);
        } catch (error) {
          setVerificationStatus(false);
        }
      }

      verifyLink();
    }
  }, []);
  const addToApple = async () => {
    if (_?.token) {
      try {
        const walletAddress=_?.userData.walletAddress;
         const response= await axios.get("/wallet/download/"+walletAddress, {
          headers: {
            "x-auth-token": _?.token,
          },
          responseType: 'arraybuffer',
        });
        console.log('aaa',response);
        const blob = new Blob([response.data], { type: 'application/vnd.apple.pkpass' });

    // Create a URL object from the blob
    const url = window.URL.createObjectURL(blob);

    // Create an anchor element to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'portalys.pkpass'); // Set the filename here
    document.body.appendChild(link);

    // Initiate the download
    link.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
       
      } catch (error) {
        console.error("Error downloading pass:", error);
      }
    }
  };
  const addToGoogle = async () => {
    if (_?.token) {
      try {
        const walletAddress=_?.userData.walletAddress;
         const response= await axios.get("/wallet/getGooglePass/"+walletAddress, {
          headers: {
            "x-auth-token": _?.token,
          },
        });
        const passUrl = response.data.passUrl;

        // Initiate the download by opening the URL in a new tab
        window.open(passUrl, "_blank");
       
      } catch (error) {
        console.error("Error downloading pass:", error);
      }
    }
  };

  const renderQRCode = () => {
    console.log({ verificationStatus });
    if (true) {
      return (
        <QRCode
          value={ _.userData?.walletAddress + "/" + Date.now()}
          size={300}
          imageSettings={{
            src: portalys,
            width: 40, // Adjust the logo width as needed
            height: 40, // Adjust the logo height as needed
            excavate: true,
          }}
        />
      );
    }
  };

  const onBottomNavbarItemClick = (path) => {
    setShowQRCode(false);
    navigate(path);
  };



  const isUserLoggedIn = _?.token && _.userData?.userId;

  return (
    <>
      {children}
      {isMobile && showQRCode && (
        <Box
          width={"100%"}
          height={"100%"}
          zIndex={999}
          pos={"fixed"}
          bottom={"4.75rem"}
          left={0}
          onClick={() => setShowQRCode(false)}
        >
         
          <Box
            zIndex={999}
            pos={"fixed"}
            bottom={"4.75rem"}
            left={"50%"}
            transform={"translateX(-50%)"}
            maxWidth={"22.0625rem"}
            maxHeight={"37.5625rem"}
            width={"100%"}
            height={"100%"}
            onClick={(e) => e.stopPropagation()}
            display="flex" // Center items horizontally
            flexDirection="column" // Stack items vertically
            alignItems="center" // Center items horizontally
            justifyContent="center" 
            paddingTop={
              window.innerHeight < 37.5625 * 16 ? "110px" : window.innerHeight < 41.69 * 16 ? "50px" : 0
            } // Add padding only if the window height is less than the container's height
            paddingRight={
              window.innerWidth < 22.0625 * 16 ? "2.5px" : "auto"
            }
            paddingLeft={
              window.innerWidth < 22.0625 * 16 ? "2.5px" : "auto"
            }
                   >
                    
            <Box
      pos={"relative"}
      width={"100%"}
      height={"100%"}
    >
      <Image
        src={qrcodeImage}
        width={"100%"}
        height={"100%"}
        objectFit={"cover"}
        display={"block"}
      />
      <Box
        pos={"absolute"}
        top={"50%"}
        left={"50%"}
        transform={"translate(-50%, -50%)"}
        width={"100%"}
        padding={"0 1.56rem"}
        color={"#000000"}
        display={"flex"}
        flexDir={"column"}
        gap={"2.5rem"}
        paddingRight={
          window.innerWidth < 22.0625 * 16 ? "10px" : "auto"
        }
        paddingLeft={
          window.innerWidth < 22.0625 * 16 ? "10px" : "auto"
        }
      >

        {renderQRCode()}

        <Text
          width={"100%"}
          textAlign={"center"}
          fontSize={"0.85rem"}
          className="gordita600"
          lineHeight={"140%"}
          letterSpacing={"-0.01rem"}
          textTransform={"capitalize"}
        >
          Screenshots don't work! The QR code is autogenerated to avoid fraud.
        </Text>
        <Flex  justify="center" direction="row" align="center" gap={"10px"}>
        <Box
              onClick={addToApple}
            >
              <Image
                src={appleWallet}
                style={{
                  width: "15.75rem",
                  height: "0rem",
                  minHeight:"48px"
                }}
              />
            </Box>
<Box
              onClick={addToGoogle}
            >
              <Image
                src={googleWallet}
                style={{
                  width: "19.75rem",
                  //height: "1.5rem",
                  minHeight:"48px"
                }}
              />
            </Box>

</Flex>
      </Box>
    </Box>
  </Box>
</Box>
      )}

      {isMobile && isUserLoggedIn  && (
        <Box
          zIndex={1}
          pos={"fixed"}
          left={0}
          bottom={0}
          //   maxWidth={"24.5625rem"}
          width={"100%"}
          height={"4.75rem"}
          padding={"0.3125rem 1.25rem"}
          borderRadius={"2.5rem 2.5rem 0rem 0rem"}
          borderTop={
            "1px solid var(--Border-B---Secondary, rgba(255, 255, 255, 0.10))"
          }
          bg={"rgba(0, 0, 0, 0.50)"}
          backdropFilter={"blur(75px)"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <HStack
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}
          >
            <Box
              className={pathname === "/" ? "active" : "inactive"}
              onClick={() => onBottomNavbarItemClick("/")}
            >
              <Image
                src={HomeIcon}
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                }}
              />
            </Box>
            <Box
              className={
                pathname.includes("/mytickets") ? "active" : "inactive"
              }
              onClick={() => onBottomNavbarItemClick("/mytickets")}
            >
              <Image
                src={localActivityIcon}
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                }}
              />
            </Box>
            <Box
              width={"4.125rem"}
              height={"4.125rem"}
              padding={"1.06rem"}
              borderRadius={"6.25rem"}
              border={"1px solid rgba(255, 255, 255, 0.20)"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              onClick={() => setShowQRCode(!showQRCode)}
              cursor={"pointer"}
            >
              <Image
                src={showQRCode ? closeIcon : qrcodeIcon}
                style={{
                  width: showQRCode ? "2rem" : "1.5rem",
                  height: showQRCode ? "2rem" : "1.5rem",
                }}
              />
            </Box>
            <Box
              className={pathname.includes("/wishlist") ? "active" : "inactive"}
              onClick={() => onBottomNavbarItemClick("/wishlist")}
            >
              <Image
                src={favoriteIcon}
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                }}
              />
            </Box>
            <Box
              className={pathname.includes("/settings") || pathname.includes("/resell-dashboard") ? "active" : "inactive"}
              onClick={() => onBottomNavbarItemClick("/settings")}
              padding={0}
            >
              <Image
                borderRadius="2.5rem"
                // padding={"0.8125rem"}
                width="2.5rem"
                height="2.5rem"
                src={image || defaultProfileImage}
              />
            </Box>
          </HStack>
        </Box>
      )}
    </>
  );
}

export default BottomNavbar;
