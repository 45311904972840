import React from "react";
import { Box } from "@chakra-ui/react";
import successIcon from "../../static/assets/images/successIcon.svg";
import errorIcon from "../../static/assets/images/errorIcon.svg";
import closeIcon from "../../static/assets/images/closeIcon.svg";

const NotificationComponent = ({ title, status, onClose }) => {
  const icon = status === "success" ? successIcon : errorIcon;

  return (
    <Box
      color="white"
      p={3}
      bg="black"
      borderRadius={"20px"}
      border={"1px solid gray"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "felx-start",
          alignItems: "flex-start",
          gap: ".75rem",
        }}
      >
        <img src={icon} alt="toast-icon" />
        <div>{title}</div>
      </div>
      <button onClick={onClose} style={{ marginLeft: "1rem" }}>
        <img
          src={closeIcon}
          style={{ width: "1rem", height: "1rem" }}
          alt="close-icon"
        />
      </button>
    </Box>
  );
};

export { NotificationComponent };
