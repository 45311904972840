// src/components/StoreRedirect.js

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import secrets from '../../../secrets';


const StoreRedirect = () => {
  const location = useLocation();

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = secrets.iosLink; // Replace with your actual iOS app link
    }
    // Android detection
    else if (/android/i.test(userAgent)) {
      window.location.href = secrets.androidLink; // Replace with your actual Android app link
    } 
    // Handle unknown devices
    else {
      console.log("Device not recognized.");
    }
  }, [location]);

  return (
    <div style={{ backgroundColor: 'black', minHeight: '100vh', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
      <h2>Redirecting to the appropriate store...</h2>
      <p>If you are not redirected, please search for the 'Portalys' app in your app store.</p>
    </div>
  );
};

export default StoreRedirect;
