import LightLogo from "../../static/assets/images/logo.svg";
import linkedin from "../../static/assets/images/socialIcon/linkedin.svg";
import twitter from "../../static/assets/images/socialIcon/twitterLight.svg";
import insta from "../../static/assets/images/socialIcon/instaLight.svg";
import DivisorSvg from "../../static/assets/images/Divider.svg";
import MobileDivisorSvg from "../../static/assets/images/MobileDivider.svg";
import SignUpOrganizer from "../pages/SignUpOrganizer";
import { Button, useToast } from "@chakra-ui/react";
import {
  RegistraionModalTypes,
  registration,
} from "../../recoil/atoms/registration";
import { useRecoilState } from "recoil";
import { useMediaQuery } from "../../utils/useMediaQuery";
import { user } from "../../recoil/atoms/user";
import { useNavigate } from "react-router-dom";
import { theme } from "../../styles/theme/base";
import "./footer.css";

const ImageComponent = ({ src, additionalClass, altName, link }) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <img src={src} className={additionalClass} alt={altName} />
    </a>
  );
};

const socialMediaItems = [
  {
    source: linkedin,
    name: "linkedin",
    link: "https://www.linkedin.com/company/portalys-io/",
  },
  {
    source: insta,
    name: "instagram",
    link: "https://www.instagram.com/portalys.io/",
  },
];

const Footer = () => {
  const isMobile = useMediaQuery("(max-width: 767px)");
  const [_, setRegistrationModal] = useRecoilState(registration);
  const [_U, setUser] = useRecoilState(user);
  const toast = useToast();
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling animation
    });
  };

  const renderModal = () => {
    switch (_.modalType) {
      case RegistraionModalTypes.SIGNUPORGANIZER:
        return <SignUpOrganizer />;
    }
  };

  return (
    <div className="footer-container">
      <div className={"footer-row-1"}>
        <ImageComponent
          src={LightLogo}
          altName={"portalys-logo"}
          additionalClass={"footer-logo"}
        />
        <div className="footer-right-content">
        {/* <Button
            sx={theme.buttons.tercary.shape}
            bg={theme.buttons.tercary.bgColor}
            color={theme.buttons.tercary.color}
            _hover={{ bg: theme.buttons.tercary.hoverBgColor }}
            css={{
              width: "fit-content !important",
              padding: "0px 25px",
            }}
            _active={{
              bg: theme.buttons.tercary.pressedBgColor,
            }}
            onClick={() => navigate("/secondary-market/circoloco")}

          >
            Resell Tickets
          </Button> */}
          <Button
            sx={theme.buttons.tercary.shape}
            bg={theme.buttons.tercary.bgColor}
            color={theme.buttons.tercary.color}
            _hover={{ bg: theme.buttons.tercary.hoverBgColor }}
            css={{
              width: "fit-content !important",
              padding: "0px 25px",
            }}
            _active={{
              bg: theme.buttons.tercary.pressedBgColor,
            }}
            onClick={() => navigate("/contact-us")}
          >
            Contact Us
          </Button>
          <Button
            sx={theme.buttons.tercary.shape}
            bg={theme.buttons.tercary.bgColor}
            color={theme.buttons.tercary.color}
            _hover={{ bg: theme.buttons.tercary.hoverBgColor }}
            css={{
              width: "fit-content !important",
              padding: "0px 25px",
            }}
            _active={{
              bg: theme.buttons.tercary.pressedBgColor,
            }}
            onClick={() => window.open("https://partners.portalys.io", "_blank")}

          >
            Learn More
          </Button>
          <Button
            sx={theme.buttons.secondary.shape}
            bg={theme.buttons.secondary.bgColor}
            color={theme.buttons.secondary.color}
            css={{
              width: "fit-content !important",
            }}
            _hover={{
              borderColor: theme.buttons.secondary.hoverBorderColor,
            }}
            _active={{
              bg: theme.buttons.secondary.pressed,
            }}
            border={theme.buttons.secondary.border}
            onClick={() => navigate("/create-event")}
          >
            Create Your Event
          </Button>
        </div>
      </div>

      <ImageComponent
        src={isMobile ? MobileDivisorSvg : DivisorSvg}
        altName={"footer-divider"}
        additionalClass={"footer-divider"}
      />
      <div className={"footer-row-2"}>
        <div className={"footer-description"}>
          © 2024 Portalys Inc. All Rights Reserved
        </div>
        <div className={"footer-right-content"}>
          <Button
            sx={theme.buttons.tercary.shape}
            bg={theme.buttons.tercary.bgColor}
            color={theme.buttons.tercary.color}
            _hover={{ bg: theme.buttons.tercary.hoverBgColor }}
            css={{
              width: "fit-content !important",
              padding: "0px 20px",
            }}
            _active={{
              bg: theme.buttons.tercary.pressedBgColor,
            }}
            onClick={() =>
              window.open(
                "https://portalysio.notion.site/United-States-Purchase-Terms-202e5af4ed9d4d609aec3b527b132a3a?pvs=74"
              )
            }
          >
            Purchase terms
          </Button>
          <Button
            sx={theme.buttons.tercary.shape}
            bg={theme.buttons.tercary.bgColor}
            color={theme.buttons.tercary.color}
            _hover={{ bg: theme.buttons.tercary.hoverBgColor }}
            css={{
              width: "fit-content !important",
              padding: "0px 20px",
            }}
            _active={{
              bg: theme.buttons.tercary.pressedBgColor,
            }}
            onClick={() =>
              window.open(
                "https://portalysio.notion.site/United-States-Terms-of-use-8d7db5649105423e906e06862afe9690?pvs=74"
              )
            }
          >
            Terms of use
          </Button>
          <Button
            sx={theme.buttons.tercary.shape}
            bg={theme.buttons.tercary.bgColor}
            color={theme.buttons.tercary.color}
            _hover={{ bg: theme.buttons.tercary.hoverBgColor }}
            css={{
              width: "fit-content !important",
              padding: "0px 20px",
            }}
            _active={{
              bg: theme.buttons.tercary.pressedBgColor,
            }}
            onClick={() =>
              window.open(
                "https://portalysio.notion.site/United-States-Privacy-Policy-eb213f30516a4a239367dbad2f1b9248"
              )
            }
          >
            Privacy Policy
          </Button>

          <div className="social-icons-container">
            {socialMediaItems?.map(({ source, name, link }, index) => (
              <Button
                key={index}
                bg={theme.buttons.tercary.bgColor}
                color={theme.buttons.tercary.color}
                _hover={{ bg: theme.buttons.tercary.hoverBgColor }}
                _active={{
                  bg: theme.buttons.tercary.pressedBgColor,
                }}
                css={{
                  width: "fit-content",
                  padding: 0,
                  borderRadius: "100px",
                }}
              >
                <ImageComponent
                  src={source}
                  altName={`${name}-icon`}
                  additionalClass={"social-icon"}
                  link={link}
                />
              </Button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
