import React, { useState, useEffect } from 'react';
import {QrReader} from 'react-qr-reader';
import { useParams } from 'react-router-dom'; // Import useParams if you're using React Router
import { useNavigate } from "react-router-dom";
import { AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Button, VStack } from "@chakra-ui/react";
import { userRoles } from '../../utils/constants';
import withAuth from '../../utils/withAuth';
import { theme } from '../../styles/theme/base';



/**
 * The Settings function is a React component that renders a form for users to update their profile
 * information, including their name, email, website, social media links, and profile image.
 * @param props - An object containing the properties passed down to the Settings component.
 * @returns A functional component named "Settings" is being returned.
 */
 function Scanner(props) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);

    const [data, setData] = useState("");
    const params = useParams();
    const { id } = params;
    const navigate = useNavigate();
    useEffect(() => {
        if (data) {
          navigate(`/verifier/validate/${encodeURIComponent(data)}/${encodeURIComponent(id)}`);
          setData("");
        }
      }, [data, navigate, id]);
  

      const handleNavigateClick= (id) => {
        const encodedId = encodeURIComponent(id);
        navigate(`/verifier/list/${encodedId}`);
      };
      const handleNavigateClick2 = (id) => {
        const encodedId = encodeURIComponent(id);
        navigate(`/verifier/guest/${encodedId}`);
      };
  
    return (
        <div style={{
            position: 'fixed',  // Use 'fixed' position to cover the entire viewport
            top: 0,
            left: 0,
            width: '100vw',     // Take up the entire viewport width
            height: '100vh',    // Take up the entire viewport height
            
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor:'#000000'
          }}>
         <QrReader
          constraints={{
            facingMode: 'environment'
        }}
      onResult={(result, error) => {
        if (!!result) {
          const qrData = result?.text;
          const separatorIndex = qrData.indexOf('/');
        
          if (separatorIndex !== -1) {
            
            const walletAddress = qrData.substring(0, separatorIndex);
            const qrTimestamp = qrData.substring(separatorIndex + 1);
            const currentTime = Date.now();
            const timeDifference = currentTime - qrTimestamp;
            console.log('timeDifference',timeDifference)
            
           
        
            if (timeDifference > (60 * 60 * 1000)) {
              setIsOpen2(true);
              return // Show the second popup
            }
            setData(walletAddress);
            
          } else {
            setIsOpen(true); // Show the first popup for invalid format
          }
        }
        
      }}
      style={{
        width: '100%',
        height: '100%',
      }}
    />
    <Button    onClick={() => handleNavigateClick(id)}       sx={ theme.buttons.primary.shape} 
         bg={theme.buttons.secondary.border}  color={theme.buttons.secondary.color}
         border={theme.buttons.secondary.border} 
         style={{
          position: 'absolute',
          left: '50%',
          top: '70%',
          transform: 'translate(-50%, -50%)',
        }}>See Attendees List
      
         </Button>
         <Button    onClick={() => handleNavigateClick2(id)}       sx={ theme.buttons.primary.shape} 
         bg={theme.buttons.secondary.border}  color={theme.buttons.secondary.color}
         border={theme.buttons.secondary.border} 
         style={{
          position: 'absolute',
          left: '50%',
          top: '80%',
          transform: 'translate(-50%, -50%)',
        }}>See Guest List
      
         </Button>
    <AlertDialog isOpen={isOpen} onClose={() => setIsOpen(false)}>
  <AlertDialogOverlay>
    <AlertDialogContent>
      <AlertDialogHeader fontSize="lg" fontWeight="bold">
        Invalid QR Code
      </AlertDialogHeader>

      <AlertDialogBody>
        The QR code data format is invalid.
      </AlertDialogBody>

      <AlertDialogFooter>
        <Button colorScheme="blue" onClick={() => setIsOpen(false)}>
          Close
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialogOverlay>
</AlertDialog>
<AlertDialog isOpen={isOpen2} onClose={() => setIsOpen2(false)}>
  <AlertDialogOverlay>
    <AlertDialogContent>
      <AlertDialogHeader fontSize="lg" fontWeight="bold">
        Invalid QR Code
      </AlertDialogHeader>

      <AlertDialogBody>
        The QR code data is an old screenshot. Show the actual one.
      </AlertDialogBody>

      <AlertDialogFooter>
        <Button colorScheme="blue" onClick={() => setIsOpen2(false)}>
          Close
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialogOverlay>
</AlertDialog>

         
        </div>
      );
    }

    export default withAuth(Scanner, [userRoles.VERIFIER]);