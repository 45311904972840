
const  secrets ={
    "GoogleClientSecret": process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
    "GoogleApiKey": process.env.REACT_APP_GOOGLE_API_KEY,
    "GoogleClientId": process.env.REACT_APP_GOOGLE_CLIENT_ID,
    "resetLink": process.env.REACT_APP_RESET_LINK,
    "verifyLink": process.env.REACT_APP_VERIFY_LINK,
    "googleMapApi": process.env.REACT_APP_GOOGLE_MAP_API,
    "apiBaseUrl": process.env.REACT_APP_API_BASE_URL,
    "sitePassword": process.env.REACT_APP_SITE_PASSWORD,
    "stripeReturnUrl":process.env.REACT_APP_STRIPE_RETURN_URL,
    "stripeReturnUrlMarketplace": process.env.REACT_APP_STRIPE_RETURN_URL_MARKETPLACE,
    "customHeader": process.env.X_CUSTOM_HEADER,
    "clientUrl": process.env.REACT_APP_CLIENT_URL,
    "stripeKey":process.env.REACT_APP_STRIPE_KEY,
    "recaptchaSiteKey": process.env.REACT_APP_SITE_KEY||"c",
    "environmentCrossmint": process.env.REACT_APP_ENVIRONMENT_CROSSMINT,
    "portalysDownloadLink": process.env.REACT_APP_PORTALYS_DOWNLOAD_LINK,
    "iosLink": process.env.REACT_APP_IOS_LINK,
    "androidLink": process.env.REACT_APP_ANDROID_LINK,
    "stripeReturnUrlSecondary": process.env.REACT_APP_STRIPE_RETURN_URL_SECONDARY,
  };

  export default secrets;




