import {
    Box,
  } from "@chakra-ui/react";
const TextComponent = ({ text }) => {
    return (
      <Box
        borderRadius={"6.25rem"}
        border={"1px solid rgba(255, 255, 255, 0.10)"}
        padding={"0.44rem 1.25rem"}
        fontSize={"1rem"}
        className="gordita700"
        color="#FFFFFF"
        letterSpacing={"-0.01rem"}
      >
        {text}
      </Box>
    );
  };


export const TagsComponent = ({
    eventType
  }) => 
    (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      width={"100%"}
      gap={"0.63rem"}
      marginTop={"14px"}
      flexWrap="wrap"
    >
      {eventType &&
        Object.entries(JSON.parse(eventType)).map(([key, value]) => (
          <TextComponent key={key} text={`${value}`} />
        ))}
    </Box>
  );
