import React, { useState,useEffect } from "react";
import {
  Box,
  Button,
  Text,
  VStack,
  Input,
  Textarea,
  Image,
  useToast,
} from "@chakra-ui/react";
import Layout from "../../modules/layout/layout";
import { useMediaQuery } from "../../utils/useMediaQuery";
import twitterIcon from "../../static/assets/images/twitter.svg";
import instagramIcon from "../../static/assets/images/instagram.svg";
import linkedinIcon from "../../static/assets/images/socialIcon/linkedin.svg";
import { theme } from "../../styles/theme/base";
import { useRecoilState } from "recoil";
import { user } from "../../recoil/atoms/user";
import axios from "../../utils/api/axios";
import secrets from "../../secrets";
import ReCAPTCHA from "react-google-recaptcha";



const ContactUs = (props) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isTablet = useMediaQuery("(max-width: 1192px)");

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [_, setUser] = useRecoilState(user);
  const toast = useToast();
  const [isRecaptchaVerified, setRecaptchaVerified] = useState(false);

  const handleRecaptchaChange = (value) => {
    // Set the recaptcha verification state
    setRecaptchaVerified(true);
  };

  const handleNameChange = (event) => {
    setFullName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };


  useEffect(() => {
    setFullName((_?.userData?.firstName||"") + " "+(_?.userData?.lastName||""));
    setEmail(_.userData?.email);

  }, [_]);

  const handleSendMessage = async () => {
    try {
      const response = await axios.post("/api/sendMessage",
       { 
        fullName,
        email,
        message,
      },
      {
        headers: {
          "x-auth-token": "Bearer " + _?.token,
        },
      });
      if (response.data.success === true) {
        setMessage("");
        toast({
          title: "Message sent successfully",
          status: "success",
          description:"We got your message, we will get back to you ASAP",
          isClosable: true,
          duration: 4000,
          position: "top-right",
        });
        return true;
      } else {
        toast({
          title: "Error sending message",
          status: "error",
          description:"Error sending message, try again later",
          isClosable: true,
          duration: 4000,
          position: "top-right",
        });
        return false;
      }
    } catch (error) {
      console.error("Error sending message:", error);
      return false;
    }
  };

  return (
    <Layout {...props}>
      <Box
        maxW={"63.625rem"}
        padding={"0 1.25rem"}
        margin={isMobile ? "4.13rem auto 4rem" : "8.13rem auto 5.31rem"}
      >
        <Text
          fontSize={isMobile ? "1.75rem" : "2.375rem"}
          className="gordita600"
          lineHeight={"150%"}
          letterSpacing={"-0.02375rem"}
          textTransform={"capitalize"}
          marginBottom={"2.19rem"}
        >
          Reach out to us.
        </Text>
        <Box
          display={"flex"}
          flexDir={isMobile ? "column" : "row"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          gap={"3.19rem"}
        >
          <Box>
            <Box
              display={"flex"}
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"flex-start"}
              gap={"1.12rem"}
              marginBottom={"3.12rem"}
            >
              <Text
                fontSize={"1.3125rem"}
                className="gordita400"
                lineHeight={"160%"}
              >
                We will get back to you as soon as we can.
              </Text>
              <Text
                fontSize={"1rem"}
                className="gordita400"
                lineHeight={"160%"}
                color={"rgba(255, 255, 255, 0.60)"}
              >
                In the meantime, you can follow us on social.
              </Text>
              <Box display={"flex"} alignItems={"center"} gap={"1rem"}>
                <a
                  href={`https://twitter.com/PortalysEvents`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={twitterIcon} width={"24px"} height={"24px"} />
                </a>
                <a
                  href={`https://www.linkedin.com/company/portalys-io/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={linkedinIcon} width={"24px"} height={"24px"} />
                </a>
                <a
                  href={`https://www.instagram.com/portalys.io/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={instagramIcon} width={"24px"} height={"24px"} />
                </a>
              </Box>
            </Box>
            <Box
              display={"flex"}
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"flex-start"}
              gap={"1.88rem"}
              marginBottom={"2.19rem"}
            >
              <Text
                fontSize={"1.125rem"}
                className="gordita700"
                lineHeight={"150%"}
                letterSpacing={"-0.01125rem"}
              >
                hello@portalys.io
              </Text>
            </Box>
            <Box
              display={"flex"}
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"flex-start"}
              gap={"0.31rem"}
            >
              <Text
                fontSize={"1rem"}
                className="gordita400"
                lineHeight={"160%"}
              >
                Portalys Inc.
              </Text>
              <Text
                fontSize={"0.75rem"}
                className="gordita400"
                lineHeight={"160%"}
                color={"rgba(255, 255, 255, 0.60)"}
              >
                345 Harrison Avenue, Boston Massachusetts, US.
              </Text>
            </Box>
          </Box>
          <Box width={isMobile ? "100%" : "23.75rem"}>
            <VStack
              width={"100%"}
              margin={"0 !important"}
              marginBottom={"1.25rem !important"}
              gap={"0.94rem"}
            >
              <Input
                placeholder="Name"
                fontSize={14}
                border="1px solid"
                borderRadius="10px"
                borderColor="rgba(255, 255, 255, 0.20)"
                type="text"
                color="#FFFFFF"
                focusBorderColor="#FFFFFF"
                _hover={{
                  borderColor: "#FFFFFF !important",
                }}
                _placeholder={{
                  color: "rgba(255, 255, 255, 0.40)",
                }}
                h="55px"
                w={"100%"}
                value={fullName}
                onChange={handleNameChange}
              />
              <Input
                placeholder="Email"
                fontSize={14}
                border="1px solid"
                borderRadius="10px"
                borderColor="rgba(255, 255, 255, 0.20)"
                type="text"
                color="#FFFFFF"
                focusBorderColor="#FFFFFF"
                _hover={{
                  borderColor: "#FFFFFF !important",
                }}
                value={email}
                onChange={handleEmailChange}
                _placeholder={{
                  color: "rgba(255, 255, 255, 0.40)",
                }}
                h="55px"
                w={"100%"}
                margin={"0 !important"}
              />

              <Textarea
                bg="#000000"
                borderRadius="10px"
                className="gordita400"
                placeholder={"Message"}
                border="1px solid rgba(255, 255, 255, 0.20)"
                size="sm"
                minH="199px"
                w={"100%"}
                color="#FFFFFF"
                focusBorderColor="#FFFFFF"
                _hover={{
                  borderColor: "#FFFFFF",
                }}
                _placeholder={{
                  color: "rgba(255, 255, 255, 0.40)",
                  fontSize: 16,
                }}
                margin={"0 !important"}
                value={message}
                onChange={handleMessageChange}
              />
            </VStack>

            <VStack
              width={"100%"}
              margin={"0 !important"}
              marginBottom={"1.25rem !important"}
              gap={"0.94rem"}
            >
            <ReCAPTCHA
    sitekey={secrets.recaptchaSiteKey} // Replace with your actual site key
    onChange={handleRecaptchaChange} // Add a function to handle recaptcha changes
    theme="light"
  />
            <Button
              transition="all 0.2s ease-in-out"
              bg={theme.buttons.primary.default}
              color={theme.buttons.primary.color}
              _hover={{
                bg: theme.buttons.primary.hover,
                boxShadow: theme.buttons.primary.hoverBoxShadow,
              }}
              _active={{
                boxShadow: theme.buttons.primary.activeBoxShadow,
              }}
              css={{
                height: "3.125rem",
                fontsize: "1rem",
              }}
              className="gordita700"
              w={"100%"}
              borderRadius={"3.125rem"}
               onClick={handleSendMessage}
              disabled={!message || !fullName || !email|| !isRecaptchaVerified}
              isLoading={isButtonLoading}
            >
              Send
            </Button>
            </VStack>

          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default ContactUs;
