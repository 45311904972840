import { React, useState, useRef, useEffect } from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Button,
  HStack,
  VStack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { user } from "../../recoil/atoms/user";
import axios from "../../utils/api/axios";
import { theme } from "../../styles/theme/base";
import { useLocation } from "react-router-dom";

import { useParams } from "react-router-dom"; // Import useParams if you're using React Router
import { useNavigate } from "react-router-dom";
import { userRoles } from "../../utils/constants";
import withAuth from "../../utils/withAuth";
import { ticket } from "../../recoil/atoms/tickets";

const ValidateGuest = () => {
  const [_, setUser] = useRecoilState(user);
  const [tickets, setTickets] = useState([]);
  const token = localStorage.getItem("x-auth-token");
  const params = useParams();
  const navigate = useNavigate();
  const { instagram, eventuuid } = params;

  const fetchNFTs = async (instagram, eventuuid) => {
    if (token) {
      try {
        const response = await axios.get("/api/getSingularGuest", {
          headers: {
            "x-auth-token": token,
          },
          params: {
            eventUuid: eventuuid,
            instagram: instagram,
          },
        });
        setTickets(response.data.guest);
      } catch (error) {
        console.error("Error fetching guest:", error);
      }
    }
  };

  useEffect(() => {

    fetchNFTs(instagram, eventuuid);
  }, [token]);

  const validateEntry = async (guestId) => {
    try {
      if (token) {
        const headers = {
          "x-auth-token": token,
        };

        const data = {
          guestId: guestId,
        };

        const response = await axios.post("/api/validateGuestlistEntry", data, {
          headers,
        });

        if (response.data.success) {
          return response.data.message;
        } else {
          throw new Error(response.data.message);
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const toast = useToast();

  const handleValidateClick = async (guestId) => {
    try {
      const message = await validateEntry(guestId);
      toast({
        title: "Validation Successful",
        description: message,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      fetchNFTs(instagram, eventuuid);
    } catch (error) {
      console.error("Validation failed:", error.message);
      toast({
        title: "Validation Failed",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };
  return (
    <Box // Container to center the VStack vertically
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh" // Make the container take the full viewport height
      bg="#000000"
    >
      <VStack spacing={4} alignItems="stretch">
        <Box>
        <Text
  color="#FFF"
  textAlign="center"
  fontFamily="Gordita"
  fontSize="28px"
  fontStyle="normal"
  fontWeight="500"
  lineHeight="150%"
  letterSpacing="-0.28px"
  textTransform="capitalize"
>
  {tickets.fullName}
</Text>

<Text
  color="rgba(255, 255, 255, 0.60)"
  textAlign="center"
  fontFamily="Gordita"
  fontSize="18px"
  fontStyle="normal"
  fontWeight="400"
  lineHeight="150%"
  letterSpacing="-0.18px"
  textTransform="capitalize"
>
  {tickets.phoneNumber}
</Text>

<Text
  color="rgba(255, 255, 255, 0.60)"
  textAlign="center"
  fontFamily="Gordita"
  fontSize="18px"
  fontStyle="normal"
  fontWeight="400"
  lineHeight="150%"
  letterSpacing="-0.18px"
>
 @{tickets.instagram}
</Text>
<div  marginTop="10px">
{tickets.isVerified ? (
                    <Button
                      sx={theme.buttons.primary.shape}
                      bg={theme.buttons.primary.border} // Change to the desired color for verified tickets
                      color="#959597" // Change to the desired text color for verified tickets
                      border="#FF1B1B"
                    >
                      Verified
                    </Button>
                  ) : (
                    <Button
                      sx={theme.buttons.primary.shape}
                      bg={theme.buttons.secondary.border} // Change to the desired color for unverified tickets
                      color={theme.buttons.secondary.color} // Change to the desired text color for unverified tickets
                      border={theme.buttons.secondary.border}
                      onClick={() =>
                        handleValidateClick(tickets.guestId, token)
                      }
                    >
                      Validate
                    </Button>
                  )}
                  </div>
        </Box>
        <Button
          sx={{
            borderRadius: `100px`,
            display: `flex`,
            isolation: `isolate`,
            flexDirection: `row`,
            justifyContent: `center`,
            alignItems: `center`,
            padding: `15px 30px`,
            boxSizing: `border-box`,
            overflow: `hidden`,
            height: `43px`,
            width: `180px`,
          }}
          bg={theme.buttons.primary.default}
          color={theme.buttons.primary.color}
          _hover={{
            bg: theme.buttons.primary.hover,
            boxShadow: theme.buttons.primary.hoverBoxShadow,
          }}
          _active={{
            boxShadow: theme.buttons.primary.activeBoxShadow,
          }}
          position="fixed"
          bottom="4"
          right="4"
          onClick={() => {
            // Pass the selected event object to the next screen
            navigate(`/verifier/scanner/${eventuuid}`);
          }}
        >
          Back to Scanning
        </Button>
      </VStack>
    </Box>
  );
};

export default withAuth(ValidateGuest, [userRoles.VERIFIER]);
