export const hexToHSL = (hex) => {
  if (!hex)
    return {
      bgColor: "#000000",
      accentColor: "#FFFFFF",
    };
  // Remove the # symbol if present
  hex = hex.replace(/^#/, "");

  // Convert the hex value to RGB
  const r = parseInt(hex.slice(0, 2), 16) / 255;
  const g = parseInt(hex.slice(2, 4), 16) / 255;
  const b = parseInt(hex.slice(4, 6), 16) / 255;

  // Find the maximum and minimum values to determine the lightness
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h, s, l;

  // Calculate the hue
  if (max === min) {
    h = 0;
  } else if (max === r) {
    h = (60 * ((g - b) / (max - min)) + 360) % 360;
  } else if (max === g) {
    h = 60 * ((b - r) / (max - min)) + 120;
  } else {
    h = 60 * ((r - g) / (max - min)) + 240;
  }

  // Modify the saturation and lightness
  //   s = 0.95; // Set saturation to 95%
  //   l = 0.05; // Set lightness to 5%

  // Format HSL as a string
  const bgColor = `hsl(${Math.round(h)}, ${Math.round(95)}%, ${Math.round(
    3
  )}%)`;

  const accentColor = `hsl(${Math.round(h)}, ${Math.round(50)}%, ${Math.round(
    75
  )}%)`;

  return {
    bgColor,
    accentColor,
  };
};
