/**
 * This module sets up and exports a customized Axios instance for making HTTP requests.
 * 
 * - Imports the Axios library and a secret configuration file.
 * - Creates an Axios instance with a base URL and custom headers including a custom header for authentication.
 * - Sets up an Axios response interceptor to handle 401 (Unauthorized) errors by attempting to refresh the access token.
 *   - If a 401 error is encountered, it makes a request to refresh the token.
 *   - If the token refresh is successful, it updates the Authorization header with the new token and retries the original request.
 * - Exports the configured Axios instance for use in making HTTP requests throughout the application.
 * 
 * @module axios
 */
import Axios from "axios";
import secrets from "../../secrets";

const axios = Axios.create({
  baseURL: secrets.apiBaseUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "x-custom-header": secrets.customHeader || "PortalysSecretHeader",
  },
  withCredentials: true
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axios.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function(resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
        .then(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return axios(originalRequest);
        })
        .catch(err => {
          return Promise.reject(err);
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function(resolve, reject) {
        axios.post('/api/refreshToken', {}, { withCredentials: true })
          .then(({ data }) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.accessToken;
            localStorage.setItem("x-auth-token", data.accessToken);
            originalRequest.headers['Authorization'] = 'Bearer ' + data.accessToken;
            processQueue(null, data.accessToken);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);

export default axios;

