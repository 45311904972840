import {
  Box,
  Text,
  VStack,
  Image,
  useToast,
  Spinner,
  Tooltip,
  Flex,
  HStack,
  chakra,
  Icon,
  IconButton,
} from "@chakra-ui/react";
import axios from "../../../../utils/api/axios";
import { useRef, useState } from "react";
import { upload2 } from "../../../../static/assets/images/dashboard/sidebar";
import secrets from "../../../../secrets";
import { DeleteIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { exportSvg } from "../../../../static/assets/images/dashboard/sidebar";
import defaultCoverImage from "../../../../static/assets/images/defaultCoverImage.png";
import defaultCoverImage1 from "../../../../static/assets/images/defaultCoverImage1.png";
import { theme } from "../../../../styles/theme/base";

/**
 * The Promotion function is a React component that handles the uploading and display of a video
 * trailer, cover picture, and event image for a promotional event form.
 * @param props - props is an object that contains the properties passed down to the Promotion
 * component. It includes the setFieldValue and values properties, which are used to set and retrieve
 * form field values, as well as the toast property, which is used to display toast notifications. The
 * loading, image, dragging, and position properties
 * @returns The Promotion component is being returned, which renders a form for uploading a trailer,
 * cover picture, and event image. It also displays a preview of the uploaded event image. The
 * component uses useState and useRef hooks to manage state and handle drag and drop functionality. It
 * also uses axios to make API calls to upload the files and useToast to display error messages.
 */
const Promotion = (props) => {
  const { setFieldValue, values } = props;
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const dropRef = useRef(null);

  /**
   * The function uploads a video trailer using FormData and axios, and sets the trailer link in the
   * form field value if successful.
   * @param video - The video parameter is the actual video file that needs to be uploaded. It is passed
   * as an argument to the uploadTrailer function.
   */
  const uploadCover = async (image) => {
    const fd = new FormData();
    fd.append("image", image);
    fd.append("type", "cover");
    setLoading(true);
    await axios
      .post( "/api/uploadImage", fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status) {
          if (res.data.link) {
            setFieldValue("coverPicture", res.data.link);
            setFieldValue("eventImage", res.data.link);
          }
        } else {
          toast({
            title: "Cover Uploading Err",
            status: "error",
            isClosable: true,
            duration: 4000,
            position: "top-right",
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast({
          title: "Cover Uploading Err",
          status: "error",
          isClosable: true,
          duration: 4000,
          position: "top-right",
        });
      });
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files) uploadEventImage(e.dataTransfer.files[0]);
  };

  const handleMouseDown = (e) => {
    setDragging(true);
    const initialX = e.clientX - position.x;
    const initialY = e.clientY - position.y;

    const handleMouseMove = (e) => {
      setPosition({
        x: e.clientX - initialX,
        y: e.clientY - initialY,
      });
    };

    document.addEventListener("mousemove", handleMouseMove);

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      setDragging(false);
    };

    document.addEventListener("mouseup", handleMouseUp);
  };
  const uploadEventImage = async (image) => {
    const fd = new FormData();
    fd.append("image", image);
    fd.append("type", "event");
    setLoading(true);
    await axios
      .post("/api/uploadImage", fd,{
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status) {
          if (res.data.link) {
            setFieldValue("eventImage", res.data.link);
          }
        } else {
          toast({
            title: "Cover Uploading Err",
            status: "error",
            isClosable: true,
            duration: 4000,
            position: "top-right",
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast({
          title: "Cover Uploading Err",
          status: "error",
          isClosable: true,
          duration: 4000,
          position: "top-right",
        });
      });
  };

  return (
    <VStack pos="relative" alignItems="flex-start" w="100%">
      {loading && (
        <Box
          pos="absolute"
          h="100%"
          w="50%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner colorScheme="#02F6AD" size="xl" />
        </Box>
      )}

      {values?.eventImage ? (
        <Box
          position="relative"
          maxWw="39.625rem"
          width={"100%"}
          h={{ base: "100%", sm: "75vh", md: "49.5rem" }}
          bg="#000000"
          borderRadius="3.125rem"
          border="1px solid rgba(255, 255, 255, 0.20)"
        >
          <Image
            w="100%"
            h="100%"
            borderRadius="3.125rem"
            objectFit={"fill"}
            src={values.eventImage}
          />
          <IconButton
            icon={<DeleteIcon />}
            aria-label="Close video"
            position="absolute"
            color="red"
            top={4}
            right={4}
            zIndex={1}
            onClick={() => setFieldValue("eventImage", "")}
            size="lg"
          />
        </Box>
      ) : (
        <Box
          maxWw="39.625rem"
          width={"100%"}
          h={{ base: "50vh", sm: "75vh", md: "49.5rem" }}
          bg="#000000"
          borderRadius="3.125rem"
          border="1px solid rgba(255, 255, 255, 0.20)"
        >
          <Image
            pos={"absolute"}
            w="100%"
            h="100%"
            borderRadius="3.125rem"
            src={defaultCoverImage1}
            objectFit={"cover"}
          />
          <Box width={"100%"} height={"100%"}>
            <Box
              height={"100%"}
              display={"flex"}
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              pos={"relative"}
              width={"100%"}
              textAlign={"center"}
            >
              <Text
                className="gordita600"
                fontSize={"1.125rem"}
                lineHeight={"150%"}
                letterSpacing={"-0.01125rem"}
              >
                Cover image recommendations: *
              </Text>
              <Text
                className="gordita400"
                fontSize={"1rem"}
                lineHeight={"150%"}
                letterSpacing={"-0.01rem"}
              >
                Resolution: 1080 x 1350px <br /> Aspect ratio: 4:5 <br /> Size:
                2mb <br />
                (Same as Instagram’s posts)
              </Text>
            </Box>
            <input
              style={{ display: "none" }}
              id="eventImage"
              type="file"
              accept=".jpg,.jpeg,.png"
              onChange={(e) => {
                const reader = new FileReader();
                const file = e.target.files[0]; // Store the file in a variable

                reader.onload = (e) => {
                  const img = new window.Image(); // Use window.Image instead of Image
                  img.onload = () => {
                    const height = img.height;
                    uploadCover(file);
                  };
                  img.src = e.target.result;
                };
                if (e.target.files.length > 0) reader.readAsDataURL(file);
              }}
            ></input>
            <label htmlFor="eventImage">
              <Box
                cursor="pointer"
                display="flex"
                justifyContent="center"
                alignItems="center"
                minW={"6.25rem"}
                height={"3.125rem"}
                padding={"0rem 1.5625rem"}
                borderRadius={"3.125rem"}
                border={"1px solid rgba(255, 255, 255, 0.20)"}
                margin={"1.25rem"}
                pos={"absolute"}
                bottom={0}
                right={0}
                bg={theme.buttons.secondary.border}
                color={theme.buttons.secondary.color}
                _hover={{
                  borderColor: theme.buttons.secondary.hoverBorderColor,
                }}
                _active={{
                  bg: theme.buttons.secondary.pressed,
                }}
                fontSize={"1rem"}
                className="gordita700"
                textTransform={"capitalize"}
              >
                Upload Image
              </Box>
            </label>
          </Box>
        </Box>
      )}
    </VStack>
  );
};

export default Promotion;
