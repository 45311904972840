import {
    Text,
    Button
  } from "@chakra-ui/react";
  import React, { useState, useEffect } from "react";
  import { useParams,useNavigate } from "react-router-dom";
  import axios from "../../utils/api/axios";

  
  export default function SendLink(props) {
    const params = useParams();
    const { id } = params;
  
    // State variables
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [countdown, setCountdown] = useState(20);
    const navigate = useNavigate();
    useEffect(() => {
      navigate(`/?qrcode=true`);
    }, []);
  
    /*const sendLink = async () => {


      // Start loading
      setLoading(true);
      setButtonDisabled(true);
  
      try {
        const response = await axios.post("/api/sendQRLink", { id });
  
        // Stop loading
        setLoading(false);
  
        console.log("response data:", response.data);
  
        // Check the response and update the message accordingly
        if (response.data.success) {
          setMessage(`We just sent you a One-Time link to your QR code on your Phone Number at ${response.data.phoneNumber}`);
        } else {
          setMessage("There was an error, try again in a couple of seconds");
        }
      } catch (error) {
        console.error("Error sending link:", error);
  
        // Stop loading and set an error message
        setLoading(false);
        setMessage("There was an error, try again in a couple of seconds");
      }
    };
  
    useEffect(() => {
      if (buttonDisabled) {
        const countdownInterval = setInterval(() => {
          setCountdown(prevCountdown => prevCountdown - 1);
        }, 1000);
  
        setTimeout(() => {
          clearInterval(countdownInterval);
          setCountdown(20);
          setButtonDisabled(false);
        }, 20000);
      }
    }, [buttonDisabled]);
    */
  
    return (
<div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <Button 
        //onClick={sendLink} 
        isDisabled={buttonDisabled}                     
        color="white.100"
                    bg="primary.100"
                    type="submit"
                    className="gordita700"
                    w="90%"
                    borderRadius="48px"
                    h="65px"
                    _hover={{
                      bg: "primary.100",
                    }}
                    style={{ margin: '10px' }} 
                    >
          Send Link to QR code
        </Button>
        <Text w={"100%"} m={"2rem 0"} p={"0 20px"} fontFamily={"gordita"} fontWeight={"bold"} color="white">
          {message}
        </Text>
        {buttonDisabled && (
          <Text w={"100%"} fontFamily={"gordita"} fontWeight={"bold"} color="white">
            Button disabled. Retry in {countdown} seconds...
          </Text>
        )}
      </div>
    </div>
    );
  }
  