import {
    Button,

  } from "@chakra-ui/react";
  import { theme } from "../../../styles/theme/base";
  import { useNavigate } from "react-router-dom";


  export const PageNotFound = 
  ({
  }) => {
    const navigate = useNavigate();
    

  return(<div
style={{
  margin: "0 auto",
  fontSize: "2rem",
  fontWeight: "700",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "90vh",
  flexDirection:"column"
}}
>
404: Page Not Found

<Button
        fontWeight="700"
        textAlign="center"
        margin={"20px 0"}
        fontSize="1rem"
        borderRadius="40px"
        sx={theme.buttons.primary.shape}
        bg={"transparent"}
        color={theme.buttons.primary.default}
        className="gordita700"
        css={{
          width: "auto",
          border: "1px solid rgba(255, 255, 255, 0.20)",
          textTransform: "capitalize",
        }}
        _hover={{
          borderColor: "#FFF",
        }}
        _active={{
          backgroundColor: "rgba(255, 255, 255, 0.05)",
        }}
        _disabled={{
          borderColor: "rgba(255, 255, 255, 0.10)",
          color: "rgba(255, 255, 255, 0.4)",
        }}
        onClick={() => {
          navigate('/');
        }}
      >Return to home page</Button>
</div>)
  };