import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "../../utils/api/axios";

function Track() {
  const { eventSlug, trackingId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Call backend API for tracking
    axios
      .get(`/track/${eventSlug}/${trackingId}`)
      .then((response) => {
        // Handle the response
        console.log(response.data);
        // Redirect or take other actions
        navigate(`/event/${eventSlug}?tracking=${trackingId}`);
      })
      .catch((error) => {
        console.error("Tracking failed", error);
        // Nagivate to the event page anyway, event if tracking fails
        navigate(`/event/${eventSlug}?tracking=${trackingId}`);
      });
  }, [eventSlug, trackingId, navigate]);
}

export default Track;
