import {
    Box,
    Heading,
    Text,
    Image,
    HStack,
    Flex
  } from "@chakra-ui/react";
  import { isEmpty } from "lodash";
  import lineupPerformer from "../../../static/assets/images/lineupPerformer.png";


export const DecriptionComponent = ({
    about,
    description,

  }) => 
(
    <Box>
    <Heading
      as="h4"
      size="md"
      color="#FFFFFF"
      fontSize="21px"
      fontFamily={"gordita600"}
    >
     {about}
    </Heading>
    {description &&
      description
        ?.split("\n")
        ?.map((line, index) => (
          <Text
            key={index}
            color="#FFFFFF"
            fontSize="16px"
            marginTop="25px"
          >
            {line}
          </Text>
        ))}
  </Box>
);

export const TrailerComponent = ({
    trailerText,
    trailer,

  }) => 
(
<Box marginTop="100px">
<Box className="darkcard">
  <Heading
    as="h4"
    size="md"
    color="#FFFFFF"
    fontSize="25px"
  >
    {trailerText}
  </Heading>
  <video
    src={trailer}
    width="100%"
    controls
    autoPlay={false}
    style={{
      width: "100%",
      aspectRatio: "16/9",
      marginTop: "25px",
    }}
  />
</Box>
</Box>
);

export const LineupComponent = ({
    performers,
    lineup,
    isTablet

  }) => 
(
<Box className="darkcard">
<Heading
  as="h4"
  size="md"
  color="#FFFFFF"
  fontSize="21px"
  marginTop="1.88rem"
>
  {performers}
</Heading>
<Flex
  flexWrap="wrap" // Allow items to wrap to a new line
  justifyContent="flex-start" // Center items horizontally
  alignItems="center" // Center items vertically
  marginTop="25px"
  columnGap="1.25rem"
  rowGap={"0.62rem"}
>
  {!isEmpty(lineup) &&
    Object.values(lineup || {})?.map(
      (item, index) => (
        <Box
          key={index}
          textAlign="center"
          className="trackcardshero"
          width={isTablet ? "100%" : "280px"}
          height={"80px"}
          border={"1px solid #FFFFFF1A"}
          borderRadius={"100px"}
          display={"flex"}
          justifyContent={"start"}
          alignItems={"center"}
          gap={"1rem"}
          p={"0 .31rem"}
        >
          <Image
            width={"70px"}
            height={"70px"}
            src={item?.image ?? lineupPerformer}
            borderRadius={"50%"}
          />
          <Text
            color="#FFFFFF"
            fontSize="23px"
            fontWeight={"700"}
            whiteSpace={"nowrap"}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
          >
            {item?.performerName}
          </Text>
        </Box>
      )
    )}
</Flex>
</Box>
);
export const SpotifyComponent = ({
    bestTrack,
    trackId

  }) => 
(
<Box>
<Box width="100%" className="darkcard1">
  <Heading
    as="h4"
    size="md"
    color="#FFFFFF"
    fontSize="21px"
  >
    {bestTrack}
  </Heading>
  <HStack>
    <HStack w="100%" borderRadius="12px">
      <iframe
        style={{ borderRadius: "12px" }}
        src={`https://open.spotify.com/embed/track/${trackId}?utm_source=generator&theme=0`}
        width="100%"
        height="80"
        allowFullScreen=""
        frameBorder="0"
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      ></iframe>
    </HStack>
  </HStack>
</Box>
<Box className="div-separator" />
</Box>
);