import React from "react";
import { Box, Heading, Button, VStack,   useToast, HStack, Image} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "../../../../utils/useMediaQuery";
import ImageTextComponent from "./ImageTextComponent";
import { theme } from "../../../../styles/theme/base";
import marketplace1 from "../../../../static/assets/images/marketplace1.png";
import marketplace4 from "../../../../static/assets/images/marketplace4.png";
import sellout from "../../../../static/assets/images/sell-out.png";
import { RegistraionModalTypes,registration } from "../../../../recoil/atoms/registration";
import { useRecoilState } from "recoil";
import {user} from "../../../../recoil/atoms/user";
import { NotificationComponent } from "../../../shared/notificationComponent";


function EventsOverview() {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isTablet = useMediaQuery("(max-width: 1024px)");
  const [_, setRegistrationModal] = useRecoilState(registration);
  const [_U, setUser] = useRecoilState(user);
  const toast = useToast();
  const navigate = useNavigate();


  return (
    <Box w="100%">
      <Box
        display={"flex"}
        flexDir={"column"}
        gap={isTablet ? "5.31rem" : "16.16rem"}
      >
        <ImageTextComponent
          imageSrc={sellout}
          title={"Sell out your event for less"}
          subtitle={"Earn more from your events"}
          description={
            "The most reliable and low-cost app to host, manage, and market live events."
          }
          isTabletReverse={true}
          button={
            <HStack width={"100%"} gap="1.25rem" justifyContent={"start"} marginTop="2rem"  flexDirection={isTablet ? "column" : "row"}>
              <Button
                sx={theme.buttons.primary.shape}
                bg={theme.buttons.primary.default}
                color={theme.buttons.primary.color}
                className="gordita700"
                fontSize="1rem"
                _hover={{
                  bg: theme.buttons.primary.hover,
                  boxShadow: theme.buttons.primary.hoverBoxShadow,
                }}
                _active={{
                  boxShadow: theme.buttons.primary.activeBoxShadow,
                }}
                style={{
                  minWidth: isTablet ? "100%" : "fit-content",
                  padding: "0 1.56rem",
                  textTransform: "capitalize",
                }}
                onClick={() => navigate("/create-event")}
              >
                Create Your Event
              </Button>
              <Button
                sx={theme.buttons.secondary.shape}
                bg={theme.buttons.secondary.bgColor}
                color={theme.buttons.secondary.color}
                css={{
                  width: isTablet ? "100%" : "12.5rem",
                  textTransform: "capitalize",
                }}
                _hover={{
                  borderColor: theme.buttons.secondary.hoverBorderColor,
                }}
                _active={{
                  bg: theme.buttons.secondary.pressed,
                }}
                className="gordita700"
                onClick={() => window.open("https://partners.portalys.io", "_blank")}
                >
                Learn More
              </Button>
            </HStack>
          }
        />

      </Box>
    </Box>
  );
}

export default EventsOverview;
