import React from "react";
import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  Image,
  useToast,
  Icon,
  Input,
  IconButton,
} from "@chakra-ui/react";
import { useState } from "react";
import { CopyIcon } from "@chakra-ui/icons";
import { InputBox } from "./SharedModules";

function EventPassword(props) {
  const { handleBlur, values, setFieldValue, handleChange } = props;
  const [condition, setCondition] = useState(false);
  const [eventPassword, setEventPassword] = useState("");


  return (
    <VStack w="100%" alignItems={"flex-start"}>
      <Text
        fontSize={"1.3125rem"}
        className="gordita600"
        textTransform={"capitalize"}
        mb={"1rem"}
      >
        Add Password
      </Text>
      <HStack
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
        flexWrap={"wrap"}
      >
        <div
          style={{
            display: "flex",
            alignItems: "start",
            gap: "10px",
          }}
        >
          <input
            type="checkbox"
            id="condition"
            name="condition"
            checked={condition || values?.eventPassword}
            value={condition}
            onChange={(e) => {
              setCondition(e.target.checked);
              if (!e.target.checked) {
                setCondition(false);
                setFieldValue("eventPassword", "");

              }
            }}
            style={{
              cursor: "pointer",
              marginTop: "2px",
              height: " 1rem",
              color: "#FFFFFF",
            }}
          />
          <label
            htmlFor="condition"
            style={{
              color: "#FFFFFF",
              fontSize: "16px",
            }}
          >
            Yes, add a password
          </label>
        </div>
        {(condition || values?.eventPassword) && (
          <div
            style={{
              display: "flex",
              alignItems: "start",
              gap: "10px",
            }}
          >
           <InputBox
          values={values}
          handleBlur={handleBlur}
          handleChange={handleChange}
          placeholder="Enter Password"
          name="eventPassword"
          maxW="100%"
          w={"100%"}
        />
           
          </div>
        )}
      </HStack>
      <Box>
          <Text
            marginTop={"1.56rem"}
            className="gordita400"
            fontSize={16}
            textAlign="left"
            w="100%"
            color="rgba(255, 255, 255, 0.60)"
          >
            You have the option to add a password for your event. You will need to share this password with the people you want to have access to the event page. You can always set a password now and remove it later or vice versa.
          </Text>
        </Box>
    </VStack>
  );
}

export default EventPassword;
