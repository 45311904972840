import {
    Box,
    Text,
    Heading,
    HStack,

  } from "@chakra-ui/react";
  import Map from "../../../utils/Map";
  import { ReactComponent as LocationIcon } from "../../../static/assets/images/TypePlace.svg";



export const MapComponent = ({ location, latitude,longitude, accentColor, locationText }) => {
    return (
      <Box>
        {location && (
          <Box className="darkcard">
            <Heading as="h4" size="md" color="#FFFFFF" fontSize="21px">
              {locationText}
            </Heading>
            {
              <Box
                margin="10px 0 10px 0"
                display="flex"
                justifyContent="start"
                alignItems="center"
              >
                <HStack>
                  <LocationIcon fill={accentColor} />
                  <Text fontSize="15px" fontWeight="700" color="#ffff">
                    {location}
                  </Text>
                </HStack>
              </Box>
            }
            <Box h="350px" overflow={"hidden"} borderRadius={"1.25rem"}>
              {longitude && latitude && (
                <Map lat={latitude} lng={longitude} zoom={12.5} />
              )}
            </Box>
            <Box className="div-separator" />
          </Box>
        )}
      </Box>
    );
  };