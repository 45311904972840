import React, { useRef } from "react";
import { DatePicker as DStyle } from "../../styles/layout/sharedModules";
import { DatePicker } from "antd";
import moment from "moment";
import "./styles.css";
import dayjs from "dayjs";
import { ReactComponent as CalendarIcon } from "../../static/assets/images/calendarIcon.svg";

const MONTH_NAMES_DEFAULT = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const DAY_NAMES_DEFAULT = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const DATE_FORMAT_DEFAULT = "DD/MM/YYYY";

/**
 * This is a SingleDatepicker component in JavaScript that takes in various props and renders a date
 * picker.
 * @returns A functional component named `SingleDatepicker` is being returned. It takes in several
 * props including `configs`, `color`, `dark`, `w`, `className`, and `props`. It renders a `DatePicker`
 * component from a library called `antd` with some custom styles and props passed down from the parent
 * component. The `DatePicker` component is used to select a single date and the selected
 */
export const SingleDatepicker = ({
  configs = {
    dateFormat: DATE_FORMAT_DEFAULT,
    monthNames: MONTH_NAMES_DEFAULT,
    dayNames: DAY_NAMES_DEFAULT,
  },
  color,
  dark,
  w,
  className,
  backgroundColor = "#000",
  ...props
}) => {
  const { date, onDateChange, maxW } = props;
  const ref = useRef();

  return (
    <DStyle dark={dark}>
      <DatePicker
        className={className ? className + " gordita400" : "gordita400"}
        style={{
          border: "1px solid rgba(255, 255, 255, 0.20)",
          borderRadius: "6.25rem",
          backgroundColor: backgroundColor,
          height: 56,
          width: w,
          color: "#FFFFFF",
          fontSize: 14,
          maxWidth: maxW,
          padding: "0.31rem",
        }}
        popupClassName="date-picker-popup"
        value={date ? dayjs(date) : ""}
        showToday={true}
        onChange={onDateChange}
        suffixIcon={<CalendarIcon />}
        inputReadOnly={true}
        {...props}
      />
    </DStyle>
  );
};
