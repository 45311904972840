import lockIcon from '../../../static/assets/images/ic_round-lock.svg';
import footerIcon from '../../../static/assets/images/ic_round-lock(1).svg';
import { Box, Text, Image } from '@chakra-ui/react';

export const SecuredResell = ({isMobile}) => {
  return (
    <Box
    display="flex"
    height="36px"
    padding="12px 16px 12px 12px"
    alignItems="center"
    justifyContent="center"
    gap="8px"
    borderRadius="20px"
    background="rgba(92, 255, 177, 0.12)"
    width={isMobile ? "100%" : "auto"}
    >
      <Image src={lockIcon} width="18px" height="18px" />
    <Text
      color="#5CFFB1"
      fontFamily="Gordita"
      fontSize="16px"
      fontWeight={700}
      lineHeight="160%"
      textEdge="cap"
    >
      Secured Resell
    </Text>
  </Box>
  )
};

export const SecondaryMarketHeader = () => {
  return (
    <Box
      display="flex"
      maxWidth="580px"
      width="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="24px"
      margin="0 auto"
      padding="16px"
    >
      <SecuredResell />

      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap="16px"
        alignSelf="stretch"
      >
        <Text
          color="#FFF"
          textAlign="center"
          fontFamily="Gordita"
          fontSize="48px"
          fontWeight={700}
          lineHeight="146%"
        >
          Safely buy or sell tickets for nightlife events.
        </Text>
        <Text
          color="rgba(255, 255, 255, 0.64)"
          textAlign="center"
          fontFamily="Gordita"
          fontSize="20px"
          fontWeight={500}
          lineHeight="146%"
          alignSelf="stretch"
        >
          Get your tickets 100% guaranteed or you get your money back, no
          questions asked.
        </Text>
      </Box>
    </Box>
  );
};

export const SecondaryMarketFooter = ({isMobile}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap="20px"
      margin="0 auto"
      padding="16px"
    >
      {!isMobile && (
      <Box
        display="flex"
        width="72px"
        height="72px"
        justifyContent="center"
        alignItems="center"
        borderRadius="16px"
        background="rgba(255, 255, 255, 0.04)"
      >
        <Image src={footerIcon} width="24px" height="24px" flexShrink={0} />
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap="8px"
      >
        <Text
          color="#FFF"
          fontFamily="Gordita"
          fontSize="20px"
          fontWeight={500}
          lineHeight="132%"
        >
          We secure your money till you get your tickets
        </Text>
        <Text
          color="rgba(255, 255, 255, 0.64)"
          fontFamily="Gordita"
          fontSize="14px"
          fontWeight={400}
          lineHeight="150%"
        >
          If you don't get your tickets, we will refund your purchase.
        </Text>
      </Box>
    </Box>
  );
};

export const TicketsSentToAccount = ({isMobile}) => {
    return (
      <Box
        display="flex"
        alignItems="center"
        gap="20px"
        margin="0 auto"
        padding="16px"
      >
        {!isMobile && (
        <Box
          display="flex"
          width="72px"
          height="72px"
          justifyContent="center"
          alignItems="center"
          borderRadius="16px"
          background="rgba(255, 255, 255, 0.04)"
        >
          <Image src={footerIcon} width="24px" height="24px" flexShrink={0} />
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap="8px"
        >
          <Text
            color="#FFF"
            fontFamily="Gordita"
            fontSize="20px"
            fontWeight={500}
            lineHeight="132%"
          >
            We secure your money till you have your tickets!
          </Text>
          <Text
            color="rgba(255, 255, 255, 0.64)"
            fontFamily="Gordita"
            fontSize="14px"
            fontWeight={400}
            lineHeight="150%"
          >
            If you don't get your tickets in 12h, we will refund your purchase.
          </Text>
        </Box>
      </Box>
    );
  };
  