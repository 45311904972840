import React from "react";
import { HStack, Image } from "@chakra-ui/react";

import TextComponent from "./TextComponent";

import { useMediaQuery } from "../../../../utils/useMediaQuery";

function ImageTextComponent({
  imageSrc,
  title,
  subtitle,
  description,
  button,
  displayReverse,
  fullWidth = true,
  widthImage,
  heightImage,
  gapComponent,
  isTabletReverse=false
}) {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isTablet = useMediaQuery("(max-width: 1024px)");

  return (
    <HStack
      width={"100%"}
      justifyContent={"space-between"}
      flexDir={isTablet ? (isTabletReverse ? "column" : "column-reverse") : displayReverse ? "row-reverse" : "row"}
      gap={gapComponent ? gapComponent : "7.5rem"}
    >
      <Image
        src={imageSrc}
        maxW={widthImage ? widthImage : isMobile ? "100%" : "33.788rem"}
        maxH={heightImage ? heightImage : "46.25rem"}
        width={fullWidth && "100%"}
        flexShrink={0}
        borderRadius={isMobile ? "1.5625rem" : "3.125rem"}
      />
      <TextComponent
        title={title}
        subtitle={subtitle}
        description={description}
        button={button}
      />
    </HStack>
  );
}

export default ImageTextComponent;
