import DashboardLayout from "../modules/layout/dashboard";
import EventLayout from "../modules/layout/event";
import DashboardComponent from "../modules/pages/Dashboard";
import { userRoles } from "../utils/constants";
import withAuth from "../utils/withAuth";

/**
 * The Dashboard component is exported with authentication requirements for specific user roles.
 * @param props - props is an object that contains all the properties passed to the Dashboard
 * component. These properties can be accessed using dot notation, for example, props.propertyName.
 * @returns The `Dashboard` component wrapped with the `withAuth` higher-order component, which takes
 * in the `Dashboard` component as its first argument and an array of allowed user roles as its second
 * argument. The `Dashboard` component returns a `DashboardLayout` component with a
 * `DashboardComponent` nested inside it.
 */
const Dashboard = (props) => {
  return (
    <EventLayout {...props}>
      <DashboardComponent />
    </EventLayout>
  );
};

export default withAuth(Dashboard, [
  userRoles.ORGANIZER,
  userRoles.ADMIN,
  userRoles.PREMIUM_ORGANIZER,
]);
