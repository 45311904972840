import React from "react";
import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  Image,
  useToast,
  Icon,
} from "@chakra-ui/react";
import { useState } from "react";

function GuestList(props) {
  const { handleBlur, values, setFieldValue } = props;
  const [consdition, setCondition] = useState(false);
  return (
    <VStack w="100%" alignItems={"flex-start"}>
      <Text
        fontSize={"1.3125rem"}
        className="gordita600"
        textTransform={"capitalize"}
        mb={"1rem"}
      >
        Guest List
      </Text>
      <HStack
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
        flexWrap={"wrap"}
      >
        <div
          style={{
            display: "flex",
            alignItems: "start",
            gap: "10px",
          }}
        >
          <input
            type="checkbox"
            id="guestList"
            name="guestList"
            checked={values.guestList}
            value={values.guestList}
            onChange={(e) => {
              setFieldValue("guestList", e.target.checked);
              if (!e.target.checked) {
                setFieldValue("showGuestList", false);
              }
            }}
            style={{
              cursor: "pointer",
              marginTop: "2px",
              height: " 1rem",
              color: "#FFFFFF",
            }}
          />
          <label
            htmlFor="guestList"
            style={{
              color: "#FFFFFF",
              fontSize: "16px",
            }}
          >
            Enable guest list
          </label>
        </div>
        {values?.guestList && (
          <div
            style={{
              display: "flex",
              alignItems: "start",
              gap: "10px",
            }}
          >
            <input
              type="checkbox"
              id="showGuestList"
              name="showGuestList"
              checked={values.showGuestList}
              value={values.showGuestList}
              onChange={(e) => setFieldValue("showGuestList", e.target.checked)}
              style={{
                cursor: "pointer",
                marginTop: "2px",
                height: " 1rem",
                color: "#FFFFFF",
              }}
            />
            <label
              htmlFor="showGuestList"
              style={{
                color: "#FFFFFF",
                fontSize: "16px",
              }}
            >
              Show guest list on event page?
            </label>
          </div>
        )}
      </HStack>
    </VStack>
  );
}

export default GuestList;
