import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "../../../utils/api/axios";
import {
  RegistraionModalTypes,
  registration,
} from "../../../recoil/atoms/registration";
import {
  Box,
  Button,
  Divider,
  HStack,
  Text,
  VStack,
  Input,
  chakra,
  Image,
  useToast,
} from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import AppStore from "../../../static/assets/images/app-store.svg";
import GooglePlay from "../../../static/assets/images/google-play.svg";
import secrets from "../../../secrets";
function TicketsApp() {
  const toast = useToast();
  const handleAppStoreClick = () => {
    window.open(secrets.iosLink, '_blank');
  };

  const handleGooglePlayClick = () => {
    window.open(secrets.androidLink, '_blank');
  };
  const handlePortalysDownloadClick = () => {
    window.open(secrets.portalysDownloadLink, '_blank');
  };
  return (
    <Box w="100%" padding="32px" justifyContent="center" textAlign="center">
      <VStack>
        <Text className="gordita700" fontSize={28} marginBottom={"12px"}>
        Your tickets are in the app!
        </Text>

        <Text className="gordita700" fontSize={20} color="#FFFFFFA3">
        Access with your number to see the tickets and your dynamic QR code.
        </Text>
      </VStack>
      <HStack marginTop={"24px"} justifyContent="center" gap="20px">
        <Box
          onClick={handleAppStoreClick}
          cursor="pointer"
        >
          <Image
            src={AppStore}
            style={{
              width: "120px",
              height: "40px",
            }}
          />
        </Box>
        <Box
          onClick={handleGooglePlayClick}
          cursor="pointer"
        >
          <Image
            src={GooglePlay}
            style={{
              width: "135px",
              height: "40px",
            }}
          />
        </Box>
      </HStack>
    </Box>
  );
}

export default TicketsApp;
