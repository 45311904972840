import {
  Box,
  Button,
  chakra,
  Divider,
  Flex,
  Image,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { React, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import DashboardLayout from "../../modules/layout/dashboard";
import { user } from "../../recoil/atoms/user";
import facebook from "../../static/assets/images/fb.svg";
import "../../styles/profile/profile.css";
import { fetchUserData } from "../../utils/actions/registration";

/**
 * The Settings function is a React component that renders a form for users to update their profile
 * information, including their name, email, website, social media links, and profile image.
 * @param props - An object containing the properties passed down to the Settings component.
 * @returns A functional component named "Settings" is being returned.
 */
export default function NotAvailable(props) {
  return (
    <Box background="black" paddingBottom="150px">
      AVAILABLE ON MOBILE ONLY.
    </Box>
  );
}
