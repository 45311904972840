import React, { useState } from 'react';
import { Box, Text, Button, Image, Input, Select } from '@chakra-ui/react';
import userIcon from '../../../static/assets/images/defaultProfileImage.svg';
import eyeIcon from '../../../static/assets/images/ic_round-visibility.svg';
import sendIcon from '../../../static/assets/images/sendIcon.svg';
import sendIconWhite from '../../../static/assets/images/sendIconWhite.svg';
import usdIcon from '../../../static/assets/images/usdIcon.svg';
import arrowDown from '../../../static/assets/images/arrowDown.svg';
import { theme } from '../../../styles/theme/base';
import footerIcon from '../../../static/assets/images/ic_round-lock(1).svg';
import checkIcon from '../../../static/assets/images/check.svg';
import plusIcon from '../../../static/assets/images/plus.svg';

export const ResellTabs = ({ handleTabClick, selectedTab }) => {
  return (
    <Box
      display="flex"
      height="40px"
      padding="4px"
      alignItems="flex-start"
      alignSelf="stretch"
      borderRadius="24px"
      border="1px solid rgba(255, 255, 255, 0.24)"
      background="#000"
      boxShadow="0px 1px 4px 0px rgba(0, 0, 0, 0.40), 0px 0px 16px 16px rgba(0, 0, 0, 0.40)"
    >
      <Box
        as="button"
        display="flex"
        padding="0 16px"
        justifyContent="center"
        alignItems="center"
        gap="5px"
        flex="1 0 0"
        alignSelf="stretch"
        borderRadius="20px"
        background={
          selectedTab === 'tickets'
            ? 'rgba(255, 255, 255, 0.08)'
            : 'transparent'
        }
        onClick={() => handleTabClick('tickets')}
        minWidth="0"
      >
        <Text
          color={
            selectedTab === 'tickets' ? '#FFF' : 'rgba(255, 255, 255, 0.64)'
          }
          textAlign="center"
          fontFamily="Gordita"
          fontSize="16px"
          fontStyle="normal"
          fontWeight="700"
          lineHeight="32px"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          width="100%"
        >
          Tickets Available
        </Text>
      </Box>

      <Box
        as="button"
        display="flex"
        padding="0 16px"
        justifyContent="center"
        alignItems="center"
        gap="5px"
        flex="1 0 0"
        alignSelf="stretch"
        borderRadius="20px"
        background={
          selectedTab === 'buyers' ? 'rgba(255, 255, 255, 0.08)' : 'transparent'
        }
        onClick={() => handleTabClick('buyers')}
        minWidth="0"
      >
        <Text
          color={
            selectedTab === 'buyers' ? '#FFF' : 'rgba(255, 255, 255, 0.64)'
          }
          textAlign="center"
          fontFamily="Gordita"
          fontSize="16px"
          fontStyle="normal"
          fontWeight="700"
          lineHeight="32px"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          width="100%"
        >
          Interested buyers
        </Text>
      </Box>
    </Box>
  );
};

export const SellerComponent = ({listing,handleClick, handleRemove, handleViewProof, handleSendListing, isInCart}) => {
  return (
    <Box
      display="flex"
      padding="8px 12px 8px 8px"
      justifyContent="space-between"
      alignItems="center"
      alignSelf="stretch"
      borderRadius="40px"
      border="1px solid rgba(255, 255, 255, 0.24)"
    >
      <Box display="flex" alignItems="center" gap="16px" flex="1" minWidth="0">
        <Box
          display="flex"
          width="48px"
          height="48px"
          justifyContent="center"
          alignItems="center"
          flexShrink="0"
        >
          <Box
            borderRadius="var(--Spacing-spacing-none, 48px)"
            background={`url(${listing.seller.image === 'undefined' || !listing.seller.image ? userIcon : listing.seller.image}) lightgray 50% / cover no-repeat`}
            width="48px"
            height="48px"
            flexShrink={0}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap="2px"
          minWidth="0"
          flex="1"
        >
          <Box display="flex" alignItems="center" gap="8px" width="100%">
            <Box display="flex" alignItems="center" gap="4px" width="100%" minWidth="0">
              <Text
                color="#FFF"
                fontFamily="Gordita"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="132%"
                flexShrink="0"
              >
                ${Number(listing.price).toFixed(0)}
              </Text>
              <Text
                color="rgba(255, 255, 255, 0.80)"
                fontFamily="Gordita"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="132%"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                flex="1"
                minWidth="0"
              >
                {listing.type}
              </Text>
            </Box>
          </Box>
          <Box display="flex" alignItems="flex-end" gap="4px" width="100%">
            <Text
              color="rgba(255, 255, 255, 0.64)"
              fontFamily="Gordita"
              fontSize="12px"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="124%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              flex="1"
              minWidth="0"
            >
              {listing.seller.firstName||""} 
            </Text>
          </Box>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" gap="15px" flexShrink="0">
      <Button
          display="flex"
          width="45px"
          height="45px"
          padding="0px 12px"
          justifyContent="center"
          alignItems="center"
          gap="8px"
          borderRadius="100px"
          border="1px solid rgba(255, 255, 255, 0.24)"
          background="transparent"
          onClick={handleSendListing}
        >
          <Image src={sendIconWhite} alt="Send Icon" width="18px" height="18px" />
        </Button>
        <Button
          display="flex"
          width="45px"
          height="45px"
          padding="0px 12px"
          justifyContent="center"
          alignItems="center"
          gap="8px"
          borderRadius="100px"
          border="1px solid rgba(255, 255, 255, 0.24)"
          background="transparent"
          onClick={handleViewProof}
        >
          <Image src={eyeIcon} alt="Eye Icon" width="18px" height="18px" />
        </Button>
        <Button
          display="flex"
          width="45px"
          height="45px"
          padding="0px 12px"
          justifyContent="center"
          alignItems="center"
          gap="8px"
          borderRadius="100px"
          border="1px solid rgba(255, 255, 255, 0.24)"
          background={isInCart ? "#FFF" : "transparent"}
          onClick={isInCart ? handleRemove : handleClick}
        >
         <Image src={isInCart? checkIcon: plusIcon} flexShrink={0} alt="Check Icon" width="50px" height="50px" />
        </Button>
      </Box>
    </Box>
  );
};

export const BuyerComponent = ({handleClick,offer,handleSendOffer}) => {
  return (
    <Box
      display="flex"
      padding="8px 12px 8px 8px"
      justifyContent="space-between"
      alignItems="center"
      alignSelf="stretch"
      borderRadius="40px"
      border="1px solid rgba(255, 255, 255, 0.24)"
    >
      <Box display="flex" alignItems="center" gap="16px" flex="1" minWidth="0">
        <Box
          display="flex"
          width="48px"
          height="48px"
          justifyContent="center"
          alignItems="center"
          flexShrink="0"
        >
          <Box
            borderRadius="var(--Spacing-spacing-none, 48px)"
            background={`url(${offer.buyer.image && offer.buyer.image !== '' ? offer.buyer.image : userIcon}) lightgray 50% / cover no-repeat`}
            width="48px"
            height="48px"
            flexShrink="0"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap="2px"
          minWidth="0"
          flex="1"
        >
          <Box display="flex" alignItems="center" gap="8px" width="100%">
            <Box display="flex" alignItems="center" gap="4px" width="100%">
              <Text
                color="#FFF"
                fontFamily="Gordita"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="132%"
                flexShrink="0"
              >
                ${Number(offer.price * offer.quantity).toFixed(0)}
              </Text>
              <Text
                color="rgba(255, 255, 255, 0.80)"
                fontFamily="Gordita"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="132%"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                flex="1"
              >
                {offer.quantity}x {offer.type}
              </Text>
            </Box>
          </Box>
          <Box display="flex" alignItems="flex-end" gap="4px" width="100%">
            <Text
              color="rgba(255, 255, 255, 0.64)"
              fontFamily="Gordita"
              fontSize="12px"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="124%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              flex="1"
            >
              {offer.buyer.firstName||""} 
            </Text>
          </Box>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" gap="15px" flexShrink="0">
      <Button
          display="flex"
          width="45px"
          height="45px"
          padding="0px 12px"
          justifyContent="center"
          alignItems="center"
          gap="8px"
          borderRadius="100px"
          border="1px solid rgba(255, 255, 255, 0.24)"
          background="transparent"
          onClick={handleSendOffer}
        >
          <Image src={sendIconWhite} alt="Send Icon" width="18px" height="18px" />
        </Button>
        <Button
          display="flex"
          height="44px"
          minWidth="80px"
          padding="0px 24px"
          justifyContent="center"
          alignItems="center"
          gap="5px"
          borderRadius="40px"
          border="1px solid rgba(255, 255, 255, 0.24)"
          background="#000"
          onClick={handleClick}
        >
          <Text
            color="#FFF"
            textAlign="center"
            fontFamily="Gordita"
            fontSize="16px"
            fontStyle="normal"
            fontWeight="700"
            lineHeight="150%"
          >
             Sell
          </Text>
        </Button>
      </Box>
    </Box>
  );
};

export const PlaceBidSection = ({ handlePlaceBid, isMobile,price, setPrice,quantity,setQuantity }) => {
  return (
    <Box
      display="flex"
      padding="24px"
      flexDirection="column"
      alignItems="flex-start"
      gap="24px"
      alignSelf="stretch"
      borderRadius="32px"
      border="1px solid rgba(255, 255, 255, 0.12)"
      background="rgba(255, 255, 255, 0.04)"
    >
      <Box
        display="flex"
        padding="0px 4px"
        justifyContent="center"
        alignItems="center"
        gap="4px"
        alignSelf="stretch"
      >
        <Text
          color="#FFF"
          fontFamily="Gordita"
          fontSize="16px"
          fontStyle="normal"
          fontWeight="700"
          lineHeight="132%"
          flex="1 0 0"
        >
          What is the MAX you're willing to pay per ticket?
        </Text>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        gap="20px"
        alignSelf="stretch"
      >
        <Box display="flex" alignItems="center" gap="24px" alignSelf="stretch">
          <Box
            display="flex"
            height="48px"
            padding="4px 16px 4px 4px"
            alignItems="center"
            //gap="16px"
            flex="1 0 0"
            borderRadius="10px"
            border="1px solid rgba(255, 255, 255, 0.20)"
            background="rgba(255, 255, 255, 0.05)"
          >
            <Box
              display="flex"
              width="40px"
              padding="9px"
              justifyContent="center"
              alignItems="center"
              gap="5px"
              alignSelf="stretch"
              borderRadius="5px"
              background="rgba(255, 255, 255, 0.05)"
            >
              <Image
                src={usdIcon}
                alt="USD Icon"
                width="18px"
                height="18px"
                flexShrink="0"
              />
            </Box>
            <Input
              color="rgba(255, 255, 255, 255)"
              fontFamily="Gordita"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="132%"
              background="transparent"
              width="100%"
              placeholder="Enter amount"
              border="none"
              _focus={{
                border: 'none',
                outline: 'none',
                boxShadow: 'none',
              }}
              value={price}
              type="number"
              onChange={(e) => setPrice(e.target.value)}
            />
            {!isMobile && (
            <Box
              display="flex"
              justifyContent="flex-end"
              padding={{ base: "6px", md: "9px" }}
              alignItems="center"
              gap="5px"
              borderRadius="5px"
              background="rgba(255, 255, 255, 0.05)"
              whiteSpace="nowrap"
              marginRight="-16px"
              fontSize={{ base: "12px", md: "14px" }}
            >
              Per Ticket
            </Box>
            )}
          </Box>
      

          <Select
            display="flex"
            width="138px"
            height="48px"
            padding="16px 8px 16px 20px"
            alignItems="center"
            gap="4px"
            borderRadius="16px"
            background="rgba(255, 255, 255, 0.08)"
            lineHeight="19.2px"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          >
            <option value="1"> 1 Ticket</option>
            <option value="2"> 2 Tickets</option>
            <option value="3"> 3 Tickets</option>
            <option value="4"> 4 Tickets</option>
            <option value="5"> 5 Tickets</option>
          </Select>
        </Box>

        <Box
          display={isMobile ? 'flex' : 'block'}
          justifyContent={isMobile ? 'center' : 'flex-start'}
          width="100%"
        >
          <Button
            fontWeight="700"
            textAlign="center"
            margin={isMobile ? '0 20px' : '20px 0'}
            fontSize="1rem"
            borderRadius="40px"
            outline="none"
            sx={theme.buttons.primary.shape}
            bg={theme.buttons.primary.default}
            color={theme.buttons.primary.color}
            className="gordita700"
            css={{
              width: '100%',
            }}
            _hover={{
              bg: theme.buttons.primary.hover,
              boxShadow: theme.buttons.primary.hoverBoxShadow,
              cursor: 'pointer',
            }}
            _active={{
              boxShadow: theme.buttons.primary.activeBoxShadow,
            }}
            _disabled={{
              bg: theme.buttons.primary.disabled,
              color: theme.buttons.primary.colorDisabled,
            }}
            onClick={handlePlaceBid}
          >
            Place Bid
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export const SecuredTickets = ({isMobile}) => {
    return (
        <Box
          display="flex"
          alignItems="center"
          gap="16px"
          alignSelf="stretch"
        >
  
          <Box
            display="flex"
            width="48px"
            height="48px"
            justifyContent="center"
            alignItems="center"
            borderRadius="16px"
            background="rgba(255, 255, 255, 0.04)"
          >
            <Image src={footerIcon} width="24px" height="24px" flexShrink={0} />
            </Box>
          
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap="4px"
          >
            <Text
              color="#FFF"
              fontFamily="Gordita"
              fontSize="14px"
              fontWeight={700}
              lineHeight="132%"
            >
              We secure your money till you get your tickets
            </Text>
            <Text
              color="rgba(255, 255, 255, 0.64)"
              fontFamily="Gordita"
              fontSize="12px"
              fontWeight={400}
              lineHeight="150%"
            >
              If you don't get your tickets, we will refund your purchase.
            </Text>
          </Box>
        </Box>
      );
  };
  
  export const TicketsSentToAccount = ({platform}) => {
      return (
        <Box
          display="flex"
          alignItems="center"
          gap="16px"
          alignSelf="stretch"
        >
  
          <Box
            display="flex"
            width="48px"
            height="48px"
            justifyContent="center"
            alignItems="center"
            borderRadius="16px"
            background="rgba(255, 255, 255, 0.04)"
          >
            <Image src={sendIcon} width="24px" height="24px" flexShrink={0} />
            </Box>
          
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap="4px"
          >
            <Text
              color="#FFF"
              fontFamily="Gordita"
              fontSize="14px"
              fontWeight={700}
              lineHeight="132%"
            >
              Tickets delivered to your {platform||'platform' } account or by email
            </Text>
            <Text
              color="rgba(255, 255, 255, 0.64)"
              fontFamily="Gordita"
              fontSize="12px"
              fontWeight={400}
              lineHeight="150%"
            >
              Tickets will be delivered to your {platform||'platform' } account or by email before the event.
            </Text>
          </Box>
        </Box>
      );
    };
    
    export const PaymentSentAfterTransfer = ({isMobile}) => {
        return (
            <Box
              display="flex"
              alignItems="center"
              gap="16px"
              alignSelf="stretch"
            >
      
              <Box
                display="flex"
                width="48px"
                height="48px"
                justifyContent="center"
                alignItems="center"
                borderRadius="16px"
                background="rgba(255, 255, 255, 0.04)"
                flexShrink={0}
              >
                <Image src={footerIcon} width="24px" height="24px" flexShrink={0} />
              </Box>
              
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                gap="4px"
              >
                <Text
                  color="#FFF"
                  fontFamily="Gordita"
                  fontSize="14px"
                  fontWeight={700}
                  lineHeight="132%"
                >
                  Payment will be sent to you after ticket transfer.
                </Text>
                <Text
                  color="rgba(255, 255, 255, 0.64)"
                  fontFamily="Gordita"
                  fontSize="12px"
                  fontWeight={400}
                  lineHeight="150%"
                >
                  We collect the money from the buyer when the tickets are purchased but for security reasons, we will send it to you after the ticket transfer.
                </Text>
              </Box>
            </Box>
          );
      };



      export const SecuredPayment = () => {
        return (
            <Box
              display="flex"
              alignItems="center"
              gap="16px"
              alignSelf="stretch"
            >
      
              <Box
                display="flex"
                width="48px"
                height="48px"
                justifyContent="center"
                alignItems="center"
                borderRadius="16px"
                background="rgba(255, 255, 255, 0.04)"
              >
                <Image src={footerIcon} width="24px" height="24px" flexShrink={0} />
                </Box>
              
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                gap="4px"
              >
                <Text
                  color="#FFF"
                  fontFamily="Gordita"
                  fontSize="14px"
                  fontWeight={700}
                  lineHeight="132%"
                >
                  Get securedly paid out via Stripe
                </Text>
               
              </Box>
            </Box>
          );
      };
      
      export const TicketTransfer = () => {
          return (
            <Box
              display="flex"
              alignItems="center"
              gap="16px"
              alignSelf="stretch"
            >
      
              <Box
                display="flex"
                width="48px"
                height="48px"
                justifyContent="center"
                alignItems="center"
                borderRadius="16px"
                background="rgba(255, 255, 255, 0.04)"
              >
                <Image src={sendIcon} width="24px" height="24px" flexShrink={0} />
                </Box>
              
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                gap="4px"
              >
                <Text
                  color="#FFF"
                  fontFamily="Gordita"
                  fontSize="14px"
                  fontWeight={700}
                  lineHeight="132%"
                >
                 Ticket transfer instructions provided to you
                </Text>
            

              </Box>
            </Box>
          );
        };
        