import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import axios from "../../../utils/api/axios";
import { RegistraionModalTypes, registration } from '../../../recoil/atoms/registration';
import { Box, Button } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
function CheckEmail() {

  return (
    <Box w="100%" h="80px" justifyContent="center" display="flex">
    <div style={{ fontWeight: 700 }}>    Check your inbox to verify your email!
   </div>
 
   </Box>
  );
}

export default CheckEmail;
