import React, { Fragment, useEffect, useRef, useState, useMemo } from "react";
import {
  Box,
  Text,
  VStack,
  Button,
  useToast,
} from "@chakra-ui/react";
import "../../styles/portalys/portalys.css";
import {
  registration,
} from "../../recoil/atoms/registration";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../modules/layout/layout";
import { getEventsBySlug } from "../../utils/actions/event";
import { isEmpty } from "lodash";
import moment from "moment";
import { useRecoilState } from "recoil";
import { ticket } from "../../recoil/atoms/tickets";
import { user } from "../../recoil/atoms/user";
import { theme } from "../../styles/theme/base";
import { useMediaQuery } from "../../utils/useMediaQuery";
import "./event-components/eventDetails.css";
import { Collapse } from "antd";
import { hexToHSL } from "../../helpers/fucntions/getThemeColor";
import { NotificationComponent } from "../../modules/shared/notificationComponent";
import PhoneInput from "react-phone-input-2/";
import "react-phone-input-2/lib/bootstrap.css";
import i18n from 'i18next';
import translationEN from './locales/en.json'
import translationFR from './locales/fr.json';
import translationES from './locales/es.json';
import axios from "../../utils/api/axios";
import { EventInfoComponent } from "./event-components/eventInfo";
import { MainWrapper } from "../../styles/layout/layout";


i18n.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
      translation: translationEN,
    },
    fr: {
      translation: translationFR,
    },
    es:{
      translation: translationES,
    }
  },
});

export default function GuestList(props) {
  const [_R, setRegistrationModal] = useRecoilState(registration);
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const [event, setEvent] = useState();
  const [_, setTicket] = useRecoilState(ticket);
  const [_U, setUser] = useRecoilState(user);
  const toast = useToast();
  const isTablet = useMediaQuery("(max-width: 991px)");
  const isMobile = useMediaQuery("(max-width: 760px)");
  const is24HourFormat = moment.locale() !== 'en';
  const TimeFormat = is24HourFormat ? 'HH:mm' : 'h:mm A';
  const [fullName, setFullName] = useState(
    (_U?.userData?.firstName ? _U?.userData?.firstName + " " : "") +
    (_U?.userData?.lastName ?? "")
  );
    const [phoneNumber, setPhoneNumber] = useState(_U?.userData?.phoneNumber||"")
  const [instagram, setInstagram] = useState("");

  const path = window.location.pathname;
  const isEmbed = path.includes('/embed/');
  const handlePhoneNumberChange = (value) => {
    // Check if the value is numeric or empty
    if (/^\d*$/.test(value) || value === "") {
      setPhoneNumber(value);
    }
  };

  let filteredColors =
    event?.extractedColors
      ?.sort((a, b) => b.area - a.area)
      .filter((color) => {
        // Count the number of "f"s in the color string
        let fCount = (color.hex.match(/f/g) || []).length;
        // Count the number of "f"s in the color string
        let zeroCount = (color.hex.match(/0/g) || []).length;

        // Keep the color if it has at most two "f"s
        return fCount <= 2 && zeroCount <= 3;
      }) || [];

  const { bgColor, accentColor } = hexToHSL(filteredColors?.[0]?.hex);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
          const res = await axios.post("/api/postGuestlist", 
            {
              uuid: event.uuid,
              fullName: fullName,
              instagram: instagram,
              phoneNumber: phoneNumber,
            },{
            headers: { "Content-Type": "application/json" },
            }

          );
          toast({
            position: "top-right",
            duration: 4000,
            render: ({ onClose }) => (
              <NotificationComponent
                title={
                  res?.status===200
                    ?  `${i18n.t("requestSent")}`
                    : "There was an error"
                }
                status={res?.status===200 ? "success" : "error"}
                onClose={onClose}
              />
            ),
          });
  
          if (res.status === 500) {
            const data = await res.json();
            // Display the error message to the user
            alert(data.error);
            throw new Error(data.error); // Throw an error to handle it further if needed
          } else {
              navigate(-1);
              setLoading(false);
            }



    } catch (error) {
      console.error("Error during form submission:", error);
      setLoading(false);
    }
  };
  

  const init = async () => {
    if (id) {
      try {
        const val = await getEventsBySlug(id, '');
        if (!isEmpty(val)) {
          setEvent(val);
          i18n.changeLanguage(val.language);
          if (val.isMintedCompletely) {
            setIsChecked(true);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    init();
  }, [id]);

  const isGetYourTicketsButtonDisabled =fullName=== "" || phoneNumber=== "" || instagram=== "" ;
  const guestlistbutton = (
    <Button
      fontWeight="700"
      textAlign="center"
      margin={isMobile ? "0 20px" : "20px 0"}
      fontSize="1rem"
      borderRadius="40px"
      outline="none"
      sx={theme.buttons.primary.shape}
      bg={theme.buttons.primary.default}
      color={theme.buttons.primary.color}
      className="gordita700"
      css={{
        width: "100%",
      }}
      _hover={{
        bg: !isGetYourTicketsButtonDisabled && theme.buttons.primary.hover,
        boxShadow:
          !isGetYourTicketsButtonDisabled &&
          theme.buttons.primary.hoverBoxShadow,
        cursor: !isGetYourTicketsButtonDisabled ? "pointer" : "default",
      }}
      _active={{
        boxShadow:
          !isGetYourTicketsButtonDisabled &&
          theme.buttons.primary.activeBoxShadow,
      }}
      _disabled={{
        bg: theme.buttons.primary.disabled,
        color: theme.buttons.primary.colorDisabled,
      }}
      onClick={handleSubmit}
      isDisabled={ fullName=== "" || phoneNumber=== "" || instagram=== "" } // Add the isDisabled prop here
    >
       {i18n.t("requestAccess")}
    </Button>
  );


  const TicketDetails = (
    <>
      <Box width={"100%"} pos="relative">
         <EventInfoComponent
         eventName={event?.eventName}
         accentColor={accentColor}
         startTime={event?.startTime}
         endTime={event?.endTime}
         startDate={event?.startDate}
         city={event?.city}
         location={event?.location}
         numberOfLikes={event?.numberOfLikes}
         hideLikes={true}
         seePerks={false}
         sawPerks={false}
         isMobile={isMobile}
         moment={moment}
         TimeFormat={TimeFormat}
         people={i18n.t("people")}
         by={i18n.t("by")}

         />
        
      

      </Box>

      <Box className="div-separator" />

      {!event?.isOver && event?.guestList ? (
        <Box width={"100%"}>
          <VStack width={"100%"} margin="0 auto" className="regularcmards">
            <Box
              display="block"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
              className="darkcard"
            >
              {event?.ticketTypes?.length > 0 && (
                <Text
                  fontSize={"1.3125rem"}
                  className="gordita600"
                  lineHeight={"160%"}
                  color={"#FFFFFF"}
                  marginBottom={"0.75rem"}
                >
                   {i18n.t("guestList")}
                </Text>
              )}
              <Box display={"flex"} flexDir={"column"} gap={"0.94rem"}>
              <div style={{ marginBottom: "20px", width: "100%" }}>
            <input
              type="text"
              id="fullName"
              name="fullName"
              placeholder= {i18n.t("fullName")}
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              style={{
                padding: "1.25rem",
                borderRadius: "0.625rem",
                border: "1px solid rgba(255, 255, 255, 0.20)",
                backgroundColor: "transparent",
                width: "100%",
                height: "3.4375rem",
              }}
              required // Make the input field mandatory
            />
          </div>
          <div style={{ marginBottom: "20px", width: "100%" }}>
          <PhoneInput
                              inputProps={{
                                name: "phoneNumber", // Specify the name for the input field
                                placeholder:  `${i18n.t("phoneNumber")}`,
                                style: {
                                  width: "100%",
                                  border: "1px solid #FFFFFF",
                                  borderRadius: "6.25rem",
                                  height: "3.375rem",
                                  fontSize: "14px",
                                  color: "#FFFFFF",
                                  backgroundColor: "transparent",
                                },
                              }}
                              style={{
                                marginTop: "0.30rem",
                              }}
                              dropdownStyle={{ backgroundColor: "#000000" }}
                              country={"us"} // Set your default country
                              value={phoneNumber} // Set the value explicitly
                              onChange={handlePhoneNumberChange} // Handle input changes
                            />
          </div>
          <div style={{ marginBottom: "20px", width: "100%" }}>
            <input
              type="text"
              id="instagram"
              name="instagram"
              placeholder={i18n.t("instagram")}
              value={instagram}
              onChange={(e) => setInstagram(e.target.value)}
              style={{
                padding: "1.25rem",
                borderRadius: "0.625rem",
                border: "1px solid rgba(255, 255, 255, 0.20)",
                backgroundColor: "transparent",
                width: "100%",
                height: "3.4375rem",
              }}
              required // Make the input field mandatory
            />
          </div>
          <div>
          <Text fontSize={"10"}>*By requesting access to the Guest List, you consent to receive messages from us regarding your Guest List approval status, as well as promotional content related to the organizer.</Text>

    </div>
              </Box>

            </Box>
          </VStack>

          {!isMobile && !isTablet && (
            <VStack
              width={"100%"}
              margin="1.5rem auto"
              display="flex"
              className="regularcards"
            >
              <Box width={"100%"} className="darkcard">
                <Box>
                  <Box
                    borderBottom={"1px solid rgba(255, 255, 255, 0.10)"}
                    padding={"1.31rem 0"}
                  >
                    
                  </Box>

                  {!isMobile && !isTablet && guestlistbutton}
                </Box>
                  </Box>
            </VStack>
          )}
         
        </Box>
      ) : (
        <Box>
          <Text color="red" fontWeight="700">
            NO GUESTLIST AVAILABLE. GUESTLIST IS CLOSED
          </Text>
        </Box>
      )}
    </>
  );

  const content = event !== undefined ? (

    <>
    <Box className={"event-details-wrapper"}>
      <Box className={"event-left-components"}>
        <img
          src={event?.eventImage}
          style={{
            maxWidth: "100%",
            maxHeight: "722px",
            width: "100%",
            borderRadius: "40px",
            objectFit: "fill",
            marginBottom: isTablet ? 0 : "2.81rem",
          }}
          alt={"event-image"}
        />

        {(isMobile || isTablet) && (
          <Box margin={"1.88rem 0"}>{TicketDetails}</Box>
        )}

      </Box>
      <Box className={"event-separator"} />

      {!isMobile && !isTablet && (
        <Box className={"event-right-components"}>{TicketDetails}</Box>
      )}
    </Box>
    {(isTablet || isMobile) && (
<Box
  position={"sticky"}
  bottom={"0"}
  width={"100%"}
  maxW={"1200px"}
  display={"flex"}
  gap={"0.62rem"}
  flexDir={"column"}
  justifyContent={"center"}
  alignItems={"center"}
  borderRadius={"40px 40px 0 0"}
  bg={bgColor}
  padding={"1.25rem"}
  borderTop="1px solid rgba(255, 255, 255, 0.20)"
  backdropFilter={"blur(75px)"}
>
  {guestlistbutton}
</Box>
)}

  </>): (
          <div
            style={{
              margin: "0 auto",
              fontSize: "2rem",
              fontWeight: "700",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            Loading..
          </div>
        );
      


  return (
    <Box bg={bgColor}>
      {isEmbed? (
      <MainWrapper {...props} bgColor={bgColor}>   
        {content}
      </MainWrapper>
      ):(
        <Layout {...props} bgColor={bgColor}>   
        {content}
      </Layout>

      )}
    </Box>
  );
}
