/* This code is importing the dayjs library and its plugins for custom parsing and localized
formatting. It then sets the locale to English and extends the dayjs object with the custom parsing
and localized formatting plugins. This allows for more flexibility in parsing and formatting dates
and times in JavaScript. */
import dayjs from "dayjs";
import "dayjs/locale/en";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.locale("en");
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
