import EventLayout from "../../modules/layout/event";
import UsersComponent from "../../modules/pages/Admin/UsersComponent/index";
import { userRoles } from "../../utils/constants";
import withAuth from "../../utils/withAuth";

/**
 * This is a React component that renders a dashboard layout with a UsersComponent and requires
 * authentication for certain user roles.
 * @param props - props is an object that contains all the properties passed to the Users component.
 * These properties can be accessed using dot notation, for example, props.propertyName.
 * @returns The `Users` component is being returned, which is a functional component that renders a
 * `DashboardLayout` component with a prop `dashboardName` set to "Events" and a child component
 * `UsersComponent`. The `withAuth` higher-order component is also applied to the `Users` component
 * with an array of user roles as the second argument.
 */
const Users = (props) => {
  return (
    <EventLayout {...props} dashboardName="Events">
      <UsersComponent />
    </EventLayout>
  );
};

export default withAuth(Users, [
  userRoles.ADMIN,
  userRoles.ORGANIZER,
  userRoles.PREMIUM_ORGANIZER,
]);
