import React from 'react';
import { Box, Text } from "@chakra-ui/react";

const AndroidPage = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      textAlign="center"
    >
      <Text
        fontSize="2xl"
        fontWeight="bold"
      >
        The Portalys Android App is Coming Soon!!
      </Text>
    </Box>
  );
};

export default AndroidPage;

