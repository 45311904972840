import {
  Box,
  HStack,
  Stack,
  Table,
  Thead,
  Tbody,
  Th,
  Td,
  TableContainer,
  Text,
  Tr,
  useColorModeValue,
  Button,
  useToast,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  VStack,
  Checkbox,
  useDisclosure,
  Textarea,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../../utils/api/axios";
import secrets from "../../../../secrets";
import { useRecoilState } from "recoil";
import { user } from "../../../../recoil/atoms/user";
import { theme } from "../../../../styles/theme/base";
import { getEvents } from "../../../../utils/actions/event";
import { isEmpty } from "lodash";
import "../sharedAdminStyles.css";

const moment = require("moment");

const spacing = {
  gap: 0,
  spacing: 0,
  w: "100%",
};
const generateRandomString = (length) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
};
/**
 * The UsersComponent function displays a table of attendees or organizers, with options to view
 * details or approve organizers if the user is an admin.
 * @returns The `UsersComponent` functional component is being returned.
 */
const SMSComponent = () => {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const navigate = useNavigate();
  const type = window.location.href?.includes("organizers")
    ? "ORGANIZER"
    : "ATTENDEE";
  const [_, setUser] = useRecoilState(user);
  const colorValue = useColorModeValue("black.100", "gray.100");
  const [currentPage, setCurrentPage] = useState(1);
  const toast = useToast();
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [checkedItems, setCheckedItems] = useState([false, false]);
  const [events, setEvents] = useState([]);
  const [isAllEventsSelected, setIsAllEventsSelected] = useState(false);
  const [verifierId, setVerifierId] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedEvents, setSelectedEvents] = useState([]);
  const currentDate = new Date();
  const formattedCurrentDate = currentDate.toISOString().split("T")[0]; // Format 'YYYY-MM-DD'
  const organizerId = _.userData?.organizerId || _.userData?.userId;
  const [internalName, setInternalName] = useState("");
  const [message, setMessage] = useState("");
  const [characterCount, setCharacterCount] = useState(0);
  const [contactLists, setContactLists] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleInternalNameChange = (event) => {
    // Update the internalName state with the new value from the input
    setInternalName(event.target.value);
  };
  const handleMessageChange = (event) => {
    // Update the internalName state with the new value from the input
    const newValue = event.target.value;
    setMessage(newValue);
    // Update the character count
    setCharacterCount(newValue.length);
  };

  const handleCheckboxToggle = (id) => {
    setEvents((prevEvents) =>
      prevEvents.includes(id)
        ? prevEvents.filter((eventId) => eventId !== id)
        : [...prevEvents, id]
    );
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = data.filter(
    (item) =>
      item.eventName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.databaseName?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredContactLists = contactLists.filter((list) =>
    list.listName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCopyCode = async (codeToCopy) => {
    try {
      // Attempt to copy the code to the clipboard
      await navigator.clipboard.writeText(codeToCopy);
      toast({
        title: "Code Copied",
        status: "success",
        isClosable: true,
        duration: 2000,
        position: "top-right",
      });
    } catch (error) {
      console.error("Error copying code:", error);
      toast({
        title: "Copy Error",
        description: "Failed to copy the code. Please try again.",
        status: "error",
        isClosable: true,
        duration: 4000,
        position: "top-right",
      });
    }
  };
  const handleCopyCode2 = () => {
    // Copy the code to the clipboard
    navigator.clipboard.writeText(code);
  };

  useEffect(() => {
    if (isModalOpen) {
      // Generate a new random code when the modal is opened
      setEmail("");
      setName("");
      setEvents([]);
      setCode(generateRandomString(8));
    }
  }, [isModalOpen]);

  const getOrganizerLists = async () => {
    try {
      const token = localStorage.getItem("x-auth-token");

      let url = "/api/lists/" + organizerId;
      const response = await axios.get(url, {
        headers: {
          "x-auth-token": "Bearer " + token,
        },
      });
      setContactLists(response?.data?.lists);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const init = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user_d"));
      const token = localStorage.getItem("x-auth-token");
      if (user?.userRole === "ADMIN") {
        await axios
          .get("/api/events/admin", {
            headers: {
              "x-auth-token": "Bearer " + token,
            },
          })
          .then((res) => {
            const data = res.data;
            if (!isEmpty(data)) {
              const newEvents = [];
              Object.values(data).forEach((et) => {
                newEvents.push({ ...et });
              });
              console.log("newevents1", newEvents);
              setData(newEvents);
            }
          })
          .catch((err) => {
            console.log({ err });
          });
      } else {
        getOrganizerLists();
        await axios
          .get("/api/smsdatabases/organizer/" + organizerId, {
            headers: {
              "x-auth-token": "Bearer " + token,
            },
          })
          .then((res) => {
            const data = res.data;
            console.log('smsdatabases data', data)
            if (!isEmpty(data)) {
              const newEvents = [];
              Object.values(data).forEach((et) => {
                newEvents.push({ ...et });
              });
              console.log("smsdatabases", newEvents);
              setData(newEvents);
            }
          })
          .catch((err) => {
            console.log({ err });
          });

        if (!isEmpty(val)) {
          console.log("checkpoint3");
          const newEvents = [];
          Object?.values(val)?.forEach((et) => {
            newEvents.push({ ...et });
          });
          console.log("newevents2", newEvents);
          setData(newEvents);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    init();
  }, []);

  const handleButtonClick = () => {
    setMessage("");
    setInternalName("");
    setIsModalOpen(true);
  };
  const handleButtonClick2 = () => {
    setSelectedEmail(email);
    setIsModalOpen2(true);
  };

  const createSMSBlast = async () => {
    try {
      const payload = {
        uuids: events,
        message: message,
        internalName: internalName,
        organizerId: organizerId,
      };
      let url = "/api/createSMSBlast";
      const response = await axios.post(url, payload, {
        headers: {
          "x-auth-token": "Bearer " + _?.token,
        },
      });
      if (response.data.success) {
        toast({
          title: "SMS Blast Initiated Successfully!",
          status: "success",
          isClosable: true,
          duration: 1500,
          position: "top-right",
        });
        getSMSBlasts();

        setIsModalOpen(false);
      } else {
        toast({
          title: response?.data?.message,
          status: "error",
          isClosable: true,
          duration: 1500,
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast({
        title: "Error Sending SMS Blast",
        status: "error",
        isClosable: true,
        duration: 1500,
        position: "top-right",
      });
    }
  };
  const getSMSBlasts = async () => {
    try {
      const token = localStorage.getItem("x-auth-token");

      let url = "/api/getSMSBlasts/" + organizerId;
      const response = await axios.get(url, {
        headers: {
          "x-auth-token": "Bearer " + token,
        },
      });
      console.log('smsblast',response.data)
      setData2(response?.data?.blasts);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getSMSBlasts();
  }, [_.userData?.userId]);

  const isAllEventsToggled =
    data?.map((event) => event?.uuid).length === events.length;

  const commonButtonStyle = {
    display: "flex",
    height: "45px",
    padding: "15px 25px",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    flex: "1 0 0",
    borderRadius: "100px",
  };

  const selectedButton = {
    ...commonButtonStyle,
    background: "var(--colors-shades-white-10, rgba(255, 255, 255, 0.10))",
    color: "white",
  };

  const notSelectedButton = {
    ...commonButtonStyle,
    background: "none",
    color: "#FFFFFF99",
  };

  return (
    <Box
      w="100%"
      style={{
        fontFamily: "Gordita Regular",
        width: "100%",
        height: "100vh",
        overflow: "scroll",
      }}
    >
      {" "}
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
        <ModalOverlay />
        <ModalContent
          bg="black.100"
          border="1px solid #333333"
          borderRadius="1.25rem"
        >
          <ModalHeader color="white.100">Cancel</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete this verifier? This action cannot be
            undone.
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onClose}
              sx={theme.buttons.primary.shape}
              bg={theme.buttons.secondary.default}
              color={theme.buttons.secondary.color}
              _hover={{ bg: theme.buttons.secondary.hover }}
              style={{
                width: "fit-content",
              }}
              mr={3}
            >
              Cancel
            </Button>
            <Button
              //onClick={removeVerifier}
              sx={theme.buttons.primary.shape}
              bg={theme.buttons.primary.default}
              color={theme.buttons.primary.color}
              _hover={{
                bg: theme.buttons.primary.hover,
                boxShadow: theme.buttons.primary.hoverBoxShadow,
              }}
              _active={{
                boxShadow: theme.buttons.primary.activeBoxShadow,
              }}
              style={{
                width: "5rem",
                padding: "0",
              }}
            >
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box w="100%" minH="80vh">
        <Stack
          {...{ spacing }}
          flexDir="row"
          color="#FBFAFB"
          pos={"sticky"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          top={0}
          gap={"10px"}
          flexWrap={"wrap"}
          p={"30px"}
          minHeight={"110px"}
          zIndex={10}
          w="100%"
          fontWeight={700}
          bg={"rgba(0, 0, 0, 0.50)"}
          backdropFilter={"blur(150px)"}
        >
          <Box className="team-component-header-title" fontSize={"32px"}>
            SMS/Emails
          </Box>

          <Box
            sx={{
              display: "flex",
              maxWidth: "634px",
              padding: "5px",
              alignItems: "flex-start",
              borderRadius: "100px",
              border:
                "1px solid var(--border-b-primary, rgba(255, 255, 255, 0.20))",
              background: "var(--surface-s-glass, rgba(0, 0, 0, 0.50))",
              backdropFilter: "blur(75px)",
            }}
            overflow={"scroll"}
            gap={"5px"}
            className="control-header-component-filters"
          >
            <Button
              onClick={() => {
                navigate("/blasts/email");
              }}
              sx={notSelectedButton}
              minW={"150px"}
              p={"5px 25px"}
            >
              Email Blasts
            </Button>
            <Button sx={selectedButton} minW={"150px"} p={"5px 25px"}>
              SMS Blasts
            </Button>
            <Button
              onClick={() => {
                navigate("/blasts/lists");
              }}
              sx={notSelectedButton}
              minW={"150px"}
              p={"5px 25px"}
            >
              Lists
            </Button>
          </Box>

          <Button
            onClick={handleButtonClick}
            sx={theme.buttons.primary.shape}
            bg={theme.buttons.primary.default}
            color={theme.buttons.primary.color}
            _hover={{
              bg: theme.buttons.primary.hover,
              boxShadow: theme.buttons.primary.hoverBoxShadow,
            }}
            _active={{
              bg: theme.buttons.primary.hover,
              boxShadow: theme.buttons.primary.activeBoxShadow,
            }}
            className="control-header-component-button"
          >
            Create SMS Blast
          </Button>
        </Stack>
        <Box w="100%" px={"30px"} marginBottom={"30px"}>
          <TableContainer>
            <Table
              style={{
                borderCollapse: "separate",
                borderSpacing: "0 18px",
                marginBottom: "30px",
              }}
              variant="unstyled"
            >
              <Thead
                borderBottom="1px solid"
                borderColor="rgba(153, 159, 187,0.2)"
              >
                <Tr>
                  {[
                    "Internal Name",
                    "Message",
                    "# Of Recipients",
                    "Date",
                    "Deliverability %",
                  ].map((column, index) => {
                    return (
                      <Th
                        key={index}
                        paddingBottom={"20px"}
                        textTransform="capitalize"
                        color="var(--surface-surface-secondary, #959597)" // Added color
                        fontFamily="Gordita" // Added font family
                        fontSize="1rem" // Added font size
                        fontStyle="normal"
                        fontWeight={400} // Added font weight
                        lineHeight="150%" // Added line height
                        letterSpacing="-0.01rem" // Added letter spacing
                        position="relative"
                        textAlign={"center"}
                        border={"none"}
                        borderRadius="0rem" // Added border radius
                        borderRight="0px solid rgba(255, 255, 255, 0.10)" // Added border right
                        borderBottom="1px solid rgba(255, 255, 255, 0.10)" // Added border bottom
                        borderLeft="0px solid rgba(255, 255, 255, 0.10)" // Added border left
                        background="rgba(0, 0, 0, 0.50)" // Added background
                        backdropFilter="blur(75px)" // Added blur
                      >
                        {column}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody marginBottom={"30px"}>
                {Object?.values(data2)?.map(
                  (
                    {
                      internalName,
                      message,
                      numberOfPeople,
                      createdAt,
                      deliverabilityPercentage,
                    },
                    index
                  ) => {
                    // Convert the events JSON object into an array of event IDs
                    console.log("aaa", deliverabilityPercentage);
                    return (
                      <Tr overflow="hidden" color="white.100" key={index}>
                        <Td
                          m={0}
                          py={0}
                          pl={20}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          borderLeft={"1px solid rgba(255, 255, 255, 0.10)"}
                          borderTopLeftRadius={"0.9375rem"}
                          borderBottomLeftRadius={"0.9375rem"}
                          overflow={"hidden"}
                          maxWidth={"15rem"}
                        >
                          <HStack py={0} spacing={10}>
                            <Text
                              className="gordita400"
                              color="#FFF" // Added color
                              fontFeatureSettings="'ss08' on, 'ss04' on" // Added font feature settings
                              fontFamily="Gordita" // Added font family
                              fontSize="1.125rem" // Added font size
                              fontStyle="normal"
                              fontWeight={700} // Added font weight
                              lineHeight="110%" // Added line height
                              letterSpacing="-0.01125rem" // Added letter spacing
                            >
                              {`${internalName || ""}`}
                            </Text>
                          </HStack>
                        </Td>
                        <Td
                          m={0}
                          py={0}
                          textAlign={"center"}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          maxWidth={"14rem"}
                        >
                          <Text
                            fontSize={14}
                            className="gordita400"
                            style={{
                              maxWidth: "100%",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              textAlign: "center",
                            }}
                          >
                            <Text
                              fontSize={14}
                              className="gordita400"
                              style={{
                                maxWidth: "100%",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {message}
                            </Text>
                          </Text>
                        </Td>
                        <Td
                          m={0}
                          py={0}
                          textAlign={"center"}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          maxWidth={"14rem"}
                        >
                          <Text
                            className="gordita400"
                            color="#FFF"
                            fontFamily="Gordita"
                            fontSize="1.125rem"
                            fontStyle="normal"
                            fontWeight="500"
                            lineHeight="110%"
                            letterSpacing="-0.01125rem"
                            fontFeatureSettings="'ss08' on, 'ss04' on"
                          >
                            {numberOfPeople || 0}
                          </Text>
                        </Td>
                        <Td
                          m={0}
                          py={0}
                          textAlign={"center"}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          position={"relative"}
                          alignContent={"center"}
                          justifyContent={"center"}
                        >
                          {/* Make the cell relative to position the icon */}
                          <Text
                            className="gordita400"
                            color="#FFF"
                            fontFamily="Gordita"
                            fontSize="1.125rem"
                            fontStyle="normal"
                            fontWeight="500"
                            lineHeight="110%"
                            letterSpacing="-0.01125rem"
                            fontFeatureSettings="'ss08' on, 'ss04' on"
                          >
                            {moment(createdAt).format("MMMM DD, YYYY") || 0}
                          </Text>
                        </Td>
                        <Td
                          m={0}
                          pl={0}
                          textAlign={"center"}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          borderTopRightRadius={"0.9375rem"}
                          borderBottomRightRadius={"0.9375rem"}
                        >
                          <Text
                            className="gordita400"
                            color="#FFF"
                            fontFamily="Gordita"
                            fontSize="1.125rem"
                            fontStyle="normal"
                            fontWeight="500"
                            lineHeight="110%"
                            letterSpacing="-0.01125rem"
                            fontFeatureSettings="'ss08' on, 'ss04' on"
                          >
                            {deliverabilityPercentage === 0 ||
                            deliverabilityPercentage === null
                              ? "Sending..."
                              : `${deliverabilityPercentage * 100}%`}
                          </Text>
                        </Td>
                      </Tr>
                    );
                  }
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        size="6xl"
        isCentered
        zIndex={15}
      >
        <ModalOverlay />
        <ModalContent
          bg="black"
          border="1px solid #333333"
          borderColor="#333333"
          borderRadius="1.25rem"
        >
          <ModalHeader>Send SMS Blast</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack
              display={"flex"}
              alignItems={"start"}
              justifyContent={"center"}
              mt={"3rem"}
              gap={"50px"}
            >
              <VStack w={"100%"} maxW={"25rem"} spacing={"25px"}>
                <Box w="100%" spacing={0} alignItems="flex-start">
                  <Text
                    fontSize={"1.3125rem"}
                    mb="8px"
                    className="gordita600"
                    fontWeight={500}
                    textTransform={"capitalize"}
                  >
                    Internal name for SMS blast
                  </Text>
                  <Input
                    placeholder="Enter Bast Internal name"
                    value={internalName}
                    onChange={handleInternalNameChange}
                  />
                </Box>

                <Box w="100%" spacing={0} alignItems="flex-start">
                  <Text
                    fontSize={"1.3125rem"}
                    mb="8px"
                    className="gordita600"
                    fontWeight={500}
                    textTransform={"capitalize"}
                  >
                    Message to send out
                  </Text>
                  <Textarea
                    bg="#000000"
                    borderRadius=".625rem"
                    w="100%"
                    className="gordita400"
                    placeholder="Enter your message here"
                    border="1px solid rgba(255, 255, 255, 0.20)"
                    value={message}
                    onChange={handleMessageChange} //{handleTextareaChange} // Update the onChange handler
                    size="sm"
                    minH="199px"
                    color="#FFFFFF"
                    focusBorderColor="#FFFFFF"
                    _hover={{
                      borderColor: "#FFFFFF",
                    }}
                    _placeholder={{
                      color: "rgba(255, 255, 255, 0.40)",
                      fontSize: 16,
                    }}
                    maxLength={150}
                  />
                  <Text mt="4px" fontSize="sm" color="gray.400">
                    {characterCount}/150 characters
                  </Text>
                  <Text mt="4px" fontSize="sm">
                    <Text
                      as="span"
                      fontWeight="bold"
                      textDecoration="underline"
                    >
                      Tips:
                    </Text>{" "}
                    Let users know which organizer is sending them a message. Do
                    not use URL shorteners as they will be flagged by carriers
                    since it's a technique used to spam.
                  </Text>

                  <Text paddingTop={"20px"} mt="4px" fontSize="sm">
                    <Text
                      as="span"
                      fontWeight="bold"
                      textDecoration="underline"
                    >
                      Disclaimer:
                    </Text>{" "}
                    Above a certain number of SMS sent, we will contact you
                    regarding our SMS pricing. If you are in Europe, SMS sending
                    can get very expensive.
                  </Text>
                  {characterCount === 150 && (
                    <Text mt="2px" fontSize="sm" color="red.500">
                      Maximum character limit reached
                    </Text>
                  )}
                </Box>
              </VStack>
              <VStack w={"50%"}>
              <Text fontWeight="bold">Who do you want to send the blast to?</Text>

                {/* Search bar */}
                    <Input
                      borderRadius={"20px"}
                      placeholder="Search events or contact lists"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      my="1rem"
                    />

                <Box
                    m={".5rem 0"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    w={"100%"}
                    pr={"1.75rem"}
                  >
                  <Checkbox
                    isChecked={isAllEventsSelected}
                    colorScheme="white"
                    onChange={() => {
                      if (isAllEventsSelected) {
                        setIsAllEventsSelected(false);
                        setEvents([]);
                      } else {
                        setIsAllEventsSelected(true);
                        setEvents([
                          ...filteredData.map((event) => event.uuid),
                          ...filteredContactLists.map((list) => list.id),
                        ]);
                      }
                    }}
                  >
                    <Text pb=".35rem">Select All</Text>
                  </Checkbox>

                {events.length !== 0 && (
                <Box>
                <Text fontSize=".9rem">{events.length} Selected</Text>
                </Box>
                )}
                </Box>

                <Box maxHeight="25rem" overflow="auto">
                {filteredData.length !== 0 || filteredContactLists.length !== 0 ? (
                <>
                {/* Render filtered events */}
                {filteredData.map((event) => (
                <Checkbox
                colorScheme="white"
                isChecked={events.includes(event.uuid)}
                key={event.uuid}
                onChange={() => handleCheckboxToggle(event.uuid)}
                >
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    gap: ".25rem",
                    width: "20rem",
                    border: "1px solid rgba(255, 255, 255, 0.20)",
                    borderColor: events.includes(event.uuid)
                      ? "#fff"
                      : "rgba(255, 255, 255, 0.20)",
                    margin: ".25rem",
                    padding: ".5rem 1.75rem",
                    borderRadius: "50px",
                  }}
                >
                  <Text
                    fontSize="1rem"
                    fontWeight="700"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    w="100%"
                  >
                    {event.eventName || event.databaseName}
                  </Text>
                  <div style={{ display: "flex", gap: ".75rem" }}>
                    <Text fontSize=".7rem" fontWeight="400">
                      {event.startDate}
                    </Text>
                    <Text
                      fontSize=".7rem"
                      fontWeight="400"
                      color="rgba(255, 255, 255, 0.60)"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                    >
                      {event.location}
                    </Text>
                  </div>
                </div>
                </Checkbox>
                ))}

                {/* Render filtered contact lists */}
                {filteredContactLists.map((list) => (
                <Checkbox
                colorScheme="white"
                isChecked={events.includes(list.id)}
                key={list.id}
                onChange={() => handleCheckboxToggle(list.id)}
                >
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    gap: ".25rem",
                    width: "20rem",
                    border: "1px solid rgba(255, 255, 255, 0.20)",
                    borderColor: events.includes(list.id)
                      ? "#fff"
                      : "rgba(255, 255, 255, 0.20)",
                    margin: ".25rem",
                    padding: ".5rem 1.75rem",
                    borderRadius: "50px",
                  }}
                >
                  <Text
                    fontSize="1rem"
                    fontWeight="700"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    w="100%"
                  >
                    {list.listName}
                  </Text>
                  <Text fontSize=".7rem" fontWeight="400">
                    {list.totalContacts} Contacts
                  </Text>
                </div>
                </Checkbox>
                ))}
                </>
                ): (
                      <>
                        <Text
                          marginTop="4rem"
                          fontSize="1.25rem"
                          fontWeight="700"
                        >
                          You have no upcoming events
                        </Text>
                        <Button
                          marginTop="1.56rem"
                          sx={theme.buttons.tercary.shape}
                          color={theme.buttons.tercary.color}
                          border={theme.buttons.tercary.border}
                          onClick={() =>
                            navigate("/events/create", {
                              state: { isEditMode: false },
                            })
                          }
                        >
                          Create an Event!
                        </Button>
                      </>
                    )}
                  </Box>
              </VStack>
            </HStack>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => setIsModalOpen(false)}
              sx={theme.buttons.tercary.shape}
              bg={theme.buttons.tercary.bgColor}
              color={theme.buttons.tercary.color}
              _hover={{ bg: theme.buttons.tercary.hover }}
              css={{
                width: "fit-content !important",
                padding: "1.25rem",
                marginRight: "10px",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={createSMSBlast}
              sx={theme.buttons.primary.shape}
              bg={
                internalName && message && events.length > 0
                  ? theme.buttons.primary.default
                  : theme.buttons.primary.disabled
              }
              color={
                internalName && message && events.length > 0
                  ? theme.buttons.primary.color
                  : theme.buttons.primary.colorDisabled
              }
              _hover={{
                bg: theme.buttons.primary.hover,
                boxShadow: theme.buttons.primary.hoverBoxShadow,
              }}
              _active={{
                boxShadow: theme.buttons.primary.activeBoxShadow,
              }}
              style={{
                width: "6rem",
              }}
              disabled={
                internalName && message && events.length > 0 ? "" : "true"
              }
            >
              Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SMSComponent;