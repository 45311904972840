import { React, useEffect, useState, useCallback } from "react";
import {
  Box,
  Text,
  VStack,
  Image,
  Progress as LinearProgress,
  Heading,
  HStack,
  Button,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useRecoilState } from "recoil";
import { ticket } from "../../../recoil/atoms/tickets";
import { user } from "../../../recoil/atoms/user";
import secrets from "../../../secrets";
import withAuth from "../../../utils/withAuth";
import { userRoles } from "../../../utils/constants";
import { theme } from "../../../styles/theme/base";
import { useMediaQuery } from "../../../utils/useMediaQuery";
import getSymbolFromCurrency from "currency-symbol-map";
import { ReactComponent as ArrowDownIcon } from "../../../static/assets/images/arrowDownIcon.svg";
import { ReactComponent as ArrowUpIcon } from "../../../static/assets/images/arrowUpIcon.svg";
import { TypeArrowback } from "../../../static/assets/images/dashboard/sidebar";
import moment from "moment";
import  Countdown  from 'react-countdown';
import i18n from 'i18next';
import translationEN from '../../portalys/locales/en.json'
import translationFR from '../../portalys/locales/fr.json';
import translationES from '../../portalys/locales/es.json';
import axios from "../../../utils/api/axios";
import { EventInfoComponent } from "../../portalys/event-components/eventInfo";
import { MainWrapper } from "../../../styles/layout/layout";
import { TicketsInAppComponent } from "../../appPromo/downloadApp";

i18n.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
      translation: translationEN,
    },
    fr: {
      translation: translationFR,
    },
    es:{
      translation: translationES,
    },
  },
});

function Detail(props) {
  const isMobile = useMediaQuery("(max-width: 905px)");
  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [stripeAccount, setStripeAccount] = useState("");
  const stripePromise = loadStripe(secrets.stripeKey, {
    stripeAccount: stripeAccount,
  });
  const [promoCode, setPromoCode] = useState("");
  const [_, setTicket] = useRecoilState(ticket);
  const [_U, setUser] = useRecoilState(user);
  const navigate = useNavigate();
  const [seeDetails, setSeeDetails] = useState(false);
  const [moreInfo, setIsMoreInfo] = useState(false); 
  const [extra, setExtra] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [showError, setShowError] = useState(false);
  const toast = useToast();
  const is24HourFormat = moment.locale() !== 'en';
  const TimeFormat = is24HourFormat ? 'HH:mm' : 'h:mm A';
  const [hostedUrl, setHostedUrl] = useState('');
  const [showInstagram, setShowInstagram] = useState(false);


  let temp = 0; 
  const appearance = {
    theme: "night",
    variables: {
      colorPrimary: "#FFFFFF",
      colorBackground: "#141414",
      colorText: "#FFFFFF",
      colorDanger: "#df1b41",
      fontFamily: "Ideal Sans, system-ui, sans-serif",
      // See all possible variables below
    },
  };
  const options = {
    clientSecret,
    appearance,
  };


  useEffect(() => {
    if (!_?.ticketSelected) {
      navigate("/");
    }
  }, []);



  const fetchCheckout = async () => {
    setClientSecret("");
    const walletAddress = _U.userData?.walletAddress;
    if (_?.donationAmount !== 0) {
      temp += _?.donationAmount;
      setIsMoreInfo(true);
      setExtra(temp);
    }
    if (_?.taxi) {
      temp += Math.ceil(_?.perksTotal+_?.total+temp+(_?.total+_?.perksTotal)*_?.participant_fee_percentage+_?.quantity*_?.participant_fee_fixed) - (_?.perksTotal+_?.total+temp+(_?.total+_?.perksTotal)*_?.participant_fee_percentage+_?.quantity*_?.participant_fee_fixed);
      setExtra(temp);
      setIsMoreInfo(true);
    }
    await axios.post("/api/checkout", 
    {
      id: _.uuid,
      ticketSelected: _.ticketSelected,
      quantity: _.quantity,
      walletAddress,
      userId: _U.userData?.userId,
      promoter: _?.promoterSlug,
      taxi: _?.taxi,
      extracharge: temp,
      codeName: promoCode,
      perkSelected:_?.perkSelected,
      cryptoPayments:_?.cryptoPayments,
    },
    {
      headers: { 
      "x-auth-token": _?.token,
       },
    })
      .then((res) => {
        if (res.status === 201) {
          alert(res.data.error); // Assuming the error message is in res.data.error
          window.history.back();
          throw new Error(res.data.error); 
        }
  
        return res.data; // Directly return the parsed data for other statuses
      })
      .then((data) => {
        setDiscount(Number(data?.discount) || 0);

        if (Number(data?.discount)===100.00)
        {
          setTicket((lp) => {
            return {
              ...lp,
              codeName:promoCode

            };
          });
          navigate('/booking/rsvp');
        }
        setClientSecret(data?.client_secret);
        setStripeAccount(data?.stripeAccount);
        setPaymentIntentId(data?.paymentId);
        setShowError(data?.flag); // Throw an error to handle it further if needed
        setHostedUrl(data?.hostedUrl);
        if (data?.discount > 0.0) {
          toast({
            title: "Promo Code Applied!",
            description: "Promo Code Applied Successfully",
            status: "success",
            isClosable: true,
            duration: 4000,
            position: "top",
          });
        }
        
      })
      .catch((error) => {
        // Log the error for debugging
        console.error("Error during fetch:", error);
      });
  };

  const cancelPaymentIntent = useCallback(async () => {
    if (clientSecret) {
      try {
        await axios.post("/api/cancelCheckout", {
          paymentId: paymentIntentId,
          stripeAccount: stripeAccount,
        }, {
          headers: { 
            "x-auth-token": _?.token,
          },
        });
        console.log("Payment intent cancelled successfully");
      } catch (error) {
        console.error("Error cancelling payment intent:", error);
      }
    }
  }, [clientSecret, _?.token]);

  useEffect(() => {
    return () => {
      cancelPaymentIntent();
    };
  }, [cancelPaymentIntent]);

  useEffect(() => {
    i18n.changeLanguage(_?.language);
    fetchCheckout();
    const hasInstagramTicket = _?.ticketSelected?.some(ticket => ticket?.instagram === true);

    if (hasInstagramTicket) {
      setShowInstagram(true);
    }
    
  }, []);


  const ticketType = `${i18n.t("tickets")}`;

  const ticketDetails = (
    <VStack width={"100%"} gap={"1.87rem"}>
      {_?.ticketSelected.length>0 && (
      <Box width={"100%"}>
        <Box marginBottom={"0.94rem"}>
          <Text
            fontSize={"1.1875rem"}
            fontWeight={"500"}
            className={"gordita600"}
            color={"rgba(255, 255, 255, 0.60)"}
          >
            {ticketType}
          </Text>
        </Box>
        {_?.ticketSelected?.map((ticket, index) => (
        <HStack
          padding={"0.94rem 2.19rem"}
          width={"100%"}
          borderRadius={ "6.25rem"}
          border={"1px solid rgba(255, 255, 255, 0.10)"}
          borderColor={"rgba(255, 255, 255, 0.10)"}
          justifyContent="space-between"
          height={"4.625rem"}
          marginBottom={2.5}
        >
          <Box
            display={"flex"}
            flexDir={"column"}
            alignItems={"flex-start"}
            justifyContent={"center"}
            gap={"0.37rem"}
            width={"100%"}
            overflow={"hidden"}
          >
            <Text
              fontSize="1rem"
              color={"#FFFFFF99"}
              className="gordita600"
              lineHeight={"120%"}
              letterSpacing={"-0.01188rem"}
              overflow={"hidden"}
              whiteSpace={"nowrap"}
              textOverflow={"ellipsis"}
              minW={"0"}
              width={"100%"}
            >
              {ticket.type}
            </Text>

            <Text
              color={_?.accentColor}
              fontSize={"1.4375rem"}
              className="gordita600"
              lineHeight={"110%"}
              letterSpacing={"-0.01438rem"}
            >
              x{ticket.quantity}
            </Text>
          </Box>
          <Box
            display={"flex"}
            flexDir={"column"}
            alignItems={"flex-end"}
            justifyContent={"flex-end"}
            gap={"0.37rem"}
            width={"100%"}
            overflow={"hidden"}
          >
            <Text
              fontSize="1rem"
              lineHeight={"110%"}
              className="gordita600"
              letterSpacing={"-0.01188rem"}
              color={"rgba(255, 255, 255, 0.60)"}
            >
              {getSymbolFromCurrency(_?.currency)}
              {Number(ticket.price)}
            </Text>
            <Text
              fontSize={"1.1875rem"}
              color={"#FFFFFF"}
              className="gordita700"
            >
              {getSymbolFromCurrency(_?.currency)}
              {Number(ticket.price * ticket.quantity)}
            </Text>
          </Box>
        </HStack>
        ))}
      </Box>
      )}
 {_?.perkSelected?.length>0 &&(
      <Box width={"100%"}>
        <Box marginBottom={"0.94rem"}>
          <Text
            fontSize={"1.1875rem"}
            fontWeight={"500"}
            className={"gordita600"}
            color={"rgba(255, 255, 255, 0.60)"}
          >
            Perks
          </Text>
        </Box>
        {_?.perkSelected?.map((perk, index) => (
        <HStack
          padding={
             "0.94rem 2.19rem"
          }
          width={"100%"}
          borderRadius={
            "6.25rem" 
          }
          border={"1px solid rgba(255, 255, 255, 0.10)"}
          borderColor={"rgba(255, 255, 255, 0.10)"}
          justifyContent="space-between"
          height={
            "4.625rem" 
          }
          marginBottom={2.5}
        >
          <Box
            display={"flex"}
            flexDir={"column"}
            alignItems={"flex-start"}
            justifyContent={"center"}
            gap={"0.37rem"}
            width={"100%"}
            overflow={"hidden"}
          >
            <Text
              fontSize="1rem"
              color={"#FFFFFF99"}
              className="gordita600"
              lineHeight={"120%"}
              letterSpacing={"-0.01188rem"}
              overflow={"hidden"}
              whiteSpace={"nowrap"}
              textOverflow={"ellipsis"}
              minW={"0"}
              width={"100%"}
            >
              {perk.type}
            </Text>

            <Text
              color={_?.accentColor}
              fontSize={"1.4375rem"}
              className="gordita600"
              lineHeight={"110%"}
              letterSpacing={"-0.01438rem"}
            >
              x{perk.quantity}
            </Text>
          </Box>
          <Box
            display={"flex"}
            flexDir={"column"}
            alignItems={"flex-end"}
            justifyContent={"flex-end"}
            gap={"0.37rem"}
            width={"100%"}
            overflow={"hidden"}
          >
            <Text
              fontSize="1rem"
              lineHeight={"110%"}
              className="gordita600"
              letterSpacing={"-0.01188rem"}
              color={"rgba(255, 255, 255, 0.60)"}
            >
              {getSymbolFromCurrency(_?.currency)}
              {Number(perk.price)}
            </Text>
            <Text
              fontSize={"1.1875rem"}
              color={ "#FFFFFF"
              }
              className="gordita700"
            >
              {getSymbolFromCurrency(_?.currency)}
              {Number(perk.price *perk.quantity)}
            </Text>
          </Box>
        </HStack>
        ))}
        
      </Box>

)}

      {/* ********** TAXES SECTION ********* */}
      {/* <Box width={"100%"}>
        <Box marginBottom={"0.94rem"}>
          <Text
            fontSize={"1.1875rem"}
            fontWeight={"500"}
            className={"gordita600"}
            color={"rgba(255, 255, 255, 0.60)"}
          >
            Taxes
          </Text>
        </Box>

        <HStack
          padding="1rem 1.25rem"
          width={"100%"}
          borderRadius={"6.25rem"}
          border={"1px solid rgba(255, 255, 255, 0.10)"}
          borderColor={"rgba(255, 255, 255, 0.10)"}
          justifyContent="space-between"
          height={"3.4375rem"}
        >
          <Text
            fontSize="1.125rem"
            color={"#FFFFFF"}
            className="gordita600"
            lineHeight={"150%"}
            letterSpacing={"-0.01188rem"}
            overflow={"hidden"}
            whiteSpace={"nowrap"}
            textOverflow={"ellipsis"}
            minW={"0"}
            width={"100%"}
          >
            10%
          </Text>

          <Text fontSize={"1.1875rem"} color={"#FFFFFF"} className="gordita700">
            {getSymbolFromCurrency(_?.currency)}
            65
          </Text>
        </HStack>
      </Box> */}
      {moreInfo && (
        <Box width={"100%"}>
          <Box marginBottom={"0.94rem"}>
            <Text
              fontSize={"1.1875rem"}
              fontWeight={"500"}
              className={"gordita600"}
              color={"rgba(255, 255, 255, 0.60)"}
            >
              Extra Services
            </Text>
          </Box>

          <HStack
            padding="1rem 1.25rem"
            width={"100%"}
            borderRadius={"6.25rem"}
            border={"1px solid rgba(255, 255, 255, 0.10)"}
            borderColor={"rgba(255, 255, 255, 0.10)"}
            justifyContent="space-between"
            height={"3.4375rem"}
          >
            <Text
              fontSize="1.125rem"
              color={"#FFFFFF"}
              className="gordita600"
              lineHeight={"150%"}
              letterSpacing={"-0.01188rem"}
              overflow={"hidden"}
              whiteSpace={"nowrap"}
              textOverflow={"ellipsis"}
              minW={"0"}
              width={"100%"}
            >
              Tips and Donations:
            </Text>

            <Text
              fontSize={"1.1875rem"}
              color={"#FFFFFF"}
              className="gordita700"
            >
              {getSymbolFromCurrency(_?.currency)}
              {extra.toFixed(2)}
            </Text>
          </HStack>
        </Box>
      )}

      <Box className="div-separator" />
      {discount && (
        <HStack
          width={"100%"}
          m={"2rem 1.25rem"}
          justifyContent={"space-between"}
        >
          <Text color="red" fontSize="1rem" className="gordita400">
            Discount
          </Text>
          <Text color="red" fontSize="15px">
            - {getSymbolFromCurrency(_?.currency)}
            {Number((_.total+_?.perksTotal) * (discount / 100))}
          </Text>
        </HStack>
      )}
      <HStack
        width={"100%"}
        m={"2rem 1.25rem"}
        justifyContent={"space-between"}
      >
        <Text
          color="rgba(255, 255, 255, 0.60)"
          fontSize="1rem"
          className="gordita400"
        >
          {i18n.t("serviceFees")}
        </Text>
        <Text color="#FFFFFF" fontSize="15px">
          {Number(_?.total)
            ? `${getSymbolFromCurrency(_?.currency)}${Number(
                (
                  (_?.total+_?.perksTotal) * _?.participant_fee_percentage + _?.participant_fee_fixed * _?.quantity
                ).toFixed(2)
              )}`
            : (Number(_?.perksTotal))?(`${getSymbolFromCurrency(_?.currency)}${Number(
              (
                (_?.perksTotal) *
                  _?.participant_fee_percentage + _?.participant_fee_fixed * _?.quantity
              ).toFixed(2)
            )}`): `${getSymbolFromCurrency(_?.currency)}0`}
        </Text>
      </HStack>

      <HStack
        width={"100%"}
        m={"2rem 1.25rem"}
        justifyContent={"space-between"}
      >
        <Box
          fontSize={"1.1875rem"}
          lineHeight={"150%"}
          letterSpacing={"-0.01188rem"}
          className="gordita600"
        >
          {"Tickets total"}
        </Box>
        <Box
          fontSize={"1.1875rem"}
          className="gordita700"
          lineHeight={"150%"}
          letterSpacing={"-0.01188rem"}
          color={_?.accentColor}
        >
          {getSymbolFromCurrency(_?.currency)}
          {+(
              
                Number(
                  _?.perksTotal+_?.total +
                  (_?.perksTotal+_?.total) * _?.participant_fee_percentage +
                   _?.participant_fee_fixed * _?.quantity +extra
                ) - Number((_?.total +_?.perksTotal) * (discount / 100)) || 0
              )
                .toFixed(2)
                .toString()
           }
        </Box>
      </HStack>
    </VStack>
  );

  return (
    <MainWrapper background={_?.bgColor}>
      {clientSecret.length === 0 ? (
        <Box
          height={"100vh"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          fontSize={"2rem"}
          className="gordita700"
        >
          Loading..
        </Box>
      ) : (
        <Box>
          {isMobile ? (
            <Box
              mt={"0.1rem"}
              mb={"1.5rem"}
              height={"7.375rem"}
              pos={"relative"}
            >
              <Image
                alt=""
                width={"100%"}
                height={"100%"}
                src={_?.selectedEvent?.eventImage}
              />

              {console.log({ city: _?.selectedEvent?.city })}

              <HStack
                backdropFilter={"blur(100px)"}
                pos={"absolute"}
                top={0}
                left={0}
                width={"100%"}
                mb={"1.5rem"}
                height={"7.575rem"}
                padding={"1.25rem"}
                gap={"0.94rem"}
                alignItems={"flex-start"}
              >
                <Button
                  sx={theme.buttons.secondary.shape}
                  bg={theme.buttons.secondary.bgColor}
                  color={theme.buttons.secondary.color}
                  css={{
                    width: "2.75rem",
                    height: "2.75rem",
                    textTransform: "capitalize",
                    padding: 0,
                  }}
                  _hover={{
                    cursor: "pointer",
                    borderColor: theme.buttons.secondary.hoverBorderColor,
                  }}
                  _active={{
                    bg: theme.buttons.secondary.pressed,
                  }}
                  onClick={() => navigate(-1)}
                >
                  <Image src={TypeArrowback} />
                </Button>
                <Box maxW={"100%"}>
                  <Heading
                    color="#FBFAFB"
                    fontWeight="700"
                    fontSize={isMobile ? "23px" : "29px"}
                    maxW={"80%"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                    textOverflow={"ellipsis"}
                  >
                    {_?.selectedEvent?.eventName}
                  </Heading>
                  <Text
                    fontSize={"1rem"}
                    className="gordita400"
                    lineHeight={"150%"}
                    letterSpacing={"-0.01rem"}
                  >
                    {moment(_?.selectedEvent?.startDate).format("ddd, D MMM")}
                  </Text>
                  <Text
                    fontSize={"1rem"}
                    className="gordita400"
                    lineHeight={"150%"}
                    letterSpacing={"-0.01rem"}
                    color={"rgba(255, 255, 255, 0.60)"}
                  >
                    {_?.selectedEvent?.city}
                  </Text>
                </Box>
              </HStack>
            </Box>
          ) : (
            <Heading
              textAlign="center"
              padding={"2rem 0 !important"}
              fontSize={"2.375rem"}
              className="gordita600"
              lineHeight={"150%"}
              letterSpacing={"-0.02375rem"}
            >
            
            </Heading>
          )}

          <Box width="100vw" mb={"2rem"}>
            {isMobile ? (
              <Box transition="all 0.9s ease-in-out" paddingX="0.5rem">
                <Heading
                  textAlign="left"
                  fontSize={"1.4375rem"}
                  className="gordita600"
                  mx={"1.25rem"}
                >
                  Checkout
                </Heading>
                <TicketsInAppComponent />
                {!seeDetails && (
                  <HStack
                    display={seeDetails ? "none" : "flex"}
                    m={"1.25rem"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    transition="all 0.9s ease-in-out"
                  >
                    <Box
                      fontSize={"1.1875rem"}
                      lineHeight={"150%"}
                      letterSpacing={"-0.01188rem"}
                      className="gordita600"
                    >
                      {"Tickets total"}
                    </Box>
                    <Box
                      fontSize={"1.1875rem"}
                      className="gordita700"
                      lineHeight={"150%"}
                      letterSpacing={"-0.01188rem"}
                      color={_?.accentColor}
                    >
                      {getSymbolFromCurrency(_?.currency)}
                      {
                         +(
                            Number(
                              _?.perksTotal+_?.total +
                              (_?.perksTotal+_?.total) * _?.participant_fee_percentage +
                                 _?.participant_fee_fixed * _?.quantity+extra
                            ) -
                              Number(
                                _?.total * (discount / 100)
                              ) || 0
                          )
                            .toFixed(2)
                            .toString()
                        }
                    </Box>
                  </HStack>
                )}
                <Box
                  height={seeDetails ? "100%" : "2.5rem"}
                  transition="all .5s ease"
                  borderBottom={"1px solid rgba(255, 255, 255, 0.10)"}
                  paddingBottom={"1.5rem"}
                  overflow={"hidden"}
                  margin={"0 1.25rem"}
                >
                  {seeDetails && (
                    <Box
                      m={"1rem 0"}
                      maxHeight={seeDetails ? "100%" : 0}
                      transition="all 0.5s ease"
                      overflow={"hidden"}
                    >
                      {ticketDetails}
                    </Box>
                  )}
                  <HStack
                    width={"8rem"}
                    transition="all 0.9s ease-in-out"
                    fontSize={"1rem"}
                    fontWeight={"700"}
                    lineHeight={"110%"}
                    letterSpacing={"-0.01rem"}
                    textTransform={"capitalize"}
                    cursor={"pointer"}
                    onClick={() => setSeeDetails(!seeDetails)}
                    color={"rgba(255, 255, 255, 0.60)"}
                  >
                    <Box>{seeDetails ? `${i18n.t("hide")}` : `${i18n.t("see")}`} Details</Box>
                    {seeDetails ? (
                      <ArrowUpIcon fill={"rgba(255, 255, 255, 0.60)"} />
                    ) : (
                      <ArrowDownIcon fill={"rgba(255, 255, 255, 0.60)"} />
                    )}
                  </HStack>
                </Box>

                <VStack
                  width={"100%"}
                  padding={"0 1.25rem"}
                  margin={"1.5rem auto"}
                >
                  <div style={{ marginBottom: "20px", flex: "1" }}>
                    <label
                      htmlFor="promoCode"
                      style={{
                        display: "block",
                        marginBottom: "0.94rem",
                      }}
                    >
                      {i18n.t("promoCode1")}
                    </label>
                    
                    <HStack spacing={4} alignItems="center">
                      <input
                        type="text"
                        id="promoCode"
                        name="promoCode"
                        placeholder={i18n.t("promoCode")}
                        value={promoCode}
                        onChange={(e) => setPromoCode(e.target.value)}
                        style={{
                          padding: "1.25rem",
                          borderRadius: "0.625rem",
                          border: "1px solid rgba(255, 255, 255, 0.20)",
                          backgroundColor: "transparent",
                          width: "100%",
                          height: "3.4375rem",
                        }}
                      />
                      <Button width={"20%"} onClick={fetchCheckout}>
                      {i18n.t("apply")}
                      </Button>
                    </HStack>
                    {showError && (
                      <label style={{ color: "red" }}>Promo code invalid</label>
                    )}
                  </div>
                  {clientSecret && (
                    <>
<Countdown
  date={Date.now() + 420000} // 7 minutes = 420000 milliseconds
  renderer={({ minutes, seconds, completed }) => {
    if (completed) {
      // Timer has completed
      return <span>Timer expired, payment won't work unless you restart a checkout session</span>;
    } else {
      // Render the timer
      return (
        <span>
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </span>
      );
    }
  }}
/>


                    <Elements options={options} stripe={stripePromise}>
                      <CheckoutForm language={_?.language} hostedUrl={hostedUrl} showInstagram={showInstagram} />
                    </Elements>
                    
                    </>
                  )}
                </VStack>
              </Box>
            ) : (
              <HStack
                justifyContent="center"
                //alignItems="flex-end"
                gap={"7.5rem"}
                padding={"0 1.25rem"}
              >
                <VStack width={"100%"} maxWidth={"23.75rem"}>
                  <div >

                    <HStack spacing={4} alignItems="center" >
                      <input
                        type="text"
                        id="promoCode"
                        name="promoCode"
                        placeholder={i18n.t("promoCode")}
                        value={promoCode}
                        onChange={(e) => setPromoCode(e.target.value)}
                        style={{
                          padding: "1.25rem",
                          borderRadius: "0.625rem",
                          border: "1px solid rgba(255, 255, 255, 0.20)",
                          backgroundColor: "transparent",
                          width: "100%",
                          height: "3.4375rem",
                        }}
                      />
                      <Button width={"20%"} onClick={fetchCheckout}>
                      {i18n.t("apply")}
                      </Button>
                    </HStack>
                    {showError && (
                      <label style={{ color: "red" }}>Promo code invalid</label>
                    )}
                  </div>
                  {clientSecret && (
                     <>
                     <Countdown
                       date={Date.now() + 420000} // 7 minutes = 420000 milliseconds
                       renderer={({ minutes, seconds, completed }) => {
                         if (completed) {
                           // Timer has completed
                           return <span>Timer expired, payment won't work unless you restart a checkout session</span>;
                         } else {
                           // Render the timer
                           return (
                             <span>
                               {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                             </span>
                           );
                         }
                       }}
                     />

                    <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm language={_?.language} hostedUrl={hostedUrl} showInstagram={showInstagram}/>
                    </Elements>
                    </>
                  )}
                </VStack>

                <Box w={"30rem"}>
                <Heading
              textAlign="center"
              padding={"1rem 0 !important"}
              fontSize={"2.375rem"}
              className="gordita600"
              lineHeight={"150%"}
              letterSpacing={"-0.02375rem"}
            >
            </Heading>
                  <VStack width={"100%"} gap={"3.5rem"}>
                  <EventInfoComponent
         eventName={_?.selectedEvent?.eventName}
         accentColor={_?.accentColor}
         startTime={_?.selectedEvent?.startTime}
         endTime={_?.selectedEvent?.endTime}
         startDate={_?.selectedEvent?.startDate}
         city={_?.selectedEvent?.city}
         location={_?.selectedEvent?.location}
         numberOfLikes={_?.selectedEvent?.numberOfLikes}
         hideLikes={true}
         seePerks={false}
         sawPerks={false}
         isMobile={isMobile}
         moment={moment}
         TimeFormat={TimeFormat}
         people={i18n.t("people")}
         by={i18n.t("by")}

         />
         <TicketsInAppComponent />

                    {ticketDetails}
                  </VStack>
                </Box>
              </HStack>
            )}
          </Box>
        </Box>
      )}
    </MainWrapper>
  );
}

export default withAuth(Detail, [
  userRoles.ORGANIZER,
  userRoles.ADMIN,
  userRoles.ATTENDEE,
  userRoles.PREMIUM_ORGANIZER,
]);
