import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  Button,
  ModalFooter,
  Box,
  Text,
  Select,
  Image,
  InputGroup,
  InputLeftElement,
  useToast,
  IconButton,
  Spinner,
} from '@chakra-ui/react';
import { theme } from '../../../styles/theme/base';
import { BuySellTicketsButton } from './eventButtons';
import userIcon from '../../../static/assets/images/defaultProfileImage.svg';
import logo from '../../../static/assets/images/portalysLogo.svg';
import uploadIcon from '../../../static/assets/images/uploadIcon.svg';
import { useEffect, useState, useRef } from 'react';
import axios from '../../../utils/api/axios';
import { DeleteIcon } from '@chakra-ui/icons';
import {
  TicketsSentToAccount,
  SecuredTickets,
  PaymentSentAfterTransfer,
  SecuredPayment,
  TicketTransfer,
} from './resellSection';
import lockIcon from '../../../static/assets/images/lockIcon-black.svg';
import defaultProfileImage from '../../../static/assets/images/defaultProfileImage.svg';

export const UnlockModal = ({
  isOpen,
  onClose,
  enteredTicketPassword,
  setEnteredTicketPassword,
  handleTicketPasswordSubmit,
  isMobile,
}) => (
  <Modal isOpen={isOpen} onClose={onClose} isCentered>
    <ModalOverlay />
    <ModalContent
      color="white.100"
      borderRadius="2.5rem"
      boxShadow={
        isMobile
          ? 'none'
          : '0px 0px 100px 20px rgba(0, 0, 0, 0.90), -1px -1px 0px 0px rgba(255, 255, 255, 0.20) inset, 1px 1px 0px 0px rgba(255, 255, 255, 0.50) inset'
      }
      bg={'rgba(0, 0, 0, 0.50)'}
      backdropFilter={'blur(150px)'}
    >
      <ModalHeader p={'40px 40px 0 40px'}>Unlock Hidden Items</ModalHeader>
      <ModalCloseButton />
      <ModalBody p={'40px 40px 40px 40px'} textAlign={'start'}>
        <Box display="flex" flexDirection="column" alignItems="center" gap={4}>
          <Input
            padding="1.25rem"
            borderRadius="0.625rem"
            border="1px solid rgba(255, 255, 255, 0.20)"
            backgroundColor="transparent"
            width="100%"
            height="3.4375rem"
            placeholder="Enter code"
            value={enteredTicketPassword}
            onChange={(e) => setEnteredTicketPassword(e.target.value)}
          />
          <Button
            fontWeight="700"
            textAlign="center"
            margin={'20px 0'}
            fontSize="1rem"
            borderRadius="40px"
            outline="none"
            sx={theme.buttons.primary.shape}
            bg={theme.buttons.primary.default}
            color={theme.buttons.primary.color}
            className="gordita700"
            css={{
              width: '100%',
            }}
            _hover={{
              bg: theme.buttons.primary.hover,
              boxShadow: theme.buttons.primary.hoverBoxShadow,
            }}
            _active={{
              boxShadow: theme.buttons.primary.activeBoxShadow,
            }}
            _disabled={{
              bg: theme.buttons.primary.disabled,
              color: theme.buttons.primary.colorDisabled,
            }}
            onClick={handleTicketPasswordSubmit}
          >
            Unlock
          </Button>
        </Box>
      </ModalBody>
    </ModalContent>
  </Modal>
);

export const BuyModal = ({
  isOpen,
  onClose,
  isMobile,
  offering,
  setOffering,
  selling,
  text1,
  text2,
  handleFunction1,
  handleFunction2,
  price,
  setPrice,
  quantity,
  setQuantity,
  ticketTypes,
  selectedTicketType,
  setSelectedTicketType,
  timezone,
  expiration,
  setExpiration,
  endDate,
  startDate,
  participant_fee_percentage,
  participant_fee_fixed,
  fee_percentage,
  fee_fixed,
  selectedListing,
  setSelectedListing,
  total,
  setTotal,
  setBuyerFee,
  setSellerFee,
  platform,
  directLink,
}) => {
  const endDateFormatted = new Date(endDate);
  endDateFormatted.setDate(endDateFormatted.getDate() + 1);
  const startDateFormatted = new Date(startDate);
  startDateFormatted.setDate(startDateFormatted.getDate() + 1);
  const today = new Date();

  // Calculate 5 and 7 days from now
  const fiveDaysFromNow = new Date(
    new Date().getTime() + 5 * 24 * 60 * 60 * 1000
  );
  const sevenDaysFromNow = new Date(
    new Date().getTime() + 7 * 24 * 60 * 60 * 1000
  );

  // Format the dates
  const formattedFiveDaysFromNow = fiveDaysFromNow.toLocaleDateString('en-US', {
    timeZone: timezone,
    month: 'short',
    day: 'numeric',
  });
  const formattedSevenDaysFromNow = sevenDaysFromNow.toLocaleDateString(
    'en-US',
    { timeZone: timezone, month: 'short', day: 'numeric' }
  );
  const formattedEndDate = endDateFormatted.toLocaleDateString('en-US', {
    timeZone: timezone,
    month: 'short',
    day: 'numeric',
  });

  const formattedStartDate = startDateFormatted.toLocaleDateString('en-US', {
    timeZone: timezone,
    month: 'short',
    day: 'numeric',
  });

  // Calculate the difference in days between today and endDate
  const diffInDays = Math.floor(
    (endDateFormatted - today) / (1000 * 60 * 60 * 24)
  );

  // Dynamically adjust options based on the number of days until endDate
  const getExpirationOptions = () => {
    // Event is happening in less than 5 days
    if (diffInDays < 5) {
      return (
        <>
          <option value={formattedEndDate}>Until {formattedStartDate}</option>
        </>
      );
    }
    // Event is happening in less than 7 days, but more than 5
    else if (diffInDays < 7) {
      return (
        <>
          <option value={formattedFiveDaysFromNow}>
            {formattedFiveDaysFromNow}
          </option>
          <option value={formattedEndDate}>Until {formattedStartDate}</option>
        </>
      );
    }
    // Default case: Event is more than 7 days away
    else {
      return (
        <>
          <option value={formattedFiveDaysFromNow}>
            {formattedFiveDaysFromNow}
          </option>
          <option value={formattedSevenDaysFromNow}>
            {formattedSevenDaysFromNow}
          </option>
        </>
      );
    }
  };

  useEffect(() => {
    const expirationOptions = getExpirationOptions();

    // Extract the values from the option JSX elements
    const optionValues = React.Children.map(
      expirationOptions.props.children,
      (child) => child.props.value
    );

    if (optionValues.length > 0) {
      setExpiration(optionValues[0]); // Set expiration to the first option
    }
  }, [endDate, isOpen]);

  let buyerFee = 0;
  let sellerFee = 0;
  if (price * quantity !== 0) {
    buyerFee = Number(
      (price * quantity * participant_fee_percentage) / 100 +
        participant_fee_fixed * quantity
    ).toFixed(2);
    sellerFee = Number(
      (price * quantity * fee_percentage) / 100 + fee_fixed * quantity
    ).toFixed(2);
    setBuyerFee(buyerFee);
    setSellerFee(sellerFee);
  }
  let finalPrice = selling
    ? Number(Number(price) * Number(quantity) - Number(sellerFee)).toFixed(2)
    : Number(Number(price) * Number(quantity) + Number(buyerFee)).toFixed(2);
  if (Number(price) * Number(quantity) === 0) {
    finalPrice = '0.00';
  }
  setTotal(finalPrice);
  useEffect(() => {
    if (
      quantity !== selectedListing.quantity ||
      price !== selectedListing.price ||
      selectedTicketType !== selectedListing.type
    ) {
      setSelectedListing([]);
      if (!selling) {
        setOffering(true);
      }
    }
  }, [
    quantity,
    selectedListing.quantity,
    price,
    selectedListing.price,
    selectedTicketType,
    selectedListing.type,
  ]);
  return (
    <Modal
      size={isMobile ? 'full' : 'xl'}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      width="100%"
      margin="auto"
    >
      {directLink && <ModalOverlay bg="black" />}
      <ModalContent
        display="flex"
        maxWidth={isMobile ? '100%' : '507px'}
        padding="32px 24px 0 24px"
        flexDirection="column"
        alignItems="flex-start"
        borderRadius="32px"
        border="1px solid rgba(255, 255, 255, 0.24)"
        background="#000"
        maxHeight="90vh"
      >
        <Box
          display="flex"
          paddingLeft="4px"
          justifyContent="space-between"
          alignItems="center"
          alignSelf="stretch"
          position="sticky"
          top="0"
          zIndex="1"
          background="#000"
        >
          <Text
            color="#FFF"
            fontFamily="Gordita"
            fontSize="18px"
            fontStyle="normal"
            fontWeight={700}
            lineHeight="23.76px"
          >
            {offering ? 'Offering to buy' : selling ? 'Sell now' : 'Buy now'}
          </Text>
        </Box>
        <ModalCloseButton
          top={isMobile ? '16px' : '8px'}
          right={isMobile ? '16px' : '8px'}
          zIndex="1000" // Increase zIndex to ensure it is on top
        />
        <ModalBody
          display="flex"
          padding="24px 0"
          flexDirection="column"
          alignItems="flex-start"
          alignSelf="stretch"
          width="100%"
          overflowY="auto"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap="16px"
            width="100%"
          >
            {!directLink && (
              <Box display="flex" alignItems="flex-start" gap="24px" width="100%">
                <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                gap="8px"
                flex="1 0 0"
              >
                <Text
                  color="#FFF"
                  fontFamily="Gordita"
                  fontSize="14px"
                  fontStyle="normal"
                  fontWeight="400"
                  lineHeight="120%"
                  paddingLeft="16px"
                >
                  Price Per Ticket
                </Text>
                <Box
                  display="flex"
                  height="56px"
                  alignItems="center"
                  borderRadius="10px"
                  border="1px solid rgba(255, 255, 255, 0.20)"
                  background="rgba(255, 255, 255, 0.05)"
                >
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color="rgba(255, 255, 255, 255)"
                      fontSize="16px"
                      children="$"
                      disabled={!offering && !selling}
                    />
                    <Input
                      color="rgba(255, 255, 255, 255)"
                      fontFamily="Gordita"
                      fontSize="16px"
                      fontStyle="normal"
                      fontWeight="400"
                      lineHeight="132%"
                      display="flex"
                      background="transparent"
                      paddingLeft="24px"
                      border="none"
                      _focus={{
                        border: 'none',
                        outline: 'none',
                        boxShadow: 'none',
                      }}
                      pl="28px"
                      type="number"
                      onChange={(e) => setPrice(e.target.value)}
                      value={price}
                    />
                  </InputGroup>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                gap="8px"
                flex="1 0 0"
              >
                <Text
                  color="#FFF"
                  fontFamily="Gordita"
                  fontSize="14px"
                  fontStyle="normal"
                  fontWeight="400"
                  lineHeight="120%"
                  paddingLeft="16px"
                >
                  Quantity
                </Text>
                <Select
                  display="flex"
                  height="56px"
                  justifyContent="space-between"
                  alignItems="center"
                  alignSelf="stretch"
                  borderRadius="10px"
                  border="1px solid rgba(255, 255, 255, 0.12)"
                  background="rgba(255, 255, 255, 0.08)"
                  lineHeight="19.2px"
                  onChange={(e) => setQuantity(e.target.value)}
                  value={quantity}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </Select>
              </Box>
            </Box>
            )}
            {!directLink && (
            <Box display="flex" alignItems="flex-start" width="100%" gap="24px">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                flex="1 0 0"
                gap="8px"
              >
                <Text
                  color="#FFF"
                  fontFamily="Gordita"
                  fontSize="14px"
                  fontStyle="normal"
                  fontWeight="400"
                  lineHeight="120%"
                  paddingLeft="16px"
                >
                  Ticket Type
                </Text>
                <Select
                  display="flex"
                  height="56px"
                  justifyContent="space-between"
                  alignItems="center"
                  alignSelf="stretch"
                  borderRadius="10px"
                  border="1px solid rgba(255, 255, 255, 0.12)"
                  background="rgba(255, 255, 255, 0.08)"
                  lineHeight="19.2px"
                  onChange={(e) => setSelectedTicketType(e.target.value)}
                  value={selectedTicketType}
                  isDisabled={directLink}
                >
                  {ticketTypes
                    .replace(/[\[\]"]+/g, '')
                    .split(',')
                    .map((type, index) => (
                      <option key={index} value={type.trim()}>
                        {type.trim()}
                      </option>
                    ))}
                </Select>
              </Box>
              {offering && (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  flex="1 0 0"
                  gap="8px"
                >
                  <Text
                    color="#FFF"
                    fontFamily="Gordita"
                    fontSize="14px"
                    fontStyle="normal"
                    fontWeight="400"
                    lineHeight="120%"
                    paddingLeft="16px"
                  >
                    Keep Offer Until
                  </Text>
                  <Select
                    display="flex"
                    height="56px"
                    justifyContent="space-between"
                    alignItems="center"
                    alignSelf="stretch"
                    borderRadius="10px"
                    border="1px solid rgba(255, 255, 255, 0.12)"
                    background="rgba(255, 255, 255, 0.08)"
                    lineHeight="19.2px"
                    onChange={(e) => setExpiration(e.target.value)}
                    value={expiration}
                  >
                    {getExpirationOptions()}
                  </Select>
                </Box>
              )}
            </Box>
            )}
          </Box>
          {!directLink && (
              <Box className="div-separator" />
            )}
            <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap="20px"
            alignSelf="stretch"
          >
            <Box
              display="flex"
              padding="0px 4px"
              justifyContent="center"
              alignItems="center"
              gap="4px"
            >
              <Text
                color="rgba(255, 255, 255, 0.80)"
                fontFamily="Gordita"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="132%"
              >
                {selling ? 'Items' : 'Purchase'}
              </Text>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              gap="16px"
              alignSelf="stretch"
              borderRadius="40px"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                alignSelf="stretch"
              >
                <Box display="flex" alignItems="center" gap="16px">
                  <Box
                    display="flex"
                    width="48px"
                    height="48px"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Image
                      src={
                        selectedListing?.seller
                          ? selectedListing.seller.image
                          : selectedListing?.buyer
                          ? selectedListing.buyer.image
                          : userIcon
                      }
                      alt="User Icon"
                      width="34px"
                      height="34px"
                      borderRadius="44px"
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    gap="2px"
                  >
                    <Box display="flex" alignItems="center" gap="8px">
                      <Text
                        color="#FFF"
                        fontFamily="Gordita"
                        fontSize="16px"
                        fontStyle="normal"
                        fontWeight="700"
                        lineHeight="132%"
                      >
                        {quantity} Ticket{quantity > 1 && 's'}
                      </Text>
                    </Box>
                    <Box display="flex" alignItems="flex-end" gap="4px">
                      <Text
                        color="rgba(255, 255, 255, 0.64)"
                        fontFamily="Gordita"
                        fontSize="12px"
                        fontStyle="normal"
                        fontWeight="400"
                        lineHeight="124%"
                      >
                        {selectedListing?.seller
                          ? `${selectedListing.seller.firstName || ''}`.trim()
                          : selectedListing?.buyer
                          ? `${selectedListing.buyer.firstName || ''} `.trim()
                          : 'John Doe'}
                      </Text>
                    </Box>
                  </Box>
                </Box>
                <Text
                  color="rgba(255, 255, 255, 0.80)"
                  textAlign="center"
                  fontFamily="Gordita"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="400"
                  lineHeight="24px"
                >
                  ${price * quantity}
                </Text>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                alignSelf="stretch"
              >
                <Box display="flex" alignItems="center" gap="16px">
                  <Box
                    display="flex"
                    width="44px"
                    height="44px"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Image
                      src={logo}
                      alt="User Icon"
                      width="44px"
                      height="44px"
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    gap="2px"
                  >
                    <Box display="flex" alignItems="center" gap="8px">
                      <Text
                        color="#FFF"
                        fontFamily="Gordita"
                        fontSize="16px"
                        fontStyle="normal"
                        fontWeight="700"
                        lineHeight="132%"
                      >
                        Service Fee
                      </Text>
                    </Box>
                  </Box>
                </Box>
                <Text
                  color="rgba(255, 255, 255, 0.80)"
                  textAlign="center"
                  fontFamily="Gordita"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="400"
                  lineHeight="24px"
                >
                  ${selling ? sellerFee : buyerFee}
                </Text>
              </Box>
            </Box>
            <Box
              display="flex"
              padding="0 4px"
              justifyContent="space-between"
              alignItems="center"
              alignSelf="stretch"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                gap="4px"
              >
                <Text
                  color="#FFF"
                  fontFamily="Gordita"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="132%"
                >
                  {selling ? 'Payout' : 'Total to pay'}
                </Text>
                <Text
                  color="rgba(255, 255, 255, 0.80)"
                  fontFamily="Gordita"
                  fontSize="14px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="132%"
                >
                  {quantity} Ticket{quantity > 1 && 's'}
                </Text>
              </Box>
              <Text
                color="#FFF"
                textAlign="center"
                fontFamily="Gordita"
                fontSize="18px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="27px"
              >
                ${total}
              </Text>
            </Box>
          </Box>
          <Box className="div-separator" />

          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap="16px"
            alignSelf="stretch"
          >
            {selling ? <SecuredPayment /> : <SecuredTickets />}

            {selling ? <TicketTransfer /> : <TicketsSentToAccount platform={platform} />}
          </Box>
        </ModalBody>
        <ModalFooter
          display="flex"
          padding="8px 0 24px 0"
          alignItems="center"
          gap="16px"
          alignSelf="stretch"
          position="sticky"
          bottom="0"
          background="#000"
        >
          <Box width="100%" >
            <BuySellTicketsButton
              text1={text1}
              handleFunction1={handleFunction1}
              text2={text2}
              handleFunction2={handleFunction2}
              reverseOrder={true}
            />
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const UploadProofModal = ({
  isOpen,
  onClose,
  isMobile,
  text1,
  text2,
  handleNext,
  images,
  setImages,
  directLink,
}) => {
  const [dragging, setDragging] = useState(false);
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const dropRef = useRef(null);
  const [errorFields, setErrorFields] = useState();

  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files) {
      Array.from(e.dataTransfer.files).forEach((file) => {
        uploadPurchaseImage(file);
      });
    }
  };

  const handleMouseDown = (e) => {
    setDragging(true);
    const initialX = e.clientX - position.x;
    const initialY = e.clientY - position.y;

    const handleMouseMove = (e) => {
      setPosition({
        x: e.clientX - initialX,
        y: e.clientY - initialY,
      });
    };

    document.addEventListener('mousemove', handleMouseMove);

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      setDragging(false);
    };

    document.addEventListener('mouseup', handleMouseUp);
  };

  const uploadPurchaseImage = async (image) => {
    const fd = new FormData();
    fd.append('image', image);
    fd.append('type', 'event');
    setLoading(true);
    await axios
      .post('/api/uploadImage', fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.data.status) {
          if (res.data.link) {
            setImages((prevImages) => [...prevImages, res.data.link]);
          }
        } else {
          toast({
            title: 'Cover Uploading Error',
            status: 'error',
            isClosable: true,
            duration: 4000,
            position: 'top-right',
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast({
          title: 'Cover Uploading Error',
          status: 'error',
          isClosable: true,
          duration: 4000,
          position: 'top-right',
        });
      });
  };
  const handleDeleteImage = (indexToDelete) => {
    setImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToDelete)
    );
  };
  const handleFileSelect = (e) => {
    const files = e.target.files;
    if (files) {
      Array.from(files).forEach((file) => uploadPurchaseImage(file));
    }
  };

  return (
    <Modal
      size={isMobile ? 'full' : 'xl'}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      width="100%"
    >
      {directLink && <ModalOverlay bg="black" />}
      <ModalContent
        display="flex"
        maxWidth={isMobile ? '100%' : '507px'}
        padding="32px 24px 0px 24px"
        flexDirection="column"
        alignItems="flex-start"
        borderRadius="32px"
        border="1px solid rgba(255, 255, 255, 0.24)"
        background="#000"
        overflow="auto"
        position="relative" // Ensure the parent has a position set
      >
        <ModalCloseButton
          top={isMobile ? '16px' : '8px'}
          right={isMobile ? '16px' : '8px'}
          color="#FFF"
        />
        <Box
          display="flex"
          paddingLeft="4px"
          alignItems="center"
          gap="4px"
          alignSelf="stretch"
        >
          <Text
            color="#FFF"
            fontFamily="Gordita"
            fontSize="18px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="132%"
          >
            Upload proof of purchase
          </Text>
        </Box>

        <Box
          display="flex"
          padding="24px 0px"
          flexDirection="column"
          alignItems="flex-start"
          gap="24px"
          alignSelf="stretch"
        >
          <Box
            display="flex"
            padding="40px 0px"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="4px"
            alignSelf="stretch"
            borderRadius="24px"
            border="1px dashed rgba(255, 255, 255, 0.40)"
            onClick={() => {
              if (!loading) {
                const input = document.createElement('input');
                input.type = 'file';
                input.accept = 'image/*';
                input.multiple = true; // Allow multiple files
                input.onchange = handleFileSelect;
                input.click();
              }
            }}
            _hover={{
              background: !loading ? 'rgba(255, 255, 255, 0.08)' : 'none',
              cursor: !loading ? 'pointer' : 'default',
            }}
          >
            {loading ? (
              // Display loading state
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap="16px"
              >
                <Spinner size="xl" color="white" />
                <Text
                  color="rgba(255, 255, 255, 0.80)"
                  textAlign="center"
                  fontFamily="Gordita"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="132%"
                >
                  Uploading...
                </Text>
              </Box>
            ) : images.length > 0 ? (
              // Display uploaded images
              <Box display="flex" gap="10px" flexWrap="wrap">
                {images.map((img, index) => (
                  <Box
                    key={index}
                    position="relative"
                    display="inline-block"
                    margin="8px"
                  >
                    <IconButton
                      icon={<DeleteIcon />}
                      aria-label="Delete image"
                      position="absolute"
                      color="red"
                      top={2}
                      right={2}
                      zIndex={1}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteImage(index); // Call handleDeleteImage with the index
                      }}
                      size="lg"
                    />
                    <Image
                      src={img}
                      width="200px"
                      height="auto"
                      borderRadius="12px"
                    />
                  </Box>
                ))}
              </Box>
            ) : (
              // Display upload prompt if no images are uploaded
              <Box
                display="flex"
                width="225px"
                flexDirection="column"
                alignItems="center"
                gap="16px"
              >
                <Box
                  display="flex"
                  width="179px"
                  flexDirection="column"
                  alignItems="center"
                  gap="12px"
                >
                  <Image src={uploadIcon} width="40px" height="40px" />
                  <Text
                    color="rgba(255, 255, 255, 0.80)"
                    textAlign="center"
                    fontFamily="Gordita"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight="500"
                    lineHeight="132%"
                  >
                    Browse files to upload proof
                  </Text>
                </Box>
                <Text
                  color="#FF5C5C"
                  textAlign="center"
                  fontFamily="Gordita"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="132%"
                >
                  Do not upload the QR Code
                </Text>
              </Box>
            )}
          </Box>

          <Box className="separator" />

          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap="16px"
            alignSelf="stretch"
          >
            <PaymentSentAfterTransfer />
          </Box>
        </Box>
        <ModalFooter
          display="flex"
          padding="8px 0 24px 0"
          alignItems="center"
          gap="16px"
          alignSelf="stretch"
        >
          <Box width="100%">
            <Box
              display="flex"
              alignItems="center"
              gap="24px"
              alignSelf="stretch"
            >
              <Button
                height="44px"
                minWidth="100px"
                padding="0 24px"
                justifyContent="center"
                alignItems="center"
                gap="5px"
                flex="1 0 0"
                borderRadius="40px"
                border="1px solid rgba(255, 255, 255, 0.24)"
                bg="black"
                color="white"
                textAlign="center"
                fontFamily="Gordita"
                fontSize={['14px', '16px']}
                fontWeight="500"
                lineHeight="150%"
                onClick={onClose}
              >
                Back
              </Button>
              <Button
                borderRadius="40px"
                bg="white"
                height="44px"
                minWidth="80px"
                padding="0 24px"
                justifyContent="center"
                alignItems="center"
                gap="5px"
                flex="1 0 0"
                color="black"
                textAlign="center"
                fontFamily="Gordita"
                fontSize={['14px', '16px']}
                fontWeight="700"
                lineHeight="150%"
                disabled={images.length === 0 || loading}
                onClick={handleNext}
              >
                Continue
              </Button>
            </Box>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const ViewProofModal = ({ isOpen, onClose, isMobile, proofUrl }) => {
  console.log('pppppp', proofUrl);
  const parsedProofUrl = proofUrl;
  return (
    <Modal
      size={isMobile ? 'full' : 'xl'}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      width="100%"
    >
      <ModalOverlay />
      <ModalContent
        display="flex"
        maxWidth={isMobile ? '100%' : '507px'}
        maxHeight="80vh"
        padding="32px 24px 24px 24px"
        flexDirection="column"
        alignItems="flex-start"
        borderRadius="32px"
        border="1px solid rgba(255, 255, 255, 0.24)"
        background="#000"
        overflowY="auto"
      >
        <ModalCloseButton
          top={isMobile ? '16px' : '8px'}
          right={isMobile ? '16px' : '8px'}
        />
        <Box width="100%" overflowY="auto">
          {parsedProofUrl?.map((url, index) => (
            <Image src={url} alt="Proof" width="100%" key={index} mb={4} />
          ))}
        </Box>
      </ModalContent>
    </Modal>
  );
};

export const SellerInfoModal = ({
  isOpen,
  onClose,
  isMobile,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  profilePicture,
  setProfilePicture,
  handleSubmit,
  total,
  isLoading,
  directLink,
}) => {
  const fileInputRef = useRef();
  const [isImageLoading, setisImageLoading] = useState(false);

  // Trigger file input click
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // Handle image upload
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    const size = file?.size;
    const type = file?.type;

    // Validate image file type
    if (type !== 'image/jpeg' && type !== 'image/gif' && type !== 'image/png') {
      toast({
        title: 'Image type must be jpg, gif or png',
        status: 'warning',
        isClosable: true,
        duration: 4000,
        position: 'top-right',
      });
      return;
    } else {
      uploadProfileImage(file);
    }
  };

  // Image upload function
  const uploadProfileImage = async (image) => {
    const fd = new FormData();
    fd.append('image', image);
    fd.append('type', 'profile');
    setisImageLoading(true);
    await axios
      .post('/api/uploadImage', fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.data.status) {
          if (res.data.link) {
            setProfilePicture(res.data.link);
          }
        } else {
          toast({
            title: 'Profile Image Uploading Err',
            status: 'error',
            isClosable: true,
            duration: 4000,
            position: 'top-right',
          });
        }
        setisImageLoading(false);
      })
      .catch((err) => {
        setisImageLoading(false);
        toast({
          title: 'Profile Image Uploading Err',
          status: 'error',
          isClosable: true,
          duration: 4000,
          position: 'top-right',
        });
      });
  };

  return (
    <Modal
      size={isMobile ? 'full' : 'xl'}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      width="100%"
    >
      {directLink && <ModalOverlay bg="black" />}
      <ModalContent
        display="flex"
        maxWidth={isMobile ? '100%' : '507px'}
        padding="32px 24px 24px 24px"
        flexDirection="column"
        alignItems="flex-start"
        borderRadius="32px"
        border="1px solid rgba(255, 255, 255, 0.24)"
        background="#000"
      >
        <ModalCloseButton
          top={isMobile ? '16px' : '8px'}
          right={isMobile ? '16px' : '8px'}
        />

        <Box
          display="flex"
          paddingLeft="4px"
          alignItems="center"
          gap="4px"
          alignSelf="stretch"
          marginBottom="24px"
        >
          <Text
            color="#FFF"
            fontFamily="Gordita"
            fontSize="18px"
            fontWeight="500"
            lineHeight="132%"
          >
            One Last Step!
          </Text>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          gap="1.25rem"
          marginBottom="20px"
        >
          {/* Profile picture with spinner */}
          {isImageLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="full"
              bg="linear-gradient(180deg, #1A1A1A 0%, rgba(26, 26, 26, 0) 100%)"
              width="5.5625rem"
              height="5.5625rem"
            >
              <Spinner size="lg" />
            </Box>
          ) : (
            <Image
              width="5.5625rem"
              height="5.5625rem"
              objectFit="cover"
              src={profilePicture}
              borderRadius="full"
              border="1px solid red"
            />
          )}

          {/* Change Photo Button */}
          <Button
            sx={theme.buttons.primary.shape}
            bg={theme.buttons.secondary.border}
            color={theme.buttons.secondary.color}
            border={theme.buttons.secondary.border}
            _hover={{
              borderColor: theme.buttons.secondary.hoverBorderColor,
            }}
            className="gordita700"
            onClick={handleButtonClick}
          >
            Upload Photo
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileInputChange}
          />
        </Box>

        {/* Input fields for first name, last name, email */}
        <Box marginBottom="20px" width="100%">
          <input
            type="text"
            id="firstName"
            name="firstName"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            style={{
              padding: '1.25rem',
              borderRadius: '0.625rem',
              border: '1px solid rgba(255, 255, 255, 0.20)',
              backgroundColor: 'transparent',
              width: '100%',
              height: '3.4375rem',
            }}
          />
        </Box>

        <Box marginBottom="20px" width="100%">
          <input
            type="text"
            id="lastName"
            name="lastName"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            style={{
              padding: '1.25rem',
              borderRadius: '0.625rem',
              border: '1px solid rgba(255, 255, 255, 0.20)',
              backgroundColor: 'transparent',
              width: '100%',
              height: '3.4375rem',
            }}
          />
        </Box>

        <Box marginBottom="20px" width="100%">
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              padding: '1.25rem',
              borderRadius: '0.625rem',
              border: '1px solid rgba(255, 255, 255, 0.20)',
              backgroundColor: 'transparent',
              width: '100%',
              height: '3.4375rem',
            }}
          />
        </Box>

        {/* Footer with Buttons */}
        <ModalFooter
          display="flex"
          padding="8px 0 24px 0"
          alignItems="center"
          gap="16px"
          alignSelf="stretch"
        >
          <Box width="100%">
            <Box
              display="flex"
              alignItems="center"
              gap="24px"
              alignSelf="stretch"
            >
              <Button
                height="44px"
                minWidth="100px"
                padding="0 24px"
                justifyContent="center"
                alignItems="center"
                gap="5px"
                flex="1 0 0"
                borderRadius="40px"
                border="1px solid rgba(255, 255, 255, 0.24)"
                bg="black"
                color="white"
                fontFamily="Gordita"
                fontSize={['14px', '16px']}
                fontWeight="500"
                onClick={onClose}
              >
                Back
              </Button>

              <Button
                height="40px"
                minWidth="100px"
                padding="0px 16px"
                justifyContent="center"
                alignItems="center"
                gap="5px"
                flex="1 0 0"
                borderRadius="40px"
                bg="#FFF"
                disabled={
                  firstName === '' ||
                  lastName === '' ||
                  email === '' ||
                  profilePicture === ''
                }
                onClick={handleSubmit}
                isLoading={isLoading}
                _loading={{
                  bg: '#FFF',
                  color: '#000',
                }}
              >
                <Image
                  src={lockIcon}
                  width="18px"
                  height="18px"
                  marginBottom="6px"
                />
                <Text
                  color="#000"
                  fontFamily="Gordita"
                  fontSize={['14px', '16px']}
                  fontWeight="700"
                >
                  Sell for ${total}
                </Text>
              </Button>
            </Box>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

