import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import secrets from "../../../secrets";
import { theme } from "../../../styles/theme/base";
import { Button, Flex, useToast, Link, Text, Box, HStack } from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { user } from "../../../recoil/atoms/user";
import { useMediaQuery } from "../../../utils/useMediaQuery";
import axios from "../../../utils/api/axios"
import i18n from 'i18next';
import translationEN from '../../portalys/locales/en.json'
import translationFR from '../../portalys/locales/fr.json';
import translationES from '../../portalys/locales/es.json'
import PhoneInput from 'react-phone-input-2/';
import 'react-phone-input-2/lib/style.css';

i18n.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
      translation: translationEN,
    },
    fr: {
      translation: translationFR,
    },
    es: {
      translation: translationES,
    },
  },
});

const CheckoutForm = ({language,hostedUrl,showInstagram, secondary=false}) => {
  const isMobile = useMediaQuery("(max-width: 905px)");
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [socialLinks, setSocialLinks] = useState([]);
  const [instagramHandle, setInstagramHandle] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [response_m, setResponse_m] = useState(true);
  const [_, setUser] = useRecoilState(user);
  const toast = useToast();
  i18n.changeLanguage(language);
  useEffect(() => {
    setFirstName(_?.userData?.firstName);
    setLastName(_?.userData?.lastName);
    setEmail(_?.userData?.email);
    setSocialLinks(_.userData?.socialLinks);
    setPhoneNumber(_.userData?.phoneNumber);
    if (_.userData?.socialLinks) {
      try {
        const socialLinksObj = _.userData.socialLinks;

        // Check if Instagram handle exists
        if (socialLinksObj.instagram) {
          setInstagramHandle(socialLinksObj.instagram);
        }
      } catch (error) {
        console.error("Error parsing socialLinks JSON:", error);
      }
    }
  }, [_?.userData]);


  const updateUser = async () => {
    const token = _?.token;
    const capitalizedFirstName =
      firstName.charAt(0).toUpperCase() + firstName.slice(1);
    const capitalizedLastName =
      lastName.charAt(0).toUpperCase() + lastName.slice(1);

    await axios
      .put(
        "/api/",
        {
          firstName: capitalizedFirstName,
          lastName: capitalizedLastName,
          socialLinks: JSON.stringify({
            ...socialLinks,
            instagram: instagramHandle,
          }),
          email,

        },
        {
          headers: {
            "x-auth-token": "Bearer " + token,
          },
        }
      )
      .catch((err) => {
        toast({
          title: "Data Updating Error",
          status: "error",
          isClosable: true,
          duration: 4000,
          position: "top-right",
        });
      });
  };

 

  const handleSubmit = async (event) => {
     updateUser();

      /*if (!(response_m))
    {
      alert(
      "Email is already in use by another user different from this account. Please enter another email"
    );
   // 
   return;

    }*/
    

    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: secondary ? secrets.stripeReturnUrlSecondary : secrets.stripeReturnUrl,
      },
    });

    if (!error) {
      console.log("Payment confirmed successfully");
    } else {
      if (error.decline_code === "incorrect_zip") {
        alert(
          "The ZIP Code you provided is not correct. Please try again with a valid zip code. \nThe transactions may be marked as pending by your bank but you will not be charged."
        );
      } else {
        alert(
          "Payment error. Double check the card details, validity, CCV/CSV and ZIP Code. \n\n The transactions may be marked as pending by your bank but you will not be charged. \n\n If you're using apple pay or google pay, try to enter the card number manually, it could fix the issue"
        );
      }
    }
  };

  const desktopButtonStyle = {
    justifyContent: "center",
    mt: 4,
  };
  const mobileButtonStyle = {
    borderTopLeftRadius: "40px",
    borderTopRightRadius: "40px",
    height: "5.875rem",
    width: "100vw",
    backdropFilter: "blur(150px)",
    pos: "fixed",
    bottom: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "0 1.25rem",
    zIndex: 10,
  };
  const handleCrypto = () => {
    if (hostedUrl) {
      if (! (email && firstName && lastName))
      {
        alert(
          "Fill out your first name, last name and email"
        );
        return
      }
      updateUser();
      window.location.href = hostedUrl;
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        width: "100%",
        marginBottom: isMobile ? "3rem" : "0rem",
      }}
    >
      {secondary && (
      <Text fontSize={"1.25rem"} fontWeight={"700"} marginBottom={"1.25rem"}>
      Confirm Ticket Delivery Details:
      </Text>
      )}
      {/* Name Input */}
        <>
          <div style={{ marginBottom: "20px", width: "100%" }}>
            <input
              type="text"
              id="firstName"
              name="firstName"
              placeholder={i18n.t("firstName")}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              style={{
                padding: "1.25rem",
                borderRadius: "0.625rem",
                border: "1px solid rgba(255, 255, 255, 0.20)",
                backgroundColor: "transparent",
                width: "100%",
                height: "3.4375rem",
              }}
              required // Make the input field mandatory
            />
          </div>
          <div style={{ marginBottom: "20px", width: "100%" }}>
            <input
              type="text"
              id="lastName"
              name="lastName"
              placeholder={i18n.t("lastName")}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              style={{
                padding: "1.25rem",
                borderRadius: "0.625rem",
                border: "1px solid rgba(255, 255, 255, 0.20)",
                backgroundColor: "transparent",
                width: "100%",
                height: "3.4375rem",
              }}
              required // Make the input field mandatory
            />
          </div>
        </>
   


            <div style={{ marginBottom: "20px" }}>
              <input
                type="email"
                id="email"
                name="email"
                placeholder={i18n.t("email")}
                value={email}
                pattern="(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))+"
                onChange={(e) => setEmail(e.target.value)}
                style={{
                  padding: "1.25rem",
                  borderRadius: "0.625rem",
                  border: "1px solid rgba(255, 255, 255, 0.20)",
                  backgroundColor: "transparent",
                  width: "100%",
                  height: "3.4375rem",
                }}
                required // Make the input field mandatory
              />
            </div>

            {secondary && (
                          <div style={{ marginBottom: "20px", width: "100%" }}>
                            <PhoneInput
                              value={phoneNumber}
                              onChange={setPhoneNumber}
                              inputProps={{
                                name: 'phoneNumber',
                                placeholder: "Phone Number",
                                style: {
                                  width: "100%",
                                  borderRadius: "0.625rem",
                                  border: "1px solid rgba(255, 255, 255, 0.20)",
                                  height: "3.4375rem",
                                  color: "white",
                                  fontSize: "14px",
                                  backgroundColor: "transparent",
                                },
                              }}
                              style={{
                                marginTop: '0.30rem',
                              }}
                              dropdownStyle={{ backgroundColor: 'transparent' }}
                              buttonStyle={{ 
                                backgroundColor: 'transparent', 
                                border: 'none',
                                borderRadius: '0',
                                pointerEvents: 'none',
                              }}
                              containerStyle={{ backgroundColor: 'transparent' }}
                              searchStyle={{ backgroundColor: 'transparent' }}
                              //disableCountryCode={true}
                              disableDropdown={true}
                              //countryCodeEditable={false}
                              inputStyle={{
                                backgroundColor: 'transparent',
                                borderRadius: '0.625rem',
                                border: '1px solid rgba(255, 255, 255, 0.20)',
                              }}
                              disabled={true}
                              required
                            />
                          </div>
                        )}

            {showInstagram &&(
            <div style={{ marginBottom: "20px", width: "100%" }}>
            <input
              type="text"
              id="instagramHandle"
              name="instagramHandle"
              placeholder="Instagram handle without the '@'"
              value={instagramHandle}
              onChange={(e) => setInstagramHandle(e.target.value)}
              style={{
                padding: "1.25rem",
                borderRadius: "0.625rem",
                border: "1px solid rgba(255, 255, 255, 0.20)",
                backgroundColor: "transparent",
                width: "100%",
                height: "3.4375rem",
              }}
              required // Make the input field mandatory
            />
          </div>
          )}

      {/* PaymentElement */}
      <Box
        border={"1px solid rgba(255, 255, 255, 0.20)"}
        borderRadius={"1.5625rem"}
        padding={"1rem"}
      >
        <div>
          <PaymentElement />
        </div>

        {/* Pay Button */}
        <Flex {...(isMobile ? mobileButtonStyle : desktopButtonStyle)}>
          <Button
            disabled={!stripe}
            type="submit"
            sx={theme.buttons.primary.shape}
            bg={theme.buttons.primary.default}
            color={theme.buttons.primary.color}
            _hover={{
              bg: theme.buttons.primary.hover,
              boxShadow: theme.buttons.primary.hoverBoxShadow,
            }}
            _active={{
              boxShadow: theme.buttons.primary.activeBoxShadow,
            }}
            style={{
              width: "100%",
            }}
          >
            {i18n.t("pay")}
          </Button>
        </Flex>
      </Box>
      {hostedUrl && (
      <Box textAlign="center" mt={8}> {/* Center everything and add spacing */}
  <Box mb={4}>OR</Box> {/* Add spacing and include 'OR' */}
    <Button sx={theme.buttons.primary.shape}
            bg={theme.buttons.secondary.default}
            color={theme.buttons.secondary.color}

            _active={{
              boxShadow: theme.buttons.secondary.activeBoxShadow,
            }}
            style={{
              width: "100%",
            }} onClick={handleCrypto} disabled={!hostedUrl}>
      Pay with Crypto
    </Button>

</Box>
)}
      <Text className="gordita400" marginTop={"0.94rem"} fontSize={"0.75rem"} marginBottom={"1.2rem"}>
      {i18n.t("payText")}
        <Link className={"gordita700"} color="#FFFFFF"   href="https://portalysio.notion.site/Legal-Compliance-b4922535aa5c463a81621aa533066195"
  target="_blank"
  rel="noopener noreferrer">
          Terms & Conditions
        </Link>
      </Text>

      {/* Error Message */}
      {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
    </form>
  );
};

export default CheckoutForm;
