import {
    Box,
    Text,
    chakra,
    HStack,
  } from "@chakra-ui/react";
  import getSymbolFromCurrency from "currency-symbol-map";

export const PerkComponent = ({
    perkName,
    perkPrice,
    noOfRmainingPerks,
    description,
    perkImage,
    waveType,
    currency,
    perkSelected,
    decreaseFunction,
    increaseFunction,
    accentColor
  }) => {
    return (
      <div>
        <Box width={"100%"}>
          <HStack
            padding="0.94rem"
            width={"100%"}
            borderRadius={description ? "2.5rem" : "4.25rem"}
            border={"1px solid rgba(255, 255, 255, 0.10)"}
            borderColor={
              noOfRmainingPerks <= 0
                ? "rgba(255, 255, 255, 0.05)"
                : "rgba(255, 255, 255, 0.10)"
            }
            justifyContent="space-between"
            alignItems={description ? "flex-start" : "center"}
          >
            {perkImage && (
              <img
                src={perkImage}
                alt={"image-icon"}
                style={{
                  width: "2.8125rem",
                  height: "2.8125rem",
                  borderRadius: "6.25rem",
                }}
              />
            )}
            <Box
              display={"flex"}
              flexDir={"column"}
              alignItems={"flex-start"}
              justifyContent={"center"}
              gap={"0.62rem"}
              marginLeft={"15px"}
              width={"100%"}
              overflow={"hidden"}
            >
              <Text
                fontSize="1.1875rem"
                color={"rgba(255, 255, 255, 0.60)"}
                className="gordita600"
                lineHeight={"120%"}
                letterSpacing={"-0.01188rem"}
                overflow={"hidden"}
                whiteSpace={"nowrap"}
                textOverflow={"ellipsis"}
                minW={"0"}
                width={"100%"}
              >
                {perkName}
              </Text>
              <Text
                color={
                  noOfRmainingPerks <= 0
                    ? "rgba(255, 255, 255, 0.40)"
                    : "#FFFFFF"
                }
                fontSize="1.1875rem"
                lineHeight={"110%"}
                letterSpacing={"-0.01188rem"}
                className="gordita700"
              >
                {perkPrice === 0
                  ? "Free"
                  : `${getSymbolFromCurrency(currency)} ${perkPrice}`}
              </Text>
              {description && (
                <Text
                  color={"rgba(255, 255, 255, 0.60)"}
                  fontSize={"0.875rem"}
                  className="gordita400"
                >
                  {description}
                </Text>
              )}
            </Box>

            {noOfRmainingPerks > 0 ? (
              <HStack
                display="flex"
                gap="5px"
                alignItems="center"
                // w={"18rem"}
              >
                <chakra.button
                  border="1px"
                  borderColor="rgba(255, 255, 255, 0.20)"
                  m={0}
                  p={0}
                  w="54px"
                  h="54px"
                  borderRadius="50%"
                  display={
                    perkSelected.find((perk) => perk.perkId === waveType)
                      ?.quantity > 0
                      ? "flex"
                      : "none"
                  }
                  alignItems="center"
                  justifyContent="center"
                  color="#FFFFFF"
                  cursor="pointer"
                  onClick={() => decreaseFunction(waveType)}
                  disabled={
                    perkSelected.find((perk) => perk.perkId === waveType)
                      ?.quantity === 0
                  }
                  _hover={{
                    borderColor: "#FFFFFF",
                  }}
                  _active={{
                    backgroundColor: "rgba(255, 255, 255, 0.05)",
                  }}
                  _disabled={{
                    color: "rgba(255, 255, 255, 0.40)",
                    borderColor: "rgba(255, 255, 255, 0.10)",
                    cursor: "not-allowed",
                  }}
                >
                  <span
                    style={{
                      fontSize: "2.5rem",
                      paddingTop: "4px",
                    }}
                  >
                    -
                  </span>
                </chakra.button>
                <chakra.span
                  color={
                    perkSelected.find((perk) => perk.perkId === waveType)
                      ?.quantity > 0
                      ? accentColor
                      : "rgba(255, 255, 255, 0.60)"
                  }
                  fontSize={"1.4375rem"}
                  width={"2rem"}
                  height={"2rem"}
                  paddingTop={"3px"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  className="gordita600"
                >
                  {perkSelected.find((perk) => perk.perkId === waveType)
                    ?.quantity || 0}
                </chakra.span>
                <chakra.button
                  border="1px"
                  borderColor="rgba(255, 255, 255, 0.20)"
                  m={0}
                  p={0}
                  w="54px"
                  h="54px"
                  borderRadius="50%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  color="#FFFFFF"
                  cursor="pointer"
                  onClick={() => increaseFunction(waveType)}
                  _hover={{
                    borderColor: "#FFFFFF",
                  }}
                  _active={{
                    backgroundColor: "rgba(255, 255, 255, 0.05)",
                  }}
                  disabled={
                    perkSelected.find((perk) => perk.perkId === waveType)
                      ?.quantity >= noOfRmainingPerks
                  }
                  _disabled={{
                    color: "rgba(255, 255, 255, 0.40)",
                    borderColor: "rgba(255, 255, 255, 0.10)",
                    cursor: "not-allowed",
                  }}
                >
                  <span
                    style={{
                      fontSize: "1.75rem",
                      paddingTop: "6px",
                    }}
                  >
                    +
                  </span>
                </chakra.button>
              </HStack>
            ) : (
              <Box
                bg={"rgba(255, 255, 255, 0.05)"}
                borderRadius={"6.25rem"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                color={"rgba(255, 255, 255, 0.05)"}
                w={"6.3125rem"}
                h={"3.375rem"}
                flexShrink={"0"}
              >
                <Text
                  fontSize={"1rem"}
                  color={"rgba(255, 255, 255, 0.60)"}
                  className="gordita700"
                  letterSpacing={"-0.01rem"}
                >
                  Sold Out
                </Text>
              </Box>
            )}
          </HStack>
        </Box>
      </div>
    );
  };