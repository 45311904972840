import {
  Button,
  Flex,
  HStack,
  Input,
  Select,
  Text,
  useColorModeValue,
  VStack,
  Textarea,
  Switch,
  Box,
  InputGroup,
  InputRightElement,
  useColorMode,
  Tooltip,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { theme } from "../../../../styles/theme/base";
import { SingleDatepicker } from "../../../shared/datepicker";
import { BsArrowRight, BsChevronLeft } from "react-icons/bs";
import { InfoOutlineIcon, TimeIcon } from "@chakra-ui/icons";
import { TimeBoxWrapper } from "../../../../styles/pages/dashboard";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import {
  EventBar as EventStyles,
  SelectItem,
} from "../../../../styles/layout/sharedModules";
import { Steps, TimePicker } from "antd";
import { ReactComponent as TimerIcon } from "../../../../static/assets/images/timerIcon.svg";
import { times } from "lodash";

/* `stepss` is an array of objects that represent the steps in a multi-step form. Each object has two
properties: `status` and `title`. `status` represents the current status of the step (e.g. "wait"
for a step that hasn't been completed yet, "process" for a step that is currently being completed,
and "finish" for a step that has been completed). `title` represents the title of the step. This
array is used to generate a progress bar in the `EventBar` component. */
const stepss = [
  {
    status: "wait",
    title: "Event Basics",
  },
  {
    status: "wait",
    title: "Ticket Details ",
  },
  {
    status: "wait",
    title: "Artist and Lineup",
  },
  {
    status: "wait",
    title: "Branding",
  },
  {
    status: "wait",
    title: "NFT Metadata",
  },
  {
    status: "wait",
    title: "Summary",
  },
];

export const EventBar = ({
  textValue,
  step,
  values,
  onStepChange,
  onGoBack,
  endStep,
  handleSubmit,
  onProgressChange,
  children,
}) => {
  const [items, setItems] = useState();
  const color = useColorModeValue("#000000", "#ffffff");
  useEffect(() => {
    const newSteps = stepss.map((val, index) =>
      index === step - 1 ? { status: "process", title: val.title } : val
    );
    setItems(newSteps);
  }, [step]);
  return (
    <EventStyles color={color}>
      <HStack justifyContent="space-between" spacing={10} flex={1}>
        <HStack
          alignItems="center"
          onClick={onGoBack}
          flex={1}
          cursor="pointer"
          className="gordita600"
          color={textValue}
          fontSize={16}
        >
          <BsChevronLeft />
          <Text pt={1}>Go Back</Text>
        </HStack>
        <HStack
          h="48px"
          px={24}
          className="gordita600"
          flex={12}
          color={textValue}
        >
          <Steps
            type="navigation"
            current={step - 1}
            onChange={onProgressChange}
            className="site-navigation-steps"
            items={items}
          />
        </HStack>
        {endStep && (
          <Flex mt={56} justifyContent="flex-end" flex={1}>
            <Button
              className="gordita600"
              onClick={handleSubmit}
              sx={theme.buttons.primary.shape}
              bg={theme.buttons.primary.default}
              color={theme.buttons.primary.color}
              _hover={{
                bg: theme.buttons.primary.hover,
                boxShadow: theme.buttons.primary.hoverBoxShadow,
              }}
              _active={{
                boxShadow: theme.buttons.primary.activeBoxShadow,
              }}
            >
              Publish
              <BsArrowRight />
            </Button>
          </Flex>
        )}
      </HStack>
      {children}
      {step < 6 && (
        <Flex mt={56} justifyContent="flex-end" flex={1}>
          <Button
            className="gordita600"
            onClick={() => {
              localStorage.setItem("event", JSON.stringify(values));
              onStepChange();
            }}
            sx={theme.buttons.primary.shape}
            color={theme.buttons.secondary.color}
            bg={theme.buttons.secondary.border}
            border={theme.buttons.secondary.border}
          >
            Next Step
            <BsArrowRight />
          </Button>
        </Flex>
      )}
    </EventStyles>
  );
};

export const InputBox = ({
  label,
  name,
  placeholder,
  maxW,
  tooltip = false,
  required = false,
  type = "text",
  disable = false,
  error = false,
  noSpaces = false,
  ...rest
}) => {
  const textValue = useColorModeValue("#000000", "#FFFFFF");
  const { values, handleBlur, handleChange, max, min } = rest;

  const handleInputChange = (event) => {
    if (noSpaces) {
      // Remove white spaces
      event.target.value = event.target.value.replace(/\s+/g, '');
    }
    handleChange(event); // Call the original handleChange
  };

  return (
    <Box w={maxW}>
      {label && (
        <Flex mb="0.94rem" alignItems="center" gap={10}>
          <Text
            fontSize={"1.3125rem"}
            color={textValue}
            className="gordita600"
            fontWeight={500}
            textTransform={"capitalize"}
          >
            {label} {required && <span style={{ color: "white" }}>*</span>}
          </Text>
          {tooltip && (
            <Tooltip hasArrow label="Data Required" bg="gray.300" color="black">
              <InfoOutlineIcon />
            </Tooltip>
          )}
        </Flex>
      )}
      <Input
        name={name}
        w="100%"
        type={type}
        h="56px"
        max={max}
        min={min}
        className="gordita400"
        borderRadius="0.625rem"
        bg="#000000"
        border="1px solid rgba(255, 255, 255, 0.20)"
        borderColor={error ? "#FF6B6B" : "rgba(255, 255, 255, 0.20)"}
        color="#FFFFFF"
        placeholder={placeholder}
        value={values[name]}
        onChange={handleInputChange}
        onBlur={handleBlur}
        disabled={disable}
        focusBorderColor={error ? "#FF6B6B" : "#FFFFFF"}
        _hover={{
          borderColor: error ? "#FF6B6B" : "#FFFFFF !important",
        }}
        _placeholder={{
          color: "rgba(255, 255, 255, 0.40)",
        }}
        {...rest}
        required={required}
      />
    </Box>
  );
};
export const TextBox = ({ name, label, placeholder, ...rest }) => {
  const textValue = useColorModeValue(
    theme.colors.black[100],
    theme.colors.white[100]
  );
  const { values, handleBlur, handleChange } = rest;
  /* const handleTextareaChange = (event) => {
    if (event.target.value.length <= 250) {
      handleChange(event);
    }
  };
  const characterCount = values[name]?.length || 0; // Get the current character count*/

  return (
    <Box w="100%" spacing={0} alignItems="flex-start">
      <Text
        fontSize={"1.3125rem"}
        mb="8px"
        color={textValue}
        className="gordita600"
        fontWeight={500}
        textTransform={"capitalize"}
      >
        {label}
      </Text>
      <Textarea
        bg="#000000"
        borderRadius=".625rem"
        w="100%"
        className="gordita400"
        placeholder={placeholder}
        border="1px solid rgba(255, 255, 255, 0.20)"
        value={values[name]}
        onChange={handleChange} //{handleTextareaChange} // Update the onChange handler
        onBlur={handleBlur}
        name={name}
        size="sm"
        minH="199px"
        color="#FFFFFF"
        focusBorderColor="#FFFFFF"
        _hover={{
          borderColor: "#FFFFFF",
        }}
        _placeholder={{
          color: "rgba(255, 255, 255, 0.40)",
          fontSize: 16,
        }}
        // maxLength={250}
      />
      {/*<Text mt="4px" fontSize="sm" color="gray.400">
        {characterCount}/250 characters
      </Text>
      {characterCount === 250 && (
        <Text mt="2px" fontSize="sm" color="red.500">
          Maximum character limit reached
      </Text>)}*/}
    </Box>
  );
};

/**
 * The code defines several reusable form input components in JavaScript.
 * @returns Four React functional components are being returned: SelectBox, DateBox, SwitchBox, and
 * TimeBox.
 */
export const SelectBox = ({
  label,
  placeholder,
  name,
  maxW,
  options,
  ...rest
}) => {
  const textValue = useColorModeValue(
    theme.colors.black[100],
    theme.colors.white[100]
  );
  const { values, handleBlur, handleChange } = rest;
  return (
    <Box w={maxW}>
      <SelectItem>
        <Text
          fontSize={14}
          mb="8px"
          color="#FFFFFF"
          className="gordita600"
          fontWeight={500}
        >
          {label}
        </Text>
        <Select
          color="#FFFFFF"
          w="100%"
          maxW={maxW}
          className="gordita400"
          h="56px"
          borderRadius="6.25rem"
          border="none"
          handleBlur={handleBlur}
          value={values[name]}
          placeholder={placeholder}
          onChange={(e) => handleChange(name, e.target.value)}
        >
          {!isEmpty(options) &&
            options.map(({ label, value }, index) => (
              <option className="optionss" value={value}>
                {label}
              </option>
            ))}
        </Select>
      </SelectItem>
    </Box>
  );
};

export const DateBox = ({
  name,
  label,
  placeholder,
  maxW,
  w,
  format,
  ...rest
}) => {
  const [date, setDate] = useState();
  const textValue = useColorModeValue(
    theme.colors.black[100],
    theme.colors.white[100]
  );
  const { values, handleBlur, handleChange } = rest;
  return (
    <Box w={maxW || "100%"}>
      <Text
        fontSize={"1.3125rem"}
        mb="8px"
        color="#FFFFFF"
        className="gordita600"
        fontWeight={500}
        textTransform={"capitalize"}
      >
        {label}
      </Text>
      <SingleDatepicker
        className="shared"
        name={name}
        maxW={"100%"}
        w={w}
        // date={values[name + "1"]}
        date={values[name]}
        onDateChange={(date, dateString) => {
          handleChange(name, dateString);
          handleChange(name + "1", date);
        }}
        placeholder={placeholder}
      />
    </Box>
  );
};

export const SwitchBox = ({
  name,
  maxW,
  label,
  placeholder,
  tooltip = false,
  tooltipData,
  ...rest
}) => {
  const textValue = useColorModeValue(
    theme.colors.black[100],
    theme.colors.white[100]
  );
  const { values, handleBlur, handleChange } = rest;
  return (
    <HStack
      w="100%"
      maxW={maxW}
      spacing={0}
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Flex mb="8px" alignItems="center" gap={10}>
        <Text
          fontSize={14}
          color={textValue}
          className="gordita600"
          fontWeight={500}
        >
          {label}
        </Text>
        {tooltip && (
          <Tooltip hasArrow label={tooltipData} bg="gray.300" color="black">
            <InfoOutlineIcon />
          </Tooltip>
        )}
      </Flex>
      <Switch
        defaultChecked={values[name]}
        onChange={(val) => handleChange(name, val.target.checked)}
        size="md"
        pl={56}
      />
    </HStack>
  );
};

export const TimeBox = ({
  name,
  label,
  placeholder,
  maxW,
  color = "#FFFFFF",
  ...rest
}) => {
  const [date, setDate] = useState();
  const textValue = useColorModeValue(
    theme.colors.black[100],
    theme.colors.white[100]
  );
  const { values, handleBlur, handleChange } = rest;

  const icon = <TimeIcon fontSize="sm" />;
  dayjs.extend(customParseFormat);

  const formattedTime = values[name]
    ? dayjs(values[name], "HH:mm:ss")
    : values[name];


  return (
    <TimeBoxWrapper>
      <Box w={maxW || "100%"}>
        <Text
          fontSize={"1.3125rem"}
          mb="8px"
          color="#FFFFFF"
          className="gordita600"
          fontWeight={500}
          textTransform={"capitalize"}
        >
          {label}
        </Text>
        <TimePicker
          onChange={(time, timeString) => {
            // handleChange(name, moment(timeString, "h:mm A").format("HH:mm"));

            const formattedTime = dayjs(time).format("HH:mm:ss");
            handleChange(name, formattedTime);
          }}
          name={name}
          placeholder={placeholder}
          use12Hours
          format="h:mm a"
          value={formattedTime}
          style={{
            border: "1px solid rgba(255, 255, 255, 0.20)",
            borderRadius: "6.25rem",
            backgroundColor: "#000000",
            height: 56,
            width: "100%",
            color: "#FFFFFF",
            color: color,
            fontSize: 14,
            padding: "0.31rem",
          }}
          popupClassName="time-picker-popup"
          suffixIcon={<TimerIcon />}
        />
      </Box>
    </TimeBoxWrapper>
  );
};
