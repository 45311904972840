import React, { useState } from "react";
import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  Image,
  useToast,
  Icon,
  Input,
} from "@chakra-ui/react";
import { LinkIcon } from '@chakra-ui/icons'; 
import { InfoIcon } from '@chakra-ui/icons'; 

import { theme } from "../../../../styles/theme/base";

import deleteIcon from "../../../../static/assets/images/deleteIcon.svg";
import { InputBox } from "./SharedModules";

function TableDetails(props) {
  const { handleBlur, values, setFieldValue, handleChange } = props;

  const [focused, setFocused] = useState(false);


  return (
    <VStack width={"100%"} alignItems={"flex-start"}>
      <Text
        fontSize={"1.25rem"}
        className="gordita700"
        textTransform={"capitalize"}
      >
        Tables 
      </Text>
      <Text
              marginTop={"1.56rem"}
              className="gordita400"
              fontSize={16}
              textAlign="left"
              w="100%"
              color="rgba(255, 255, 255, 0.60)"
            >
              You can add the link to the platform you use for managing your tables. If the attendees click on your table, they will be redirected to the link you added. It can be any link like Sevenrooms, Whatsapp, Email etc...
            </Text>
    
          <InputBox
              values={values}
              handleBlur={handleBlur}
              handleChange={handleChange}
              placeholder="Add tables link"
              label=""
              name="tableLink"
              width='100%'
              maxW="100%"
              marginTop='10px'

          />

          
          <InputBox
               values={values}
               marginTop='10px'
               handleChange={handleChange}
               handleBlur={handleBlur}
               placeholder="Add a small description (optional)"
               label=""
               name="tableDescription"
               maxW="100%"


          />
       
    </VStack>
  );
}

export default TableDetails;
