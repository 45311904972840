import {
  Box,
  HStack,
  Stack,
  Table,
  Thead,
  Tbody,
  Th,
  Td,
  TableContainer,
  Text,
  Image,
  Tr,
  useColorModeValue,
  Link,
  Flex,
  Button,
  useToast,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  VStack,
  IconButton,
  Checkbox,
  Heading,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../../utils/api/axios";
import secrets from "../../../../secrets";
import { useRecoilState } from "recoil";
import { user } from "../../../../recoil/atoms/user";
import { theme } from "../../../../styles/theme/base";
import { getEvents } from "../../../../utils/actions/event";
import { isEmpty } from "lodash";
import { addNewVerifier } from "../../../../utils/actions/registration";
import { assignVerifier } from "../../../../utils/actions/registration";
import { VerifierSchema } from "../../../../utils/schema";
import { CopyIcon } from "@chakra-ui/icons";
import trashicon from "../../../../static/assets/images/TypeDelete.svg";
import { SearchIcon } from "@chakra-ui/icons";
import "./teamComponent.css";

const spacing = {
  gap: 0,
  spacing: 0,
  w: "100%",
};
const generateRandomString = (length) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
};
/**
 * The UsersComponent function displays a table of attendees or organizers, with options to view
 * details or approve organizers if the user is an admin.
 * @returns The `UsersComponent` functional component is being returned.
 */
const VerifierComponent = () => {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const navigate = useNavigate();
  const type = window.location.href?.includes("organizers")
    ? "ORGANIZER"
    : "ATTENDEE";
  const [_, setUser] = useRecoilState(user);
  const colorValue = useColorModeValue("black.100", "gray.100");
  const [currentPage, setCurrentPage] = useState(1);
  const toast = useToast();
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [checkedItems, setCheckedItems] = useState([false, false]);
  const [events, setEvents] = useState([]);
  const [isAllEventsSelected, setIsAllEventsSelected] = useState(false);
  const [verifierId, setVerifierId] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedEvents, setSelectedEvents] = useState([]);
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 2); // Add 2 days to the current date
  const formattedCurrentDate = currentDate.toISOString().split("T")[0];
  const organizerId = _.userData?.organizerId || _.userData?.userId;

  const isEventsAvailable =
    data?.filter((event) => event?.endDate >= formattedCurrentDate).length !==
    0;

  const handleCheckboxToggle = (idEvent) => {
    setIsAllEventsSelected(false);
    setEvents((prevEvents) => {
      // Toggle the event ID in the events array
      if (prevEvents.includes(idEvent)) {
        return prevEvents.filter((event) => event !== idEvent);
      } else {
        return [...prevEvents, idEvent];
      }
    });
  };

  const handleCopyCode = async (codeToCopy) => {
    try {
      // Attempt to copy the code to the clipboard
      await navigator.clipboard.writeText(codeToCopy);
      toast({
        title: "Code Copied",
        status: "success",
        isClosable: true,
        duration: 2000,
        position: "top-right",
      });
    } catch (error) {
      console.error("Error copying code:", error);
      toast({
        title: "Copy Error",
        description: "Failed to copy the code. Please try again.",
        status: "error",
        isClosable: true,
        duration: 4000,
        position: "top-right",
      });
    }
  };
  const handleCopyCode2 = () => {
    // Copy the code to the clipboard
    navigator.clipboard.writeText(code);
  };

  useEffect(() => {
    if (isModalOpen) {
      // Generate a new random code when the modal is opened
      setEmail("");
      setName("");
      setEvents([]);
      setCode(generateRandomString(8));
    }
  }, [isModalOpen]);

  const init = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user_d"));
      if (user?.userRole === "ADMIN") {
        await axios
          .get("/api/events/admin", {
            headers: {
              "x-auth-token": "Bearer " + _?.token,
            },
          })
          .then((res) => {
            const data = res.data;
            if (!isEmpty(data)) {
              const newEvents = [];
              Object.values(data).forEach((et) => {
                newEvents.push({ ...et });
              });
              console.log("newevents1", newEvents);
              setData(newEvents);
            }
          })
          .catch((err) => {
            console.log({ err });
          });
      } else {
        console.log("checkpoint2");
        const val = await getEvents(organizerId);

        if (!isEmpty(val)) {
          console.log("checkpoint3");
          const newEvents = [];
          Object?.values(val)?.forEach((et) => {
            newEvents.push({ ...et });
          });
          console.log("newevents2", newEvents);
          setData(newEvents);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    init();
  }, []);

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };
  const handleButtonClick2 = () => {
    setSelectedEmail(email);
    setIsModalOpen2(true);
  };

  const handleSaveClick = async () => {
    try {
      await VerifierSchema.validate({ email });
      setIsEmailValid(true); // Email is valid
      const payload = {
        email,
        organizerId: organizerId,
        name,
        code,
        events,
      };
      console.log("checkpoint30");
      const addVerifier = await addNewVerifier(payload);
      console.log("checkpoint31");
      if (!addVerifier.success) {
        console.log("checkpoint32");
        toast({
          title: "Verifier Creation Error",
          description: "Verifier already exists",
          status: "error",
          isClosable: true,
          duration: 4000,
        });
      } else {
        console.log("checkpoint33");
        toast({
          title: "Verifier Created Successfully",
          status: "success",
          isClosable: true,
          duration: 1500,
          position: "top-right",
        });
        getVerifiers();
        setIsModalOpen(false);
      }
      // Continue with your save logic...
    } catch (error) {
      setIsEmailValid(false); // Email is invalid
    }
  };
  const handleEditClick = async () => {
    try {
      const payload = {
        email: selectedEmail,
        events,
      };
      console.log("checkpoint30");
      const addVerifier = await assignVerifier(payload);
      console.log("checkpoint31");
      if (!addVerifier) {
        console.log("checkpoint32");
        toast({
          title: "Event Assigning Error",
          description: "Event already assigned",
          status: "error",
          isClosable: true,
          duration: 4000,
        });
      } else {
        console.log("checkpoint33");
        toast({
          title: "Verifier Assigned Successfully",
          status: "success",
          isClosable: true,
          duration: 1500,
          position: "top-right",
        });
        getVerifiers();
        setIsModalOpen2(false);
      }
      // Continue with your save logic...
    } catch (error) {
      setIsEmailValid(false); // Email is invalid
    }
  };

  const getVerifiers = async () => {
    try {
      let url = "/api/getVerifiers/" + organizerId;
      const response = await axios.get(url, {
        headers: {
          "x-auth-token": "Bearer " + _?.token,
        },
      });

      setData2(response?.data?.verifier);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getVerifiers();
  }, [_.userData?.userId]);

  const removeVerifier = async () => {
    const user = _?.userData;
    const token = _?.token;
    await axios
      .delete( "/api/deleteVerifier/" + verifierId, {
        headers: {
          "x-auth-token": "Bearer " + token,
        },
      })
      .then(async (res) => {
        await init();
        getVerifiers();
        onClose();

        toast({
          title: "Verifier Removed",
          position: "bottom",
        });
      })
      .catch((err) => {
        console.log({ err });
        onClose();
      });
  };



  const isAllEventsToggled =
    data
      ?.filter((event) => event?.endDate >= formattedCurrentDate)
      .map((event) => event?.idEvent).length === events.length;

  const commonButtonStyle = {
    display: "flex",
    height: "45px",
    padding: "15px 25px",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    flex: "1 0 0",
    borderRadius: "100px",
  };

  const selectedButton = {
    ...commonButtonStyle,
    background: "var(--colors-shades-white-10, rgba(255, 255, 255, 0.10))",
    color: "white",
  };

  const notSelectedButton = {
    ...commonButtonStyle,
    background: "none",
    color: "#FFFFFF99",
  };

  return (
    <Box
      w="100%"
      style={{
        fontFamily: "Gordita Regular",
        width: "100%",
        height: "100vh",
        overflow: "scroll",
      }}
    >
      {" "}
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
        <ModalOverlay />
        <ModalContent
          bg="black.100"
          border="1px solid #333333"
          borderRadius="1.25rem"
        >
          <ModalHeader color="white.100">Cancel</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete this verifier? This action cannot be
            undone.
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onClose}
              sx={theme.buttons.primary.shape}
              bg={theme.buttons.secondary.default}
              color={theme.buttons.secondary.color}
              _hover={{ bg: theme.buttons.secondary.hover }}
              style={{
                width: "fit-content",
              }}
              mr={3}
            >
              Cancel
            </Button>
            <Button
              onClick={removeVerifier}
              sx={theme.buttons.primary.shape}
              bg={theme.buttons.primary.default}
              color={theme.buttons.primary.color}
              _hover={{
                bg: theme.buttons.primary.hover,
                boxShadow: theme.buttons.primary.hoverBoxShadow,
              }}
              _active={{
                boxShadow: theme.buttons.primary.activeBoxShadow,
              }}
              style={{
                width: "5rem",
                padding: "0",
              }}
            >
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box w="100%" minH="80vh">
        <Stack
          {...{ spacing }}
          flexDir="row"
          color="#FBFAFB"
          pos={"sticky"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={"10px"}
          top={0}
          p={"30px"}
          minHeight={"110px"}
          zIndex={10}
          w="100%"
          fontWeight={700}
          bg={"rgba(0, 0, 0, 0.50)"}
          backdropFilter={"blur(150px)"}
          flexWrap={"wrap"}
          className={"team-component-header-container"}
        >
          <Box className="team-component-header-title" fontSize={"32px"}>
            Team
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            flexWrap={"wrap"}
            gap={"10px"}
            className="team-component-header-filters"
          >
            <Box
              sx={{
                display: "flex",
                maxWidth: "416px",
                padding: "5px",
                alignItems: "flex-start",
                gap: "5px",
                borderRadius: "100px",
                border:
                  "1px solid var(--border-b-primary, rgba(255, 255, 255, 0.20))",
                background: "var(--surface-s-glass, rgba(0, 0, 0, 0.50))",
                backdropFilter: "blur(75px)",
              }}
            >
              <Button sx={selectedButton}> Verifiers</Button>
              <Button
                onClick={() => {
                  navigate("/team");
                }}
                sx={notSelectedButton}
              >
                {" "}
                Collaborators
              </Button>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              width="18.1875rem"
              padding="var(--spacing-spacing-s, 0.9375rem) var(--spacing-spacing-m, 1.25rem)"
              gap="var(--spacing-spacing-s, 0.9375rem)"
              flexShrink={0}
              borderRadius="var(--radius-radius-full, 6.25rem)"
              border="1px solid rgba(255, 255, 255, 0.20)"
              height="3.375rem"
            >
              <SearchIcon color="#fff" />
              <Input
                placeholder="Search Verifier"
                focusBorderColor="none"
                border={"none"}
                color="#fff"
                className="gordita400"
                outline="none"
                type="text"
                _placeholder={{
                  color: "rgba(255, 255, 255, 0.40)",
                }}
              />
            </Box>
          </Box>

          {/* <HStack justifyContent="flex-end" flex={1} /> */}

          <Button
            onClick={handleButtonClick}
            sx={theme.buttons.primary.shape}
            bg={theme.buttons.primary.default}
            color={theme.buttons.primary.color}
            _hover={{
              bg: theme.buttons.primary.hover,
              boxShadow: theme.buttons.primary.hoverBoxShadow,
            }}
            _active={{
              bg: theme.buttons.primary.hover,
              boxShadow: theme.buttons.primary.activeBoxShadow,
            }}
            className="team-component-header-button"
          >
            Add Verifier
          </Button>
        </Stack>
        <Box w="100%" px={"30px"} marginBottom={"30px"}>
        {Object.values(data2)?.length > 0 ? (

          <TableContainer>
            <Table
              style={{
                borderCollapse: "separate",
                borderSpacing: "0 18px",
                marginBottom: "30px",
              }}
              variant="unstyled"
            >
              <Thead
                borderBottom="1px solid"
                borderColor="rgba(153, 159, 187,0.2)"
              >
                <Tr>
                  {["Name", "Email", "Events", "Code", "Actions"].map(
                    (column, index) => {
                      return (
                        <Th
                          key={index}
                          paddingBottom={"20px"}
                          textTransform="capitalize"
                          color="var(--surface-surface-secondary, #959597)" // Added color
                          fontFamily="Gordita" // Added font family
                          fontSize="1rem" // Added font size
                          fontStyle="normal"
                          fontWeight={400} // Added font weight
                          lineHeight="150%" // Added line height
                          letterSpacing="-0.01rem" // Added letter spacing
                          position="relative"
                          textAlign={"center"}
                          border={"none"}
                          borderRadius="0rem" // Added border radius
                          borderRight="0px solid rgba(255, 255, 255, 0.10)" // Added border right
                          borderBottom="1px solid rgba(255, 255, 255, 0.10)" // Added border bottom
                          borderLeft="0px solid rgba(255, 255, 255, 0.10)" // Added border left
                          background="rgba(0, 0, 0, 0.50)" // Added background
                          backdropFilter="blur(75px)" // Added blur
                        >
                          {column}
                        </Th>
                      );
                    }
                  )}
                </Tr>
              </Thead>
              <Tbody marginBottom={"30px"}>
                {Object?.values(data2)?.map(
                  ({ name, email, code, events, verifierId }, index) => {
                    // Convert the events JSON object into an array of event IDs
                    const eventIds = events ? Object?.values(events) : [];

                    // Find the event names using the event IDs from the 'data' variable
                    const eventNames = eventIds.map((eventId) => {
                      const event = data.find(
                        (event) => event.idEvent === eventId
                      );
                      return event ? event.eventName : "";
                    });

                    return (
                      <Tr overflow="hidden" color="white.100" key={index}>
                        <Td
                          m={0}
                          py={0}
                          pl={20}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          borderLeft={"1px solid rgba(255, 255, 255, 0.10)"}
                          borderTopLeftRadius={"0.9375rem"}
                          borderBottomLeftRadius={"0.9375rem"}
                          overflow={"hidden"}
                          maxWidth={"15rem"}
                        >
                          <HStack py={0} spacing={10}>
                            <Text
                              className="gordita400"
                              color="#FFF" // Added color
                              fontFeatureSettings="'ss08' on, 'ss04' on" // Added font feature settings
                              fontFamily="Gordita" // Added font family
                              fontSize="1.125rem" // Added font size
                              fontStyle="normal"
                              fontWeight={700} // Added font weight
                              lineHeight="110%" // Added line height
                              letterSpacing="-0.01125rem" // Added letter spacing
                            >
                              {`${name || ""}`}
                            </Text>
                          </HStack>
                        </Td>
                        <Td
                          m={0}
                          py={0}
                          textAlign={"center"}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          maxWidth={"14rem"}
                        >
                          <Text
                            fontSize={14}
                            className="gordita400"
                            style={{
                              maxWidth: "100%",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              textAlign: "center",
                            }}
                          >
                            {email || ""}
                          </Text>
                        </Td>
                        <Td
                          m={0}
                          py={0}
                          textAlign={"center"}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          maxWidth={"14rem"}
                        >
                          {eventNames.length > 0
                            ? eventNames.map((eventName, eventIndex) => (
                                <Text
                                  key={eventIndex}
                                  fontSize={14}
                                  className="gordita400"
                                  style={{
                                    maxWidth: "100%",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {eventName}
                                </Text>
                              ))
                            : "Not Assigned Yet"}
                        </Td>
                        <Td
                          m={0}
                          py={0}
                          textAlign={"center"}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          position={"relative"}
                          alignContent={"center"}
                          justifyContent={"center"}
                        >
                          <Box
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            gap={".5rem"}
                            borderRadius={"6.25rem"}
                            border={"1px solid rgba(255, 255, 255, 0.20)"}
                            width={"10.4375rem"}
                            margin={"auto"}
                            bg={"black"}
                          >
                            {/* Make the cell relative to position the icon */}
                            <Text
                              className="gordita400"
                              color="#FFF"
                              fontFamily="Gordita"
                              fontSize="1.125rem"
                              fontStyle="normal"
                              fontWeight="500"
                              lineHeight="110%"
                              letterSpacing="-0.01125rem"
                              fontFeatureSettings="'ss08' on, 'ss04' on"
                            >
                              {code || 0}
                            </Text>

                            <IconButton
                              icon={<CopyIcon />}
                              aria-label="Copy Code"
                              onClick={() => handleCopyCode(code)} // Pass the code to the handleCopyCode function
                              // You can style the IconButton as needed
                              style={{
                                display: "flex",
                              }}
                              bg={"black"}
                            />
                          </Box>
                        </Td>
                        <Td
                          m={0}
                          pl={0}
                          textAlign={"end"}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          borderTopRightRadius={"0.9375rem"}
                          borderBottomRightRadius={"0.9375rem"}
                        >
                          <HStack
                            justifyContent="center"
                            alignItems="center"
                            spacing={24}
                          >
                            <Button
                              cursor="pointer"
                              borderRadius={"3.125rem"}
                              border={"1px solid rgba(255, 255, 255, 0.20)"}
                              bg="transparent"
                              onClick={() => {
                                setSelectedEmail(email);
                                setIsModalOpen2(true);
                                setEvents(eventIds);
                              }}
                            >
                              Assign
                            </Button>
                            <Button
                              cursor="pointer"
                              onClick={() => {
                                setVerifierId(verifierId);
                                onOpen();
                              }}
                              sx={theme.fixedButtons.secondary.shape}
                              bg={theme.buttons.secondary.default}
                              border="1px solid rgba(255, 255, 255, 0.20)"
                              backdropFilter="blur(100px)"
                            >
                              <Image alt="" src={trashicon} />
                            </Button>
                          </HStack>
                        </Td>
                      </Tr>
                    );
                  }
                          )}
                          </Tbody>
                        </Table>
                        </TableContainer>
                      ) : (
                        <Box
  display="flex"
  flexDirection="column"
  justifyContent="center"
  alignItems="center"
  height="100%"
>
  <Text textAlign="center" mb={4}> {/* Added margin-bottom to create space between text and button */}
    Add your first verifier to scan tickets
  </Text>

  <Button
    onClick={() => window.open("https://portalys.io?verifier=true", "_blank")}
    sx={theme.buttons.tercary.shape}
    bg={theme.buttons.tercary.bgColor}
    color={theme.buttons.tercary.color}
    _hover={{ bg: theme.buttons.tercary.hover }}
    css={{
      width: "fit-content !important",
      whiteSpace: "normal",  // Allows text to wrap
      textAlign: "center",   // Ensures text is centered even when wrapping
    }}
  >
    Download the Portalys Access App to scan tickets &#8599;
  </Button>
</Box>

                      )}
                      </Box>
                      </Box>
                      <Modal
                      isOpen={isModalOpen}
                      onClose={() => setIsModalOpen(false)}
                      size="6xl"
                      isCentered
                      zIndex={15}
                      >
                      <ModalOverlay />
                      <ModalContent
                        bg="black"
                        border="1px solid #333333"
                        borderColor="#333333"
                        borderRadius="1.25rem"
                      >
                        <ModalHeader>Add Verifier</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                        <HStack
                          display={"flex"}
                          alignItems={"start"}
                          justifyContent={"center"}
                          mt={"3rem"}
                          gap={"50px"}
                          flexWrap={"wrap"}
                        >
                          <VStack w={"100%"} maxW={"25rem"}>
                          <Text w={"100%"} fontWeight={"bold"} textAlign="center">
                            Info
                          </Text>
                          <Input
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            borderRadius={"20px"}
                            borderColor={"rgba(255, 255, 255, 0.20)"}
                            color="#FFFFFF"
                            focusBorderColor="#FFFFFF"
                            _hover={{
                            borderColor: "#FFFFFF !important",
                            }}
                            _placeholder={{
                            color: "rgba(255, 255, 255, 0.40)",
                            }}
                          />
                          {!isEmailValid && (
                            <div style={{ color: "red", textAlign: "center" }}>
                            Invalid email address
                            </div>
                          )}
                          <Input
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            borderRadius={"20px"}
                            borderColor={"rgba(255, 255, 255, 0.20)"}
                            color="#FFFFFF"
                            focusBorderColor="#FFFFFF"
                            _hover={{
                            borderColor: "#FFFFFF !important",
                            }}
                            _placeholder={{
                            color: "rgba(255, 255, 255, 0.40)",
                            }}
                          />
                          <div
                            style={{
                            position: "relative",
                            width: "100%",
                            }}
                          >
                            <Input
                            value={code}           borderRadius={"20px"}
                            w={"100%"}
                            readOnly                    borderRadius={"20px"}
                    borderColor={"rgba(255, 255, 255, 0.20)"}
                    color="#FFFFFF"
                    focusBorderColor="#FFFFFF"
                    _hover={{
                      borderColor: "#FFFFFF !important",
                    }}
                    _placeholder={{
                      color: "rgba(255, 255, 255, 0.40)",
                    }}
                  />
                                    <IconButton
                    icon={<CopyIcon />}
                    aria-label="Copy Code"
                    onClick={handleCopyCode2}
                    // You can style the IconButton as needed
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "0.75rem",
                      transform: "translateY(-50%)",
                    }}
                  />
                </div>

                <Button onClick={() => window.open("https://portalys.io?verifier=true", "_blank")} 
                                sx={theme.buttons.tercary.shape}
                                bg={theme.buttons.tercary.bgColor}
                                color={theme.buttons.tercary.color}
                                _hover={{ bg: theme.buttons.tercary.hover }}
                                css={{
                                  width: "fit-content !important",
                                  whiteSpace: "normal",  // Allows text to wrap
                                  textAlign: "center",   // Ensures text is centered even when wrapping
                                }}>
                      Download the Portalys Access App to scan tickets &#8599;
                    </Button>    
              </VStack>
              <VStack w={"50%"}>
                <Text w={"100%"} fontWeight={"bold"}>
                  Assign events
                </Text>
                <Box // New container to center the Text and Button
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  {isEventsAvailable ? (
                    <Box
                      m={".5rem 0"}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      w={"100%"}
                      pr={"1.75rem"}
                    >
                      <Checkbox
                        isChecked={isAllEventsSelected || isAllEventsToggled}
                        colorScheme="white"
                        textAlign={"center"}
                        onChange={() => {
                          setEvents(
                            data
                              ?.filter(
                                (event) =>
                                  event?.endDate >= formattedCurrentDate
                              )
                              .map((event) => event?.idEvent)
                          );

                          if (isAllEventsSelected || isAllEventsToggled) {
                            setIsAllEventsSelected(false);
                            setEvents([]);
                          } else {
                            setIsAllEventsSelected(true);
                          }
                        }}
                      >
                        <Text pb={".35rem"}>Select All</Text>
                      </Checkbox>
                      {events?.length !== 0 && (
                        <Box>
                          <Text fontSize={".9rem"}>
                            {events?.length} Events Selected
                          </Text>
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <span>No Events Available</span>
                  )}
                  <Box height={"25rem"} overflow={"auto"}>
                    {data.length !== 0 ? (
                      data
                        .filter(
                          (event) => event?.endDate >= formattedCurrentDate
                        )
                        .map((event) => (
                          <Checkbox
                            colorScheme="white"
                            isChecked={events.includes(event?.idEvent)}
                            key={event?.idEvent}
                            onChange={() =>
                              handleCheckboxToggle(event?.idEvent)
                            }
                          >
                            <div
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "column",
                                gap: ".25rem",
                                width: "30rem",
                                border: "1px solid rgba(255, 255, 255, 0.20)",
                                borderColor: events.includes(event?.idEvent)
                                  ? "#fff"
                                  : "rgba(255, 255, 255, 0.20)",
                                margin: ".25rem",
                                padding: ".5rem 1.75rem",
                                borderRadius: "50px",
                              }}
                            >
                              <div key={event?.idEvent}>
                                <Text
                                  fontSize="1rem"
                                  fontWeight="700"
                                  overflow={"hidden"}
                                  whiteSpace={"nowrap"}
                                  textOverflow={"ellipsis"}
                                  w={"100%"}
                                >
                                  {event?.eventName}
                                </Text>
                                {/* Render other event details */}
                              </div>
                              <div style={{ display: "flex", gap: ".75rem" }}>
                                <Text fontSize=".7rem" fontWeight="400">
                                  {event?.startDate}
                                </Text>
                                <Text
                                  fontSize=".7rem"
                                  fontWeight="400"
                                  color="rgba(255, 255, 255, 0.60)"
                                  overflow={"hidden"}
                                  whiteSpace={"nowrap"}
                                  textOverflow={"ellipsis"}
                                >
                                  {event?.location}
                                </Text>
                              </div>
                            </div>
                          </Checkbox>
                        ))
                    ) : (
                      <>
                        <Text
                          marginTop="4rem"
                          fontSize="1.25rem"
                          fontWeight="700"
                        >
                          You have no upcoming events
                        </Text>
                        <Button
                          marginTop="1.56rem"
                          sx={theme.buttons.tercary.shape}
                          color={theme.buttons.tercary.color}
                          border={theme.buttons.tercary.border}
                          onClick={() =>
                            navigate("/events/create", {
                              state: { isEditMode: false },
                            })
                          }
                        >
                          Create an Event!
                        </Button>
                      </>
                    )}
                  </Box>
                </Box>
              </VStack>
            </HStack>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => setIsModalOpen(false)}
              sx={theme.buttons.tercary.shape}
              bg={theme.buttons.tercary.bgColor}
              color={theme.buttons.tercary.color}
              _hover={{ bg: theme.buttons.tercary.hover }}
              css={{
                width: "fit-content !important",
                padding: "1.25rem",
                marginRight: "10px",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSaveClick}
              sx={theme.buttons.primary.shape}
              bg={theme.buttons.primary.default}
              color={theme.buttons.primary.color}
              _hover={{
                bg: theme.buttons.primary.hover,
                boxShadow: theme.buttons.primary.hoverBoxShadow,
              }}
              _active={{
                boxShadow: theme.buttons.primary.activeBoxShadow,
              }}
              style={{
                width: "6rem",
              }}
              // disabled={!isEventsAvailable}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isModalOpen2}
        onClose={() => {
          setIsModalOpen2(false);
          setEvents([]);
          setIsAllEventsSelected(false);
        }}
        size="2xl"
        isCentered
        zIndex={10}
      >
        <ModalOverlay />
        <ModalContent
          bg="black"
          border="1px solid #333333"
          borderRadius="1.25rem"
        >
          <ModalHeader>Assign events</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box // New container to center the Text and Button
              display="flex"
              flexDirection="column"
              alignItems="start"
              flexWrap={"wrap"}
              m={"1rem 0"}
              w={"100%"}
            >
              {isEventsAvailable ? (
                <Box
                  m={".5rem 0"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  w={"100%"}
                  pr={"1.75rem"}
                >
                  <Checkbox
                    isChecked={
                      isAllEventsSelected ||
                      data
                        ?.filter(
                          (event) => event?.endDate >= formattedCurrentDate
                        )
                        .map((event) => event?.idEvent)?.length ===
                        events?.length
                    } // Add this line
                    colorScheme="white"
                    textAlign={"center"}
                    onChange={() => {
                      setEvents(
                        data
                          ?.filter(
                            (event) => event?.endDate >= formattedCurrentDate
                          )
                          .map((event) => event?.idEvent)
                      );

                      if (isAllEventsSelected || isAllEventsToggled) {
                        setIsAllEventsSelected(false);
                        setEvents([]);
                      } else {
                        setIsAllEventsSelected(true);
                      }
                    }}
                  >
                    <Text pb={".35rem"}>Select All</Text>
                  </Checkbox>
                  {events?.length !== 0 && (
                    <Box>
                      <Text fontSize={".9rem"}>
                        {events?.length} Events Selected
                      </Text>
                    </Box>
                  )}
                </Box>
              ) : (
                <span>No Events Available</span>
              )}
              <Box height={"25rem"} overflow={"auto"}>
                {data.length !== 0 ? (
                  data
                    ?.filter((event) => event?.endDate >= formattedCurrentDate)
                    ?.map((event) => (
                      <Checkbox
                        isChecked={events.includes(event?.idEvent)} // Add this line
                        colorScheme="white"
                        key={event?.idEvent}
                        onChange={() => handleCheckboxToggle(event?.idEvent)}
                        w={"100%"}
                      >
                        <div
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            flexDirection: "column",
                            gap: ".25rem",
                            width: "36rem",
                            border: "1px solid",
                            borderColor: events.includes(event?.idEvent)
                              ? "#FFF"
                              : "rgba(255, 255, 255, 0.20)",
                            margin: ".25rem",
                            padding: ".5rem 1.75rem",
                            borderRadius: "50px",
                          }}
                        >
                          <div key={event?.idEvent}>
                            <Text
                              fontSize="1rem"
                              fontWeight="700"
                              overflow={"hidden"}
                              whiteSpace={"nowrap"}
                              textOverflow={"ellipsis"}
                              w={"100%"}
                            >
                              {event?.eventName}
                            </Text>
                            {/* Render other event details */}
                          </div>
                          <div style={{ display: "flex", gap: ".75rem" }}>
                            <Text fontSize=".7rem" fontWeight="400">
                              {event?.startDate}
                            </Text>
                            <Text
                              fontSize=".7rem"
                              fontWeight="400"
                              color="rgba(255, 255, 255, 0.60)"
                              overflow={"hidden"}
                              whiteSpace={"nowrap"}
                              textOverflow={"ellipsis"}
                            >
                              {event?.location}
                            </Text>
                          </div>
                        </div>
                      </Checkbox>
                    ))
                ) : (
                  <>
                    <Text marginTop="4rem" fontSize="1.25rem" fontWeight="700">
                      You have no upcoming events
                    </Text>
                    <Button
                      marginTop="1.56rem"
                      sx={theme.buttons.tercary.shape}
                      color={theme.buttons.secondary.color}
                      border={theme.buttons.secondary.border}
                      onClick={() =>
                        navigate("/events/create", {
                          state: { isEditMode: false },
                        })
                      }
                    >
                      Create an Event!
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => setIsModalOpen2(false)}
              sx={theme.buttons.primary.shape}
              bg={theme.buttons.secondary.default}
              color={theme.buttons.secondary.color}
              _hover={{ bg: theme.buttons.secondary.hover }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleEditClick}
              sx={theme.buttons.primary.shape}
              bg={theme.buttons.primary.default}
              color={theme.buttons.primary.color}
              _hover={{
                bg: theme.buttons.primary.hover,
                boxShadow: theme.buttons.primary.hoverBoxShadow,
              }}
              _active={{
                boxShadow: theme.buttons.primary.activeBoxShadow,
              }}
              style={{
                width: "6rem",
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default VerifierComponent;
