import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { user } from "../../recoil/atoms/user";
import CreateEvent from "../../modules/pages/Events/Create/index";

const OnboardingEvent = (props) => {

    return (<CreateEvent {...props} />);
};

export default OnboardingEvent;

