export const TipsComponent = ({
    isChecked,
    handleCheckboxChange,
    donationAmount,
    handleDonationChange

  }) => 
(
<div>
<div style={{ marginBottom: "10px" }}>
  <label>
    <input
      type="checkbox"
      checked={isChecked}
      onChange={handleCheckboxChange}
    />{" "}
    Round up to donate to Grads Helping Grads fund?
  </label>
</div>
<div style={{ marginLeft: "5px", marginTop: "5px" }}>
  <p style={{ marginTop: "5px" }}>
    Select tip to donate:
  </p>

  <label
    style={{ marginRight: "10px", display: "block" }}
  >
    <input
      type="checkbox"
      name="donationAmount"
      value={1}
      checked={donationAmount === 1}
      onChange={handleDonationChange}
      style={{ marginLeft: "5px" }}
    />
    $1
  </label>

  <label
    style={{ marginRight: "10px", display: "block" }}
  >
    <input
      type="checkbox"
      name="donationAmount"
      value={2}
      checked={donationAmount === 2}
      onChange={handleDonationChange}
      style={{ marginLeft: "5px" }}
    />
    $2
  </label>

  <label style={{ display: "block" }}>
    <input
      type="checkbox"
      name="donationAmount"
      value={3}
      checked={donationAmount === 3}
      onChange={handleDonationChange}
      style={{ marginLeft: "5px" }}
    />
    $3
  </label>

  <label
    style={{ marginRight: "10px", display: "block" }}
  >
    <input
      type="checkbox"
      name="donationAmount"
      value={0}
      checked={donationAmount === 0}
      onChange={handleDonationChange}
      style={{ marginLeft: "5px" }}
    />
    None
  </label>
</div>
</div>
);