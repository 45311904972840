import React, { useState } from "react";
import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  Image,
  useToast,
  Icon,
  Input,
} from "@chakra-ui/react";
import { theme } from "../../../../styles/theme/base";

import deleteIcon from "../../../../static/assets/images/deleteIcon.svg";
import spotifyIcon from "../../../../static/assets/images/spotifyIcon.svg";

function Music(props) {
  const { handleBlur, values, setFieldValue, handleChange } = props;

  const [focused, setFocused] = useState(false);
  return (
    <VStack width={"100%"} alignItems={"flex-start"}>
      <Text
        fontSize={"1.25rem"}
        className="gordita700"
        textTransform={"capitalize"}
      >
        Music 
      </Text>
      <HStack width={"100%"} gap={"10px"}>
        <Box
          w={"100%"}
          maxWidth={"34.9375rem"}
          height={"3.4375rem"}
          borderRadius={"6.25rem"}
          border={"1px solid rgba(255, 255, 255, 0.20)"}
          borderColor={focused ? "#FFFFFF" : "rgba(255, 255, 255, 0.20)"}
          padding={"0.31rem"}
          display={"flex"}
          alignItems={"center"}
          gap={"0.63rem"}
          _hover={{
            borderColor: "#FFFFFF",
          }}
        >
          <Box
            width={"2.8125rem"}
            height={"2.8125rem"}
            borderRadius={"50%"}
            backgroundColor={"rgba(255, 255, 255, 0.05)"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <img
              src={spotifyIcon}
              alt={"videocam-icon"}
              style={{ width: "1.5rem", height: "1.5rem" }}
            />
          </Box>
          <Input
            name={"spotifyId"}
            w="90%"
            h="100%"
            className="gordita400"
            borderRadius="6.625rem"
            bg="#000000"
            border={"none"}
            color="#FFFFFF"
            placeholder={"Paste the Spotify’s song link"}
            value={values?.spotifyId}
            onChange={handleChange}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            padding={1}
            outline={"none"}
            _placeholder={{
              color: "rgba(255, 255, 255, 0.40)",
            }}
            _active={{
              border: "none",
              outline: "none",
            }}
            _focus={{
              border: "none",
              outline: "none",
            }}
            _focusVisible={{
              border: "none",
              outline: "none",
            }}
            // {...rest}
            // required={required}
          />
        </Box>
        <Button
          sx={theme.buttons.secondary.shape}
          bg={theme.buttons.secondary.default}
          color={theme.buttons.secondary.color}
          _hover={{
            borderColor: theme.buttons.secondary.hoverBorderColor,
          }}
          _active={{
            bg: theme.buttons.secondary.pressed,
          }}
          style={{
            width: "3.4375rem",
            height: "3.4375rem",
            borderRadius: "50%",
          }}
          disabled={!values?.spotifyId}
          border={"1px solid #FFFFFF33"}
          onClick={(e) => {
            e.stopPropagation(); // Prevent the click event from propagating

            setFieldValue("spotifyId", ""); // Update formik values
          }}
        >
          <img
            src={deleteIcon}
            alt={"delete-icon"}
            style={{ width: "44px", height: "44px", fontSize: "40px" }}
          />
        </Button>
      </HStack>
    </VStack>
  );
}

export default Music;
