import {
  Box,
  Button,
  Divider,
  HStack,
  Text,
  VStack,
  Input,
  chakra,
  useToast,
  Image,
  InputGroup,
  InputRightElement,
  IconButton,
  Center,
} from "@chakra-ui/react";
import { LoginSchema } from "../../../utils/schema";
import { Formik, Field, Form } from "formik";
import {
  RegistraionModalTypes,
  registration,
} from "../../../recoil/atoms/registration";
import { user } from "../../../recoil/atoms/user";
import { useRecoilState } from "recoil";
import { Navigate, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useMediaQuery } from "../../../utils/useMediaQuery";
import { useCookies } from "react-cookie";
import PhoneInput from "react-phone-input-2/";
import "react-phone-input-2/lib/bootstrap.css";
import { userRoles } from "../../../utils/constants";
import { theme } from "../../../styles/theme/base";
import axios from "../../../utils/api/axios";
import { useLocation } from "react-router-dom";


const initialValues = { phoneNumber: "", password: "" };

const CustomToast = chakra("div", {
  baseStyle: {
    zIndex: "modal",
  },
});
/**
 * The Login function is a React component that handles user login, including email and password
 * authentication as well as Google login.
 * @returns The Login component is being returned.
 */

const Login = () => {
  const [_, setRegistrationModal] = useRecoilState(registration);
  const [u, setUser] = useRecoilState(user);
  const toast = useToast();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["x-auth-token"]);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [verificationStatus, setVerificationStatus] = useState("");
  const [verificationCheck, setVerificationCheck] = useState(false);
  const userRole = userRoles.ATTENDEE;
  const location = useLocation();
  const shouldShowContent = location.pathname.includes("/claimticket");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");


  const handleSendOTP = async (phoneNumber) => {
    try {
      const response = await axios.post("/api/sendOTP", { phoneNumber });
      if (response.data.success === true) {
        setVerificationStatus(response.data.message);
        console.log("aaaaaaaaachcekoiin");
        setVerificationCheck(true);
        return true;
      } else {
        setVerificationStatus(response.data.message);
        setVerificationCheck(false);
        return false;
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setVerificationStatus("Failed to send OTP");
      return false;
    }
  };

  

  return (
    <Box w="100%">
      <VStack gap={50}>
        <Text className="gordita700" fontSize={{ base: 18, md: 24 }}>
          {shouldShowContent?"Claim Ticket!":"Log In to Portalys!"}
        </Text>
        <Formik
          initialValues={initialValues}
          validationSchema={LoginSchema}
          onSubmit={async (values) => {
            try {
              // Attempt to send OTP
              const response = await handleSendOTP("+" + values.phoneNumber);
              console.log("aaaa", verificationCheck);

              // Check if OTP sending was successful
              if (response) {
                // Proceed with setting the modal
                setRegistrationModal((lp) => ({
                  ...lp,
                  openModal: true,
                  modalType: RegistraionModalTypes.LOGIN_VERIFICATION,
                  userRole,
                  userData: {
                    phoneNumber: values.phoneNumber,
                    email: email,
                    firstName:firstName,
                    lastName:lastName,

                  },
                }));
              }
            } catch (error) {
              console.error("Error sending OTP:", error);
              setVerificationStatus("Failed to send OTP");
              setVerificationCheck(false);
            }
          }}
        >
          {(formik) => {
            const { handleSubmit, errors, touched, setFieldValue } = formik;

            const handlePhoneNumberChange = (value) => {
              // Check if the value is numeric or empty
              if (/^\d*$/.test(value) || value === "") {
                setFieldValue("phoneNumber", value);
              }
            };

            return (
              <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <VStack px={{ base: 0, md: 36 }} alignItems="flex-start">
                {shouldShowContent && (
                  <>
                 <div style={{ marginBottom: "20px", width: "100%" }}>
                 <label
                   htmlFor="name"
                   style={{
                     display: "block",
                     marginLeft: "20px",
                     marginBottom: "5px",
                   }}
                 >
                   First Name
                   {/* Add an indicator for required field */}
                   <span style={{ color: "red", fontSize: "12px" }}>*</span>
                 </label>
                 <input
                   type="text"
                   id="firstName"
                   name="firstName"
                   placeholder="Your First Name"
                   value={firstName}
                   onChange={(e) => setFirstName(e.target.value)}
                   style={{
                     padding: "8px",
                     borderRadius: "6.25rem",
                     border: "1px solid #fff",
                     backgroundColor: "#000",
                     width: "100%",
                     height: "54px",
                   }}
                   required // Make the input field mandatory
                 />
               </div>
               <div style={{ marginBottom: "20px", width: "100%" }}>
                 <label
                   htmlFor="name"
                   style={{
                     display: "block",
                     marginLeft: "20px",
                     marginBottom: "5px",
                   }}
                 >
                   Last Name
                   {/* Add an indicator for required field */}
                   <span style={{ color: "red", fontSize: "12px" }}>*</span>
                 </label>
                 <input
                   type="text"
                   id="lastName"
                   name="lastName"
                   placeholder="Your Last Name"
                   value={lastName}
                   onChange={(e) => setLastName(e.target.value)}
                   style={{
                     padding: "8px",
                     borderRadius: "6.25rem",
                     border: "1px solid #fff",
                     backgroundColor: "#000",
                     width: "100%",
                     height: "54px",
                   }}
                   required // Make the input field mandatory
                 />
               </div>
            

             <div style={{ marginBottom: "40px" }}>
               <label
                 htmlFor="name"
                 style={{
                   display: "block",
                   marginLeft: "20px",
                   marginBottom: "5px",
                 }}
               >
                 Email
                 {/* Add an indicator for required field */}
                 <span style={{ color: "red", fontSize: "12px" }}>*</span>
               </label>
               <input
                 type="email"
                 id="email"
                 name="email"
                 placeholder="Your Email"
                 value={email}
                 pattern="(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))+"
                 onChange={(e) => setEmail(e.target.value)}
                 style={{
                   padding: "8px",
                   borderRadius: "6.25rem",
                   border: "1px solid #fff",
                   backgroundColor: "#000",
                   width: "100%",
                   height: "54px",
                 }}
                 required // Make the input field mandatory
               />
             </div>
             </>
                )}
                  <Text fontWeight={400} fontSize="0.75rem" pl={"1.5rem"}>
                    Phone Number
                  </Text>
                  <Field
                    type="phoneNumber"
                    name="phoneNumber"
                    id="phoneNumber"
                    render={({ field }) => (
                      <PhoneInput
                        inputProps={{
                          name: "phoneNumber", // Specify the name for the input field
                          placeholder: "Enter Your Phone Number",
                          style: {
                            width: "100%",
                            border: "1px solid #FFFFFF",
                            borderRadius: "6.25rem",
                            height: "3.375rem",
                            fontSize: "14px",
                            color: "#FFFFFF",
                            backgroundColor: "transparent",
                          },
                        }}
                        style={{
                          marginTop: "0.30rem",
                        }}
                        dropdownStyle={{ backgroundColor: "#000000" }}
                        country={"us"} // Set your default country
                        value={field.value} // Set the value explicitly
                        onChange={handlePhoneNumberChange} // Handle input changes
                      />
                    )}
                  />

                  {errors.phoneNumber && touched.phoneNumber && (
                    <Text
                      color="red"
                      className="gordita400"
                      fontWeight={400}
                      fontSize={14}
                    >
                      {errors.phoneNumber}
                    </Text>
                  )}

                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: 700,
                      color: "red",
                    }}
                  >
                    {verificationStatus}
                  </p>
                </VStack>

                <Box px={{ base: 0, md: 36 }} mt={24} w="100%">
                  <Button
                    type="submit"
                    className="gordita700"
                    sx={theme.buttons.primary.shape}
                    bg={theme.buttons.primary.default}
                    color={theme.buttons.primary.color}
                    css={{
                      width: "100%",
                    }}
                    _hover={{
                      bg: theme.buttons.primary.hover,
                      boxShadow: theme.buttons.primary.hoverBoxShadow,
                    }}
                    _active={{
                      boxShadow: theme.buttons.primary.activeBoxShadow,
                    }}

                  >
                    Receive SMS With The Code
                  </Button>
                </Box>

                <Box
                  className="gordita400"
                  fontSize={16}
                  px={{ base: 0, md: 36 }}
                  mt={24}
                  w="100%"
                  textAlign="center"
                >
                  Don't have an account?
                  <chakra.span
                    color="white"
                    cursor="pointer"
                    fontWeight="700"
                    onClick={() =>
                      setRegistrationModal((lp) => {
                        return {
                          ...lp,
                          openModal: true,
                          modalType: RegistraionModalTypes.SIGNUP,
                        };
                      })
                    }
                  >
                    {" "}
                    Sign Up
                  </chakra.span>
                </Box>

                {isMobile && (
                  <Box>
                    <HStack my={36} px={{ base: 0, md: 36 }}>
                      <Divider orientation="horizontal" bg="#A2A2A6" w="100%" />
                    </HStack>
                    <Box
                      className="gordita400"
                      fontSize={16}
                      px={{ base: 0, md: 36 }}
                      mt={24}
                      w="100%"
                      pb={35}
                      textAlign="center"
                    >
                      Are you a verifier?
                      <chakra.span
                        color="white"
                        cursor="pointer"
                        fontWeight="700"
                        onClick={() =>
                          setRegistrationModal((lp) => {
                            return {
                              ...lp,
                              openModal: true,
                              modalType: RegistraionModalTypes.VERIFIER,
                            };
                          })
                        }
                      >
                        {" "}
                        Log In As Verifier
                      </chakra.span>
                    </Box>
                  </Box>
                )}
              </Form>
            );
          }}
        </Formik>
      </VStack>
    </Box>
  );
};

export default Login;
