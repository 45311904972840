/**
 * This is a module that exports functions for user registration, login, password reset, and fetching
 * user data from an API.
 * @param payload - It is an object that contains the data needed to perform a specific action, such as
 * adding a new user, logging in, resetting a password, etc. The contents of the payload object may
 * vary depending on the specific function being called.
 * @returns Each function is returning an object that contains the data received from the API call,
 * with the spread operator used to copy all properties of the data object. If an error occurs, the
 * function logs the error to the console.
 */
import secrets from "../../secrets";
import {
  postUser,
  postVerifier,
  postTeamMember,
  getUser,
  googleLogin,
  forgotPassword,
  resetPassword,
  fetchUserData as getUserData,
  getVerifier,
  assignEvent
} from "../api/registration";
export const addNewUser = async (payload) => {
  try {
    const res = await postUser({ ...payload, link: secrets.verifyLink });
    const data = res.data;
    return {
      ...data,
    };
  } catch (error) {
    console.log(error);
  }
};
export const addNewVerifier = async (payload) => {
  try {
    const res = await postVerifier(payload);
    const data = res.data;
    return {
      ...data,
    };
  } catch (error) {
    console.log(error);
  }
};
export const addNewMember = async (payload) => {
  try {
    const res = await postTeamMember(payload);
    const data = res.data;
    return {
      ...data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const assignVerifier = async (payload) => {
  try {
    const res = await assignEvent(payload);
    const data = res.data;
    return {
      ...data,
    };
  } catch (error) {
    console.log(error);
  }
};


export const fetchUser = async (payload) => {
  try {
    const res = await getUser(payload);
    const data = res.data;
    return {
      ...data,
    };
  } catch (error) {
    console.log(error);
  }
};
export const fetchVerifier= async (payload) => {
  try {
    const res = await getVerifier(payload);
    const data = res.data;
    return {
      ...data,
    };
  } catch (error) {
    console.log(error);
  }
};



export const loginWithGoogle = async (payload) => {
  try {
    const res = await googleLogin(payload);
    const data = res.data;
    return {
      ...data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const sendForgotEmail = async (payload) => {
  try {
    const res = await forgotPassword({ ...payload, link: secrets.resetLink });
    const data = res.data;
    return {
      ...data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const changePassword = async (payload) => {
  try {
    const res = await resetPassword(payload);
    const data = res.data;
    return {
      ...data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const fetchUserData = async (token) => {
  try {
    const res = await getUserData(token);
    const data = res.data;
    return {
      ...data,
    };
  } catch (error) {
    console.log(error);
  }
};
