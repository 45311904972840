/**
 * This is a module that exports functions for making API requests related to user authentication and
 * data retrieval.
 * @param payload - `payload` is an object that contains data to be sent in the request body. The
 * specific data contained in `payload` will depend on the function being called. For example,
 * `postUser` and `getUser` functions expect `payload` to contain user login/signup information, while
 * `forgotPassword
 * @returns This code exports several functions that make HTTP requests to different endpoints of an
 * API. Each function returns a Promise that resolves to the response data from the server. The
 * functions are:
 */
import axios from "./axios";

export const postUser = async (payload) => {
  return axios.post("/api/signup", payload);
};

export const postVerifier = async (payload) => {
  return axios.post("/api/createVerifier", payload);
};

export const postTeamMember = async (payload) => {
  return axios.post("/api/createTeamMember", payload);
};

export const assignEvent = async (payload) => {
  return axios.post("/api/assignEvent", payload);
};

export const getUser = async (payload) => {
  return axios.post("/api/login", payload);
};
export const getVerifier = async (payload) => {
  return axios.post("/api/loginVerifier", payload);
};

export const googleLogin = async (payload) => {
  return axios.post("/api/google-oauth", payload);
};

export const forgotPassword = async (payload) => {
  return axios.post("/api/forgot", payload);
};
export const resetPassword = async (payload) => {
  return axios.post("/api/reset", payload);
};

export const userLogout = async (userId) => {
  return axios.post('/api/logout', { userId }); 
};

export const fetchUserData = async (token) => {
  return axios.get("/api/me", {
    headers: {
      "x-auth-token": "Bearer " + token,
    },
  });
};
