import { React, useState } from 'react';
import {
  Box,
  Text,
  Button,
  HStack,
  VStack,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useToast,
  Image,
  useDisclosure,
  Input,
} from '@chakra-ui/react';
import { AiOutlineClose } from 'react-icons/ai';
import { theme } from '../../styles/theme/base';
import { CrossmintNFTDetail } from '@crossmint/client-sdk-react-ui';
import { tickets } from '../../static/assets/images';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2/';
import axios from '../../utils/api/axios';
import secrets from '../../secrets';
import { useRecoilState } from 'recoil';
import { user } from '../../recoil/atoms/user';
import { ticket } from '../../recoil/atoms/tickets';
import { useMediaQuery } from '../../utils/useMediaQuery';
import { TypeArrowback } from '../../static/assets/images/dashboard/sidebar';
import LightLogo from '../../static/assets/images/logo.svg';

const TicketModal = ({ isOpen, tickets, closeModal }) => {
  const navigate = useNavigate();

  const isMobile = useMediaQuery('(max-width: 768px)');
  const [_T, setTicket] = useRecoilState(ticket);

  const [selectedTickets, setSelectedTickets] = useState([]);
  const [isAllTicketsSelected, setIsAllTicketsSelected] = useState(false);
  const [isTicketOpen, setIsTicketOpen] = useState(false);
  const [selectedTokenId, setSelectedTokenId] = useState(null);
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [confirmedPhoneNumber, setConfirmedPhoneNumber] = useState('');
  const [phoneNumbersMatch, setPhoneNumbersMatch] = useState(true);
  const { isOpen: isOpen3, onOpen: onOpen3, onClose } = useDisclosure();
  const [ticketId, setTicketId] = useState(null);

  const handleCheckboxToggle = (ticketId) => {
    setIsAllTicketsSelected(false);
    setSelectedTickets((prevTickets) => {
      if (prevTickets.includes(ticketId)) {
        return prevTickets.filter((ticket) => ticket !== ticketId);
      } else {
        return [...prevTickets, ticketId];
      }
    });
  };

  const openTicketModal = (tokenId) => {
    setSelectedTokenId(tokenId);
    setIsTicketOpen(true);
  };

  const closeTicketModal = () => {
    setSelectedTokenId(null);
    setIsTicketOpen(false);
  };

  const openShareModal = () => {
    setIsShareOpen(true);
  };

  const closeShareModal = () => {
    setIsShareOpen(false);
  };

  const nft = {
    chain: 'polygon', // ethereum | polygon | bsc
    contractAddress: tickets.event.contractAddress, //"0xF74C3ff26f9834ec6ba8e5DD96c719124BC87e6c",
    tokenId: selectedTokenId,
  };
  const handleFullNameChange = (value) => {
    setFullName(event.target.value); 
  };
  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
  };

  const handleConfirmedPhoneNumberChange = (value) => {
    setConfirmedPhoneNumber(value);
  };
  const [_, setUser] = useRecoilState(user);
  const toast = useToast();
  const handleSubmit = async () => {
    if (phoneNumber === confirmedPhoneNumber) {
      setPhoneNumbersMatch(true);
      const matchedTickets = tickets.tokens
        .filter((ticket) => selectedTickets.includes(ticket.ticketId))
        .map((ticket) => ticket.ticketId);

      try {
        const response = await axios.post(
          '/api/shareTicket',
          {
            ticketIds: matchedTickets,
            phoneNumber: '+' + phoneNumber,
            fullName,
          },
          {
            headers: {
              'x-auth-token': _?.token,
            },
          }
        );

        if (response.status === 200) {
          // Display success toast
          toast({
            title: response?.data.message,
            isClosable: true,
            duration: 4000,
            status: 'success',
            position: 'top-right',
          });
        }
        // Reload the page after a 1-second delay
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      } catch (error) {
        // Handle error and display error toast
        toast({
          title: 'An error occurred while sharing the ticket.',
          isClosable: true,
          duration: 4000,
          status: 'error',
          position: 'top-right',
        });
      }
    } else {
      setPhoneNumbersMatch(false);
    }
  };

  const cancelRSVP = async () => {
    const token = _?.token;
    await axios
      .post('/api/cancelrsvp/' + ticketId, {
        headers: {
          'x-auth-token': 'Bearer ' + token,
        },
      })
      .then(async (res) => {
        onClose();
        closeModal();
        window.location.reload(true);

        toast({
          title: 'RSVP Canceled',
          position: 'bottom',
        });
      })
      .catch((err) => {
        console.log({ err });
        onClose();
      });
  };

  const isAllTicketsToggled =
    tickets?.tokens?.map((ticket) => ticket?.ticketId).length ===
    selectedTickets.length;

  let isShareTicketsButtonDisabled = !(
    isAllTicketsSelected ||
    isAllTicketsToggled ||
    selectedTickets.length > 0
  );

  const seePerks = tickets?.tokens?.some((ticket) => ticket?.perks === true);

  const BackArrowButton = ({ onClick }) => (
    <Button
      sx={theme.buttons.secondary.shape}
      bg={theme.buttons.secondary.bgColor}
      color={theme.buttons.secondary.color}
      css={{
        width: '3.125rem',
        textTransform: 'capitalize',
        padding: 0,
      }}
      _hover={{
        cursor: 'pointer',
        borderColor: theme.buttons.secondary.hoverBorderColor,
      }}
      _active={{
        bg: theme.buttons.secondary.pressed,
      }}
      onClick={onClick}
    >
      <Image src={TypeArrowback} />
    </Button>
  );

  const ShareTicketButton = (
    <Button
      className="gordita700"
      sx={theme.buttons.primary.shape}
      bg={theme.buttons.primary.default}
      color={theme.buttons.primary.color}
      _hover={{
        bg: !isShareTicketsButtonDisabled && theme.buttons.primary.hover,
        boxShadow:
          !isShareTicketsButtonDisabled && theme.buttons.primary.hoverBoxShadow,
        cursor: !isShareTicketsButtonDisabled ? 'pointer' : 'not-allowed',
      }}
      css={{
        width: isMobile ? '100%' : '10rem',
        marginTop: '0 !important',
      }}
      _active={{
        boxShadow:
          !isShareTicketsButtonDisabled &&
          theme.buttons.primary.activeBoxShadow,
      }}
      disabled={isShareTicketsButtonDisabled}
      _disabled={{
        bg: theme.buttons.primary.disabled,
        color: theme.buttons.primary.colorDisabled,
      }}
      onClick={() => openShareModal()}
    >
      Share Tickets
    </Button>
  );

  const AddPerksButton = (
    <Button
      sx={theme.buttons.secondary.shape}
      bg={theme.buttons.secondary.bgColor}
      color={theme.buttons.secondary.color}
      css={{
        width: '10.43rem',
        textTransform: 'capitalize',
        marginTop: '0.25rem !important',
      }}
      _hover={{
        borderColor: theme.buttons.secondary.hoverBorderColor,
      }}
      _active={{
        bg: theme.buttons.secondary.pressed,
      }}
      border={theme.buttons.secondary.border}
      onClick={() => {
        setTicket((lp) => ({
          ...lp,
          perks: true,
        }));
        navigate(`/event/${tickets.event?.slug}`);
      }}
    >
      Add Perks
    </Button>
  );

  const SeeEventButton = (
    <Button
      sx={theme.buttons.secondary.shape}
      bg={theme.buttons.secondary.bgColor}
      color={theme.buttons.secondary.color}
      css={{
        width: '10.43rem',
        textTransform: 'capitalize',
        marginTop: '0.25rem !important',
      }}
      _hover={{
        borderColor: theme.buttons.secondary.hoverBorderColor,
      }}
      _active={{
        bg: theme.buttons.secondary.pressed,
      }}
      border={theme.buttons.secondary.border}
      onClick={() => navigate(`/event/${tickets.event?.slug}`)}
    >
      See the event
    </Button>
  );

  const ConfirmShareTicketButton = (
    <Button
      className="gordita700"
      sx={theme.buttons.primary.shape}
      bg={theme.buttons.primary.default}
      color={theme.buttons.primary.color}
      _hover={{
        bg: theme.buttons.primary.hover,
        boxShadow: theme.buttons.primary.hoverBoxShadow,
      }}
      css={{
        width: isMobile ? '100%' : '7.4rem',
        marginTop: '0 !important',
      }}
      _active={{
        boxShadow: theme.buttons.primary.activeBoxShadow,
      }}
      onClick={handleSubmit}
    >
      Confirm
    </Button>
  );

  const PortalysLogo = <Image alt="" src={LightLogo} />;

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        motionPreset="slideInBottom"
        size={isMobile ? 'full' : 'xl'} // Set the size conditionally
        isCentered
      >
        <ModalOverlay zIndex="999" />
        <ModalContent
          zIndex="999"
          maxW={isMobile ? '100%' : '42.4375rem'}
          maxHeight={'90%'}
          borderRadius={isMobile ? '0' : '2.125rem'}
          boxShadow={'0px 0px 100px 150px rgba(0, 0, 0, 0.30)'}
          backgroundColor={isMobile ? '#000000' : 'rgba(0, 0, 0, 0.50)'}
          border={isMobile ? 'none' : '1px solid rgba(255, 255, 255, 0.20)'}
          backdropFilter={'blur(75px)'}
          padding={isMobile ? 'none' : '1rem 0.6rem'}
          style={{
            width: '42.4375rem !important',
          }}
          overflow={'scroll'}
        >
          <ModalHeader
            fontSize={isMobile ? '1.3125rem' : '1.5625rem'}
            textTransform={'capitalize'}
            className="gordita700"
            padding={'1.25rem'}
          >
            {isMobile ? PortalysLogo : tickets.event.eventName}
          </ModalHeader>
          {!isMobile && <ModalCloseButton top={'2rem'} right={'2rem'} />}

          <ModalBody marginTop={'1rem'}>
            {seePerks && !isMobile && AddPerksButton}
            {isMobile && (
              <HStack gap={'1.13rem'} marginBottom={'2.5rem'}>
                <BackArrowButton onClick={closeModal} />
                <Text
                  fontSize={'1.3125rem'}
                  textTransform={'capitalize'}
                  className="gordita700"
                  overflow={'hidden'}
                  whiteSpace={'nowrap'}
                  textOverflow={'ellipsis'}
                >
                  {tickets.event.eventName}
                </Text>
                {seePerks && AddPerksButton}
              </HStack>
            )}
            <VStack width={'100%'} alignItems={'flex-end'} gap={'1.25rem'}>
              <HStack
                width={'100%'}
                justifyContent={'space-between'}
                paddingLeft={'1.25rem'}
              >
                <Checkbox
                  isChecked={isAllTicketsSelected || isAllTicketsToggled}
                  colorScheme="white"
                  textAlign={'center'}
                  onChange={() => {
                    setSelectedTickets(
                      tickets?.tokens?.map((ticket) => ticket?.ticketId)
                    );

                    if (isAllTicketsSelected || isAllTicketsToggled) {
                      setIsAllTicketsSelected(false);
                      setSelectedTickets([]);
                    } else {
                      setIsAllTicketsSelected(true);
                    }
                  }}
                >
                  <Text
                    marginLeft={'0.32rem'}
                    pb={'.35rem'}
                    className="gordita700"
                  >
                    Select all {!isMobile && ` Tickets`}
                  </Text>
                </Checkbox>
                {isMobile ? SeeEventButton : ShareTicketButton}
              </HStack>
              {tickets !== undefined &&
                tickets.tokens?.map((ticket) => (
                  <HStack
                    key={ticket?.ticketId}
                    margin={'0 !important'}
                    w={'100%'}
                    justifyContent={'space-between'}
                    gap={'1.25rem'}
                    height={isMobile ? '3.75rem' : '5rem'}
                    border={'1px solid rgba(255, 255, 255, 0.20)'}
                    borderColor={
                      selectedTickets.includes(ticket?.ticketId)
                        ? '#FFFFFF'
                        : 'rgba(255, 255, 255, 0.20)'
                    }
                    borderRadius={'6.25rem'}
                    padding={'0 1.25rem'}
                    transition={'all 0.2s ease-in-out'}
                  >
                    <Checkbox
                      isChecked={selectedTickets.includes(ticket?.ticketId)} // Add this line
                      colorScheme="white"
                      onChange={() => handleCheckboxToggle(ticket?.ticketId)}
                      width={'100%'}
                      height={'80%'}
                    >
                      <Text
                        fontSize="1rem"
                        fontWeight="700"
                        overflow={'hidden'}
                        whiteSpace={'nowrap'}
                        textOverflow={'ellipsis'}
                        w={'100%'}
                        className="gordita700"
                        marginLeft={isMobile ? '0.32rem' : '1rem'}
                      >
                        {ticket?.type}
                      </Text>
                    </Checkbox>
                    {ticket?.totalPrice === 0 && ticket?.item === 'ticket' && (
                      <Button
                        bg={'none'}
                        sx={theme.buttons.tercary.shape}
                        css={{
                          width: '100%',
                          maxWidth: '12.2rem',
                          textTransform: 'capitalize',
                          overflow: 'hidden', // Set overflow property to 'hidden'
                          whiteSpace: 'nowrap', // Prevent text from wrapping
                          textOverflow: 'ellipsis',
                        }}
                        _hover={{
                          background: theme.buttons.tercary.hover,
                        }}
                        _active={{
                          background: theme.buttons.tercary.pressed,
                        }}
                        onClick={(e) => {
                          e.stopPropagation(); // Stop the event from propagating further
                          setTicketId(ticket.ticketId);
                          onOpen3();
                        }}
                        className="gordita700"
                      >
                        Cancel RSVP
                      </Button>
                    )}
                    <Button
                      bg={'none'}
                      sx={theme.buttons.tercary.shape}
                      css={{
                        width: '100%',
                        maxWidth: '12.2rem',
                        textTransform: 'capitalize',
                        overflow: 'hidden', // Set overflow property to 'hidden'
                        whiteSpace: 'nowrap', // Prevent text from wrapping
                        textOverflow: 'ellipsis',
                      }}
                      _hover={{
                        background: theme.buttons.tercary.hover,
                      }}
                      _active={{
                        background: theme.buttons.tercary.pressed,
                      }}
                      onClick={(e) => {
                        e.stopPropagation(); // Stop the event from propagating further
                        navigate(`/?qrcode=true`);
                      }}
                      className="gordita700"
                    >
                      See QR Code
                    </Button>
                  </HStack>
                ))}
              <Modal isOpen={isOpen3} onClose={onClose} isCentered={true}>
                <ModalOverlay />
                <ModalContent
                  bg={'rgba(0, 0, 0, 0.50)'}
                  backdropFilter={'blur(150px)'}
                  borderRadius={'20px'}
                  border={'1px solid #333333'}
                >
                  <ModalHeader color="white.100">Cancel</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    Are you sure you want to cancel your RSVP for this event?
                    This action cannot be undone.
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      mr={3}
                      onClick={onClose}
                      sx={theme.buttons.primary.shape}
                      bg={theme.buttons.secondary.default}
                      color={theme.buttons.secondary.color}
                      style={{
                        width: 'fit-content',
                      }}
                      _hover={{ bg: theme.buttons.secondary.hover }}
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={theme.buttons.primary.shape}
                      bg={theme.buttons.primary.default}
                      color={theme.buttons.primary.color}
                      style={{
                        width: '5rem',
                        padding: '0',
                      }}
                      _hover={{
                        bg: theme.buttons.primary.hover,
                        boxShadow: theme.buttons.primary.hoverBoxShadow,
                      }}
                      _active={{
                        boxShadow: theme.buttons.primary.activeBoxShadow,
                      }}
                      onClick={cancelRSVP}
                    >
                      OK
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
              {!isMobile && SeeEventButton}
            </VStack>
          </ModalBody>
          {isMobile && (
            <Box
              position={'sticky'}
              bottom={'0'}
              width={'100%'}
              maxW={'1200px'}
              display={'flex'}
              gap={'0.62rem'}
              flexDir={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              borderRadius={'40px 40px 0 0'}
              padding={'1.25rem'}
              borderTop="1px solid rgba(255, 255, 255, 0.20)"
              backdropFilter={'blur(75px)'}
            >
              {ShareTicketButton}
            </Box>
          )}
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isTicketOpen}
        onClose={closeTicketModal}
        size={isMobile ? 'full' : 'xl'} // Set the size conditionally
        isCentered
      >
        <ModalOverlay zIndex="999" />
        <ModalContent
          zIndex="999"
          maxW={isMobile ? '100%' : '42.4375rem'}
          maxHeight={'90%'}
          height={'100%'}
          borderRadius={isMobile ? '0' : '2.125rem'}
          boxShadow={'0px 0px 100px 150px rgba(0, 0, 0, 0.30)'}
          backgroundColor={isMobile ? '#000000' : 'rgba(0, 0, 0, 0.50)'}
          border={isMobile ? 'none' : '1px solid rgba(255, 255, 255, 0.20)'}
          backdropFilter={'blur(75px)'}
          padding={isMobile ? '0 0 2rem' : '1rem 0.6rem'}
          style={{
            width: '42.4375rem !important',
            backgroundColor: 'black',
          }}
        >
          <ModalHeader
            fontSize={isMobile ? '1.3125rem' : '1.5625rem'}
            textTransform={'capitalize'}
            className="gordita700"
            padding={'1.25rem'}
          >
            {isMobile ? PortalysLogo : tickets.event.eventName}
          </ModalHeader>
          {!isMobile && <ModalCloseButton top={'2rem'} right={'2rem'} />}

          <ModalBody>
            {isMobile && (
              <HStack gap={'1.13rem'} marginBottom={'2.5rem'}>
                <BackArrowButton onClick={closeTicketModal} />
                <Text
                  fontSize={'1.3125rem'}
                  textTransform={'capitalize'}
                  className="gordita700"
                >
                  {tickets.event.eventName}
                </Text>
              </HStack>
            )}
            <div
              style={{
                height: isMobile ? '90%' : '100%',
                width: '100%',
                border: '1px solid rgba(255, 255, 255, 0.10)',
                borderRadius: '0.9375rem',
                padding: '1rem',
                backgroundColor: '#000000 !important',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                sx={theme.buttons.secondary.shape}
                bg={theme.buttons.secondary.bgColor}
                color={theme.buttons.secondary.color}
                css={{
                  width: '10.43rem',
                  textTransform: 'capitalize',
                  marginTop: '0.25rem !important',
                  marginBottom: '1.25rem !important',
                }}
                _hover={{
                  borderColor: theme.buttons.secondary.hoverBorderColor,
                }}
                _active={{
                  bg: theme.buttons.secondary.pressed,
                }}
                border={theme.buttons.secondary.border}
                onClick={() => navigate(`/?qrcode=true`)}
              >
                SHOW QR CODE
              </Button>
              <CrossmintNFTDetail
                environment={secrets.environmentCrossmint}
                nft={nft}
                uiConfig={{
                  colors: {
                    background: '#000000',
                    backgroundSecondary: '#959597',
                    textPrimary: '#FFFFFF',
                    textSecondary: '#EEEEEE',
                    accent: '#FF5C00',
                    border: '#FFFFFF',
                  },
                }}
              />
            </div>

            {/* Other Modal Body Content */}
          </ModalBody>
        </ModalContent>
      </Modal>

      {isShareOpen && (
        <Modal
          isOpen={isShareOpen}
          onClose={closeShareModal}
          size={isMobile ? 'full' : 'xl'} // Set the size conditionally
          isCentered
        >
          <ModalOverlay zIndex="999" />
          <ModalContent
            zIndex="999"
            maxW={isMobile ? '100%' : '42.4375rem'}
            maxHeight={'90%'}
            borderRadius={isMobile ? '0' : '2.125rem'}
            boxShadow={'0px 0px 100px 150px rgba(0, 0, 0, 0.30)'}
            backgroundColor={isMobile ? '#000000' : 'rgba(0, 0, 0, 0.50)'}
            border={isMobile ? 'none' : '1px solid rgba(255, 255, 255, 0.20)'}
            backdropFilter={'blur(75px)'}
            padding={isMobile ? 'none' : '1rem 0.6rem'}
            style={{
              width: '42.4375rem !important',
            }}
          >
            <ModalHeader
              fontSize={isMobile ? '1.3125rem' : '1.5625rem'}
              textTransform={'capitalize'}
              className="gordita700"
              padding={'1.25rem'}
            >
              {isMobile ? PortalysLogo : tickets.event.eventName}
            </ModalHeader>
            {!isMobile && <ModalCloseButton top={'2rem'} right={'2rem'} />}

            <ModalBody marginTop={'1rem'}>
              {isMobile && (
                <HStack gap={'1.13rem'} marginBottom={'2.5rem'}>
                  <BackArrowButton onClick={closeShareModal} />
                  <Text
                    fontSize={'1.3125rem'}
                    textTransform={'capitalize'}
                    className="gordita700"
                  >
                    {tickets.event.eventName}
                  </Text>
                </HStack>
              )}
              <VStack width={'100%'} gap={'2.5rem'}>
                <VStack width={'100%'}>
                  {selectedTickets?.map((ticketId) => (
                    <HStack
                      key={ticketId}
                      w={'100%'}
                      justifyContent={'space-between'}
                      gap={'0.62rem'}
                      height={'3.75rem'}
                      border={'1px solid rgba(255, 255, 255, 0.20)'}
                      borderRadius={'6.25rem'}
                      padding={'0 1.8rem'}
                      transition={'all 0.2s ease-in-out'}
                    >
                      <Text
                        fontSize="1rem"
                        fontWeight="700"
                        overflow={'hidden'}
                        whiteSpace={'nowrap'}
                        textOverflow={'ellipsis'}
                        w={'100%'}
                      >
                        {tickets.tokens.find(
                          (ticket) => ticket.ticketId === ticketId
                        )?.type || 'Type not found'}
                      </Text>
                      {/* Render other event details */}
                    </HStack>
                  ))}
                </VStack>

                <VStack width={'100%'} alignItems={'flex-start'} gap={'1rem'}>
                  <Box width={'100%'}>
                    <Text
                      fontSize={'1.1875rem'}
                      className={'gordita600'}
                      textTransform={'capitalize'}
                    >
                      Send the tickets to:
                    </Text>

                    <Input
                      placeholder="Enter The Receiver's Full Name"
                      value={fullName}
                      onChange={handleFullNameChange}
                      sx={{
                        width: '100%',
                        border: '1px solid rgba(255, 255, 255, 0.20)',
                        borderRadius: '0.625rem',
                        height: '3.4375rem',
                        fontSize: '14px',
                        color: '#FFFFFF',
                        backgroundColor: 'transparent',
                        marginTop: '0.30rem',
                        marginBottom: '0.30rem',
                      }}
                    />

                    <PhoneInput
                      inputProps={{
                        placeholder: "Enter The Receiver's Phone Number",
                        style: {
                          width: '100%',
                          border:
                            '1px solid var(--border-b-primary, rgba(255, 255, 255, 0.20))',
                          borderRadius: '0.625rem',
                          height: '3.4375rem',
                          fontSize: '14px',
                          color: '#FFFFFF',
                          backgroundColor: 'transparent',
                        },
                      }}
                      style={{
                        marginTop: '0.30rem',
                      }}
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      dropdownStyle={{ backgroundColor: '#000000' }}
                      country={'us'} // Set your default country
                      //value={field.value} // Set the value explicitly
                    />
                  </Box>
                  <Box width={'100%'}>
                    <Text fontSize={'1rem'}>
                      Please repeat the phone number for security{' '}
                    </Text>

                    <PhoneInput
                      inputProps={{
                        placeholder: "Enter The Receiver's Phone Number",
                        style: {
                          width: '100%',
                          border:
                            '1px solid var(--border-b-primary, rgba(255, 255, 255, 0.20))',
                          borderRadius: '0.625rem',
                          height: '3.4375rem',
                          fontSize: '14px',
                          color: '#FFFFFF',
                          backgroundColor: 'transparent',
                        },
                      }}
                      style={{
                        marginTop: '0.30rem',
                      }}
                      value={confirmedPhoneNumber}
                      onChange={handleConfirmedPhoneNumberChange}
                      dropdownStyle={{ backgroundColor: '#000000' }}
                      country={'us'} // Set your default country
                      //value={field.value} // Set the value explicitly
                    />
                  </Box>

                  {!phoneNumbersMatch && (
                    <Text color="red">
                      Phone numbers do not match. Please try again.
                    </Text>
                  )}
                </VStack>
              </VStack>
            </ModalBody>
            {!isMobile && (
              <ModalFooter>
                <Button
                  className="gordita700"
                  sx={theme.buttons.tercary.shape}
                  bg={'none'}
                  color={theme.buttons.tercary.color}
                  _hover={{
                    bg: theme.buttons.tercary.hoverBgColor,
                  }}
                  css={{
                    width: '6.25rem !important',
                    marginTop: '0 !important',
                    marginRight: '1.5rem',
                  }}
                  _active={{
                    bg: theme.buttons.tercary.pressedBgColor,
                  }}
                  onClick={closeShareModal}
                >
                  Cancel
                </Button>
                {ConfirmShareTicketButton}
              </ModalFooter>
            )}
            {isMobile && (
              <Box
                position={'sticky'}
                bottom={'0'}
                width={'100%'}
                maxW={'1200px'}
                display={'flex'}
                gap={'0.62rem'}
                flexDir={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                borderRadius={'40px 40px 0 0'}
                padding={'1.25rem'}
                borderTop="1px solid rgba(255, 255, 255, 0.20)"
                backdropFilter={'blur(75px)'}
              >
                {ConfirmShareTicketButton}
              </Box>
            )}
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default TicketModal;
