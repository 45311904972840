import {
  Box,
  Button,
  chakra,
  Divider,
  Flex,
  Image,
  Input,
  Text,
  useToast,
  VStack,
  HStack,
  Checkbox,
  Radio,
} from "@chakra-ui/react";
import { React, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import DashboardLayout from "../../modules/layout/dashboard";
import { user } from "../../recoil/atoms/user";
import facebook from "../../static/assets/images/fb.svg";
import "../../styles/profile/profile.css";
import { fetchUserData } from "../../utils/actions/registration";
import { getVerifierEvents } from "../../utils/actions/event";
import { theme } from "../../styles/theme/base";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { userRoles } from "../../utils/constants";
import withAuth from "../../utils/withAuth";

/**
 * The Settings function is a React component that renders a form for users to update their profile
 * information, including their name, email, website, social media links, and profile image.
 * @param props - An object containing the properties passed down to the Settings component.
 * @returns A functional component named "Settings" is being returned.
 */
function SelectEvent(props) {
  const [show, setShow] = useState(1);
  const [_, setUser] = useRecoilState(user);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [website, setWebsite] = useState("");
  const [socialLinks, setSocialLinks] = useState([]);
  const [data, setData] = useState([]);
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingStripe, setLoadingStripe] = useState(false);
  const toast = useToast();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedEventObject, setSelectedEventObject] = useState(null);

  const navigate = useNavigate();

  const shortenToFirstTwoWords = (inputString) => {
    const words = inputString.split(" ");
    return words.slice(0, 2).join(" ");
  };
  const init = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user_d"));
      const token = localStorage.getItem("x-auth-token");

      console.log("user", user);

      const val = await getVerifierEvents(user.verifierId);
      console.log("val", val);
      if (!isEmpty(val)) {
        console.log("checkpoint3");
        const newEvents = [];
        Object.values(val).forEach((et) => {
          newEvents.push({ ...et });
        });
        console.log("newevents2", newEvents);
        setData(newEvents);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    init();
  }, []);

  return (
    <Box // Container to center the VStack vertically
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh" // Make the container take the full viewport height
      bg="#000000"
    >
      <VStack spacing={4} alignItems="stretch">
        <Box // New container to center the Text and Button
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          {data.length ? (
            data.map((event) => (
              <Radio
                colorScheme="white"
                key={event?.idEvent}
                isChecked={selectedEvent === event?.idEvent}
                onChange={() => {
                  setSelectedEvent(event?.idEvent);
                  setSelectedEventObject(event); // Set the selected event object
                }}
              >
                <Box
                  sx={theme.Header.shape}
                  style={{ cursor: "pointer", margin: "1rem 0" }}
                >
                  <HStack width="100%" justifyContent="space-between">
                    <VStack alignItems="flex-start">
                      <div key={event?.idEvent}>
                        <Text
                          fontFamily="Gordita"
                          fontSize="1.125rem"
                          fontWeight="700"
                        >
                          {event?.eventName}
                        </Text>
                        {/* Render other event details */}
                      </div>
                      <HStack alignItems="flex-end" width="100%">
                        <Text
                          fontFamily="Gordita"
                          fontSize="1rem"
                          fontWeight="400"
                        >
                          {event?.startDate}
                        </Text>
                        <Text
                          fontFamily="Gordita"
                          fontSize="1rem"
                          fontWeight="400"
                          color={"rgba(255, 255, 255, 0.60)"}
                        >
                          {shortenToFirstTwoWords(event?.location)}
                        </Text>
                      </HStack>
                    </VStack>
                  </HStack>
                </Box>
              </Radio>
            ))
          ) : (
            <>
              <Text
                marginTop="4rem"
                fontFamily="Gordita"
                fontSize="1.25rem"
                fontWeight="700"
                textAlign="center"
              >
                You have not been assigned to an event yet
              </Text>
            </>
          )}
        </Box>

        <Button
          sx={{
            borderRadius: `100px`,
            display: `flex`,
            isolation: `isolate`,
            flexDirection: `row`,
            justifyContent: `center`,
            alignItems: `center`,
            padding: `15px 30px`,
            boxSizing: `border-box`,
            overflow: `hidden`,
            height: `43px`,
            width: `180px`,
          }}
          bg={theme.buttons.primary.default}
          color={theme.buttons.primary.color}
          _hover={{
            bg: theme.buttons.primary.hover,
            boxShadow: theme.buttons.primary.hoverBoxShadow,
          }}
          _active={{
            boxShadow: theme.buttons.primary.activeBoxShadow,
          }}
          position="fixed"
          bottom="4"
          right="4"
          disabled={!selectedEvent} // Disable the button if no event is selected
          onClick={() => {
            // Pass the selected event object to the next screen
            navigate(`/verifier/scanner/${selectedEventObject.uuid}`);
          }}
        >
          Start Scanning
        </Button>
      </VStack>
    </Box>
  );
}
export default withAuth(SelectEvent, [userRoles.VERIFIER]);
