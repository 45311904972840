/**
 * This is a React component that renders a sidebar with links to different pages based on the user's
 * role.
 * @returns The Sidebar component is being returned.
 */
import {
  useDisclosure,
  VStack,
  useColorModeValue,
  Text,
  HStack,
  Image,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Icon,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";

import { SidebarWrapper } from "../../../styles/layout/sidebar";
import { useMediaQuery } from "../../../utils/useMediaQuery";
import { theme } from "../../../styles/theme/base";
import { useNavigate } from "react-router-dom";
import ExpandedLogo from "../../../static/assets/images/logo.svg";
import ShrinkedLogo from "../../../static/assets/images/Mark.svg";
import TypeIcon from "../../../static/assets/images/TypeIcon.svg";
import { fetchUserData } from "../../../utils/actions/registration";

import {
  dashboardLight,
  dashboardPrimary,
  eventsLight,
  eventsPrimary,
  ordersLight,
  ordersPrimary,
  settingLight,
  settingPrimary,
  logout,
  dashboardDark,
  eventsDark,
  ordersDark,
  settingDark,
  TypeAdd,
  TypeArrowback,
  TypeDashboard,
  TypeEvent,
  TypeLogout,
  TypeTeam,
  TypeGrid,
  dashboardselected,
  eventsselected,
  teamselected,
  AttendeeGrey,
  AttendeeWhite,
  link,
  link1,
  events,
  analytics,
  marketing,
  team,
  control,
} from "../../../static/assets/images/dashboard/sidebar";
import { useEffect, useState } from "react";
import { expandSide, shrinkSide } from "../../../static/assets/images";
import { useRecoilState } from "recoil";
import { user } from "../../../recoil/atoms/user";
import { dashboard as dbs } from "../../../recoil/atoms/dashboard";
import userIcon from "../../../static/assets/images/userIcon.svg";

/**
 * This is a React component that renders a clickable link with an image and text, and changes its
 * appearance based on hover and selected state.
 * @returns A functional component named `RenderSideLink` is being returned.
 */
const RenderSideLink = ({
  selectedImage,
  lightImage,
  name,
  link,
  link2,
  link3,
  link4,
  darkImage,
  isExpanded,
  respectivecolor,
  borderRadius,
}) => {
  const navigate = useNavigate();
  const imageVal = useColorModeValue(darkImage, lightImage);
  const textValue = useColorModeValue(
    theme.colors.black[100],
    theme.colors.white[100]
  );
  const checkPath =
    link !== "/" &&
    (window.location.pathname?.includes(link.split("/")[1]?.toLowerCase()) ||
      window.location.pathname?.includes(link2?.split("/")[1]?.toLowerCase()) ||
      window.location.pathname?.includes(link3?.split("/")[1]?.toLowerCase()) ||
      window.location.pathname?.includes(link4?.split("/")[1]?.toLowerCase()));

  const logoColor = checkPath ? theme.sidenav.primary.color : textValue;

  const handleButtonClick = () => {
    navigate(link);
  };


  return (
    <Button
      onClick={handleButtonClick}
      sx={
        isExpanded
          ? theme.sidenav.primary.shape
          : theme.sidenav.primary.collapsed
      }
      borderRadius={borderRadius || `0.9375rem`}
      bg="transparent" //{checkPath ? `rgba(255, 92, 0, 0.1)` : theme.sidenav.primary.default}
      color={theme.sidenav.primary.color}
      _hover={{
        borderRight: "var(--spacing-spacing-none, 1px) solid #FFF",

        borderBottom: "var(--spacing-spacing-none, 1px) solid #FFF",

        borderLeft: "var(--spacing-spacing-none, 1px) solid #FFF",
        borderTop: "1px solid #FFF",
      }}
      _active={{
        bg: `hsla(0, 0%, 100%, 0.1)`,
        color: theme.sidenav.primary.color,
      }}
      _selected={{
        bg: `rgba(255, 92, 0, 0.1)`,
        color: theme.sidenav.primary.color,
      }}
      borderTop={
        checkPath
          ? `1px solid ${respectivecolor}`
          : `1px solid rgba(255, 255, 255, 0.20)`
      }
      borderRight={
        checkPath
          ? `var(--spacing-spacing-none, 1px) solid ${respectivecolor}`
          : `var(--spacing-spacing-none, 1px) solid rgba(255, 255, 255, 0.20)`
      }
      borderBottom={
        checkPath
          ? `var(--spacing-spacing-none, 1px) solid ${respectivecolor}`
          : `var(--spacing-spacing-none, 1px) solid rgba(255, 255, 255, 0.20)`
      }
      borderLeft={
        checkPath
          ? `var(--spacing-spacing-none, 1px) solid ${respectivecolor}`
          : `var(--spacing-spacing-none, 1px) solid rgba(255, 255, 255, 0.20)`
      }
      display={"flex"}
      gap={"0px"}
      justifyContent={"center"}
      alignItems={"center"}
      transition="all 0.2s ease-in-out"
    >
      <Image
        style={{
          borderRadius: "50%",
          width: "24px",
          height: "24px",
          color: checkPath ? "primary.100" : logoColor,
          background: `rgba(${parseInt(
            respectivecolor?.slice(1, 3),
            16
          )}, ${parseInt(respectivecolor?.slice(3, 5), 16)}, ${parseInt(
            respectivecolor?.slice(5, 7),
            16
          )}, 0.1)`,
        }}
        alt=""
        src={checkPath ? selectedImage : imageVal}
      />
      {isExpanded && (
        <Text
          color={checkPath ? respectivecolor : textValue}
          pt={1}
          fontWeight={"700"}
          pl={20}
          className="gordita600"
          fontSize={"1rem"}
        >
          {name}
        </Text>
      )}
    </Button>
  );
};

/**
 * This is a React component that renders a sidebar with links to different pages based on the user's
 * role and allows for expanding and collapsing the sidebar.
 * @returns A functional component named `Sidebar` is being returned.
 */
const Sidebar = ({ sidebarWidth, setSidebarWidth }) => {
  const isTablet = useMediaQuery("(max-width: 1024px)");
  const isMobile = useMediaQuery("(max-width: 992px)");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const value = useColorModeValue(
    theme.colors.white[200],
    theme.colors.black[100]
  );

  const [_U, setUser] = useRecoilState(user);
  const [_D, setDashboard] = useRecoilState(dbs);
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);

  const getUser = async () => {
    const token = _U?.token;
    if (token) {
      await fetchUserData(token)
        .then((user) => {
          setUser((lp) => {
            return {
              ...lp,
              token,
              userData: user?.user,
            };
          });
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    if (isTablet) {
      setDashboard((lp) => {
        return {
          ...lp,
          dashboard: {
            expanded: !isTablet && true,
          },
        };
      });
    } else {
      setDashboard((lp) => {
        console.log("lp", lp);
        return {
          ...lp,
          dashboard: {
            expanded: !isTablet && lp.dashboard.expanded,
          },
        };
      });
    }
   getUser();

  }, [isTablet]);

  const navigate = useNavigate();
  /* The `return` statement is returning a JSX code block that renders a sidebar component with links
  to different pages based on the user's role. The sidebar is wrapped in a `SidebarWrapper`
  component and contains a `VStack` component that holds the links and a logout button. The `VStack`
  component has several props that define its appearance, such as `h` for height, `px` and `py` for
  padding, `maxW` for maximum width, `w` for width, `bg` for background color, `pos` for position,
  `left` for left position, and `justifyContent` for vertical alignment. The sidebar also has an
  expand/collapse button that toggles the width of the sidebar. The links are defined as an array of
  objects, each with a name, selectedImage, lightImage, darkImage, and link property. These
  properties are used to render the links with an image and text, and change their appearance based
  on hover and selected state. The `RenderSideLink` component is used to render each link. */
  return (
    <>
      {!isMobile ? (
        <VStack
          h={"100vh"}
          px={24}
          py={36}
          maxW={_D.dashboard.expanded ? "225px" : "74px"}
          w="fit-content"
          bg={value}
          pos="sticky"
          top={0}
          left={0}
          justifyContent="space-between"
          transition="all 0.2s ease-in-out"
          borderRight={"1px solid hsla(240, 1%, 59%, 1)"}
        >
          <Box
            zIndex={1000000}
            pos="absolute"
            top="26px"
            transition="all 0.2s ease-in-out"
            left={_D.dashboard.expanded ? 20 : "-moz-initial"}
          >
            <Image
              alt="LOGO"
              src={_D.dashboard.expanded ? control : ShrinkedLogo}
            />
          </Box>
          <Box zIndex={1000000} pos="absolute" top="14%">
            <Button
              transition="all 0.2s ease-in-out"
              onClick={() =>
                navigate("/events/create", { state: { isEditMode: false } })
              }
              sx={
                _D.dashboard.expanded
                  ? theme.fixedButtons.primary.shape
                  : theme.fixedButtons.secondary.shape
              }
              bg={theme.buttons.primary.default}
              color={theme.buttons.primary.color}
              _hover={{
                bg: theme.buttons.primary.hover,
                boxShadow: theme.buttons.primary.hoverBoxShadow,
              }}
              _active={{
                boxShadow: theme.buttons.primary.activeBoxShadow,
              }}
            >
              {_D.dashboard.expanded ? (
                "Create Event"
              ) : (
                <img src={TypeAdd} alt="Add" />
              )}
            </Button>
            {_U?.userData?.userRole === "ADMIN" && (
              <Button
                transition="all 0.2s ease-in-out"
                onClick={() =>
                  navigate("/events/resell/create", { state: { isEditMode: false } })
                }
                sx={
                  _D.dashboard.expanded
                    ? theme.fixedButtons.primary.shape
                    : theme.fixedButtons.secondary.shape
                }
                bg= "#a9a9a9" //a bit darker than the default
                color={theme.buttons.secondary.color}
                _active={{
                  boxShadow: theme.buttons.secondary.activeBoxShadow,
                }}
                textColor="black"
                border={theme.buttons.secondary.border}
                marginTop={"10px"}
              >
                {_D.dashboard.expanded ? (
                  "Resell Tickets"
                ) : (
                  <img src={TypeAdd} alt="Add" color="white" />
                )}
               
              </Button>
            )}
          </Box>
          <VStack pt={_U?.userData?.userRole === "ADMIN" ? "25vh" : ["15vh", "18vh", "20vh"]} maxPt="10rem" w="100%" spacing={0} gap={"5px"}>
            {[
              {
                name: "Analytics",
                selectedImage: analytics,
                lightImage: analytics,
                darkImage: analytics,
                link: "/dashboard",
                respectivecolor: "#FFF848",
              },
              {
                name: "Events",
                selectedImage: events,
                lightImage: events,
                darkImage: events,
                link: "/events",
                respectivecolor: "#48E9FF",
              },
              /* {
                name: "Orders",
                selectedImage: ordersPrimary,
                lightImage: ordersLight,
                darkImage: ordersDark,
                link: "/orders",
              },*/
              {
                name: "Marketing",
                selectedImage: marketing,
                lightImage: marketing,
                darkImage: marketing,
                link: "/attendees",
                link2: "/guestlist",
                link3: "/trackinglinks",
                link4: "/promocodes",
                respectivecolor: "#FF8F27",
              },
              ...(_U?.userData?.userRole === "ADMIN"
                ? [
                    {
                      name: "Organizers",
                      selectedImage: dashboardPrimary,
                      lightImage: dashboardLight,
                      darkImage: dashboardDark,
                      link: "/organizers",
                    },
                  ]
                : []),
              {
                name: "SMS/Emails",
                selectedImage: marketing,
                lightImage: marketing,
                darkImage: marketing,
                link: "/blasts/email",
                link2: "/blasts/sms",
                respectivecolor: "#FF8F27",
              },
              {
                name: "Team",
                selectedImage: team,
                lightImage: team,
                darkImage: team,
                link: "/verifiers",
                link2: "/team",
                respectivecolor: "#51FFAB",
              },
              /*  {
            name: "Tracking Links",
            selectedImage: link1,
            lightImage: link,
            darkImage: link,
            link: "/trackinglinks",
          },*/
            ].map((sideLink, index) => (
              <RenderSideLink
                isExpanded={_D.dashboard.expanded}
                key={index}
                {...sideLink}
              />
            ))}
          </VStack>
          <VStack zIndex={1000000} spacing={0} gap={"5px"}>
            <RenderSideLink
              isExpanded={_D.dashboard.expanded}
              name={
                (_U?.userData?.organization?.name ?? "") 
              }
              lightImage={_U?.userData?.organization?.image || TypeIcon}
              darkImage={_U?.userData?.organization?.image || TypeIcon}
              selectedImage={_U?.userData?.organization?.image || TypeIcon}
              link="/account"
              borderRadius={"6.25rem"}
              respectivecolor={"#FFFFFF"}
            />
            <RenderSideLink
              isExpanded={_D.dashboard.expanded}
              name="Home Page"
              lightImage={TypeLogout}
              darkImage={TypeLogout}
              selectedImage={TypeLogout}
              link="/"
              borderRadius={"6.25rem"}
              respectivecolor={"#FFFFFF"}
            />
          </VStack>
          <Box zIndex={1000000}>
            <Button
              sx={
                _D.dashboard.expanded
                  ? theme.fixedButtons.primary.shape
                  : theme.fixedButtons.secondary.shape
              }
              bg={theme.buttons.secondary.border}
              color={theme.buttons.secondary.color}
              border={theme.buttons.secondary.border}
              _hover={{ borderColor: theme.buttons.secondary.hoverBorderColor }}
              _active={{
                bg: theme.buttons.secondary.pressed,
              }}
              transition="all 0.2s ease-in-out"
              onClick={() => {
                setDashboard((lp) => {
                  return {
                    ...lp,
                    dashboard: {
                      expanded: !_D.dashboard.expanded,
                    },
                  };
                });
              }}
            >
              <img
                src={TypeArrowback}
                alt="Collapse"
                width="24"
                height="24"
                style={{
                  transform: _D.dashboard.expanded ? "none" : `rotate(180deg)`,
                  transition: "all 0.1s ease-in-out",
                }}
              />{" "}
              {_D.dashboard.expanded ? "Collapse" : null}
            </Button>
          </Box>
        </VStack>
      ) : (
        <Box pos={"absolute"} h={"100%"} zIndex={50}>
          {!isNavbarVisible && (
            <Box
              style={{
                backgroundColor: "black",
                backdropFilter: "blur(10px) hue-rotate(90deg)",
                border: "1px solid #333333",
                borderLeft: "none",
                width: "3rem",
                height: "3rem",
                position: "sticky",
                top: "10vh",
                left: "0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "0 50px 50px 0",
                cursor: "pointer",
              }}
              onClick={() => setIsNavbarVisible(true)}
            >
              <Icon as={HamburgerIcon} />
            </Box>
          )}
        </Box>
      )}

      <Modal
        isOpen={isNavbarVisible}
        onClose={() => setIsNavbarVisible(false)}
        size="full"
        isCentered
        zIndex={100}
      >
        <ModalOverlay />
        <ModalContent
          bg={"blackAlpha.300"}
          backdropFilter={"blur(25px) hue-rotate(90deg)"}
          zIndex={100}
        >
          <ModalHeader>
            <Box transition="all 0.2s ease-in-out">
              <Image alt="LOGO" src={control} />
            </Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              pt={"5vh"}
              w={"100%"}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                transition="all 0.2s ease-in-out"
                onClick={() => {
                  navigate("/events/create", { state: { isEditMode: false } });
                  setIsNavbarVisible(false);
                }}
                sx={theme.fixedButtons.primary.shape}
                bg={theme.buttons.primary.default}
                color={theme.buttons.primary.color}
                _hover={{
                  bg: theme.buttons.primary.hover,
                  boxShadow: theme.buttons.primary.hoverBoxShadow,
                }}
                _active={{
                  boxShadow: theme.buttons.primary.activeBoxShadow,
                }}
              >
                Create Event
              </Button>
            </Box>
            <VStack pt={"2rem"} w="100%" spacing={0} gap={"5px"}>
              {[
                {
                  name: "Analytics",
                  selectedImage: analytics,
                  lightImage: analytics,
                  darkImage: analytics,
                  link: "/dashboard",
                  respectivecolor: "#FFF848",
                },
                {
                  name: "Events",
                  selectedImage: events,
                  lightImage: events,
                  darkImage: events,
                  link: "/events",
                  respectivecolor: "#48E9FF",
                },
                {
                  name: "Marketing",
                  selectedImage: marketing,
                  lightImage: marketing,
                  darkImage: marketing,
                  link: "/attendees",
                  link2: "/guestlist",
                  link3: "/trackinglinks",
                  link4: "/promocodes",
                  respectivecolor: "#FF8F27",
                },
                ...(_U?.userData?.userRole === "ADMIN"
                  ? [
                      {
                        name: "Organizers",
                        selectedImage: dashboardPrimary,
                        lightImage: dashboardLight,
                        darkImage: dashboardDark,
                        link: "/organizers",
                      },
                    ]
                  : []),
                // {
                //   name: "SMS/Emails",
                //   selectedImage: marketing,
                //   lightImage: marketing,
                //   darkImage: marketing,
                //   link: "/blasts/sms",
                //   link2: "/blasts/email",
                //   respectivecolor: "#FF8F27",
                // },
                {
                  name: "Team",
                  selectedImage: team,
                  lightImage: team,
                  darkImage: team,
                  link: "/verifiers",
                  link2: "/team",
                  respectivecolor: "#51FFAB",
                },
              ].map((sideLink, index) => (
                <RenderSideLink isExpanded={true} key={index} {...sideLink} />
              ))}
            </VStack>
            <VStack zIndex={1000000} spacing={0} gap={"5px"} mt={"5px"}>
              <RenderSideLink
                isExpanded={true}
                name={
                  (_U?.userData?.organization?.name ?? "") 
                }
                lightImage={_U?.userData?.organization?.image || TypeIcon}
                darkImage={_U?.userData?.organization?.image || TypeIcon}
                selectedImage={_U?.userData?.organization?.image || TypeIcon}
                link="/account"
                borderRadius={"6.25rem"}
                respectivecolor={"#FFFFFF"}
              />
              <RenderSideLink
                isExpanded={true}
                name="Home Page"
                lightImage={TypeLogout}
                darkImage={TypeLogout}
                selectedImage={TypeLogout}
                link="/"
                borderRadius={"6.25rem"}
                respectivecolor={"#FFFFFF"}
              />
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export default Sidebar;
