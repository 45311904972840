import { Box, useColorModeValue } from "@chakra-ui/react";
import { node, string } from "prop-types";
import React from "react";
import { MainWrapper, Child, Header } from "../../styles/layout/layout";
import Navbar from "../shared/navbar";
import Footer from "../shared/footer";
import { theme } from "../../styles/theme/base";

/**
 * This is a React functional component that renders a layout with a header, navbar, child content, and
 * footer.
 * @returns The `Layout` component is being returned.
 */
const Layout = ({ children, bgColor, ...rest }) => {
  const value = useColorModeValue(
    theme.colors.white[100],
    theme.colors.black[100]
  );
  return (
    <MainWrapper background={bgColor || value}>
      <Header>
        <Navbar {...rest} />
      </Header>
      <Child id="main">{children}</Child>
      <Box marginTop="40px">
        <Footer />
      </Box>
    </MainWrapper>
  );
};

Layout.propTypes = {
  children: node,
  background: string,
};

export default Layout;
