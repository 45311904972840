import styled from "@emotion/styled";

export const EventBar = styled.div`
  .site-navigation-steps .ant-steps-item .ant-steps-item-title {
    color: ${(props) => props.color}!important;
  }

  .site-navigation-steps .ant-steps-item .ant-steps-icon {
    color: ${(props) => props.color}!important;
  }

  .site-navigation-steps .ant-steps-item .ant-steps-item-icon {
    background-color: transparent;
    color: ${(props) => props.color}!important;
    border: 1px solid #ff5c00 !important;
  }

  .site-navigation-steps .ant-steps-item .ant-steps-icon {
    color: ${(props) => props.color}!important;
  }

  .site-navigation-steps .ant-steps-item::after {
    border-top-color: ${(props) => props.color} !important;
    border-inline-end-color: ${(props) => props.color} !important;
  }

  .site-navigation-steps .ant-steps-item::before {
    background-color: transparent !important;
  }

  /* Process */
  .site-navigation-steps .ant-steps-item-process .ant-steps-item-title {
    color: #ff5c00 !important;
  }

  .site-navigation-steps .ant-steps-item-process .ant-steps-item-icon {
    background-color: #ff5c00;
    color: ${(props) => props.color}!important;
    border: none !important;
  }

  .site-navigation-steps .ant-steps-item-process .ant-steps-icon {
    color: ${(props) => props.color}!important;
  }

  .site-navigation-steps .ant-steps-item-process::after {
    border-top-color: #ff5c00 !important;
    border-inline-end-color: #ff5c00 !important;
  }
`;

export const SelectItem = styled.div`
  .optionss: {
    background-color: red !important;
    height: 56px !important;
  }
`;

export const DatePicker = styled.div`
  .ant-picker-suffix {
    width: 2.8125rem;
    height: 2.8125rem;
    display: flex;
    justify-content: center;
    align-itens: center;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.05);
  }
  .ant-picker-input > input:placeholder-shown {
    font-weight: bold;
    color: #fff !important;
  }

  .ant-picker-input input::placeholder {
    color: #ffffff !important;
    font-size: 16px;
  }

  .ant-picker-input > input {
    color: #ffffff !important;
    margin-left: 1rem;
    width: 100%;
  }

  .ant-picker-clear {
    display: none;
  }

  .ant-picker .ant-picker-input span.ant-picker-suffix,
  .ant-picker.ant-picker-range span.ant-picker-suffix {
    margin-left: 0px;
    margin-right: 0px;
    order: -1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
