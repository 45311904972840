import {
    Box,
    Button,
    HStack,
    Stack,
    Table,
    Thead,
    Tbody,
    Th,
    Td,
    TableContainer,
    Text,
    Tr,
    useColorModeValue,
    useToast,
    useDisclosure,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Modal,
    Input,
    Image,
    Select,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    IconButton,
    Heading,
  } from "@chakra-ui/react";
  import { DeleteIcon } from "@chakra-ui/icons";
  import { useEffect, useState } from "react";
  import { useNavigate } from "react-router-dom";
  import axios from "../../../../utils/api/axios";
  import secrets from "../../../../secrets";
  import { useRecoilState } from "recoil";
  import { user } from "../../../../recoil/atoms/user";
  import { getEvents, getEventsByUUID } from "../../../../utils/actions/event";
  import { isEmpty } from "lodash";
  import { assignVerifier } from "../../../../utils/actions/registration";
  import { theme } from "../../../../styles/theme/base";
  import CountryFlag from "react-country-flag";
  import { CopyIcon } from "@chakra-ui/icons";
  import cancel from "../../../../static/assets/images/TypeClose.svg";
import edit from "../../../../static/assets/images/TypeEdit.svg";
import "../sharedAdminStyles.css";
import filtericon from "../../../../static/assets/images/ic_round-filter-list.png";
import {TypeAdd} from "../../../../static/assets/images/dashboard/sidebar";
/**
 * The UsersComponent function displays a table of attendees or organizers, with options to view
 * details or approve organizers if the user is an admin.
 * @returns The `UsersComponent` functional component is being returned.
 */

const spacing = {
  gap: 0,
  spacing: 0,
  w: "100%",
};
const PromoCodes = () => {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const navigate = useNavigate();
  const [_, setUser] = useRecoilState(user);
  const colorValue = useColorModeValue("black.100", "gray.100");
  const [currentPage, setCurrentPage] = useState(1);
  const toast = useToast();
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [checkedItems, setCheckedItems] = useState([false, false]);
  const [events, setEvents] = useState([]);
  const [isAllEventsSelected, setIsAllEventsSelected] = useState(false);
  const [verifierId, setVerifierId] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedEmail, setSelectedEmail] = useState("");
  const currentDate = new Date();
  const formattedCurrentDate = currentDate.toISOString().split("T")[0]; // Format 'YYYY-MM-DD'
  const [selectedEvent, setSelectedEvent] = useState(""); // State for selected event
  const [codeName, setCodeName] = useState("");
  const [selectedTicket, setSelectedTicket] = useState(""); // State for selected ticket
  const [ticketType, setTicketType] = useState([]); 
  const [usageAmountLimit, setUsageAmountLimit] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [promoId, setPromoId] = useState(null);
  const [editId, setEditId] = useState(null);
  const [activeLink, setActiveLink] = useState(null); // State to track the active link
  const [visitData, setVisitData] = useState({}); // State to store visit data
  const [isCopied, setIsCopied] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const organizerId = _.userData?.organizerId || _.userData?.userId;

  const createPromoCode = async () => {
    // Validation (ensure selectedEvent is not empty)
    if (!selectedEvent) {
      toast({
        title: "Missing Information",
        description: "Please select an event in order to create a promo code.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!discountPercentage) {
      toast({
        title: "Missing Information",
        description:
          "Please select a discount percentage to create a promo code.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (discountPercentage > 100) {
      toast({
        title: "Discount is too big",
        description:
          "Please select a discount percentage that is less than 100%.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!usageAmountLimit) {
      toast({
        title: "Missing Information",
        description:
          "Please select a usage amount limit to create a promo code.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!codeName) {
      toast({
        title: "Missing Information",
        description: "Please give a name to the promo code",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (codeName && codeName.length > 12) {
      toast({
        title: "Missing Information",
        description: "Promo Code cannot exceed 12 characters.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.post(
         "/api/createPromoCode",
        {
          uuid: selectedEvent,
          codeName: codeName,
          organizerId: organizerId,
          discountPercentage: discountPercentage,
          usageAmountLimit: usageAmountLimit,
          ticketTypeId:selectedTicket,
        },
        {
          headers: { "x-auth-token": "Bearer " + _?.token },
        }
      );

      // Handle successful response
      setIsModalOpen(false);
      getPromoCodes();
      toast({
        title: "Promo Code Created",
        description: "The promo code has been successfully created.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      // Handle error
      toast({
        title: "Error",
        description: `Failed to create the promo code. Make sure it doesn't exist already`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const deletePromoCode = async () => {
    if (!promoId) return;
    try {
      await axios.delete(
        `/api/deletePromoCode/${promoId}`,
        {
          headers: {
            "x-auth-token": "Bearer " + _?.token,
          },
        }
      );
      getPromoCodes(); // Refresh the list after deletion
      onClose(); // Close the modal
      setPromoId(null); // Reset the delete link ID
      toast({
        title: "Promo Code Deleted",
        description: "The promo code has been successfully deleted.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting promo code:", error);
      toast({
        title: "Error",
        description: "Failed to delete the promo code.",
        status: "error",
        isClosable: true,
        duration: 4000,
        position: "top-right",
      });
    }
  };

  const requestDeletePromoCode = (promoId) => {
    setPromoId(promoId);
    onOpen();
  };

  const isEventsAvailable =
    data?.filter((event) => event?.endDate >= formattedCurrentDate).length !==
    0;

  const handleCheckboxToggle = (idEvent) => {
    setIsAllEventsSelected(false);
    setEvents((prevEvents) => {
      // Toggle the event ID in the events array
      if (prevEvents.includes(idEvent)) {
        return prevEvents.filter((event) => event !== idEvent);
      } else {
        return [...prevEvents, idEvent];
      }
    });
  };

  const commonButtonStyle = {
    display: "flex",
    height: "45px",
    padding: "15px 25px",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    flex: "1 0 0",
    borderRadius: "100px",
  };

  const selectedButton = {
    ...commonButtonStyle,
    background: "var(--colors-shades-white-10, rgba(255, 255, 255, 0.10))",
    color: "white",
  };

  const notSelectedButton = {
    ...commonButtonStyle,
    background: "none",
    color: "#FFFFFF99",
  };

  const init = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user_d"));
      const token = localStorage.getItem("x-auth-token");
      if (user?.userRole === "ADMIN") {
        await axios
          .get("/api/events/admin", {
            headers: {
              "x-auth-token": "Bearer " + token,
            },
          })
          .then((res) => {
            const data = res.data;
            if (!isEmpty(data)) {
              const newEvents = [];
              Object.values(data).forEach((et) => {
                newEvents.push({ ...et });
              });
              console.log("newevents1", newEvents);
              setData(newEvents);
            }
          })
          .catch((err) => {
            console.log({ err });
          });
      } else {
        const val = await getEvents(organizerId);

        if (!isEmpty(val)) {
          console.log("checkpoint3");
          const newEvents = [];
          Object.values(val).forEach((et) => {
            newEvents.push({ ...et });
          });
          console.log("newevents2", newEvents);
          setData(newEvents);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const fetchEventData = async () => {
        const val = await getEventsByUUID(selectedEvent);
        setTicketType(val.ticketTypes);
    };

    fetchEventData();
}, [selectedEvent]);


  const handleButtonClick = () => {
    setIsEditing(false);
    setCodeName("");
    setSelectedEvent("");
    setSelectedTicket("");
    setDiscountPercentage("");
    setEditId("");
    setUsageAmountLimit("");
    setIsModalOpen(true);
  };

  const editPromoCode = async () => {
    if (!selectedEvent) {
      toast({
        title: "Missing Information",
        description: "Please select an event in order to create a promo code.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!discountPercentage) {
      toast({
        title: "Missing Information",
        description:
          "Please select a discount percentage to create a promo code.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (discountPercentage > 100) {
      toast({
        title: "Discount is too big",
        description:
          "Please select a discount percentage that is less than 100%.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!usageAmountLimit) {
      toast({
        title: "Missing Information",
        description:
          "Please select a usage amount limit to create a promo code.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!codeName) {
      toast({
        title: "Missing Information",
        description: "Please give a name to the promo code",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (codeName && codeName.length > 12) {
      toast({
        title: "Missing Information",
        description: "Promo Code cannot exceed 12 characters.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.post(
        `/api/editPromoCode/${editId}`,
        {
          uuid: selectedEvent,
          codeName: codeName,
          organizerId: organizerId,
          discountPercentage: discountPercentage,
          usageAmountLimit: usageAmountLimit,
          ticketTypeId:selectedTicket,

        },
        {
          headers: { "x-auth-token": "Bearer " + _?.token },
        }
      );

      // Handle successful response
      setIsModalOpen(false);
      setEditId(null);
      getPromoCodes();
      toast({
        title: "Promo Code Link Created",
        description: "The promo code has been successfully edited.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      // Handle error
      toast({
        title: "Error",
        description: `Failed to edit the promo code. Please try again.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const getPromoCodes = async () => {
    try {
      let url = "/api/getPromoCodes/";
      if (selectedEvent) {
        url += `?eventUuid=${selectedEvent}`; // Adding query parameter for event UUID
      }
      const response = await axios.get(url, {
        headers: {
          "x-auth-token": "Bearer " + _?.token,
        },
        params: {
          organizerId: organizerId,
        },
      });

      setData2(response.data?.promoCodes);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getPromoCodes();
  }, [_.userData?.userId, selectedEvent]); // Add selectedEvent as a dependency

  const handleCopyCode = async (codeToCopy) => {
    try {
      // Attempt to copy the code to the clipboard
      await navigator.clipboard.writeText(codeToCopy);
      toast({
        title: "Code Copied",
        status: "success",
        isClosable: true,
        duration: 2000,
        position: "top-right",
      });
    } catch (error) {
      console.error("Error copying code:", error);
      toast({
        title: "Copy Error",
        description: "Failed to copy the code. Please try again.",
        status: "error",
        isClosable: true,
        duration: 4000,
        position: "top-right",
      });
    }
  };

  const isAllEventsToggled =
    data
      ?.filter((event) => event?.endDate >= formattedCurrentDate)
      .map((event) => event?.uuid).length === events.length;

  return (
    <Box w="100%">
      <Box w="100%">
        <Stack
          {...{ spacing }}
          flexDir="row"
          color="#FBFAFB"
          pos={"sticky"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={"10px"}
          flexWrap={"wrap"}
          top={0}
          p={30}
          zIndex={10}
          w="100%"
          fontWeight={700}
          bg={"rgba(0, 0, 0, 0.50)"}
          backdropFilter={"blur(150px)"}
        >
          <Box fontSize={"32px"} className="control-header-component-title">
            Marketing
          </Box>

          <Box
            sx={{
              display: "flex",
              maxWidth: "634px",
              padding: "5px",
              alignItems: "flex-start",
              borderRadius: "100px",
              border:
                "1px solid var(--border-b-primary, rgba(255, 255, 255, 0.20))",
              background: "var(--surface-s-glass, rgba(0, 0, 0, 0.50))",
              backdropFilter: "blur(75px)",
            }}
            overflow={"scroll"}
            gap={"5px"}
            className="control-header-component-filters"
          >
            <Button
              sx={notSelectedButton}
              onClick={() => {
                navigate("/attendees");
              }}
              minW={"150px"}
              p={"5px 25px"}
            >
              {" "}
              Attendees
            </Button>
            <Button
              sx={notSelectedButton}
              onClick={() => {
                navigate("/guestlist");
              }}
              minW={"150px"}
              p={"5px 25px"}
            >
              {" "}
              Guest List
            </Button>
            <Button
              sx={notSelectedButton}
              onClick={() => {
                navigate("/trackinglinks");
              }}
              minW={"150px"}
              p={"5px 25px"}
            >
              {" "}
              Tracking Links
            </Button>
            <Button sx={selectedButton} minW={"150px"} p={"5px 25px"}>
              {" "}
              Promo Codes
            </Button>
          </Box>
          <Box
            sx={theme.buttons.primary.shape}
            bg={theme.buttons.secondary.border}
            color={theme.buttons.secondary.color}
            border={theme.buttons.secondary.border}
            _hover={{ borderColor: theme.buttons.secondary.hoverBorderColor }}
            _active={{
              bg: theme.buttons.secondary.pressed,
            }}
            p="1"
            className="control-header-component-button"
          >
            <Image
              src={filtericon}
              width="1.5rem"
              height="1.5rem"
              borderRadius="var(--spacing-spacing-none, 0rem)"
            />
            <Select
  value={selectedEvent}
  border="none" // Added padding for better visual appearance
  onChange={(e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "All Events") {
      setSelectedEvent(null); // Set to null when "All Events" is selected
    } else {
      setSelectedEvent(selectedValue); // Otherwise, set the selected event
    }
  }}
  width="100%" // Adjusted width to fill the entire box
>
  <option value="All Events">All Events</option>
  {data?.map((event) => (
    <option key={event.uuid} value={event.uuid}>
      {event.eventName}
    </option>
  ))}
</Select>

          </Box>
          <Button
            onClick={handleButtonClick}
            sx={theme.fixedButtons.secondary.shape}
            bg={theme.buttons.primary.default}
            color={theme.buttons.primary.color}
            _hover={{
              bg: theme.buttons.primary.hover,
              boxShadow: theme.buttons.primary.hoverBoxShadow,
            }}
            _active={{
              bg: theme.buttons.primary.hover,
              boxShadow: theme.buttons.primary.activeBoxShadow,
            }}
            className="control-header-component-button"
          >
              <img src={TypeAdd} alt="Add" />
          </Button>
        </Stack>
      </Box>
      <Box w="100%" minH="80vh" px={"30px"}>
        <TableContainer>
          <Table
            style={{
              borderCollapse: "separate",
              borderSpacing: "0 18px",
              marginBottom: "30px",
            }}
            variant="unstyled"
          >
            <Thead
              borderBottom="1px solid"
              borderColor="rgba(153, 159, 187,0.2)"
            >
              <Tr>
                {[
                  "Promo Code Name",
                  "Event Name",
                  "Discount Percentage",
                  "Number of uses",
                  "Max. Number of uses",
                  "Actions",
                ].map((column, index) => (
                  <Th
                    key={index}
                    paddingBottom={"20px"}
                    textTransform="capitalize"
                    color="var(--surface-surface-secondary, #959597)" // Added color
                    fontFamily="Gordita" // Added font family
                    fontSize="1rem" // Added font size
                    fontStyle="normal"
                    fontWeight={400} // Added font weight
                    lineHeight="150%" // Added line height
                    letterSpacing="-0.01rem" // Added letter spacing
                    position="relative"
                    textAlign={"center"}
                    border={"none"}
                    borderRadius="0rem" // Added border radius
                    borderRight="0px solid rgba(255, 255, 255, 0.10)" // Added border right
                    borderBottom="1px solid rgba(255, 255, 255, 0.10)" // Added border bottom
                    borderLeft="0px solid rgba(255, 255, 255, 0.10)" // Added border left
                    background="rgba(0, 0, 0, 0.50)" // Added background
                    backdropFilter="blur(75px)" // Added blur
                  >
                    {column}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody marginBottom={"30px"}>
              {Object.values(data2)?.map(
                (
                  {
                    codeName,
                    eventName,
                    discountPercentage,
                    currentUsageAmount,
                    usageAmountLimit,
                    promoId,
                    eventUuid,
                    ticketTypeId,
                  },
                  index
                ) => (
                  <Tr overflow="hidden" color="white.100" key={index}>
                    <Td
                      m={0}
                      py={0}
                      pl={20}
                      border={"none"}
                      borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                      borderLeft={"1px solid rgba(255, 255, 255, 0.10)"}
                      borderTopLeftRadius={"0.9375rem"}
                      borderBottomLeftRadius={"0.9375rem"}
                      overflow={"hidden"}
                      maxWidth={"15rem"}
                    >
                      <HStack py={0} spacing={10}>
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          gap={".5rem"}
                          borderRadius={"6.25rem"}
                          border={"1px solid rgba(255, 255, 255, 0.20)"}
                          width={"10.4375rem"}
                          margin={"auto"}
                          bg={"black"}
                          textOverflow={"ellipsis"}
                          overflow={"hidden"}
                          whiteSpace={"nowrap"}
                        >
                          <Text
                            className="gordita400"
                            color="#FFF" // Added color
                            fontFeatureSettings="'ss08' on, 'ss04' on" // Added font feature settings
                            fontFamily="Gordita" // Added font family
                            fontSize="1.125rem" // Added font size
                            fontStyle="normal"
                            fontWeight={700} // Added font weight
                            lineHeight="110%" // Added line height
                            letterSpacing="-0.01125rem" // Added letter spacing
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                          >
                            {codeName || ""}
                          </Text>
                          <IconButton
                            icon={<CopyIcon />}
                            aria-label="Copy Code"
                            onClick={() => handleCopyCode(codeName)} // Pass the code to the handleCopyCode function
                            // You can style the IconButton as needed
                            style={{
                              display: "flex",
                            }}
                            bg={"black"}
                          />
                        </Box>
                      </HStack>
                    </Td>
                    <Td
                      m={0}
                      py={0}
                      textAlign={"center"}
                      border={"none"}
                      borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                      maxWidth={"14rem"}
                    >
                      <Text fontSize={14} className="gordita400">
                        {eventName}
                      </Text>
                    </Td>
                    <Td
                      m={0}
                      py={0}
                      textAlign={"center"}
                      border={"none"}
                      borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                      maxWidth={"14rem"}
                    >
                      <Text
                        fontSize={14}
                        className="gordita400"
                        style={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {discountPercentage + "%" || ""}
                      </Text>
                    </Td>
                    <Td
                      m={0}
                      py={0}
                      textAlign={"center"}
                      border={"none"}
                      borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                      maxWidth={"14rem"}
                    >
                      <Text
                        fontSize={14}
                        className="gordita400"
                        style={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {currentUsageAmount}
                      </Text>
                    </Td>
                    <Td
                      m={0}
                      py={0}
                      textAlign={"center"}
                      border={"none"}
                      borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                      maxWidth={"14rem"}
                    >
                      <Text
                        fontSize={14}
                        className="gordita400"
                        style={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {usageAmountLimit}
                      </Text>
                    </Td>
                    <Td
                      m={0}
                      pl={0}
                      textAlign={"center"}
                      border={"none"}
                      borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                      borderTopRightRadius={"0.9375rem"}
                      borderBottomRightRadius={"0.9375rem"}
                    >
                      <Box
                        width={"100%"}
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems="flex-end"
                        gap={10}
                      >
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          borderRadius="6.25rem"
                          border="1px solid rgba(255, 255, 255, 0.20)"
                          width="3.125rem"
                          height="3.125rem"
                          flexShrink={0} // Corrected syntax
                          onClick={(event) => {
                            event.stopPropagation();
                            requestDeletePromoCode(promoId);
                          }}
                          cursor="pointer"
                        >
                          <Image alt="" src={cancel} />
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          borderRadius="6.25rem"
                          border="1px solid rgba(255, 255, 255, 0.20)"
                          width="3.125rem"
                          height="3.125rem"
                          flexShrink={0} // Corrected syntax
                          onClick={(event) => {
                            event.stopPropagation(); // Prevents the event from bubbling up the DOM tree
                            setCodeName(codeName);
                            setSelectedEvent(eventUuid);
                            setSelectedTicket(ticketTypeId)
                            setDiscountPercentage(discountPercentage);
                            setUsageAmountLimit(usageAmountLimit);
                            setEditId(promoId);
                            setIsEditing(true);
                            setIsModalOpen(true);
                          }}
                          cursor="pointer"
                        >
                          <Image alt="" src={edit} />
                        </Box>
                      </Box>
                    </Td>
                  </Tr>
                )
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
        <ModalOverlay />
        <ModalContent
          bg={"rgba(0, 0, 0, 0.50)"}
          backdropFilter={"blur(150px)"}
          borderRadius={"20px"}
          border={"1px solid #333333"}
        >
          <ModalHeader color="red.100">Delete Promo Code</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete this promo code? This action cannot
            be undone!
          </ModalBody>
          <ModalFooter>
            <Button
              mr={3}
              onClick={onClose}
              sx={theme.buttons.primary.shape}
              bg={theme.buttons.secondary.default}
              color={theme.buttons.secondary.color}
              style={{
                width: "fit-content",
              }}
              _hover={{ bg: theme.buttons.secondary.hover }}
            >
              Cancel
            </Button>
            <Button
              sx={theme.buttons.primary.shape}
              bg={theme.buttons.primary.default}
              color={theme.buttons.primary.color}
              style={{
                width: "5rem",
                padding: "0",
              }}
              _hover={{
                bg: theme.buttons.primary.hover,
                boxShadow: theme.buttons.primary.hoverBoxShadow,
              }}
              _active={{
                boxShadow: theme.buttons.primary.activeBoxShadow,
              }}
              onClick={deletePromoCode}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        size="xl"
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          bg="black"
          border="1px solid #333333"
          borderColor="#333333"
          borderRadius="1.25rem"
        >
          <ModalHeader>
            {" "}
            {isEditing ? "Edit Promo Code" : "Add Promo Code"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack
              display={"flex"}
              alignItems={"start"}
              justifyContent={"center"}
              mt={"3rem"}
              gap={"50px"}
              width={"100%"}
            >
              <Select
                w="33%"
                value={selectedEvent}
                onChange={(e) => setSelectedEvent(e.target.value)}
              >
                <option value="">Select Event</option>
                {data
                  .filter((event) => event?.endDate >= formattedCurrentDate)
                  .map((event) => (
                    <option key={event.uuid} value={event.uuid}>
                      {event.eventName}
                    </option>
                  ))}
              </Select>

              <Input
                w="70%"
                type="text"
                placeholder="Enter Promo Code Name"
                value={codeName}
                onInput={(e) => {
                  const inputValue = e.target.value;
                  const sanitizedValue = inputValue.replace(/\s/g, ""); // Remove spaces
                  const capitalizedValue = sanitizedValue.toUpperCase(); // Capitalize everything
                  setCodeName(capitalizedValue);
                }}
              />
            </HStack>
            <HStack
              display={"flex"}
              alignItems={"start"}
              justifyContent={"center"}
              mt={"3rem"}
              gap={"50px"}
              width={"100%"}
            >
              <Input
                w="70%"
                type="number"
                placeholder="Usage Amount"
                value={usageAmountLimit}
                onChange={(e) => setUsageAmountLimit(e.target.value)}
              />

              <Input
                w="70%"
                type="number"
                placeholder="Discount Amount %"
                value={discountPercentage}
                onChange={(e) => setDiscountPercentage(e.target.value)}
              />
            </HStack>
            {selectedEvent && (
            <Select
              w="50%"
              marginTop={'20px'}
              value={selectedTicket}
              onChange={(e) => setSelectedTicket(e.target.value)}
            >
<option style={{padding: '8px 0'}} value="">Select Ticket (optional)</option>

              {                      ticketType
                        ?.map((ticket) => (
                <option key={ticket.ticketId} value={ticket.ticketId}>
                  {ticket.ticketName} 
                </option>
              ))}
            </Select>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => setIsModalOpen(false)}
              sx={theme.buttons.tercary.shape}
              bg={theme.buttons.tercary.bgColor}
              color={theme.buttons.tercary.color}
              _hover={{ bg: theme.buttons.tercary.hover }}
              css={{
                width: "fit-content !important",
                padding: "1.25rem",
                marginRight: "10px",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={isEditing ? editPromoCode : createPromoCode}
              sx={theme.buttons.primary.shape}
              bg={
                !isEventsAvailable
                  ? theme.buttons.primary.disabled
                  : theme.buttons.primary.default
              }
              color={
                !isEventsAvailable
                  ? theme.buttons.primary.colorDisabled
                  : theme.buttons.primary.color
              }
              _hover={{
                bg: theme.buttons.primary.hover,
                boxShadow: theme.buttons.primary.hoverBoxShadow,
              }}
              _active={{
                boxShadow: theme.buttons.primary.activeBoxShadow,
              }}
              style={{
                width: "6rem",
              }}
              disabled={!isEventsAvailable}
            >
              {isEditing ? "Edit" : "Create"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default PromoCodes;
