import {
  HStack,
  InputGroup,
  VStack,
  InputRightElement,
  Input as InputComponent,
  Box,
  useColorModeValue,
  Text,
  Switch,
  Flex,
} from "@chakra-ui/react";
import { Checkbox, Input, Select } from "antd";
import {
  DateBox,
  InputBox,
  SelectBox,
  SwitchBox,
  TextBox,
  TimeBox,
} from "./SharedModules";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { Button as ABT, Dropdown, Space } from "antd";
import { theme } from "../../../../styles/theme/base";
import { useState, useEffect } from "react";
import LocationAutocomplete from "../../../../utils/autocomplete";
import secrets from "../../../../secrets";
import { message } from "antd";
import { useMediaQuery } from "../../../../utils/useMediaQuery";
import languageIcon from "../../../../static/assets/images/language.svg";
import { useLocation } from "react-router-dom";

import "./styles.css";

/**
 * This is a React component that renders a form for creating basic events, including fields for event
 * name, type, start and end dates and times, location, and description.
 * @param props - The `props` parameter is an object that contains various properties passed down from
 * the parent component to the `BasicEvents` component. These properties include `handleBlur`,
 * `handleChange`, `values`, and `setFieldValue`, which are likely functions or state variables used to
 * manage form input and state. The
 * @returns The BasicEvents component is being returned, which renders a form with input fields for
 * event name, event type, start and end dates and times, location, and description. The event type
 * field is a dropdown menu with options for Night Club, Music, Techno, and Conference. The location
 * field uses the LocationAutocomplete component to allow users to search for and select a location
 * using the Google Maps API
 */
const BasicEvents = (props) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { handleBlur, handleChange, values, setFieldValue } = props;
  const textValue = useColorModeValue(
    theme.colors.black[100],
    theme.colors.white[100]
  );
  const [selectedEvent, setSelectedEvent] = useState(values.eventType || []);
  const location = useLocation();
  const shouldShowContent = location.pathname.includes("/edit");

  const handleCheckboxChange = (checkedValues) => {
    if (checkedValues.length <= 5) {
      setFieldValue("eventType", checkedValues);
      setSelectedEvent(checkedValues);
    } else {
      // Display a message or take appropriate action when the limit is reached
      message.warning("You can select up to 5 values.");
    }
  };


  useEffect(() => {
    setSelectedEvent(values.eventType || []);
  }, [values.eventType]);

  return (
    <VStack spacing={"2.19rem"} w="100%">
      <VStack
        spacing={isMobile ? 0 : 30}
        flexDir={isMobile ? "column" : "row"}
        justifyContent="space-between"
        w="100%"
      >
        <InputBox
          values={values}
          handleBlur={handleBlur}
          handleChange={handleChange}
          placeholder="Portalys Event"
          label="Name"
          name="eventName"
          maxW="100%"
          w={"100%"}
          required
        />
      </VStack>
      
      <Box className="div-separator" />
      {/* <VStack w={"100%"}>
        <LocationAutocomplete
          values={values}
          handleBlur={handleBlur}
          handleChange={setFieldValue}
          placeholder="Select Location"
          label="Location"
          name="location"
          maxW="100%"
          apiKey={secrets.googleMapApi}
        />
      </VStack> */}
      <Box width="100%">
        <HStack
          spacing={isMobile ? 0 : 30}
          flexDir={isMobile ? "column" : "row"}
          justifyContent="space-between"
          w="100%"
        >
          <DateBox
            values={values}
            handleBlur={handleBlur}
            handleChange={setFieldValue}
            placeholder="Start Date"
            label="Date & Time *"
            name="startDate"
            format="YYYY-MM-DD"
            w={"100%"}
            
          />
          <DateBox
            values={values}
            handleBlur={handleBlur}
            handleChange={setFieldValue}
            placeholder="End Date"
            label="&nbsp;"
            name="endDate"
            format="YYYY-MM-DD"
            w={"100%"}
          />
        </HStack>

        <HStack
          spacing={isMobile ? 0 : 30}
          flexDir={isMobile ? "column" : "row"}
          justifyContent="space-between"
          w="100%"
        >
          <TimeBox
            values={values}
            handleBlur={handleBlur}
            handleChange={setFieldValue}
            placeholder="Start Time"
            maxW="100%"
            name="startTime"
          />
          <TimeBox
            values={values}
            handleBlur={handleBlur}
            handleChange={setFieldValue}
            placeholder="End Time"
            name="endTime"
            maxW="100%"
          />
        </HStack>
      </Box>
      <Box className="div-separator" />
      <Box w="100%">
        <LocationAutocomplete
          values={values}
          handleBlur={handleBlur}
          handleChange={setFieldValue}
          placeholder="Select Location"
          label="Location *"
          name="location"
          maxW="100%"
          apiKey={secrets.googleMapApi}
        />
      </Box>
      
      {/* <Flex alignItems="center" justifyContent="center" pt="20" pl={20}>
        <Text fontSize={16} className="gordita400" color="#FFFFFF">
          Hidden
        </Text>
        <Switch
          ml={10}
          defaultChecked={values["hideLocation"]}
          onChange={(val) => setFieldValue("hideLocation", val.target.checked)}
          size="lg"
          colorScheme="whiteAlpha"
        />
      </Flex> */}
      <Box className="div-separator" />
      <HStack w="100%">
        <TextBox
          values={values}
          handleBlur={handleBlur}
          handleChange={handleChange}
          placeholder="You can use text, bullets, bold letters, etc."
          label="About"
          name="description"
        />
      </HStack>
    </VStack>
  );
};

export default BasicEvents;
