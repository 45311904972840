import React from "react";
import { Box, Text, VStack, Button } from "@chakra-ui/react";
import { Collapse } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

import { useMediaQuery } from "../../utils/useMediaQuery";
import { theme } from "../../styles/theme/base";

const { Panel } = Collapse;

const FAQsComponent = ({ }) => {
  const isMobile = useMediaQuery("(max-width: 760px)");
  const isTablet = useMediaQuery("(max-width: 991px)");
  const faqsList = [
    {
      key: "1",
      label: "How to access my event after buying a ticket?",
      children:
        "Log In using your account’s phone number to see your Event Wallet QR code and show it to the event organizer. Or request it on your confirmation email to receive a link by text message. By SMS, the QR code changes every 30 seconds for screenshot protection; no screenshots work 😕 . No connection? No problem, say your name and present an ID at the door, and you’re all set.",
    },
    {
      key: "2",
      label: "What happens if I buy tickets for my friends?",
      children: `All your tickets will be inside your QR code. If you need to share a ticket, you can do so easily on the "My Tickets" page. Simply enter the event, select the ticket you want to share, and choose the recipient.`,
    },
    {
      key: "3",
      label: "Can someone steal/replicate my tickets?",
      children:
        "Your tickets cannot be stolen, duplicated, or hacked. We use blockchain for the good, making it easy for everyone. Get your tickets with peace of mind. Portalys is 100% free of fraud.",
    },
    {
      key: "4",
      label: "What if I don’t see my ticket(s)?",
      children:
        "Most commonly, failed payments. Your bank did not process a payment to us.However, if you don’t see your tickets on your “My Account” page, please email hello@portalys.io with your first and last name and phone number.",
    },
    {
      key: "5",
      label: "How can I get assistance when needed?",
      children:
        "Send us a DM on our social accounts; Instagram, Twitter, and LinkedIn. Or send us an email at hello@portalys.io.",
    },
  ];

  return (
    <VStack width={"100%"}>
      <Box className={"faqs-section"}>
        {!isTablet ? (
          <Text
            fontSize={"2.375rem"}
            textTransform={"capitalize"}
            className="gordita600"
            textAlign={"center"}
          >
            Have questions? We got you.
          </Text>
        ) : (
          <Text
            fontSize={"1.1875rem"}
            textTransform={"capitalize"}
            className="gordita600"
            textAlign={"start"}
            mb={"0.94rem"}
          >
            Portalys FAQs
          </Text>
        )}
        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIconPosition={"end"}
          style={{
            margin: isTablet ? "0 auto" : "3.44rem auto",
            width: "100%",
          }}
          expandIcon={({ isActive }) =>
            isActive ? (
              <Button
                sx={theme.buttons.tercary.shape}
                bg={theme.buttons.tercary.bgColor}
                color={theme.buttons.tercary.color}
                _hover={{ bg: theme.buttons.tercary.hoverBgColor }}
                css={{
                  width: "1.5rem !important",
                  padding: "25px",
                }}
                _active={{
                  bg: theme.buttons.tercary.pressedBgColor,
                }}
                onClick={() => navigate("/create-event")}
              >
                <MinusOutlined style={{ fontSize: "16px" }} />
              </Button>
            ) : (
              <Button
                sx={theme.buttons.tercary.shape}
                bg={theme.buttons.tercary.bgColor}
                color={theme.buttons.tercary.color}
                _hover={{ bg: theme.buttons.tercary.hoverBgColor }}
                css={{
                  width: "1.5rem !important",
                  padding: "25px",
                }}
                _active={{
                  bg: theme.buttons.tercary.pressedBgColor,
                }}
                onClick={() => navigate("/create-event")}
              >
                <PlusOutlined style={{ fontSize: "16px" }} />
              </Button>
            )
          }
          className="site-collapse-custom-collapse"
        >
          {faqsList?.map((faq) => {
            return (
              <Panel
                header={faq.label}
                key={faq.key}
                className="site-collapse-custom-panel"
              >
                <p>{faq.children}</p>
              </Panel>
            );
          })}
        </Collapse>
      </Box>
    </VStack>
  );
};

export default FAQsComponent;
