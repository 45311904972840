import React, { useState, useEffect,useMemo } from 'react';
import { Box, Text, VStack, Button, Input, useToast } from '@chakra-ui/react';
import '../../styles/portalys/portalys.css';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../../modules/layout/layout';
import { getResellEventsBySlug } from '../../utils/actions/event';
import { isEmpty } from 'lodash';
import moment from 'moment';
import 'moment/min/locales';
import { useRecoilState } from 'recoil';
import { ticket } from '../../recoil/atoms/tickets';
import { user } from '../../recoil/atoms/user';
import { useMediaQuery } from '../../utils/useMediaQuery';
import axios from '../../utils/api/axios';
import { useLocation } from 'react-router-dom';
import './event-components/eventDetails.css';
import { useCookies } from 'react-cookie';
import { getCode } from 'iso-3166-1-alpha-2';
import {
  RegistraionModalTypes,
  registration,
} from '../../recoil/atoms/registration';
import {
  BuyModal,
  UploadProofModal,
  ViewProofModal,
  SellerInfoModal,
} from './event-components/modals';
import {
  GetYourResellTicketsButton,
  BuySellTicketsButton,
} from './event-components/eventButtons';
import { EventImageComponent } from './event-components/eventInfo';
import { PageNotFound } from './event-components/pageNotFound';
import { EventInfoComponent } from './event-components/eventInfo';
import {
  DecriptionComponent,
  LineupComponent,
  SpotifyComponent,
} from './event-components/eventSections';
import { TagsComponent } from './event-components/tags';
import { MapComponent } from './event-components/mapComponent';

import {
  SecondaryMarketFooter,
  SecuredResell,
} from '../../modules/pages/SecondaryMarket/text';
import {
  ResellTabs,
  PlaceBidSection,
  SellerComponent,
  BuyerComponent,
} from './event-components/resellSection';
import { theme } from '../../styles/theme/base';
import { fetchUserData } from '../../utils/actions/registration';
import { ResellFeesComponentsMobile } from './event-components/total';

export default function ResellEvent(props) {
  const [_R, setRegistrationModal] = useRecoilState(registration);
  const toast = useToast();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [event, setEvent] = useState();
  const [_, setTicket] = useRecoilState(ticket);
  const [_U, setUser] = useRecoilState(user);
  const isTablet = useMediaQuery('(max-width: 991px)');
  const isMobile = useMediaQuery('(max-width: 760px)');
  const [password, setPassword] = useState('');
  const [cookies, setCookie] = useCookies([`${event?.eventName}-password`]);
  const [openSite, setOpenSite] = useState(true);
  const [error, setError] = useState(false);
  const [pageNotFound, setPageNotFound] = useState(false);
  const [language, setLanguage] = useState(null);
  const is24HourFormat = moment.locale() !== 'en';
  const TimeFormat = is24HourFormat ? 'HH:mm' : 'h:mm A';
  const [selectedTab, setSelectedTab] = useState('tickets');
  const [buyModalOpen, setBuyModalOpen] = useState(false);
  const [uploadProofModalOpen, setUploadProofModalOpen] = useState(false);
  const [viewProofModalOpen, setViewProofModalOpen] = useState(false);
  const [sellerInfoModalOpen, setSellerInfoModalOpen] = useState(false);
  const [offering, setOffering] = useState(false);
  const [selling, setSelling] = useState(false);
  const [text1, setText1] = useState('');
  const [text2, setText2] = useState('');
  const [price, setPrice] = useState('100');
  const [quantity, setQuantity] = useState(1);
  const [selectedTicketType, setSelectedTicketType] = useState('');
  const [expiration, setExpiration] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [images, setImages] = useState([]);
  const [profilePicture, setProfilePicture] = useState('');
  const [total, setTotal] = useState(0);
  const [proofUrl, setProofUrl] = useState([]);
  const [selectedListing, setSelectedListing] = useState([]);
  const [selectedListings, setSelectedListings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [buyerFee, setBuyerFee] = useState(0);
  const [sellerFee, setSellerFee] = useState(0);
  const [successMessage, setSuccessMessage] = useState('');
  const bgColor = '#000';
  const accentColor = '#fff';
  const [directLink, setDirectLink] = useState(false);

  //tracking data
  const trackingId = searchParams.get('tracking') || ''; // Get trackingId from URL
  const match = event?.spotifyId?.match(/\/track\/(\w+)/);
  const trackId = match ? match[1] : null;

  const memoizedComponent = useMemo(
    () => (
      <MapComponent
        location={event?.location}
        latitude={event?.latitude}
        longitude={event?.longitude}
        accentColor={accentColor}
        locationText={'Location'}
      />
    ),
    [event, accentColor]
  );


  const init = async () => {
    if (id) {
      try {
        const val = await getResellEventsBySlug(id);
        if (!isEmpty(val)) {
          setEvent(val);
          const ticketTypesJson = val?.ticketTypes
            ? JSON.parse(val.ticketTypes)
            : [];
          setSelectedTicketType(ticketTypesJson[0] || '');
        }
        if (val.error) {
          setPageNotFound(true);
        }
      } catch (error) {
        setPageNotFound(true);
        console.error(error);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    init();
  }, [id]);

  useEffect(() => {
    //if there is a param listingId, set the selectedListing to the listing with that id
    if (searchParams.get('listingId')) {
      // Convert listingId to string since URL params are strings
      const listingIdParam = searchParams.get('listingId');
      console.log('listingId from URL:', listingIdParam);
      
      // Find listing where listingId matches the URL param
      const matchingListing = event?.listings?.find(listing => 
        String(listing.listingId) === listingIdParam
      );

      if (matchingListing) {
        setSelectedListing(matchingListing);
        setSelectedListings([matchingListing]);
        setDirectLink(true);
        handleBuyNow(matchingListing);
      } else {
        console.log('No matching listing found for listingId:', listingIdParam);
      }
    } else if (searchParams.get('offerId')) {
      const offerIdParam = searchParams.get('offerId');
      console.log('offerId from URL:', offerIdParam);
      const matchingOffer = event?.offers?.find(offer => 
        String(offer.offerId) === offerIdParam
      );
      if (matchingOffer) {
        setSelectedListing(matchingOffer);
        setDirectLink(true);
        handleSell(matchingOffer);
      }
    } else {
      console.log('No offerId in URL params:', searchParams.toString());
    }
  }, [event]);

  useEffect(() => {
    setProfilePicture(_U.userData?.image || '');
    setEmail(_U.userData?.email || '');
    setFirstName(_U?.userData?.firstName || '');
    setLastName(_U.userData?.lastName || '');
  }, [_U]);

  const updateUser = async () => {
    const token = _U?.token;
    const capitalizedFirstName =
      firstName.charAt(0).toUpperCase() + firstName.slice(1);
    const capitalizedLastName =
      lastName.charAt(0).toUpperCase() + lastName.slice(1);

    await axios
      .put(
        '/api/',
        {
          firstName: capitalizedFirstName,
          lastName: capitalizedLastName,
          email,
          image: profilePicture,
        },
        {
          headers: {
            'x-auth-token': 'Bearer ' + token,
          },
        }
      )
      .catch((err) => {
        toast({
          title: 'Data Updating Error',
          status: 'error',
          isClosable: true,
          duration: 4000,
          position: 'top-right',
        });
      });
  };
  const getUser = async () => {
    const token = _U?.token;
    if (token) {
      await fetchUserData(token)
        .then((user) => {
          setUser((lp) => {
            return {
              ...lp,
              token,
              userData: user?.user,
            };
          });
        })
        .catch((err) => {});
    }
  };

  const createListing = async () => {
    const token = _U?.token;

    const body = {
      uuid: event.uuid,
      sellerId: _U.userData.userId,
      price: price,
      images: images,
      quantity: quantity,
      type: selectedTicketType,
      offerId: selectedListing.offerId || null,
    };
    await axios
      .post('/api/createListing', body, {
        headers: {
          'x-auth-token': 'Bearer ' + token,
        },
      })
      .then((r) => {
        init();
        setSuccessMessage(r?.data?.message);
        navigate(`/resell-dashboard?listed=${r?.data?.message}`);
      })
      .catch((err) => {
        toast({
          title: 'Listing Creation Error',
          status: 'error',
          isClosable: true,
          duration: 4000,
          position: 'top-right',
        });
      });
  };

  const handleBuyNow = (listing) => {
    setPrice(listing.price||100);
    setQuantity(listing.quantity||1);
    const ticketTypesJson = event?.ticketTypes
    ? JSON.parse(event.ticketTypes)
    : [];
    setSelectedTicketType(listing.type ||ticketTypesJson[0]);
    setUploadProofModalOpen(false);
      setOffering(false);
      setSelling(false);
      setText2('Cancel');
    setText1('Checkout');
    setBuyModalOpen(true);  
      };
  const handleOfferBuy = () => {
    setSelectedListings([]);
      setUploadProofModalOpen(false);
      setOffering(true);
      setSelling(false);
      setBuyModalOpen(true);
      setText2('Cancel');
      setText1('Checkout');

  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const handleSell = (offer) => {
    setSelectedListings([]);
      setPrice(offer.price || 100);
      setQuantity(offer.quantity || 1);
      const ticketTypesJson = event?.ticketTypes
      ? JSON.parse(event.ticketTypes)
      : [];
      setSelectedTicketType(offer.type ||ticketTypesJson[0]);
      setUploadProofModalOpen(false);
      setOffering(false);
      setSelling(true);
      setBuyModalOpen(true);
      setText2('Cancel');
      setText1('Upload Proof');
    
  };

  const handleProceed = () => {
    if (isEmpty(_U?.userData)) {
      setRegistrationModal((lp) => {
        return {
          ...lp,
          openModal: true,
          modalType: RegistraionModalTypes.SIGNUP,
        };
      });
      return;
    }

    if (selling) {
      setBuyModalOpen(false);
      setUploadProofModalOpen(true);
    } else  if (offering) {
      setTicket((lp) => {
        return {
          ...lp,
          quantity: quantity,
          price: price,
          selectedEvent: { ...event },
          currency: event.currency,
          total: total,
          type: selectedTicketType,
          expiration: expiration,
          bgColor,
          accentColor,
          offering,
          selectedListings,
          buyerFee,
        };
      });
      navigate(`/checkout/resell/`);
    }  else {
      handleGetYourResellTickets();
    }
  };

  const handleGetYourResellTickets = () => {
    if (isEmpty(_U?.userData)) {
      setRegistrationModal((lp) => {
        return {
          ...lp,
          openModal: true,
          modalType: RegistraionModalTypes.SIGNUP,
        };
      });
      return;
    }
    setOffering(false);
    setTicket((lp) => {
      return {
        ...lp,
        quantity: quantity,
        price: price,
        selectedEvent: { ...event },
        currency: event.currency,
        total: total,
        type: selectedTicketType,
        expiration: expiration,
        bgColor,
        accentColor,
        offering:false,
        selectedListings,
        buyerFee,
      };
    });
    navigate(`/checkout/resell/`);
  }
  const handleCloseUploadProofModal = () => {
    setUploadProofModalOpen(false);
    setBuyModalOpen(true);
  };
  const handleNext = () => {
    setUploadProofModalOpen(false);
    setSellerInfoModalOpen(true);
  };
  const handleCloseSellerInfoModal = () => {
    setSellerInfoModalOpen(false);
    setUploadProofModalOpen(true);
  };

  const handleAddMoreTickets = (listing) => {
    setSelectedListings([...selectedListings, listing]);
    setBuyModalOpen(false);
  }
  const removeTicket = (listing) => {
    setSelectedListings(selectedListings.filter(l => l.listingId !== listing.listingId));
    setBuyModalOpen(false);
  }

  

  const handleViewProof = (listing) => {
    setProofUrl(JSON.parse( listing.images)); // Set proofUrl to listing.images
    setViewProofModalOpen(true); // Open the modal
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    await updateUser();
    await getUser();
    await createListing();
    setSellerInfoModalOpen(false);
    setBuyModalOpen(false);
    setIsLoading(false);
  };

  const handleSendListing = (listing) => {
    // Copy listing URL to clipboard
    navigator.clipboard.writeText(`${window.location.origin}/resell/event/${event?.slug}?listingId=${listing.listingId}`);
    
    // Show success toast
    toast({
      title: "Link copied to clipboard",
      status: "success", 
      duration: 3000,
      isClosable: true,
      position: "top-right"
    });

  }
  const handleSendOffer = (listing) => {
    // Copy offer URL to clipboard
    navigator.clipboard.writeText(`${window.location.origin}/resell/event/${event?.slug}?offerId=${listing.offerId}`);
    toast({
      title: "Link copied to clipboard",
      status: "success", 
      duration: 3000,
      isClosable: true,
      position: "top-right"
    });
  }

  const TicketDetails = (
    <div>
      <Box width={'100%'} pos="relative">  

        {!isMobile && !isTablet && (
        <Box
          display="flex"
          width="100%"
          marginBottom="20px"
          alignSelf="stretch"
        >
          <SecuredResell isMobile={isMobile} />
        </Box>
        )}
                <EventInfoComponent
          eventName={event?.eventName}
          accentColor={accentColor}
          startTime={event?.startTime}
          endTime={event?.endTime}
          startDate={event?.startDate}
          city={event?.city}
          location={event?.location}
          totalTickets={event?.totalTickets}
          available={event?.available}
          hideLikes={true}
          seePerks={event?.seePerks}
          sawPerks={event?.sawPerks}
          isMobile={isMobile}
          moment={moment}
          TimeFormat={TimeFormat}
        />
        {!isEmpty(event?.eventType) && !isMobile && !isTablet && (
          <TagsComponent eventType={event?.eventType} />
        )}
      </Box>
      <Box className="div-separator" />
      

      {!event?.isOver ? (
        <Box width={'100%'}>
          <VStack width={'100%'} margin="0 auto" className="regularcmards">
            <Box
              display="block"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
              className="darkcard"
            >
              <Box display={'flex'} flexDir={'column'} gap={'0.94rem'}>
                <ResellTabs
                  selectedTab={selectedTab}
                  handleTabClick={handleTabClick}
                />
                {selectedTab === 'tickets' ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    gap="16px"
                    alignSelf="stretch"
                  >
                    {event?.listings?.map((listing, index) => (
                      <SellerComponent
                        listing={listing}
                        handleClick={() => {
                          setSelectedListing(listing);
                          handleAddMoreTickets(listing);
                        }}
                        handleRemove={() => {
                          setSelectedListing(listing);
                          removeTicket(listing)
                        }}
                        handleViewProof={() => handleViewProof(listing)}
                        handleSendListing={() => handleSendListing(listing)}
                        isInCart={selectedListings.includes(listing)}
                      />
                    ))}
                  </Box>
                ) : (
                  <Box display="flex" flexDirection="column" gap="112px">
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      gap="16px"
                      alignSelf="stretch"
                    >
                      {event?.offers.map((offer, index) => (
                        <BuyerComponent
                          offer={offer}
                          handleClick={() => {
                            setSelectedListing(offer);
                            handleSell(offer);
                          }}
                          handleSendOffer={() => handleSendOffer(offer)}
                        />
                      ))}
                    </Box>
                    <PlaceBidSection
                      handlePlaceBid={handleOfferBuy}
                      isMobile={isMobile}
                      price={price}
                      setPrice={setPrice}
                      quantity={quantity}
                      setQuantity={setQuantity}
                    />
                  </Box>

                )}
                {(isMobile || isTablet) && (
        <Box
          display="flex"
          width="100%"
          alignSelf="stretch"
          marginTop={'10px'}
        >
          <SecuredResell isMobile={isMobile} />
        </Box>
        )}
                {!isEmpty(event?.eventType) && (isMobile || isTablet) && (
                  <TagsComponent eventType={event?.eventType} />
                )}
                {(isMobile || isTablet) && (
                        <Box>
                      {event?.description && (
                        <DecriptionComponent
                              about={'About'}
                              description={event?.description}
                            />
                          )}
                        
                                                  {!isEmpty(event?.lineup) && (
                                                    <Box justifyContent="center">
                                                       <Box className="div-separator" />
                                                      <LineupComponent
                                                        performers={'Performers'}
                                                        lineup={event?.lineup}
                                                        isTablet={isTablet}
                                                      />
                                                     
                                                    </Box>
                                                  )}
                        
                                                  {!isEmpty(event?.spotifyId) && (
                                                    <Box>
                                                     <Box className="div-separator" />
                                                    <SpotifyComponent
                                                      bestTrack={'Best Track'}
                                                      trackId={trackId}
                                                    />
                                                    </Box>
                                                  )}
                                                   {memoizedComponent}
                                                  </Box>  
                        )}
              </Box>
            </Box>
          </VStack>
        </Box>
      ) : (
        <Box>
          <Text color="red" fontWeight="700">
            NO TICKETS AVAILABLE. EVENT IS OVER
          </Text>
        </Box>
      )}
    </div>
  );

  const CheckoutStickyComponent = () => {
    return (
      <Box
        position={'sticky'}
        bottom={'0'}
        width={'100%'}
        maxW={'1200px'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={isMobile ? '1.25rem' : '0.5rem'}
        bg={bgColor}
        borderTop="1px solid rgba(255, 255, 255, 0.20)"
        backdropFilter={'blur(75px)'}
        borderRadius={'40px 40px 0 0'}
        marginTop={'1.88rem'}
        flexDirection={isMobile ? 'column' : 'column'}
        gap={'1rem'}
      >
        <ResellFeesComponentsMobile setBuyerFee={setBuyerFee} setTotal={setTotal} selectedListings={selectedListings} participant_fee_percentage={event?.participant_fee_percentage} participant_fee_fixed={event?.participant_fee_fixed} fee_percentage={event?.fee_percentage} fee_fixed={event?.fee_fixed} accentColor={accentColor} />
        <GetYourResellTicketsButton handleFunction={handleGetYourResellTickets} />
      </Box>
    );
  };

  return (
    <Box bg={bgColor}>
      <Layout {...props} bgColor={bgColor}>
        {event !== undefined ? (
          pageNotFound ? (
            <PageNotFound />
          ) : (
            <div>
              {openSite ||
              (cookies && cookies[`${event?.eventName}-password`]) ? (
                <div>
                  <BuyModal
                    isOpen={buyModalOpen}
                    onClose={() => {setBuyModalOpen(false); setDirectLink(false)}}
                    isMobile={isMobile}
                    offering={offering}
                    setOffering={setOffering}
                    selling={selling}
                    text1={text1}
                    text2={text2}
                    handleFunction2={() => {setBuyModalOpen(false); setDirectLink(false)}}
                    handleFunction1={handleProceed}
                    price={price}
                    setPrice={setPrice}
                    quantity={quantity}
                    setQuantity={setQuantity}
                    participant_fee_percentage={
                      event?.participant_fee_percentage
                    }
                    participant_fee_fixed={event?.participant_fee_fixed}
                    fee_percentage={event?.fee_percentage}
                    fee_fixed={event?.fee_fixed}
                    selectedListing={selectedListing}
                    setSelectedListing={setSelectedListing}
                    ticketTypes={event?.ticketTypes}
                    selectedTicketType={selectedTicketType}
                    setSelectedTicketType={setSelectedTicketType}
                    timezone={event?.timezone}
                    expiration={expiration}
                    setExpiration={setExpiration}
                    endDate={event?.endDate}
                    startDate={event?.startDate}
                    setTotal={setTotal}
                    total={total}
                    setBuyerFee={setBuyerFee}
                    setSellerFee={setSellerFee}
                    platform={event?.platform}
                    directLink={directLink}
                  />
                  <UploadProofModal
                    isOpen={uploadProofModalOpen}
                    onClose={handleCloseUploadProofModal}
                    isMobile={isMobile}
                    handleNext={handleNext}
                    images={images}
                    setImages={setImages}
                    directLink={directLink}
                  />
                  <ViewProofModal
                    isOpen={viewProofModalOpen}
                    onClose={() => setViewProofModalOpen(false)}
                    isMobile={isMobile}
                    proofUrl={proofUrl}
                  />
                  <SellerInfoModal
                    isOpen={sellerInfoModalOpen}
                    onClose={handleCloseSellerInfoModal}
                    isMobile={isMobile}
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    email={email}
                    setEmail={setEmail}
                    profilePicture={profilePicture}
                    setProfilePicture={setProfilePicture}
                    total={total}
                    handleSubmit={handleSubmit}
                    isLoading={isLoading}
                    directLink={directLink}
                  />
                  <Box className={'event-details-wrapper'}>
                    <Box className={'event-left-components'}>
                      <Box
                        display="flex"
                        width="100%"
                        flexDirection="column"
                        alignItems="flex-start"
                        gap="28px"
                        marginBottom={!isMobile ? '200px' : '0px'}
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                          gap="24px"
                          alignSelf="stretch"
                        >
                          <EventImageComponent
                            extraPictures={event?.extraPictures}
                            isTablet={isTablet}
                            eventImage={event?.eventImage}
                          />
                          {!isMobile && !isTablet && !event?.isOver && (
                            <BuySellTicketsButton
                              handleFunction1={handleOfferBuy}
                              handleFunction2={() => {
                                setSelectedListing(event?.offers[0] || {});
                                handleSell(event?.offers[0] || {});
                              }}
                              isMobile={isMobile}
                              text1={`Place A Bid`}
                              text2={`Sell`}
                            />
                          )}
                        </Box>
                        <SecondaryMarketFooter isMobile={isMobile} />
                        {!isMobile && !isTablet && (
                          <Box width={'100%'}>
                            {event?.description && (
                              <Box>
                                <DecriptionComponent
                                  about={'About'}
                                  description={event?.description}
                                />
                              </Box>
                            )}

                            {!isEmpty(event?.lineup) && (
                              <Box justifyContent="center">
                                <Box className="div-separator" />
                                <LineupComponent
                                  performers={'Performers'}
                                  lineup={event?.lineup}
                                  isTablet={isTablet}
                                />
                              </Box>
                            )}

                            {!isEmpty(event?.spotifyId) && (
                              <Box>
                                <Box className="div-separator" />
                                <SpotifyComponent
                                  bestTrack={'Best Track'}
                                  trackId={trackId}
                                />
                              </Box>
                            )}
                             {memoizedComponent}
                          </Box>
                        )}
                      </Box>

                      {(isMobile || isTablet) && (
                        <>
                          <Box margin={'1.88rem 0'}>{TicketDetails}</Box>
                        </>
                      )}
                    </Box>

                    <Box className={'event-separator'} />

                    {!isMobile && !isTablet && (
                      <Box
                        marginBottom="100px"
                        className={'event-right-components'}
                      >
                        {TicketDetails}
                        {selectedListings.length > 0 && <CheckoutStickyComponent />}
                      </Box>
                    )}
                  </Box>
                </div>
              ) : (
                <Box
                  height="100vh"
                  bg={bgColor}
                  overflow="hidden"
                  display="flex"
                  alignItems="center"
                  margin={'0 20px'}
                >
                  <Box
                    w="100%"
                    bg={bgColor}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box w="100%" maxW="350px">
                      <Text color="white.100" className="gordita400" mb={10}>
                        Event Password
                      </Text>
                      <Input
                        w="100%"
                        h="56px"
                        className="gordita400"
                        borderRadius="0.625rem"
                        bg="#000000"
                        border="1px solid rgba(255, 255, 255, 0.20)"
                        borderColor={
                          error ? '#FF6B6B' : 'rgba(255, 255, 255, 0.20)'
                        }
                        color="#FFFFFF"
                        placeholder={'Enter Event Password'}
                        focusBorderColor={error ? '#FF6B6B' : '#FFFFFF'}
                        _hover={{
                          borderColor: error ? '#FF6B6B' : '#FFFFFF !important',
                        }}
                        _placeholder={{
                          color: 'rgba(255, 255, 255, 0.40)',
                        }}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                      />
                      {error && password !== event?.eventPassword && (
                        <Text color="red" className="gordita400" mb={10}>
                          Incorrect Password
                        </Text>
                      )}
                      <Box mt={12}>
                        <Button
                          onClick={() => {
                            if (password === event?.eventPassword) {
                              setOpenSite(true);

                              // Construct the path with or without the tracking query parameter based on trackingId
                              const pathWithTracking = trackingId
                                ? `/event/${event?.slug}?tracking=${trackingId}`
                                : `/event/${event?.slug}`;

                              // Set the cookie with the constructed path
                              setCookie(
                                `${event?.eventName}-password`,
                                password,
                                {
                                  path: '/',
                                }
                              );
                            } else {
                              setError(true);
                            }
                          }}
                          fontWeight="700"
                          textAlign="center"
                          fontSize="1rem"
                          borderRadius="40px"
                          outline="none"
                          sx={theme.buttons.primary.shape}
                          bg={theme.buttons.primary.default}
                          color={theme.buttons.primary.color}
                          className="gordita700"
                          css={{
                            width: '100%',
                          }}
                          _disabled={{
                            bg: theme.buttons.primary.disabled,
                            color: theme.buttons.primary.colorDisabled,
                          }}
                          disabled={!password}
                          cursor={'pointer'}
                        >
                          Access
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </div>
          )
        ) : (
          <div
            style={{
              margin: '0 auto',
              fontSize: '2rem',
              fontWeight: '700',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '90vh',
            }}
          >
            Loading...
          </div>
        )}
      </Layout>
      {(isTablet || isMobile) &&
        (openSite || (cookies && cookies[`${event?.eventName}-password`])) && (
          selectedListings.length > 0 ? (
            <CheckoutStickyComponent />
          ) : (
            <Box
              position={'sticky'}
              bottom={'0'}
              width={'100%'}
              maxW={'1200px'}
              display={'flex'}
              gap={'0.62rem'}
              flexDir={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              borderRadius={'40px 40px 0 0'}
              bg={bgColor}
              padding={'1.25rem'}
              borderTop="1px solid rgba(255, 255, 255, 0.20)"
              backdropFilter={'blur(75px)'}
            >
              {!event?.isOver && (
                <BuySellTicketsButton
                  handleFunction1={handleOfferBuy}
                  handleFunction2={() => {
                    setSelectedListing(event?.offers[0] || {});
                    handleSell(event?.offers[0] || {});
                  }}
                  isMobile={isMobile}
                  text1={`Place A Bid`}
                  text2={`Sell`}
                />
              )}
            </Box>
          )
        )}
    </Box>
  );
}
