import {
  Box,
  Button,
  Divider,
  HStack,
  Text,
  VStack,
  Input,
  chakra,
  useToast,
  Image,
  InputGroup,
  InputRightElement,
  IconButton,
  Center,
} from "@chakra-ui/react";
import { LoginVerifierSchema } from "../../../utils/schema";
import { Formik, Field, Form } from "formik";
import { google } from "../../../static/assets/images";
import {
  RegistraionModalTypes,
  registration,
} from "../../../recoil/atoms/registration";
import { user } from "../../../recoil/atoms/user";
import { useRecoilState } from "recoil";
import {
  fetchUser,
  loginWithGoogle,
  fetchVerifier,
} from "../../../utils/actions/registration";
import { Navigate, useNavigate } from "react-router-dom";
import { userRoles } from "../../../utils/constants";
import { useGoogleLogin } from "@react-oauth/google";
import { useCookies } from "react-cookie";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { useMediaQuery } from "../../../utils/useMediaQuery";
import { theme } from "../../../styles/theme/base";

const initialValues = { password: "" };

const CustomToast = chakra("div", {
  baseStyle: {
    zIndex: "modal",
  },
});
/**
 * The Login function is a React component that handles user login, including email and password
 * authentication as well as Google login.
 * @returns The Login component is being returned.
 */

const Verifier = () => {
  const [_, setRegistrationModal] = useRecoilState(registration);
  const [u, setUser] = useRecoilState(user);
  const toast = useToast();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["x-auth-token"]);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [showContent, setShowContent] = useState(false);

  const handleLoginVerifier = async (values) => {
    console.log("getuser");
    const getUser = await fetchVerifier({ ...values });
    console.log("getuser", getUser);
    if (getUser.success === false) {
      toast({
        title: "Login Error",
        description: getUser.message,
        status: "error",
        isClosable: true,
        duration: 4000,
        position: "top-right",
        zIndex: 2000, // Adjust the zIndex value as needed
      });
    } else {
      localStorage.setItem("user_d", JSON.stringify(getUser.user));
      localStorage.setItem("x-auth-token", getUser.token);

      setUser((lp) => {
        return {
          ...lp,
          token: getUser.token,
          userData: getUser.user,
        };
      });
      setRegistrationModal((lp) => {
        return {
          ...lp,
          openModal: false,
          modalType: "",
          userData: {},
          userRole: "",
        };
      });
      navigate("/verifier/selectevent");
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box w="100%">
      <VStack gap={50}>
        <Text className="gordita700" fontSize={{ base: 18, md: 24 }}>
          Verifier Log In
        </Text>
        {!showContent ? (
          <>
                  <Button
sx={theme.buttons.primary.shape}
bg={theme.buttons.secondary.bgColor}
color={theme.buttons.secondary.color}
css={{
  width: "100%",
}}
_hover={{
  borderColor: theme.buttons.secondary.hoverBorderColor,
}}
_active={{
  bg: theme.buttons.secondary.pressed,
}}
border={theme.buttons.secondary.border}
className="gordita700"
onClick={() => {
  window.open("https://apps.apple.com/us/app/portalys-access/id6476797013");
}}
                  >
                    iOS App
                  </Button>

                  <Button
sx={theme.buttons.primary.shape}
bg={theme.buttons.secondary.bgColor}
color={theme.buttons.secondary.color}
css={{
  width: "100%",
}}
_hover={{
  borderColor: theme.buttons.secondary.hoverBorderColor,
}}
_active={{
  bg: theme.buttons.secondary.pressed,
}}
border={theme.buttons.secondary.border}
className="gordita700"
onClick={() => {
  window.open("https://play.google.com/store/apps/details?id=com.portalys.access&pcampaignid=web_share");
}}
                  >
                    Android App
                  </Button>
                 <Button
                    sx={theme.buttons.primary.shape}
                    bg={theme.buttons.secondary.bgColor}
                    color={theme.buttons.secondary.color}
                    css={{
                      width: "100%",
                      textOverflow:"ellipsis"
                    }}
                    _hover={{
                      borderColor: theme.buttons.secondary.hoverBorderColor,
                    }}
                    _active={{
                      bg: theme.buttons.secondary.pressed,
                    }}
                    border={theme.buttons.secondary.border}
                    className="gordita700"
                    onClick={() => {
                      setShowContent(true);
                    }}
                    
                  >
                    Web (Not Recommended)
                  </Button>
        </>
        ):(

        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            await handleLoginVerifier(values);
          }}
        >
          {(formik) => {
            const { handleSubmit, errors, touched } = formik;
            return (
              <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <VStack
                  mt={16}
                  px={{ base: 0, md: 36 }}
                  alignItems="flex-start"
                >
                  <Text fontWeight={400} fontSize="0.75rem" pl={"1.5rem"}>
                    Enter Code
                  </Text>
                  <Field
                    type="password"
                    name="password"
                    id="password"
                    render={({ field }) => (
                      <InputGroup>
                        <Input
                          placeholder="Enter Your Password"
                          fontSize={14}
                          {...field}
                          border="1px solid"
                          borderRadius="6.25rem"
                          height="3.375rem"
                          type={showPassword ? "text" : "password"}
                          borderColor="rgba(255, 255, 255, 0.20)"
                          color="#FFFFFF"
                          focusBorderColor="#FFFFFF"
                          marginTop={"-0.30rem"}
                          _hover={{
                            borderColor: "#FFFFFF !important",
                          }}
                          _placeholder={{
                            color: "rgba(255, 255, 255, 0.40)",
                          }}
                          h="48px"
                        />
                        <InputRightElement>
                          <IconButton
                            mr={3}
                            borderRadius={"50%"}
                            backgroundColor={"transparent"}
                            onClick={handleShowPassword}
                            icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                          />
                        </InputRightElement>
                      </InputGroup>
                    )}
                  />
                  {errors.password && touched.password && (
                    <Text
                      color="red"
                      className="gordita400"
                      fontWeight={400}
                      fontSize={14}
                    >
                      {errors.password}
                    </Text>
                  )}
                </VStack>
                <VStack w="100%">
                  <Text mt={24} color="#FF5C00" fontWeight={700}>
                    Forgot Code? Contact Event Organizer
                  </Text>
                </VStack>
                <Box px={{ base: 0, md: 36 }} mt={24} w="100%">
                  <Button
                    color="white.100"
                    bg="primary.100"
                    type="submit"
                    className="gordita700"
                    w="100%"
                    borderRadius="48px"
                    h="65px"
                    _hover={{
                      bg: "primary.100",
                    }}
                  >
                    Log In
                  </Button>

                  <Button
                  marginTop={"15px"}
                    sx={theme.buttons.primary.shape}
                    bg={theme.buttons.secondary.bgColor}
                    color={theme.buttons.secondary.color}
                    css={{
                      width: "100%",
                      textOverflow:"ellipsis"
                    }}
                    _hover={{
                      borderColor: theme.buttons.secondary.hoverBorderColor,
                    }}
                    _active={{
                      bg: theme.buttons.secondary.pressed,
                    }}
                    border={theme.buttons.secondary.border}
                    className="gordita700"
                    onClick={() => {
                      setShowContent(false);
                    }}
                    
                  >
                    Back to List
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
        )}
      </VStack>
    </Box>
  );
};

export default Verifier;
