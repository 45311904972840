import React, { useState, useEffect } from "react";
import { QrReader } from "react-qr-reader";
import { useParams } from "react-router-dom"; // Import useParams if you're using React Router
import { useNavigate } from "react-router-dom";
import { userRoles } from "../../utils/constants";
import withAuth from "../../utils/withAuth";
import { theme } from "../../styles/theme/base";
import axios from "../../utils/api/axios";
import {
  VStack,
  Box,
  Input,
  Text,
  HStack,
  Button,
  Divider,
  IconButton,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

const filterAttendees = (attendees, searchQuery) => {
  const filteredAttendees = attendees?.filter((attendee) =>
    attendee?.fullName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Sort the filtered attendees alphabetically by fullName
  return filteredAttendees?.sort((a, b) => a?.fullName.localeCompare(b?.fullName));
};

/**
 * The Settings function is a React component that renders a form for users to update their profile
 * information, including their name, email, website, social media links, and profile image.
 * @param props - An object containing the properties passed down to the Settings component.
 * @returns A functional component named "Settings" is being returned.
 */
function ListAttendee(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [tickets, setTickets] = useState([]);
  const token = localStorage.getItem("x-auth-token");

  const [data, setData] = useState("");
  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();
  const fetchNFTs = async (eventuuid) => {
    if (token) {
      try {
        const response = await axios.get("/api/getAttendeesList", {
          headers: {
            "x-auth-token": token,
          },
          params: {
            eventUuid: eventuuid,
          },
        });
        console.log("tickets", response.data.filteredNfts);
        setTickets(response.data.filteredNfts);
      } catch (error) {
        console.error("Error fetching NFTs:", error);
      }
    }
  };

  useEffect(() => {
    const { id } = params;

    fetchNFTs(id);
  }, [token]);

  const handleBoxClick = (walletAddress, id) => {
    const encodedwallet = encodeURIComponent(walletAddress);
    const encodedId = encodeURIComponent(id);
    navigate(`/verifier/validate/${encodedwallet}/${encodedId}`);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const filteredAttendees = filterAttendees(tickets, searchQuery);

  return (
    <VStack
      spacing={4}
      alignItems="center" // Center vertically
      justifyContent="center" // Center horizontally
      minHeight="100vh" // Ensure full viewport height
      backgroundColor="#000000"
    >
      <Input
        placeholder="Search attendees"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        leftIcon={<SearchIcon />}
        bg="#333333"
        color="white"
        borderColor="gray.600"
        _hover={{ borderColor: "gray.400" }}
      />
      <Divider bg="gray.600" />
      <Box
        overflowY="auto"
        maxHeight="60vh"
        width="100%"
        paddingY={2}
        paddingRight={2}
      >
        {filteredAttendees?.map((attendee) => (
          <Box
            key={attendee.walletAddress}
            sx={theme.Header.shape}
            style={{ cursor: "pointer" }}
            justifyContent="center"
            bg="#000000"
            onClick={() => handleBoxClick(attendee.walletAddress, id)}
            padding={2}
            _hover={{ backgroundColor: "#333333" }}
          >
            <HStack justifyContent="space-between" width="100%">
              <Text
                fontFamily="Gordita"
                fontSize="1rem"
                fontWeight="400"
                color={"rgba(255, 255, 255, 0.60)"}
              >
                {attendee?.fullName}
              </Text>
            </HStack>
          </Box>
        ))}
      </Box>
      <Button
        sx={{
          borderRadius: `100px`,
          display: `flex`,

          isolation: `isolate`,
          flexDirection: `row`,
          justifyContent: `center`,
          alignItems: `center`,
          padding: `15px 30px`,
          boxSizing: `border-box`,
          overflow: `hidden`,
          height: `43px`,
          width: `180px`,
        }}
        bg={theme.buttons.primary.default}
        color={theme.buttons.primary.color}
        _hover={{
          bg: theme.buttons.primary.hover,
          boxShadow: theme.buttons.primary.hoverBoxShadow,
        }}
        _active={{
          boxShadow: theme.buttons.primary.activeBoxShadow,
        }}
        position="fixed"
        bottom="4"
        right="4"
        onClick={() => {
          // Pass the selected event object to the next screen
          navigate(`/verifier/scanner/${id}`);
        }}
      >
        Back to Scanning
      </Button>
    </VStack>
  );
}

export default withAuth(ListAttendee, [userRoles.VERIFIER]);
