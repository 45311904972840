import {
    Box,
    HStack,
    Text,
    VStack,
    Image,
    chakra,
    useToast,
    Spinner,
    IconButton,
  } from "@chakra-ui/react";
  import { exportSvg } from "../../../../static/assets/images/dashboard/sidebar";
  import secrets from "../../../../secrets";
  import axios from "../../../../utils/api/axios";
  import { useRef, useState } from "react";
  import { DeleteIcon } from "@chakra-ui/icons";
  import { Button } from "antd";
  import { theme } from "../../../../styles/theme/base";
  import deleteIcon from "../../../../static/assets/images/deleteIcon.svg";
import { isEmpty } from "lodash";
  
  const ExtraPictures = (props) => {
    const { values, setFieldValue } = props;
    const [image, setImage] = useState(null);
    const [dragging, setDragging] = useState(false);
    const [loading, setLoading] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const dropRef = useRef(null);
    const [images, setImages] = useState([]);

  
    const handleDragEnter = (e) => {
      e.preventDefault();
    };
  
    const handleDragOver = (e) => {
      e.preventDefault();
    };
  
    const handleDrop = (e) => {
        e.preventDefault();
        if (e.dataTransfer.files) uploadEventImages(e.dataTransfer.files[0]);
      };
  
    const handleMouseDown = (e) => {
      setDragging(true);
      const initialX = e.clientX - position.x;
      const initialY = e.clientY - position.y;
  
      const handleMouseMove = (e) => {
        setPosition({
          x: e.clientX - initialX,
          y: e.clientY - initialY,
        });
      };
  
      document.addEventListener("mousemove", handleMouseMove);
  
      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
        setDragging(false);
      };
  
      document.addEventListener("mouseup", handleMouseUp);
    };
    const uploadEventImages = async (images) => {
        setLoading(true);
        const existingImageUrls = values.extraPictures ? values.extraPictures.split(",") : [];

      
        try {
          const imageUrls = [];
      
          for (let i = 0; i < images.length; i++) {
            const fd = new FormData();
            fd.append("image", images[i]);
            fd.append("type", "event");
      
            const response = await axios.post("/api/uploadImage", fd,{
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
            
            if (response.data.status) {
              imageUrls.push(response.data.link);
            } else {
              setLoading(false);
              toast({
                title: "Cover Uploading Error",
                status: "error",
                isClosable: true,
                duration: 4000,
                position: "top-right",
              });
              return;
            }
          }
          const combinedImageUrls = [...existingImageUrls, ...imageUrls];
          // Update the state or perform any action with the imageUrls
          setFieldValue("extraPictures", combinedImageUrls.join(","));
          console.log('extrap',values.extraPictures);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          toast({
            title: "Cover Uploading Error",
            status: "error",
            isClosable: true,
            duration: 4000,
            position: "top-right",
          });
        }
      };
      const handleDeleteImage = (index) => {
        const updatedImages = values.extraPictures ? values.extraPictures.split(",") : [];
        updatedImages.splice(index, 1);
        setFieldValue("extraPictures", updatedImages.join(","));
      };
      
    return (
      <VStack
        mt={"2rem"}
        pb={"1rem"}
        pos={"relative"}
        alignItems="flex-start"
        w="100%"
        // bg="red"
      >
        {loading && (
          <Box
            pos="absolute"
            h="100%"
            w="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Spinner colorScheme="#02F6AD" size="xl" />
          </Box>
        )}
        <HStack w="100%">
          <Box
            ref={dropRef}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
           // onDrop={handleDrop}
            w="100%"
          >
            <Text
        fontSize={"1.25rem"}
        className="gordita700"
        textTransform={"capitalize"}
      >
             Images 
            </Text>
  

  <Box>
    <input
      style={{ display: "none" }}
      id="eventImages"
      type="file"
      accept=".jpg,.jpeg,.png"
      multiple
      onChange={(e) => {
        const selectedImages = Array.from(e.target.files);
        setImages(selectedImages);
        uploadEventImages(selectedImages); 
      }}
    />
    <label htmlFor="eventImages">
    <Box          
          sx={theme.buttons.secondary.shape}
          bg={theme.buttons.secondary.bgColor}
          color={theme.buttons.secondary.color}
          _hover={{
            borderColor: theme.buttons.secondary.hoverBorderColor,
          }}
          _active={{
            bg: theme.buttons.secondary.pressed,
          }}
          style={{
            width: "100%",
            marginTop: "1.56rem",
            textTransform: "capitalize",
          }}
          className="gordita700"
        >
         Add Images
        </Box>
        <Box>
          <Text
            marginTop={"1.56rem"}
            className="gordita400"
            fontSize={16}
            textAlign="left"
            w="100%"
            color="rgba(255, 255, 255, 0.60)"
          >
            You have the option to add additional images, which will be displayed as a carousel on the cover image. The image recommendations are the same that for the cover.
          </Text>
        </Box>
    </label>
  </Box>

  {!isEmpty(values.extraPictures) && (
              <Box
              onMouseDown={handleMouseDown}
              w="100%"
              h="100%"
              bg="#000000"
              borderRadius="40px"
              border="1px solid #FFFFFF33"
              p="15px"
            >
    <Box w="100%" minH="320px" display="flex" flexWrap="wrap">
      {values.extraPictures.split(',').map((imageUrl, index) => (
        <Box key={index} w="calc(33.33% - 20px)" m="10px">
          <Box w="100%" bg="#141414" borderRadius="8px" p="20px">
            <Box
              w="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              h="100%"
              borderRadius="8px"
            >
              <Box position="relative" w="100%">
                <Box w="100%">
                  <Image w="100%" h="100%" src={imageUrl.trim()} />
                </Box>
                <IconButton
                  icon={<DeleteIcon />}
                  aria-label="Delete image"
                  position="absolute"
                  color="red"
                  top={2}
                  right={2}
                  zIndex={1}
                  onClick={() => handleDeleteImage(index)}
                  size="lg"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  </Box>
)}

            </Box>
        </HStack>
      </VStack>
    );
  };
  
  export default ExtraPictures;
  