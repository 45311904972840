import {
  Box,
  Button,
  Divider,
  HStack,
  Text,
  VStack,
  Input,
  chakra,
  useToast,
  Image,
  InputGroup,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import { PasswordSchema } from "../../../utils/schema";
import { Formik, Field, Form } from "formik";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  RegistraionModalTypes,
  registration,
} from "../../../recoil/atoms/registration";
import { useRecoilState } from "recoil";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useState } from "react";
import {
  addNewUser,
  changePassword,
} from "../../../utils/actions/registration";
const initialValues = { password: "", confirmPassword: "" };
const Password = () => {
  const [_, setRegistrationModal] = useRecoilState(registration);
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const toast = useToast();

  /**
   * This function handles setting a new password or creating a new user account, depending on the
   * modal type.
   * @param values - The `values` parameter is an object that contains the form values submitted by the
   * user. It likely includes a `password` field, which is used in the function to update or create a
   * user account.
   */
  const handlePasswordSet = async (values) => {
    if (_.modalType === RegistraionModalTypes.RESET_PASSWORD) {
      const token = searchParams.get("token");
      const payload = {
        token,
        password: values.password,
      };
      const chngPassword = await changePassword(payload);
      if (!chngPassword.success) {
        toast({
          title: "Reset Password",
          description: chngPassword.message,
          status: "error",
          isClosable: true,
          duration: 4000,
        });
      } else {
        toast({
          title: "Password Updated Successfully",
          status: "success",
          isClosable: true,
          duration: 4000,
          position: "bottom-right",
        });
        setRegistrationModal((lp) => {
          return {
            ...lp,
            openModal: false,
            modalType: "",
            userData: {},
            userRole: "",
          };
        });
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    } else {
      const payload = {
        email: _.userData?.email,
        password: values.password,
        userRole: _.userRole,
      };
      const addUser = await addNewUser(payload);
      if (!addUser.success) {
        toast({
          title: "Account Creation Error",
          description: addUser.message,
          status: "error",
          isClosable: true,
          duration: 4000,
        });
      } else {
        toast({
          title: "Check your email to complete verification process",
          status: "success",
          isClosable: true,
          duration: 1500,
          position: "top-right",
        });
        setRegistrationModal((lp) => {
          return {
            ...lp,
            openModal: true,
            modalType: RegistraionModalTypes.CHECK_EMAIL,
            userData: {},
          };
        });
      }
    }
  };
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  /* This is a functional component in JavaScript that returns a JSX element. The component is called
 `Password` and it contains a form for setting a new password. It uses the `Formik` library for form
 management and validation, and the `chakra-ui` library for styling. The form has two input fields
 for the password and confirm password, and a submit button. When the form is submitted, the
 `handlePasswordSet` function is called to handle the password update or user creation, depending on
 the modal type. The component exports the `Password` component as the default export. */
  return (
    <Box w="100%">
      <VStack gap={50}>
        <Text className="gordita700" fontSize={{ base: 18, md: 24 }}>
          Account password{" "}
        </Text>
        <Formik
          initialValues={initialValues}
          validationSchema={PasswordSchema}
          onSubmit={async (values) => {
            await handlePasswordSet(values);
          }}
        >
          {(formik) => {
            const { handleSubmit, errors, touched } = formik;
            return (
              <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <VStack px={{ base: 0, md: 36 }} alignItems="flex-start">
                  <Text fontWeight={400} fontSize="0.75rem" pl={"1.5rem"}>
                    New Password
                  </Text>

                  <Field
                    type="password"
                    name="password"
                    id="password"
                    render={({ field }) => (
                      <InputGroup>
                        <Input
                          placeholder="Enter Password"
                          fontSize={14}
                          {...field}
                          border="1px solid"
                          borderRadius="6.25rem"
                          height="3.375rem"
                          borderColor="rgba(255, 255, 255, 0.20)"
                          type={showPassword ? "text" : "password"}
                          // type="text"
                          marginTop={"-0.30rem"}
                          focusBorderColor="#FFFFFF"
                          color="#FFFFFF"
                          _hover={{
                            borderColor: "#FFFFFF !important",
                          }}
                          _placeholder={{
                            color: "rgba(255, 255, 255, 0.40)",
                          }}
                          h="48px"
                        />
                        <InputRightElement>
                          <IconButton
                            mr={3}
                            borderRadius={"50%"}
                            backgroundColor={"transparent"}
                            onClick={handleShowPassword}
                            icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                          />
                        </InputRightElement>
                      </InputGroup>
                    )}
                  />
                  {errors.password && touched.password && (
                    <Text
                      color="red"
                      className="gordita400"
                      fontWeight={400}
                      fontSize={14}
                    >
                      {errors.password}
                    </Text>
                  )}
                </VStack>
                <VStack
                  mt={36}
                  px={{ base: 0, md: 36 }}
                  alignItems="flex-start"
                >
                  <Text fontWeight={400} fontSize="0.75rem" pl={"1.5rem"}>
                    Confirm Password{" "}
                  </Text>
                  <Field
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    render={({ field }) => (
                      <InputGroup>
                        <Input
                          placeholder="Enter Confirm Password"
                          fontSize={14}
                          {...field}
                          border="1px solid"
                          borderRadius="6.25rem"
                          height="3.375rem"
                          borderColor="rgba(255, 255, 255, 0.20)"
                          marginTop={"-0.30rem"}
                          type={showPassword ? "text" : "password"}
                          color="#FFFFFF"
                          focusBorderColor="#FFFFFF"
                          _hover={{
                            borderColor: "#FFFFFF !important",
                          }}
                          _placeholder={{
                            color: "rgba(255, 255, 255, 0.40)",
                          }}
                          h="48px"
                        />
                        <InputRightElement>
                          <IconButton
                            mr={3}
                            borderRadius={"50%"}
                            backgroundColor={"transparent"}
                            onClick={handleShowPassword}
                            icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                          />
                        </InputRightElement>
                      </InputGroup>
                    )}
                  />
                  {errors.confirmPassword && touched.confirmPassword && (
                    <Text
                      color="red"
                      className="gordita400"
                      fontWeight={400}
                      fontSize={14}
                    >
                      {errors.confirmPassword}
                    </Text>
                  )}
                </VStack>
                <Box pb={56} px={{ base: 0, md: 36 }} mt={24} w="100%">
                  <Button
                    color="white.100"
                    bg="primary.100"
                    type="submit"
                    className="gordita700"
                    w="100%"
                    borderRadius="48px"
                    h="65px"
                    _focus={{
                      color: "white.100",
                      bg: "primary.100",
                      borderRadius: "48px",
                    }}
                    _active={{
                      color: "white.100",
                      bg: "primary.100",
                      borderRadius: "48px",
                    }}
                  >
                    Set Your Account Password{" "}
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </VStack>
    </Box>
  );
};

export default Password;
