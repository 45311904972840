import { Box, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Input, Text, useToast } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import axios from "../../../../../utils/api/axios";
import { theme } from "../../../../../styles/theme/base";

const EditListModal = ({ isOpen, onClose, listId, currentListName, onListUpdated }) => {
  const [listName, setListName] = useState(currentListName || "");
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    // Reset listName when modal is opened
    if (isOpen) {
      setListName(currentListName || "");
    }
  }, [isOpen, currentListName]);

  const handleUpdate = async () => {
    if (!listName) {
      toast({
        title: "Error",
        description: "List name cannot be empty.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      setLoading(true);
      const response = await axios.put(`/api/update-list/${listId}`, { listName });

      if (response.status === 200) {
        toast({
          title: "Success",
          description: "List updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onListUpdated(listId, listName);
        onClose(); // Close the modal after successful update
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update the list.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent
        bg="black"
        border="1px solid #333333"
        borderColor="#333333"
        borderRadius="1.25rem"
      >
        <ModalHeader>Edit Subscriber List</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box w="100%" spacing={0} alignItems="flex-start">
            <Text
              fontSize={"1.3125rem"}
              mb="8px"
              className="gordita600"
              fontWeight={500}
              textTransform={"capitalize"}
            >
              List Name
            </Text>
            <Input
              placeholder="Enter list name"
              value={listName}
              onChange={(e) => setListName(e.target.value)}
            />
          </Box>
        </ModalBody>
        <ModalFooter>
            <Button
                onClick={
                    onClose
                }
                sx={theme.buttons.tercary.shape}
                bg={theme.buttons.tercary.bgColor}
                color={theme.buttons.tercary.color}
                _hover={{ bg: "red.500" }}
                border="1px solid rgba(255, 255, 255, 0.20)"
                css={{
                width: "fit-content !important",
                padding: "1.25rem",
                marginRight: "10px",
                }}
            >
                Cancel
            </Button>
            <Button
                onClick={
                    handleUpdate
                }
                sx={theme.buttons.tercary.shape}
                bg={theme.buttons.primary.default}
                color={theme.buttons.primary.color}
                _hover={{ bg: theme.buttons.primary.hover,
                    boxShadow: theme.buttons.primary.hoverBoxShadow }}
                _active={{
                    boxShadow: theme.buttons.primary.activeBoxShadow,
                    }}
                border="1px solid rgba(255, 255, 255, 0.20)"
                css={{
                width: "fit-content !important",
                padding: "1.25rem",
                marginRight: "10px",
                }}
                isLoading={loading}
                disabled={loading || !listName}
            >
                Update
            </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditListModal;
