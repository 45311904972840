import {
    Box,
    Heading,
    Text,
    VStack,
    HStack,
    Flex
  } from "@chakra-ui/react";

import getSymbolFromCurrency from "currency-symbol-map";
const calculateServiceFees = (ticketsTotal, perksTotal, ticketSelected, participant_fee_percentage, participant_fee_fixed, noOfTickets, currency) => {
    if (ticketsTotal) {
        const baseAmount = ticketsTotal + perksTotal;
        const percentageFee = participant_fee_percentage / 100;
        const fixedFee =  participant_fee_fixed * noOfTickets;

        const serviceFee = (baseAmount * percentageFee) + fixedFee;
        return `${getSymbolFromCurrency(currency)}${serviceFee.toFixed(2)}`;
    } else if (perksTotal) {
        const serviceFee = (perksTotal * participant_fee_percentage / 100) + (participant_fee_fixed * 1);
        return `${getSymbolFromCurrency(currency)}${serviceFee.toFixed(2)}`;
    } else {
        return `${getSymbolFromCurrency(currency)}0`;
    }
};
const calculateTotalPrice = (ticketsTotal, perksTotal, ticketSelected, participant_fee_percentage, participant_fee_fixed, noOfTickets, donationAmount, isChecked, currency) => {
    const baseAmount = ticketsTotal + perksTotal;
    const percentageFee =  participant_fee_percentage / 100;
    const fixedFee =  participant_fee_fixed * noOfTickets;

    const total = donationAmount + baseAmount + (baseAmount * percentageFee) + fixedFee;
    const totalPrice = isChecked ? Math.ceil(total) : total.toFixed(2);
    
    if (ticketsTotal || perksTotal) {
        return `${getSymbolFromCurrency(currency)} ${totalPrice}`;
    } else {
        return '0';
    }
};
export const FeesComponentsDesktop = ({
    noOfTickets,
    noOfperks,
    serviceFeesText,
    currency,
    ticketsTotal,
    perksTotal,
    ticketSelected,
    perkSelected,
    participant_fee_percentage,
    participant_fee_fixed,
    totalText,
    accentColor,
    isChecked,
    donationAmount,
      }) => {
    const serviceFees = calculateServiceFees(ticketsTotal, perksTotal, ticketSelected, participant_fee_percentage, participant_fee_fixed, noOfTickets, currency);
    const totalPrice = calculateTotalPrice(ticketsTotal, perksTotal, ticketSelected, participant_fee_percentage, participant_fee_fixed, noOfTickets, donationAmount, isChecked, currency);

return(
        <VStack
        width={"100%"}
        margin="1.5rem auto"
        display="flex"
        className="regularcards"
        >
        <Box width={"100%"} className="darkcard">
        <Box>
            {(noOfTickets>0 || noOfperks>0)  && (
            <Box
            borderTop={"1px solid rgba(255, 255, 255, 0.10)"}
            borderBottom={"1px solid rgba(255, 255, 255, 0.10)"}
            padding={"1.31rem 0"}
            >
            <HStack
                display="flex"
                justifyContent="space-between"
                marginTop="12px"
            >
                <Text
                color="rgba(255, 255, 255, 0.60)"
                fontSize="1rem"
                className="gordita400"
                >
                {serviceFeesText}
                </Text>
                <Text color="#FFFFFF" fontSize="15px">
                {serviceFees}

                </Text>
            </HStack>
            <HStack display="flex" justifyContent="space-between">
                <Text
                color="#FFFFFF"
                fontSize="1.3125rem"
                className="gordita400"
                >
                {totalText} {" "}
        <span style={{ fontSize: "1rem" }}>
        ({noOfTickets}Ticket{perkSelected.length > 0 ? `/${noOfperks}Perk` : ``})
        </span>
                </Text>
                <Text
                fontSize="1.3125rem"
                color={accentColor}
                className="gordita700"
                >
                {totalPrice}
                </Text>
            </HStack>
            </Box>)}
        </Box>
        </Box>
        </VStack>
);
};

export const FeesComponentsMobile = ({
    noOfTickets,
    noOfperks,
    serviceFeesText,
    currency,
    ticketsTotal,
    perksTotal,
    ticketSelected,
    perkSelected,
    participant_fee_percentage,
    participant_fee_fixed,
    totalText,
    accentColor,
    isChecked,
    donationAmount,
      }) => {
    const serviceFees = calculateServiceFees(ticketsTotal, perksTotal, ticketSelected, participant_fee_percentage, participant_fee_fixed, noOfTickets, currency);
    const totalPrice = calculateTotalPrice(ticketsTotal, perksTotal, ticketSelected, participant_fee_percentage, participant_fee_fixed, noOfTickets, donationAmount, isChecked, currency);

return(
    <Box
    width={"100%"}
    display={"flex"}
    justifyContent={"space-between"}
    alignItems={"center"}
    padding={"0 20px"}
    flexDir={"column"}
  >
    <HStack
      width={"100%"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      padding={"0 20px"}
    >
      <Text
        color="rgba(255, 255, 255, 0.60)"
        fontSize="1rem"
        className="gordita400"
      >
         {serviceFeesText}
      </Text>
      <Text color="#FFFFFF" fontSize="15px">
        {serviceFees}

      </Text>
    </HStack>
    <HStack
      width={"100%"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      padding={"0 20px"}
    >
<Text fontSize={"1.375rem"} className="gordita600" textOverflow={"ellipsis"}>
{totalText}
<span style={{ fontSize: "1rem" }}>
({noOfTickets}Ticket{perkSelected.length > 0 ? `/${noOfperks}Perk` : ``})
</span>
</Text>

      <Text
        fontSize="1.3125rem"
        color={accentColor}
        className="gordita700"
      >
              {totalPrice}
      </Text>
    </HStack>
  </Box>
);
};

export const ResellFeesComponentsMobile = ({
  selectedListings,
  participant_fee_percentage,
  participant_fee_fixed,
  accentColor,
  setBuyerFee,
  setTotal,
}) => {
  console.log(selectedListings, 'selectedListings');
  let fee = 0;
  let finalPrice = 0;

  selectedListings.forEach(listing => {
    const { price, quantity } = listing;
    const numPrice = parseFloat(price) || 0;
    const numQuantity = parseInt(quantity, 10) || 0;
    const numParticipantFeePercentage = parseFloat(participant_fee_percentage) || 0;
    const numParticipantFeeFixed = parseFloat(participant_fee_fixed) || 0;

    if (numPrice > 0 && numQuantity > 0) {
      const currentFee = (numPrice * numQuantity * numParticipantFeePercentage) / 100 +
                         numParticipantFeeFixed * numQuantity;
      fee += currentFee;
      finalPrice += numPrice * numQuantity + currentFee;
    }
  });

  fee = fee.toFixed(2);
  finalPrice = finalPrice.toFixed(2);
  setBuyerFee(fee);
  setTotal(finalPrice);
  return (
    <Box
      width={"100%"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      flexDir={"column"}
    >
      <HStack
        width={"100%"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"0 20px"}
      >
        <Text
          color="rgba(255, 255, 255, 0.60)"
          fontSize="1rem"
          className="gordita400"
        >
          Service Fees
        </Text>
        <Text color="#FFFFFF" fontSize="15px">
          {fee}
        </Text>
      </HStack>
      <HStack
        width={"100%"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"0 20px"}
      >
        <Text
          fontSize={"1.375rem"}
          className="gordita600"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          flex="1"
        >
          Total {" "}
          <span style={{ fontSize: "1rem" }}>
            ({selectedListings.length} listing{selectedListings.length > 1 ? 's' : ''} selected)
          </span>
        </Text>
        <Text
          fontSize="1.3125rem"
          color={accentColor}
          className="gordita700"
          flexShrink="0"
        >
          {finalPrice}
        </Text>
      </HStack>
    </Box>
  );
};