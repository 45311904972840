import {
    Box,
    HStack,
    Stack,
    Table,
    Thead,
    Tbody,
    Th,
    Td,
    TableContainer,
    Text,
    Tr,
    Button,
    useToast,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
} from "@chakra-ui/react";
  
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../../utils/api/axios";
import { useRecoilState } from "recoil";
import { user } from "../../../../recoil/atoms/user";
import { theme } from "../../../../styles/theme/base";
import "../sharedAdminStyles.css";
import { DeleteIcon, ViewIcon, EditIcon } from '@chakra-ui/icons'
import { useDropzone } from 'react-dropzone';
import ViewContactsModal from "./ActionModalComponents/ViewContactsModal";
import EditListModal from "./ActionModalComponents/EditListModal";
import DeleteListModal from "./ActionModalComponents/DeleteListModal";
  
const moment = require("moment");

const spacing = {
  gap: 0,
  spacing: 0,
  w: "100%",
};

const ListsComponent = () => {
  const [data2, setData2] = useState([]);
  const navigate = useNavigate();
  const type = window.location.href?.includes("organizers")
    ? "ORGANIZER"
    : "ATTENDEE";
  const [_, setUser] = useRecoilState(user);
  const toast = useToast();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const organizerId = _.userData?.organizerId || _.userData?.userId;
  const [internalName, setInternalName] = useState("");
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedListId, setSelectedListId] = useState(null); // To store the selected list ID
  const [selectedListName, setSelectedListName] = useState(""); // To store the selected list name for editing

  useEffect(() => {
    getOrganizerLists();
  }, [_.userData?.userId]);

  // File drop handler
  const onDrop = (acceptedFiles) => {
    setFile(acceptedFiles[0]); // Take the first uploaded file
    console.log("file", acceptedFiles)
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "text/csv": [".csv"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    },
    maxFiles: 1,
  });

  const handleInternalNameChange = (event) => {
    // Update the internalName state with the new value from the input
    setInternalName(event.target.value);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setFile(null);  // Reset the file selection on cancel
  };


  const handleButtonClick = () => {
    setInternalName("");
    setIsModalOpen(true);
  };

    // Function to upload subscriber list
  const uploadSubscriberList = async (file, listName) => {
  const formData = new FormData();

  // Append file and other fields to the formData
  formData.append("file", file);
  formData.append("listName", listName);
  formData.append("organizerId", organizerId);
  console.log(formData)

  try {
    const response = await axios.post("/api/upload-list", formData, {
      headers: {
        "x-auth-token": "Bearer " + _?.token, // Use the token for authorization
        "Content-Type": "multipart/form-data", // Ensure proper content type for file upload
      },
    });

    // Check if response is successful
    if (response.status === 201) {
      return response.data;
    } else {
      throw new Error(response.data.message || "Failed to upload the file.");
    }
  } catch (error) {
    console.error("Error uploading the subscriber list:", error);
    throw error; // Re-throw the error so the calling function can handle it
  }
  };

  const handleSubmit = async () => {
  if (!internalName || !file) {
    toast({
      title: "Error",
      description: "Please provide a list name and select a file.",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
    return;
  }

  try {
    setIsLoading(true); // Set loading state to true
    const result = await uploadSubscriberList(file, internalName);

    // Show success toast
    toast({
      title: "Success",
      description: "Subscriber list uploaded successfully.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    getOrganizerLists();
    // Optionally handle success further (e.g., close modal or refresh data)
    handleCancel(); // Reset form after success
  } catch (error) {
    // Show error toast
    toast({
      title: "Error",
      description: error.message || "Failed to upload the subscriber list.",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
  } finally {
    setIsLoading(false); // Reset loading state
  }
  };

  const getOrganizerLists = async () => {
    try {
      const token = localStorage.getItem("x-auth-token");

      let url = `/api/lists/` + organizerId;
      const response = await axios.get(url, {
        headers: {
          "x-auth-token": "Bearer " + token,
        },
      });
      setData2(response?.data?.lists);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const commonButtonStyle = {
    display: "flex",
    height: "45px",
    padding: "15px 25px",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    flex: "1 0 0",
    borderRadius: "100px",
  };

  const selectedButton = {
    ...commonButtonStyle,
    background: "var(--colors-shades-white-10, rgba(255, 255, 255, 0.10))",
    color: "white",
  };

  const notSelectedButton = {
    ...commonButtonStyle,
    background: "none",
    color: "#FFFFFF99",
  };



  // Handler for the ViewIcon click
  const handleViewClick = (listId) => {
    setSelectedListId(listId); // Set the selected list ID
    setIsViewModalOpen(true); // Open the view modal
  };

  // Handler for the EditIcon click
  const handleEditClick = (listId, listName) => {
    setSelectedListId(listId); // Set the selected list ID
    setSelectedListName(listName); // Set the selected list name for editing
    setIsEditModalOpen(true); // Open the edit modal
  };

  const handleListUpdate = (listId, newListName) => {
    setData2((prevData) =>
      prevData.map((list) =>
        list.id === listId ? { ...list, listName: newListName } : list
      )
    );
  };

  // Handler for the DeleteIcon click
  const handleDeleteClick = (listId, listName) => {
    setSelectedListId(listId); // Set the selected list ID
    setSelectedListName(listName); // Set the selected list name for deletion
    setIsDeleteModalOpen(true); // Open the delete modal
  };

  // Function to handle list deletion
  const handleListDelete = (listId) => {
    setData2((prevData) => prevData.filter((list) => list.id !== listId)); // Remove deleted list from state
  };

return (
  <Box
    w="100%"
    style={{
      fontFamily: "Gordita Regular",
      width: "100%",
      height: "100vh",
      overflow: "scroll",
    }}
  >
    {" "}
    <Box w="100%" minH="80vh">
      <Stack
        {...{ spacing }}
        flexDir="row"
        color="#FBFAFB"
        pos={"sticky"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        top={0}
        gap={"10px"}
        flexWrap={"wrap"}
        p={"30px"}
        minHeight={"110px"}
        zIndex={10}
        w="100%"
        fontWeight={700}
        bg={"rgba(0, 0, 0, 0.50)"}
        backdropFilter={"blur(150px)"}
      >
        <Box className="team-component-header-title" fontSize={"32px"}>
          Lists
        </Box>

        <Box
          sx={{
            display: "flex",
            maxWidth: "634px",
            padding: "5px",
            alignItems: "flex-start",
            borderRadius: "100px",
            border:
              "1px solid var(--border-b-primary, rgba(255, 255, 255, 0.20))",
            background: "var(--surface-s-glass, rgba(0, 0, 0, 0.50))",
            backdropFilter: "blur(75px)",
          }}
          overflow={"scroll"}
          gap={"5px"}
          className="control-header-component-filters"
        >
          <Button
            onClick={() => {
              navigate("/blasts/email");
            }}
            sx={notSelectedButton}
            minW={"150px"}
            p={"5px 25px"}
          >
            Email Blasts
          </Button>
          <Button
            onClick={() => {
              navigate("/blasts/sms");
            }}
            sx={notSelectedButton}
            minW={"150px"}
            p={"5px 25px"}
          >
            SMS Blasts
          </Button>
          <Button sx={selectedButton} minW={"150px"} p={"5px 25px"}>
            Lists
          </Button>
        </Box>

        <Button
          onClick={handleButtonClick}
          sx={theme.buttons.primary.shape}
          bg={theme.buttons.primary.default}
          color={theme.buttons.primary.color}
          _hover={{
            bg: theme.buttons.primary.hover,
            boxShadow: theme.buttons.primary.hoverBoxShadow,
          }}
          _active={{
            bg: theme.buttons.primary.hover,
            boxShadow: theme.buttons.primary.activeBoxShadow,
          }}
          className="control-header-component-button"
        >
          Upload List
        </Button>
      </Stack>
      <Box w="100%" px={"30px"} marginBottom={"30px"}>
        <TableContainer>
          <Table
            style={{
              borderCollapse: "separate",
              borderSpacing: "0 18px",
              marginBottom: "30px",
            }}
            variant="unstyled"
          >
            <Thead
              borderBottom="1px solid"
              borderColor="rgba(153, 159, 187,0.2)"
            >
              <Tr>
                {[
                  "List Name",
                  "Number Of Contacts",
                  "Created At",
                  "Actions"
                ].map((column, index) => {
                  return (
                    <Th
                      key={index}
                      paddingBottom={"20px"}
                      textTransform="capitalize"
                      color="var(--surface-surface-secondary, #959597)" // Added color
                      fontFamily="Gordita" // Added font family
                      fontSize="1rem" // Added font size
                      fontStyle="normal"
                      fontWeight={400} // Added font weight
                      lineHeight="150%" // Added line height
                      letterSpacing="-0.01rem" // Added letter spacing
                      position="relative"
                      textAlign={"center"}
                      border={"none"}
                      borderRadius="0rem" // Added border radius
                      borderRight="0px solid rgba(255, 255, 255, 0.10)" // Added border right
                      borderBottom="1px solid rgba(255, 255, 255, 0.10)" // Added border bottom
                      borderLeft="0px solid rgba(255, 255, 255, 0.10)" // Added border left
                      background="rgba(0, 0, 0, 0.50)" // Added background
                      backdropFilter="blur(75px)" // Added blur
                    >
                      {column}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody marginBottom={"30px"}>
              {Object?.values(data2)?.map(
                (
                  {
                    id,
                    listName,
                    totalContacts,
                    createdAt,
                  },
                  index
                ) => {

                  return (
                    <Tr overflow="hidden" color="white.100" key={index}>
                      <Td
                        m={0}
                        py={0}
                        pl={20}
                        border={"none"}
                        borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                        borderLeft={"1px solid rgba(255, 255, 255, 0.10)"}
                        borderTopLeftRadius={"0.9375rem"}
                        borderBottomLeftRadius={"0.9375rem"}
                        overflow={"hidden"}
                        maxWidth={"15rem"}
                      >
                        <HStack py={0} spacing={10}>
                          <Text
                            className="gordita400"
                            color="#FFF" // Added color
                            fontFeatureSettings="'ss08' on, 'ss04' on" // Added font feature settings
                            fontFamily="Gordita" // Added font family
                            fontSize="1.125rem" // Added font size
                            fontStyle="normal"
                            fontWeight={700} // Added font weight
                            lineHeight="110%" // Added line height
                            letterSpacing="-0.01125rem" // Added letter spacing
                          >
                            {`${listName || ""}`}
                          </Text>
                        </HStack>
                      </Td>
                      <Td
                        m={0}
                        py={0}
                        textAlign={"center"}
                        border={"none"}
                        borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                        maxWidth={"14rem"}
                      >
                        <Text
                          fontSize={14}
                          className="gordita400"
                          style={{
                            maxWidth: "100%",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            textAlign: "center",
                          }}
                        >
                          <Text
                            fontSize={14}
                            className="gordita400"
                            style={{
                              maxWidth: "100%",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {totalContacts}
                          </Text>
                        </Text>
                      </Td>
                      <Td
                        m={0}
                        py={0}
                        textAlign={"center"}
                        border={"none"}
                        borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                        maxWidth={"14rem"}
                      >
                        <Text
                          className="gordita400"
                          color="#FFF"
                          fontFamily="Gordita"
                          fontSize="1.125rem"
                          fontStyle="normal"
                          fontWeight="500"
                          lineHeight="110%"
                          letterSpacing="-0.01125rem"
                          fontFeatureSettings="'ss08' on, 'ss04' on"
                        >
                          {moment(createdAt).format("MMMM DD, YYYY") || 0}
                        </Text>
                      </Td>
                      <Td
                        m={0}
                        pl={0}
                        textAlign={"center"}
                        border={"none"}
                        borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                        borderTopRightRadius={"0.9375rem"}
                        borderBottomRightRadius={"0.9375rem"}
                      >
                        <Box
                        width={"100%"}
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems="flex-end"
                        gap={10}
                      >
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          borderRadius="6.25rem"
                          border="1px solid rgba(255, 255, 255, 0.20)"
                          width="3.125rem"
                          height="3.125rem"
                          flexShrink={0} 
                          cursor="pointer"
                          onClick={() => handleEditClick(id, listName)}
                        >
                          <EditIcon/>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          borderRadius="6.25rem"
                          border="1px solid rgba(255, 255, 255, 0.20)"
                          width="3.125rem"
                          height="3.125rem"
                          flexShrink={0}
                          cursor="pointer"
                          onClick={() => handleDeleteClick(id, listName)}
                        >
                          <DeleteIcon />
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          borderRadius="6.25rem"
                          border="1px solid rgba(255, 255, 255, 0.20)"
                          width="3.125rem"
                          height="3.125rem"
                          flexShrink={0} 
                          cursor="pointer"
                          onClick={() => handleViewClick(id)}
                        >
                          <ViewIcon />
                        </Box>
                      </Box>
                      </Td>
                    </Tr>
                  );
                }
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
    <Modal
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      size="xl"
      isCentered
      zIndex={15}
    >
      <ModalOverlay />
      <ModalContent
        bg="black"
        border="1px solid #333333"
        borderColor="#333333"
        borderRadius="1.25rem"
      >
        <ModalHeader>Import Subscriber List</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        <Box w="100%" spacing={0} alignItems="flex-start">
                <Text
                  fontSize={"1.3125rem"}
                  mb="8px"
                  className="gordita600"
                  fontWeight={500}
                  textTransform={"capitalize"}
                >
                  List Name
                </Text>
                <Input
                  placeholder="Enter Best List Name"
                  value={internalName}
                  onChange={handleInternalNameChange}
                />
              </Box>
                    
              <Box
        {...getRootProps()}
        border="2px dashed #333333"
        borderRadius="1rem"
        p="4"
        mt="4"
        textAlign="center"
        bg={isDragActive ? "#2C2C2C" : "transparent"}
        cursor="pointer"
        height="200px"  // Increased height to make it look like a box
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <input {...getInputProps()} />
        {file ? (
          <Text color="white" fontWeight="500">
            {file.name} selected
          </Text>
        ) : isDragActive ? (
          <Text color="gray.300">Drop the file here ...</Text>
        ) : (
          <Text color="gray.300">
            Drag 'n' drop a CSV or XLSX file here, or click to select. <Text color="red">The columns should be named "Email", "Phone", or "Name" (case sensitive). Any combination of these columns is accepted. Phone numbers should be in international format (e.g., +1917123456 or 1917123456).</Text>
          </Text>
        )}
      </Box>
                  
                
      </ModalBody>
        <ModalFooter>
          <Button
            onClick={
              handleCancel
            }
            sx={theme.buttons.tercary.shape}
            bg={theme.buttons.tercary.bgColor}
            color={theme.buttons.tercary.color}
            _hover={{ bg: theme.buttons.tercary.hover }}
            css={{
              width: "fit-content !important",
              padding: "1.25rem",
              marginRight: "10px",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            sx={theme.buttons.primary.shape}
          bg={
            internalName && file
              ? theme.buttons.primary.default
              : theme.buttons.primary.disabled
          }
          color={
            internalName && file
              ? theme.buttons.primary.color
              : theme.buttons.primary.colorDisabled
          }
          _hover={{
            bg: theme.buttons.primary.hover,
            boxShadow: theme.buttons.primary.hoverBoxShadow,
          }}
          _active={{
            boxShadow: theme.buttons.primary.activeBoxShadow,
          }}
          style={{
            width: "6rem",
          }}
          isLoading={isLoading}
          disabled={
            internalName && file ? "" : "true"
          }
          >
            Upload
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>

    {/* View List Modal */}
    <ViewContactsModal
        isOpen={isViewModalOpen}
        onClose={() => setIsViewModalOpen(false)}
        listId={selectedListId} // Pass the selected list ID to the modal
    />
    {/* Edit List Modal */}
    <EditListModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        listId={selectedListId} // Pass the selected list ID to the modal
        currentListName={selectedListName} // Pass the current list name to the modal
        onListUpdated={handleListUpdate}
    />

    {/* Delete List Modal */}
    <DeleteListModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        listId={selectedListId} // Pass the selected list ID to the modal
        listName={selectedListName} // Pass the selected list name to the modal
        onDeleteSuccess={handleListDelete} // Pass the delete handler to the modal
    />
  </Box>
);
};

export default ListsComponent;
