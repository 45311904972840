import {
    Box,
    Button,
    Divider,
    HStack,
    Text,
    VStack,
    Input,
    chakra,
    useToast,
    Image,
    InputGroup,
    InputRightElement,
    IconButton,
    Center,
  } from "@chakra-ui/react";
  import { LoginByEmailSchema } from "../../../utils/schema";
  import { Formik, Field, Form } from "formik";
  import {
    RegistraionModalTypes,
    registration,
  } from "../../../recoil/atoms/registration";
  import { user } from "../../../recoil/atoms/user";
  import { useRecoilState } from "recoil";
  import { Navigate, useNavigate } from "react-router-dom";
  import { useState } from "react";
  import { useMediaQuery } from "../../../utils/useMediaQuery";
  import { useCookies } from "react-cookie";
  import PhoneInput from "react-phone-input-2/";
  import "react-phone-input-2/lib/bootstrap.css";
  import { userRoles } from "../../../utils/constants";
  import { theme } from "../../../styles/theme/base";
  import axios from "../../../utils/api/axios";
  import { useLocation } from "react-router-dom";
  
  
  const initialValues = { email: "", password: "" };
  
  const CustomToast = chakra("div", {
    baseStyle: {
      zIndex: "modal",
    },
  });
  /**
   * The Login function is a React component that handles user login, including email and password
   * authentication as well as Google login.
   * @returns The Login component is being returned.
   */
  
  const LoginByEmail = () => {
    const [_, setRegistrationModal] = useRecoilState(registration);
    const [u, setUser] = useRecoilState(user);
    const toast = useToast();
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(["x-auth-token"]);
    const isMobile = useMediaQuery("(max-width: 768px)");
    const [verificationStatus, setVerificationStatus] = useState("");
    const [verificationCheck, setVerificationCheck] = useState(false);
    const location = useLocation();
  

    const handleSendOTP = async (email) => {
      try {
        const response = await axios.post("/api/sendOTP", {email });
        if (response.data.success === true) {
          setVerificationStatus(response.data.message);
          console.log("aaaaaaaaachcekoiin");
          setVerificationCheck(true);
          return true;
        } else {
          setVerificationStatus(response.data.message);
          setVerificationCheck(false);
          return false;
        }
      } catch (error) {
        console.error("Error sending OTP:", error);
        setVerificationStatus("Failed to send OTP");
        return false;
      }
    };
  
    
  
    return (
      <Box w="100%">
        <VStack gap={50}>
          <Text className="gordita700" fontSize={{ base: 18, md: 24 }}>
            Log In to Portalys!
          </Text>
          <Formik
            initialValues={initialValues}
            validationSchema={LoginByEmailSchema}
            onSubmit={async (values) => {
              try {
                // Attempt to send OTP
                const response = await handleSendOTP(values.email);
                console.log("aaaa", verificationCheck);
  
                // Check if OTP sending was successful
                if (response) {
                  // Proceed with setting the modal
                  setRegistrationModal((lp) => ({
                    ...lp,
                    openModal: true,
                    modalType: RegistraionModalTypes.LOGIN_VERIFICATION_EMAIL,
                    userData: {
                      email: values.email,
                    },
                  }));
                }
              } catch (error) {
                console.error("Error sending OTP:", error);
                setVerificationStatus("Failed to send OTP");
                setVerificationCheck(false);
              }
            }}
          >
            {(formik) => {
              const { handleSubmit, errors, touched, setFieldValue } = formik;
  
            
              const handleEmailChange = (value) => {
                setFieldValue("email", value);
              
            };
              return (
                <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <VStack px={{ base: 0, md: 36 }} alignItems="flex-start">
                    <Text fontWeight={400} fontSize="0.75rem" pl={"1.5rem"}>
                     Email
                    </Text>
                    <Field
                    type="email"
                    name="email"
                    id="email"
                    render={({ field }) => (
                      <Input
                        inputProps={{
                          name: "email", // Specify the name for the input field
                          placeholder: "Enter Your Email",
                          style: {
                            width: "100%",
                            border: "1px solid #FFFFFF",
                            borderRadius: "6.25rem",
                            height: "3.375rem",
                            fontSize: "14px",
                            color: "#FFFFFF",
                            backgroundColor: "transparent",
                          },
                        }}
                        style={{
                          marginTop: "0.30rem",
                        }}
                        dropdownStyle={{ backgroundColor: "#000000" }}
                        country={"us"} // Set your default country
                        value={field.value} // Set the value explicitly
                        onChange={(e) => {
                          handleEmailChange(e.target.value);
                        }}                      />
                    )}
                  />
                    {errors.email && touched.email && (
                      <Text
                        color="red"
                        className="gordita400"
                        fontWeight={400}
                        fontSize={14}
                      >
                        {errors.email}
                      </Text>
                    )}
  
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: 700,
                        color: "red",
                      }}
                    >
                      {verificationStatus}
                    </p>
                  </VStack>
  
                  <Box px={{ base: 0, md: 36 }} mt={24} w="100%">
                    <Button
                      type="submit"
                      className="gordita700"
                      sx={theme.buttons.primary.shape}
                      bg={theme.buttons.primary.default}
                      color={theme.buttons.primary.color}
                      css={{
                        width: "100%",
                      }}
                      _hover={{
                        bg: theme.buttons.primary.hover,
                        boxShadow: theme.buttons.primary.hoverBoxShadow,
                      }}
                      _active={{
                        boxShadow: theme.buttons.primary.activeBoxShadow,
                      }}
  
                    >
                      Receive Email With The Code
                    </Button>
                  </Box>
  
                  <Box
                    className="gordita400"
                    fontSize={16}
                    px={{ base: 0, md: 36 }}
                    mt={24}
                    w="100%"
                    textAlign="center"
                  >
                    Don't have an account?
                    <chakra.span
                      color="white"
                      cursor="pointer"
                      fontWeight="700"
                      onClick={() =>
                        setRegistrationModal((lp) => {
                          return {
                            ...lp,
                            openModal: true,
                            modalType: RegistraionModalTypes.SIGNUP,
                          };
                        })
                      }
                    >
                      {" "}
                      Sign Up
                    </chakra.span>
                  </Box>
  
                  {isMobile && (
                    <Box>
                      <HStack my={36} px={{ base: 0, md: 36 }}>
                        <Divider orientation="horizontal" bg="#A2A2A6" w="100%" />
                      </HStack>
                      <Box
                        className="gordita400"
                        fontSize={16}
                        px={{ base: 0, md: 36 }}
                        mt={24}
                        w="100%"
                        pb={35}
                        textAlign="center"
                      >
                        Are you a verifier?
                        <chakra.span
                          color="white"
                          cursor="pointer"
                          fontWeight="700"
                          onClick={() =>
                            setRegistrationModal((lp) => {
                              return {
                                ...lp,
                                openModal: true,
                                modalType: RegistraionModalTypes.VERIFIER,
                              };
                            })
                          }
                        >
                          {" "}
                          Log In As Verifier
                        </chakra.span>
                      </Box>
                    </Box>
                  )}
                </Form>
              );
            }}
          </Formik>
        </VStack>
      </Box>
    );
  };
  
  export default LoginByEmail;
  