import React from 'react';
import { Box, HStack, Text, VStack,Image } from "@chakra-ui/react";
import exportIcon from "../../../static/assets/images/ic_round-open-in-new.svg";

export const TableComponent = ({
  tableDescription,
  tableLink, // Ensure this prop is used if needed
  accentColor,
}) => {
  const handleImageClick = () => {
    if (tableLink) {
      // Open the tableLink in a new tab
      window.open(tableLink, '_blank');
    }
  };
  return (
    <Box>
      <VStack
        padding="0.63rem"
        width="100%"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        borderRadius="1.5625rem"
        border="1px solid rgba(255, 255, 255, 0.10)"
        borderColor="rgba(255, 255, 255, 0.10)"
        justifyContent="space-between"
        fontWeight={700}
      >
        <HStack
          padding="0.35rem 0.80rem"
          width="100%"
          justifyContent="space-between"
        >
          <VStack
            justifyContent="center"
            alignItems="flex-start"
            width="100%"
            overflow="hidden"
          >
            <HStack cursor="pointer" onClick={handleImageClick}>
            <Text
              fontSize="1rem"
              className="gordita600"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              minW="0"
              width="100%"
            >
              {'Book A Table'}
            </Text>
            {tableLink &&(
            <Image
            src={exportIcon}
            alt="icon"
            
          />
        )}

            
            

            </HStack>
          </VStack>
          <VStack justifyContent="center" alignItems="flex-end">
            <HStack>
              <Text
                color="rgba(255, 255, 255, 0.60)"
                fontSize="0.75rem"
                className="gordita400"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {/* Add content if needed */}
              </Text>
              <Text fontSize="1.1875rem" className="gordita700">
                {/* Add content if needed */}
              </Text>
            </HStack>
            <HStack>
              <Text
                color="rgba(255, 255, 255, 0.60)"
                fontSize="0.75rem"
                className="gordita400"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {/* Add content if needed */}
              </Text>
              <Text
                fontSize="1.1875rem"
                color={accentColor}
                className="gordita700"
              >
                {/* Add content if needed */}
              </Text>
            </HStack>
          </VStack>
        </HStack>
        {tableDescription && (
          <Text
            padding="0 0.80rem"
            width="100%"
            textAlign="start"
            color="rgba(255, 255, 255, 0.60)"
            fontSize="0.875rem"
            className="gordita400"
          >
            {tableDescription}
          </Text>
        )}
        <HStack width="100%" justifyContent="space-between">
          <Text
            paddingLeft="0.80rem"
            fontSize="1rem"
            className="gordita700"
            color="gray"
            _hover={{
              cursor: "not-allowed",
            }}
          >
            {/* Add content if needed */}
          </Text>
        </HStack>
      </VStack>
    </Box>
  );
};
