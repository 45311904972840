import {
    Box,
    Flex,
    HStack,
    Text,
    VStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    useDisclosure,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Image,
    IconButton,
    AccordionIcon,
    Checkbox,
  } from "@chakra-ui/react";
  import {
    DateBox,
    InputBox,
    SelectBox,
    SwitchBox,
    TextBox,
  } from "./SharedModules";
  
  import { useEffect, useState, useRef } from "react";
  import { theme } from "../../../../styles/theme/base";
  import { useMediaQuery } from "../../../../utils/useMediaQuery";
  import deleteIcon from "../../../../static/assets/images/deleteIcon.svg";
  import editIcon from "../../../../static/assets/images/editIcon.svg";
  import fillerImage from "../../../../static/assets/images/Rectangle 34624174.svg"
  import dollarIcon from "../../../../static/assets/images/dollarIcon.svg";
  import { Select } from "antd";
  import "./styles.css";
  import { useLocation } from "react-router-dom";
  import frame3 from "../../../../static/assets/images/Frame 1000005221.svg";
  import getSymbolFromCurrency from "currency-symbol-map";
import axios from "../../../../utils/api/axios";
import secrets from "../../../../secrets";
import { useRecoilState } from "recoil";
import { user } from "../../../../recoil/atoms/user";
import { DeleteIcon } from "@chakra-ui/icons";
  
  const Perks = (props) => {
    const {
      handleBlur,
      handleChange,
      values,
      setFieldValue,
      isEditMode,
    } = props;
  
    const [isPerkDialogOpened, setIsPerkDialogOpened] =
    useState(false);
    const [perkLists, setPerkLists] = useState([]);
    const [selectedPerkList, setSelectedPerkList] = useState(null);
    const [editMode, setEditMode] = useState(false); // Edit mode state
    const [editingPerkListIndex, setEditingPerkListIndex] = useState(null);
    const [editingPerkIndex, setEditingPerkIndex] = useState(null);
    const location = useLocation();
    const [_, setUser] = useRecoilState(user);
    const organizerId=_.userData?.organizerId || _.userData?.userId;
    const shouldShowContent = location.pathname.includes("/edit");
  
    const [soldoutIndex, setSoldoutIndex] = useState(null);
    const [imageIndex, setImageIndex] = useState("");


    const [errorFields, setErrorFields] = useState();

    const fileInputRef = useRef(null);
    const [existingLists, setExistingLists] = useState([]);
    const [activePerks, setActivePerks] = useState([]);
    const [isAllListsSelected, setIsAllListsSelected] = useState(false);
    const handleCheckboxToggle = (list) => {
        setIsAllListsSelected(false);
        setActivePerks(prevLists => {
          if (prevLists.some(item => item.perkListId === list.perkListId)) {
            return prevLists.filter(item => item.perkListId !== list.perkListId);
          } else {
            return [...prevLists, list];
          }
        });
      };
      
    

  
    useEffect(() => {
      if (isEditMode && values?.perkLists) {
        setPerkLists([...values?.perkLists]);
      }
    }, [values.perkLists]);
  
    const uploadImage = (image, type) => {
        return new Promise((resolve, reject) => {
          const fd = new FormData();
          fd.append("image", image);
          fd.append("type", type);
          axios
            .post("/api/uploadImage", fd,{
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              if (res.data.status) {
                resolve(res.data.link); // Resolve with the image link
              } else {
                reject("Image upload failed"); // Reject if upload fails
              }
            })
            .catch((err) => {
              reject(err); // Reject with the error if axios request fails
            });
        });
      };
      

      const handleImageUpload = async (event, perk) => {
        const imageFile = event.target.files[0];
        let imageLink;
        if (imageFile) {
          try {
            imageLink = await uploadImage(imageFile, 'perk');
            const updatedPerk = {
              name: perk.name,
              description: perk.description,
              quantity: perk.quantity,
              price: perk.price,
              perkRemainingSupply: perk.perkRemainingSupply,
              perkId: perk.perkId,
              image: imageLink
            };
      
            updatePerk(updatedPerk);
            setImageIndex("");
          } catch (error) {
            console.error('Error uploading image:', error);
          }
        }
      };
      
  
    useEffect(() => {
      if (!isEditMode) {
        setPerkLists([]);
      }
    }, [isEditMode]);
  
    const addPerk = (
      perkListIndex,
      name,
      description,
      quantity,
      price,
      image,
    ) => {
      const newPerk = {
        name: name,
        description: description,
        quantity: quantity,
        price: price,
        perkRemainingSupply: Number(quantity),
        image:image,
      };
  
      const updatedPerkLists = [...perkLists];
      updatedPerkLists[perkListIndex].perks = [
        ...updatedPerkLists[perkListIndex]?.perks,
        newPerk,
      ];
      // Update the state
      setPerkLists(updatedPerkLists);
    };
  
 
  

  
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
      isOpen: isOpen2,
      onOpen: onOpen2,
      onClose: onClose2,
    } = useDisclosure();

    const {
        isOpen: isOpen3,
        onOpen: onOpen3,
        onClose: onClose3,
      } = useDisclosure();
    const {
        isOpen: isPerkDialogOpen,
        onOpen: onOpenPerkDialog,
        onClose: onClosePerkDialog,
      } = useDisclosure();
  

    const updatePerk = (updatedPerk) => {
      const updatedPerkLists = [...perkLists];
      const updatedPerks = [
        ...updatedPerkLists[editingPerkListIndex].perks,
      ];
      updatedPerks[editingPerkIndex] = updatedPerk;
      updatedPerkLists[editingPerkListIndex].perks = updatedPerks;
      setPerkLists([...updatedPerkLists]);
    };
  
    const cancelPerkModel = () => {
      const updatedPerkLists = [...perkLists];
  
      if (editMode) {
        updatedPerkLists[editingPerkListIndex] = selectedPerkList;
      } else {
        updatedPerkLists.pop();
      }
      setPerkLists(updatedPerkLists);
      setFieldValue("perkLists", updatedPerkLists);
  
      setEditMode(false);
      setEditingPerkIndex(null);
  
      setFieldValue("listName", "");
      setFieldValue("perkName", "");
      setFieldValue("perkDescription", "");
      setFieldValue("perkQuantity", "");
      setFieldValue("perkPrice", "");
      setFieldValue("perkImage", "");

      setErrorFields({
        perkName: false,
        perkQuantity: false,
        perkPrice: false,
        perkDescription: false,
      });
      onClose();
    };
  
    const perkItem = (perk, index) => {
      return (
        <Box
          key={index}
          maxW={"36.875rem"}
          width={"100%"}
          height={"3.4375rem"}
          borderRadius={"6.25rem"}
          border={"1px solid rgba(255, 255, 255, 0.10)"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          padding={"0.31rem"}
        >
          <Box
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            gap={"0.62rem"}
          >

              <img
                      src={perk.image || fillerImage}
                      alt={"image-icon"}
                      style={{ width: "2.8125rem", height: "2.8125rem",borderRadius:"6.25rem" }}
                    />
            <Text
              w={"185px"}
              className={"gordita700"}
              fontSize={"1rem"}
              lineHeight={"150%"}
              letterSpacing={"-0.01rem"}
              textTransform={"capitalize"}
              overflow={"hidden"}
              whiteSpace={"nowrap"}
              textOverflow={"ellipsis"}
            >
              {perk?.name}
            </Text>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={"1.82rem"}
            margin={"auto 0.61rem"}
          >
            <Text
              className={"gordita400"}
              fontSize={"1rem"}
              lineHeight={"150%"}
              letterSpacing={"-0.01rem"}
            >
              {perk?.quantity}u
            </Text>
            {shouldShowContent && (
              <Text
                className={"additionalTextClass"}
                fontSize={"1rem"}
                lineHeight={"150%"}
                letterSpacing={"-0.01rem"}
              >
                Remaining Quantity: {perk?.perkRemainingSupply || 0}
              </Text>
            )}
            <Text
              className={"gordita600"}
              fontSize={"1rem"}
              lineHeight={"150%"}
              letterSpacing={"-0.01rem"}
            >
              {getSymbolFromCurrency(values.currency)}{perk?.price}
            </Text>
          </Box>
        </Box>
      );
    };
  

  
    const createPerks = () => {
      let newPerkList = {
        listName: "",
        perks: [
          {
            name: "",
            description: "",
            quantity: "",
            price: "",
            perkRemainingSupply:"",
            image:"",
          },
        ],
      };
      setPerkLists([...perkLists, newPerkList]);
      setEditingPerkListIndex(perkLists.length);
      setEditMode(false);
      setEditingPerkIndex(0);
      setFieldValue("perkName", "");
      setFieldValue("perkDescription", "");
      setFieldValue("perkQuantity", "");
      setFieldValue("perkPrice", "");
      setFieldValue("perkImage", "");
      onOpen();
    };

    const assignPerks = (activePerks) => {
      let newPerkLists = [];
      for (const perkList of activePerks) {
        const newPerkList = {
            listName: perkList.listName,
            perks: [], // Initialize an empty array to hold individual perks
          };
          for (const perk of perkList.perks) {
            // Create a new object for each perk and populate its properties
            const newPerk = {
              name: perk.perkName,
              description: perk.perkDescription,
              quantity: perk.perkQuantity,
              price: perk.perkPrice,
              perkRemainingSupply: perk.perkQuantity, // Assuming perkRemainingSupply is initially the same as quantity
              image: perk.perkImage,
            };
            console.log('aaaaaa',newPerk);
            // Push the populated perk object to the perks array of the newPerkList
            newPerkList.perks.push(newPerk);
          }

      console.log('oooooo',newPerkList);
      newPerkLists.push(newPerkList)

    
    }
    setPerkLists([...perkLists, ...newPerkLists]);
    setFieldValue("perkLists",[...perkLists, ...newPerkLists]);



}



        const getAllPerks = async () => {
            try {
              const token = localStorage.getItem("x-auth-token");
      
              let url = "/api/getAllPerks/"+organizerId ;
              const response = await axios.get(url, {
                headers: {
                  "x-auth-token": "Bearer " + token,
                },
              });
              // const filteredPerkLists = response.data?.perkLists.filter(perkList => {
              //   // Check if any local perkList has the same perkListName
              //   return !perkLists.some(localPerkList => localPerkList.listName === perkList.listName);
              // });
                            
              setExistingLists(response.data?.perkLists);

            } catch (error) {
              console.error("Error fetching data:", error);
            }
          };
  
    return (
      <Box w="100%">
        <Box
          w={"100%"}
          display={"flex"}
          alignItems={"start"}
          flexDirection={"column"}
          
        >
          <VStack >
            <Box>
              <Text
                className="gordita600"
                fontSize={"1.3125rem"}
                textAlign="left"
                color="#FFFFFF"
                textTransform={"capitalize"}
                // width={"20%"}
              >
                Perks
                <span
                style={{
                  marginLeft: "0.56rem",
                  padding: "0.3125rem 0.625rem",
                  borderRadius: "6.25rem",
                  backgroundColor: "#fff",
                  color: "#000",
                  fontSize: "0.875rem",
                  textTransform: "capitalize",
                  textAlign: "center",
                  lineHeight: "110%",
                  marginRight: "0.56rem",
                }}
                className="gordita700"
              >
                New!
              </span>
              
              </Text>
              <Box>
            <Text
              marginTop={"1.56rem"}
              className="gordita400"
              fontSize={16}
              textAlign="left"
              w="100%"
              color="rgba(255, 255, 255, 0.60)"
            >
              Perks are add-ons to the purchase. Customers can buy them after they select their tickets.
            </Text>
          </Box>
            </Box>
          </VStack>
  
          
        </Box>  
        <VStack width={"100%"} display={"flex"} alignItems={"flex-start"}>
       
        {perkLists?.length > 0 ? (
            <Box
              w={"100%"}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"start"}
              gap={"20px"}
              marginTop={"1.56rem !important"}
            >
              {perkLists
                .map((perkList, index) => (
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      marginBottom={"1.44rem"}
                      key={index}
                      border={"1px solid rgba(255, 255, 255, 0.20)"}
                      borderRadius={"1.25rem"}
                      w={"100%"}
                      height={"auto"}
                      p={"1.56rem"}

                      onClick={() => {
                        setSelectedPerkList({ ...perkList });
                        onOpen3();
                    }}
                    
                      
                    >
                      <Text
                        className="gordita700"
                        fontSize={"1.1875rem"}
                        lineHeight={"110%"}
                        letterSpacing={"-0.01188rem"}
                        textTransform={"capitalize"}
                      >
                        {perkList.listName}
                      </Text>
                      <Text
                        className="gordita700"
                        fontSize={"1.1875rem"}
                        lineHeight={"110%"}
                        letterSpacing={"-0.01188rem"}
                        textTransform={"capitalize"}
                      >
                        {perkList.perks.length}u
                      </Text>
                      <Box
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                        gap={"0.94rem"}
                      >
                        <Button
                          sx={theme.buttons.secondary.shape}
                          bg={theme.buttons.secondary.bgColor}
                          color={theme.buttons.secondary.color}
                          _hover={{
                            borderColor: theme.buttons.secondary.hoverBorderColor,
                          }}
                          _active={{
                            bg: theme.buttons.secondary.pressed,
                          }}
                          style={{
                            width: "55px",
                            height: "55px",
                            borderRadius: "50%",
                          }}
                          border={"1px solid #FFFFFF33"}
                          onClick={(e) => {
                            e.stopPropagation(); 
                            let index = perkLists.indexOf(perkList);
                            const updatedPerkLists = [...perkLists];
                            updatedPerkLists.splice(index, 1);
                            setPerkLists(updatedPerkLists);
                            setFieldValue("perkLists", updatedPerkLists); // Update formik values
                          }}
                        >
                          <img
                            src={deleteIcon}
                            alt={"delete-icon"}
                            style={{
                              width: "44px",
                              height: "44px",
                              fontSize: "40px",
                            }}
                          />
                        </Button>
                        <Button
                          sx={theme.buttons.secondary.shape}
                          bg={theme.buttons.secondary.bgColor}
                          color={theme.buttons.secondary.color}
                          _hover={{
                            borderColor: theme.buttons.secondary.hoverBorderColor,
                          }}
                          _active={{
                            bg: theme.buttons.secondary.pressed,
                          }}
                          style={{
                            width: "55px",
                            height: "55px",
                            borderRadius: "50%",
                          }}
                          border={"1px solid #FFFFFF33"}
                          onClick={(e) => {
                            e.stopPropagation(); 
                            let index = perkLists.indexOf(perkList);
  
                            setEditingPerkListIndex(index);
                            setEditingPerkIndex(0);
                            setSelectedPerkList({ ...perkList });
                            setFieldValue(
                              "listName",
                              perkList.listName
                            );
  
                            setFieldValue(
                              "perkName",
                              perkLists[index]?.perks[0].name
                            );
                            setFieldValue(
                              "perkDescription",
                              perkLists[index]?.perks[0].description
                            );
                            setFieldValue(
                              "perkQuantity",
                              perkLists[index]?.perks[0].quantity
                            );
                            setFieldValue(
                              "perkPrice",
                              perkLists[index]?.perks[0].price
                            );
                            setFieldValue(
                                "perkImage",
                                perkLists[index]?.perks[0].image
                              );
                            setEditMode(true);
                            onOpen();
                          }}
                        >
                          <img
                            src={editIcon}
                            alt={"edit-icon"}
                            style={{
                              width: "44px",
                              height: "44px",
                              fontSize: "40px",
                            }}
                          />
                        </Button>
                      </Box>
                                          {/* <Box display={"flex"} flexDir={"column"} gap={"0.62rem"}>
                      {perkList.perks?.map((perk, index) =>
                        perkItem(perk, index)
                      )}
                    </Box> */}
                    </Box>

                ))}
            </Box>
          ) : (
            ``
          )}
          <Button
 onClick={() => {
    if (isPerkDialogOpened) {
      createPerks();
    } else {
      onOpenPerkDialog();
     // setIsPerkDialogOpened(true);
    }
  }}
            sx={theme.buttons.secondary.shape}
            bg={theme.buttons.secondary.bgColor}
            color={theme.buttons.secondary.color}
            _hover={{
              borderColor: theme.buttons.secondary.hoverBorderColor,
            }}
            _active={{
              bg: theme.buttons.secondary.pressed,
            }}
            style={{
              width: "100%",
              marginTop: "1.56rem",
              textTransform: "capitalize",
            }}
            className="gordita700"
          >
            Create A Perks List
          </Button>
          <Box>
            <Text
              marginTop={"1.56rem"}
              className="gordita400"
              fontSize={16}
              textAlign="left"
              w="100%"
              color="rgba(255, 255, 255, 0.60)"
            >
              You can create from one to multiple perks. They are organized by lists. All lists can be used in multiple events.
            </Text>
          </Box>
        </VStack>

        <VStack
          width={"100%"}
          display={"flex"}
          alignItems={"flex-start"}
          marginBottom={"1.13rem"}
        >
          
  
          <Button
            onClick={() => { 
                getAllPerks();
                onOpen2();
            }}
            sx={theme.buttons.secondary.shape}
            bg={theme.buttons.secondary.bgColor}
            color={theme.buttons.secondary.color}
            _hover={{
              borderColor: theme.buttons.secondary.hoverBorderColor,
            }}
            _active={{
              bg: theme.buttons.secondary.pressed,
            }}
            style={{
              width: "100%",
              marginTop: "1.56rem",
              textTransform: "capitalize",
            }}
            className="gordita700"
          >
            Assign A Perk List
          </Button>
          <Box>
            <Text
              marginTop={"1.56rem"}
              className="gordita400"
              fontSize={16}
              textAlign="left"
              w="100%"
              color="rgba(255, 255, 255, 0.60)"
            >
             If you already have a list created, you can assign it to this event.
            </Text>
          </Box>
        </VStack>
  
        <Modal
          isCentered
          closeOnOverlayClick={false}
          isOpen={isOpen}
          onClose={function () {
            setEditMode(false);
            setEditingPerkIndex(null);
            // Clear form fields and close the modal
            setFieldValue("perkName", "");
            setFieldValue("perkDescription", "");
            setFieldValue("perkQuantity", "");
            setFieldValue("perkPrice", "");
            setFieldValue("perkImage", "");

            onClose();
          }}
          size={"xl"}
        >
          <ModalOverlay />
          <ModalContent
            bg={"rgba(0, 0, 0, 0.50)"}
            // backdropFilter={"blur(150px)"}
            borderRadius={"1.5625rem"}
            border={"1px solid #333333"}
            boxShadow={"0px 0px 100px 150px rgba(0, 0, 0, 0.30)"}
            backdropFilter={"blur(75px)"}
            padding={"0.64rem 0"}
          >
            <ModalHeader>
              {editMode ? "Update" : "Create"} A Perks List
            </ModalHeader>
            <ModalCloseButton
              top={"1.5rem"}
              right={"1.5rem"}
              onClick={cancelPerkModel}
            />
            <ModalBody>
              <>
                <Box mb={"1.87rem"}>
                  <Text
                    fontSize={"1.125rem"}
                    className="gordita600"
                    //textTransform={"capitalize"}
                    mb={"0.94rem"}
                  >
                    List Name (internal)
                  </Text>
                  <InputBox
                    values={values}
                    handleBlur={handleBlur}
                    handleChange={(e) => {
                      const updatedPerkLists = [...perkLists];
                      updatedPerkLists[editingPerkListIndex].listName =
                        e.target.value;
                      setPerkLists(updatedPerkLists);
                      handleChange(e);
                      setErrorFields({
                        ...errorFields,
                        listName: e.target.value ? false : true,
                      });
                    }}
                    placeholder={
                      "Only for internal use. Attendees won’t see this name."
                    }
                    maxW="100%"
                    name="listName"
                    error={errorFields?.listName}
                  />
                </Box>
                <Box>
                  <Text
                    fontSize={"1.125rem"}
                    className="gordita600"
                    textTransform={"capitalize"}
                    mb={"0.94rem"}
                  >
                    Perks
                  </Text>
                </Box>
  
                <Box m={"0 0 1.25rem 0"}>
                  <Accordion
                    allowToggle
                    index={editingPerkIndex}
                    defaultIndex={
                      editMode
                        ? []
                        : [
                            perkLists[editingPerkListIndex]?.perks?.length -
                              1,
                          ]
                    }
                    maxHeight={"20rem"}
                    overflow={"scroll"}
                  >
                    {perkLists[editingPerkListIndex]?.perks?.map(
                      (perk, index) => (
                        <AccordionItem
                          key={index}
                          border={"1px solid rgba(255, 255, 255, 0.10)"}
                          borderRadius={
                            editingPerkIndex === index ? "1.5625rem" : "6.25rem"
                          }
                          mb={"1.25rem"}
                        >
                          {({ isExpanded }) => (
                            <>
                              <Box
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <Box
                                  width={"100%"}
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                >
                                    {!isExpanded && (
                                          <Box
                                          marginLeft={'0.62rem'}
                                          width={"50%"}
                                          display={"flex"}
                                          justifyContent={"space-between"}
                                          alignItems={"center"}
                                        >
                                            <img
                      src={perk.image || fillerImage}
                      alt={"image-icon"}
                      style={{ width: "2.8125rem", height: "2.8125rem",borderRadius:"6.25rem" }}
                    />
                                          <Text style={{
  color: 'var(--Colors-S---White, #FFF)',
  fontFeatureSettings: "'ss08' on, 'ss04' on",
  fontFamily: 'Gordita',
  fontSize: '1.125rem',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '110%'
}}>
  {perk.name}
</Text>

                                        
                                         <Text>{getSymbolFromCurrency(values.currency)}{perk.price}</Text>
                                        
                                        <Text>{perk.quantity}u</Text>
                                          </Box>
                                    )}
                                 
                                  {/* {shouldShowContent &&
                                    isExpanded && (

                                    (perk.perkRemainingSupply === 0 ? (
                                      <Button
                                        size="sm"
                                        variant="outline"
                                        colorScheme="red"
                                        marginRight="10"
                                        onClick={(e) => {
                                          e.stopPropagation(); 
                                        }}
                                      >
                                        Sold Out
                                      </Button>
                                    ) : (
                                      <Button
                                        size="sm"
                                        variant="outline"
                                        colorScheme="white"
                                        marginRight="10"
                                        onClick={(e) => {
                                          e.stopPropagation(); 
  
                                          const updatedPerk = {
                                            name: perk.name,
                                            description: perk.description,
                                            quantity: perk.quantity,
                                            price: perk.price,
                                            perkRemainingSupply: 0,
                                            perkId: perk.perkId,
                                            image:perk.image
                                          };
                                          updatePerk(updatedPerk);
                                        }}
                                      >
                                        Mark as sold out
                                      </Button>
                                    )))} */}
  
                                  {index > 0 && (
                                    <Button
                                      sx={theme.buttons.tercary.shape}
                                      bg={theme.buttons.tercary.bgColor}
                                      color={theme.buttons.tercary.color}
                                      _hover={{
                                        bg: theme.buttons.tercary.hoverBgColor,
                                      }}
                                      _active={{
                                        bg: theme.buttons.tercary.pressedBgColor,
                                      }}
                                      style={{
                                        width: "fit-content",
                                        height: "fit-content",
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation(); 
                                        const updatedPerkLists = [
                                          ...perkLists,
                                        ];
                                        updatedPerkLists[
                                          editingPerkListIndex
                                        ].perks.splice(index, 1);
                                        setPerkLists(updatedPerkLists);
                                      }}
                                    >
                                      <img
                                        src={deleteIcon}
                                        alt={"delete-icon"}
                                        style={{
                                          width: "1.5rem",
                                          height: "1.5rem",
                                          marginRight: "0.31rem",
                                        }}
                                      />
                                    </Button>
                                  )}
                                </Box>
  
                                {perkLists[editingPerkListIndex]?.perks
                                  ?.length > 0 && (
                                  <AccordionButton
                                    sx={theme.buttons.tercary.shape}
                                    bg={theme.buttons.tercary.bgColor}
                                    color={theme.buttons.tercary.color}
                                    _hover={{
                                      bg: theme.buttons.tercary.hoverBgColor,
                                    }}
                                    _active={{
                                      bg: theme.buttons.tercary.pressedBgColor,
                                    }}
                                    style={{
                                      width: "fit-content",
                                      height: "fit-content",
                                      display:
                                        isExpanded ||
                                        perkLists[editingPerkListIndex]
                                          ?.perks?.length <= 0
                                          ? "none"
                                          : "unset",
                                      marginRight: "0.31rem",
                                    }}
                                    onClick={() => {
                                      setEditingPerkIndex(
                                        isExpanded ? null : index
                                      );
                                      setFieldValue(
                                        "perkName",
                                        perkLists[editingPerkListIndex]
                                          ?.perks[index].name
                                      );
                                      setFieldValue(
                                        "perkDescription",
                                        perkLists[editingPerkListIndex]
                                          ?.perks[index].description
                                      );
                                      setFieldValue(
                                        "perkQuantity",
                                        perkLists[editingPerkListIndex]
                                          ?.perks[index].quantity
                                      );
                                      setFieldValue(
                                        "perkPrice",
                                        perkLists[editingPerkListIndex]
                                          ?.perks[index].price
                                      );
                                      setFieldValue(
                                        "perkImage",
                                        perkLists[editingPerkListIndex]
                                          ?.perks[index].image
                                      );
                                    }}
                                  >
                                    <img
                                      src={editIcon}
                                      alt={"edit-icon"}
                                      style={{
                                        width: "1.5rem",
                                        height: "1.5rem",
                                      }}
                                    />
                                  </AccordionButton>
                                )}
                              </Box>
                              <AccordionPanel p={"1rem 1rem 1rem 1rem"}>
                                <VStack marginBottom={"1.25rem"} w="100%">
                                  <InputBox
                                    values={values}
                                    handleBlur={handleBlur}
                                    handleChange={(e) => {
                                      // Update the existing perk with the edited data
                                      const updatedPerk = {
                                        name: e.target.value,
                                        description: perk.description,
                                        quantity: perk.quantity,
                                        price: perk.price,
                                        perkRemainingSupply:
                                          perk.perkRemainingSupply,
                                        perkId: perk.perkId,
                                        image:perk.image
                                      };
                                      updatePerk(updatedPerk);
                                      handleChange(e);
                                      setErrorFields({
                                        ...errorFields,
                                        perkName: e.target.value ? false : true,
                                      });
                                    }}
                                    placeholder={"Name"}
                                    maxW="100%"
                                    name="perkName"
                                    error={errorFields?.perkName}
                                  />
                                </VStack>
                                <InputBox
                                  values={values}
                                  handleBlur={handleBlur}
                                  handleChange={(e) => {
                                    // Update the existing perk with the edited data
                                    const updatedPerk = {
                                      name: perk.name,
                                      description: e.target.value,
                                      quantity: perk.quantity,
                                      price: perk.price,
                                      perkRemainingSupply:
                                        perk.perkRemainingSupply,
                                      perkId: perk.perkId,
                                      image:perk.image

                                    };
  
                                    updatePerk(updatedPerk);
                                    handleChange(e);
                                    setErrorFields({
                                      ...errorFields,
                                      perkDescription: e.target.value
                                        ? false
                                        : true,
                                    });
                                  }}
                                  placeholder={"Description (optional)"}
                                  maxW="100%"
                                  name="perkDescription"
                                  error={errorFields?.perkDescription}
                                />
                                <HStack mt={20} spacing={19} w="100%" marginBottom={"1.25rem"}>
                                  <InputBox
                                    values={values}
                                    handleBlur={handleBlur}
                                    handleChange={(e) => {
                                      // Update the existing perk with the edited data
                                      const updatedPerk = {
                                        name: perk.name,
                                        description: perk.description,
                                        quantity: e.target.value,
                                        price: perk.price,
                                        perkRemainingSupply:
                                          perk.perkRemainingSupply +
                                          (Number(e.target.value) -
                                            perk.quantity),
                                        perkId: perk.perkId,
                                        image:perk.image

                                      };
  
                                      updatePerk(updatedPerk);
                                      handleChange(e);
                                      setErrorFields({
                                        ...errorFields,
                                        perkQuantity: e.target.value
                                          ? false
                                          : true,
                                      });
                                    }}
                                    placeholder={"Quantity"}
                                    maxW="100%"
                                    name="perkQuantity"
                                    type="number"
                                    error={errorFields?.perkQuantity}
                                  />
                                  <InputBox
                                    values={values}
                                    handleBlur={handleBlur}
                                    handleChange={(e) => {
                                      // Update the existing perk with the edited data
                                      const updatedPerk = {
                                        name: perk.name,
                                        description: perk.description,
                                        quantity: perk.quantity,
                                        price: e.target.value,
                                        perkRemainingSupply:
                                          perk.perkRemainingSupply,
                                        perkId: perk.perkId,
                                        image:perk.image
                                      };
                                      console.log('jjjjjj',index);
                                      updatePerk(updatedPerk);
                                      handleChange(e);
                                      setErrorFields({
                                        ...errorFields,
                                        perkPrice: e.target.value
                                          ? false
                                          : true,
                                      });
                                    }}
                                    placeholder={"Price"}
                                    maxW="100%"
                                    name="perkPrice"
                                    type="number"
                                    error={errorFields?.perkPrice}
                                  />
                                </HStack>
                                <input
  type="file"
  ref={fileInputRef}
  style={{ display: 'none' }}
  onChange={async (event) => {
    await handleImageUpload(event, imageIndex);
    handleChange(event);
  }}
/>


    {perk.image ? (
      <Box style={{ position: 'relative' }}>
      <img
  src={perk.image}
  alt="image-icon"
  style={{
    display: 'block', // Ensures that margin: auto works
    width: '13.6875rem',
    height: '17.125rem',
    borderRadius: '1.5625rem',
    margin: 'auto', // Center horizontally
  }}
  
/>
<IconButton
                        icon={<DeleteIcon />}
                        aria-label="Close video"
                        position="absolute"
                        color="red"
                        top={2}
                        right={2}
                        zIndex={1}
                        onClick={() => {  
                          const updatedPerk = {
                            name: perk.name,
                            description: perk.description,
                            quantity: perk.quantity,
                            price: perk.price,
                            perkRemainingSupply: perk.perkRemainingSupply,
                            perkId: perk.perkId,
                            image: ""
                          };
                          updatePerk(updatedPerk);
                          handleChange(e);
                        }}
                        

                        size="lg"
                      />
                      </Box>

                    ):(
                    <Button
                    sx={theme.buttons.secondary.shape}
                    bg={theme.buttons.secondary.bgColor}
                    color={theme.buttons.secondary.color}
                    _hover={{
                      borderColor: theme.buttons.secondary.hoverBorderColor,
                    }}
                    _active={{
                      bg: theme.buttons.secondary.pressed,
                    }}
                    style={{
                      width: "100%",
                      textTransform: "capitalize",
                    }}
                    onClick={() => {
                      fileInputRef.current.click(); // Trigger file input
                      setImageIndex(perk);
                    }}
                  >
                      Upload An Image
</Button>)}


               
                              </AccordionPanel>
                            </>
                          )}
                        </AccordionItem>
                      )
                    )}
                  </Accordion>
                </Box>
  
                <Button
                  sx={theme.buttons.secondary.shape}
                  bg={theme.buttons.secondary.bgColor}
                  color={theme.buttons.secondary.color}
                  _hover={
                    !(
                      !values.perkName ||
                      !values.perkPrice ||
                      !values.perkQuantity
                    )
                      ? {
                          borderColor: theme.buttons.secondary.hoverBorderColor,
                        }
                      : {} // Empty object when disabled to prevent hover effect
                  }
                  _active={{
                    bg: theme.buttons.secondary.pressed,
                  }}
                  disabled={
                    
                    !values.perkName ||
                    //  !values.perkDescription ||
                    !values.perkPrice ||
                    !values.perkQuantity
                      ? true
                      : false
                  }
                  style={{
                    width: "100%",
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    const {
                      perkName,
                      perkDescription,
                      perkPrice,
                      perkQuantity,
                    } = values;
                    if (
                      !perkName &&
                      !perkDescription &&
                      !perkPrice &&
                      !perkQuantity
                    )
                      return;
                    addPerk(editingPerkListIndex, "", "", "", "");
                    setEditingPerkIndex(
                      perkLists[editingPerkListIndex]?.perks.length - 1
                    );
                    setFieldValue("perkName", "");
                    setFieldValue("perkDescription", "");
                    setFieldValue("perkQuantity", "");
                    setFieldValue("perkPrice", "");
                    setFieldValue("perkImage", "");
                  }}
                >
                  Create Another Perk
                </Button>
  
                <div
                  id="error-message"
                  style={{ color: "red", marginTop: "10px" }}
                >
                  {/* Error message will be displayed here */}
                </div>
              </>
            </ModalBody>
            <ModalFooter paddingTop={0}>
              <Button
                mr={3}
                onClick={cancelPerkModel}
                sx={theme.buttons.tercary.shape}
                bg={theme.buttons.tercary.bgColor}
                color={theme.buttons.tercary.color}
                _hover={{
                  bg: theme.buttons.tercary.hoverBgColor,
                }}
                _active={{
                  bg: theme.buttons.tercary.pressedBgColor,
                }}
                style={{
                  width: "fit-content",
                  minWidth: "6.25rem",
                  padding: "0rem 1.25rem",
                }}
                className="gordita700"
              >
                Cancel
              </Button>
              <Button
                sx={theme.buttons.primary.shape}
                bg={theme.buttons.primary.default}
                color={theme.buttons.primary.color}
                _hover={
                  !(
                    !values.listName ||
                    !values.perkName ||
                    !values.perkQuantity ||
                    values.perkPrice === null ||
                    values.perkPrice === undefined 
                  )
                    ? {
                      bg: theme.buttons.primary.hover,
                      boxShadow: theme.buttons.primary.hoverBoxShadow,
                      }
                    : {} // Empty object when disabled to prevent hover effect
                }
                _active={{
                  boxShadow: theme.buttons.primary.activeBoxShadow,
                }}
                disabled={
                    
                  !values.listName ||
                    !values.perkName ||
                    !values.perkQuantity ||
                    values.perkPrice === null ||
                    values.perkPrice === undefined 
                    ? true
                    : false
                }
                onClick={() => {
                  const {
                    perkName,
                    perkDescription,
                    perkQuantity,
                    perkPrice,
                    listName,
                  } = values;
  
                  // Check if any of the required fields is empty
                  if (
                    !listName ||
                    !perkName ||
                    !perkQuantity ||
                    perkPrice === null ||
                    perkPrice === undefined 
                  ) {
                    setErrorFields({
                      listName: listName ? false : true,
                      perkName: perkName ? false : true,
                      perkQuantity: perkQuantity ? false : true,
                      perkPrice: perkPrice ? false : true,
                      // perkDescription: perkDescription ? false : true,
                    });
                    // Set the error message
                    var errorMessage="Please fill out all required fields";
                    
  
                    document.getElementById("error-message").textContent =
                      errorMessage;
                    document.getElementById("error-message").style.color =
                      "#FF6B6B";
                    return; 
                  }
                  setErrorFields({
                    listName: false,
                    perkName: false,
                    perkQuantity: false,
                    perkPrice: false,
                    perkDescription: false,
                  });
  
                  setFieldValue("perkLists", [...perkLists]);
                  setEditMode(false);
                  setEditingPerkListIndex(null);
                  setEditingPerkIndex(null);
  
                  // Clear form fields and close the modal
                  setFieldValue("listName", "");
                  setFieldValue("perkName", "");
                  setFieldValue("perkDescription", "");
                  setFieldValue("perkQuantity", "");
                  setFieldValue("perkPrice", "");
                  setFieldValue("perkImage", "");
                  onClose();
                }}
                style={{ width: "fit-content", textTransform: "capitalize" }}
                className="gordita700"
              >
                {editMode ? "Update" : "Create"} Perk List
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isPerkDialogOpen}
        onClose={() => onClosePerkDialog()}
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent
          bg={"rgba(0, 0, 0, 0.50)"}
          // backdropFilter={"blur(150px)"}
          borderRadius={"1.5625rem"}
          border={"1px solid #333333"}
          boxShadow={"0px 0px 100px 150px rgba(0, 0, 0, 0.30)"}
          backdropFilter={"blur(75px)"}
          padding={"0.525rem 0 0"}
          maxW={"28.5rem"}
          style={{
            width: "28.5rem !important",
          }}
        >
          <ModalCloseButton
            top={"1.5rem"}
            right={"1.5rem"}
            //onClick={cancelPerkModel}
          />
          <ModalBody
            display={"flex"}
            flexDir={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box
              width={"26.625rem"}
              height={"20.4375rem"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              borderRadius={"0.625rem"}
              backgroundColor={"rgba(255, 255, 255, 0.10)"}
            >
              <img
        src={frame3}  // Replace with your actual image URL
        alt="frame3"
        style={{ maxWidth: '100%', maxHeight: '100%' }}
      />
            </Box>
            <Box
              width={"100%"}
              display={"flex"}
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"flex-start"}
              padding={"1.56rem 0.94rem"}
            >
              <Text
                fontSize="1.25rem"
                className={"gordita600"}
                textTransform={"capitalize"}
              >
                Perks
              </Text>
              <Text
                mt={"0.62rem"}
                fontSize={"1rem"}
                color={"rgba(255, 255, 255, 0.60)"}
                className="gordita400"
              >
                Perks can be any add-on you offer and/or sell in your event. You can get widely creative with them.
The attendees will buy them after selecting their tickets.
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter paddingTop={0}>
            <Button
              sx={theme.buttons.primary.shape}
              bg={theme.buttons.primary.default}
              color={theme.buttons.primary.color}
              _hover={{
                bg: theme.buttons.primary.hover,
                boxShadow: theme.buttons.primary.hoverBoxShadow,
              }}
              _active={{
                boxShadow: theme.buttons.primary.activeBoxShadow,
              }}
              onClick={() => {
                createPerks();
                onClosePerkDialog();
              }}
              style={{
                width: "fit-content",
                padding: "0rem 1.5625rem",
                textTransform: "capitalize",
              }}
              className="gordita700"
            >
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isOpen2}
        onClose={() => onClose2()}
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent
            bg={"rgba(0, 0, 0, 0.50)"}
            // backdropFilter={"blur(150px)"}
            borderRadius={"1.5625rem"}
            border={"1px solid #333333"}
            boxShadow={"0px 0px 100px 150px rgba(0, 0, 0, 0.30)"}
            backdropFilter={"blur(75px)"}
            padding={"0.64rem 0"}
          >
            <ModalHeader>
            Assign A Perks List
            </ModalHeader>
          <ModalCloseButton
            top={"1.5rem"}
            right={"1.5rem"}
            //onClick={cancelPerkModel}
          />
          <ModalBody
            display={"flex"}
            flexDir={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
           <Box width={"100%"} mb={"1.87rem"} mt={"1.87rem"}>
                {existingLists.length !== 0 ? (
                  existingLists?.map((list) => (
                <Checkbox
                isChecked={activePerks.some(item => item.perkListId === list?.perkListId)}
                colorScheme="white"
                key={list?.perkListId}
                onChange={() => handleCheckboxToggle(list)}
                w={"100%"}
                isDisabled={perkLists.some(localPerkList => localPerkList.listName === list.listName)}
                >

                        <div
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            flexDirection: "column",
                            gap: ".25rem",
                            width: "30rem",
                            border: "1px solid",
                            borderColor: activePerks.some(item => item.perkListId === list?.perkListId)
                              ? "#FFF"
                              : "rgba(255, 255, 255, 0.20)",
                            margin: ".25rem",
                            padding: ".5rem 1.75rem",
                            borderRadius: "50px",
                          }}
                        >
                             <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={"1.82rem"}
            margin={"auto 0.61rem"}
            key={list?.perkListId}
          >
            <Text
              className={"gordita400"}
              fontSize={"1rem"}
              lineHeight={"150%"}
              letterSpacing={"-0.01rem"}
              textOverflow={"ellipsis"}

            >
               {list?.listName}
            </Text>
            <Text
              className={"gordita400"}
              fontSize={"1rem"}
              lineHeight={"150%"}
              letterSpacing={"-0.01rem"}
            >
               {list?.perks.length}u
            </Text>
            </Box>
                          
                        </div>
                      </Checkbox>
                    ))
                ) : (
                  <VStack justifyContent={"center"}  alignItems={"center"} width={"100%"} spacing={"25"} display={"flex"}>
                    <Text marginTop="4rem" fontSize="1.25rem" fontWeight="700">
                      You have no existing Perk Lists
                    </Text>
                    <Button
                                 sx={theme.buttons.secondary.shape}
                                 bg={theme.buttons.secondary.bgColor}
                                 color={theme.buttons.secondary.color}
                                 _hover={{
                                   borderColor: theme.buttons.secondary.hoverBorderColor,
                                 }}
                                 _active={{
                                   bg: theme.buttons.secondary.pressed,
                                 }}
                                 style={{
                                   width: "auto",
                                   marginTop: "1.56rem",
                                   textTransform: "capitalize",
                                 }}
                                 className="gordita700"
                      onClick={() => {
                        onClose2();
                        if (isPerkDialogOpened) {
                            createPerks();
                          } else {
                            onOpenPerkDialog();
                           // setIsPerkDialogOpened(true);
                          }

                      }}  
                    >
                      Create A Perks List!
                    </Button>
                  </VStack>
                )}
              </Box>
          </ModalBody>
          <ModalFooter paddingTop={0}>
          <Button
                mr={3}
                onClick={() => {
                    onClose2();
                  }}                
                sx={theme.buttons.tercary.shape}
                bg={theme.buttons.tercary.bgColor}
                color={theme.buttons.tercary.color}
                _hover={{
                  bg: theme.buttons.tercary.hoverBgColor,
                }}
                _active={{
                  bg: theme.buttons.tercary.pressedBgColor,
                }}
                style={{
                  width: "fit-content",
                  minWidth: "6.25rem",
                  padding: "0rem 1.25rem",
                }}
                className="gordita700"
              >
                Cancel
              </Button>
            <Button
              sx={theme.buttons.primary.shape}
              bg={theme.buttons.primary.default}
              color={theme.buttons.primary.color}
              _hover={{
                bg: theme.buttons.primary.hover,
                boxShadow: theme.buttons.primary.hoverBoxShadow,
              }}
              _active={{
                boxShadow: theme.buttons.primary.activeBoxShadow,
              }}
              onClick={() => {
                assignPerks(activePerks);
                onClose2();
                setActivePerks([]);
              }}
              style={{
                width: "fit-content",
                padding: "0rem 1.5625rem",
                textTransform: "capitalize",
              }}
              className="gordita700"
              disabled={activePerks.length <= 0}

            >
              Assign
            </Button>
            
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isOpen3}
        onClose={() => onClose3()}
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent
            bg={"rgba(0, 0, 0, 0.50)"}
            // backdropFilter={"blur(150px)"}
            borderRadius={"1.5625rem"}
            border={"1px solid #333333"}
            boxShadow={"0px 0px 100px 150px rgba(0, 0, 0, 0.30)"}
            backdropFilter={"blur(75px)"}
            padding={"0.64rem 0"}
          >
            <ModalHeader>
            {selectedPerkList?.listName}
            </ModalHeader>
          <ModalCloseButton
            top={"1.5rem"}
            right={"1.5rem"}
            //onClick={cancelPerkModel}
          />
          <ModalBody
            display={"flex"}
            flexDir={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
           <Box display={"flex"} flexDir={"column"} gap={"0.62rem"}>
                      {selectedPerkList?.perks?.map((perk, index) =>
                        perkItem(perk, index)
                      )}
                    </Box> 
          </ModalBody>
          <ModalFooter paddingTop={0}>
          <Button
                mr={3}
                onClick={() => {
                    onClose3();
                  }}                
                sx={theme.buttons.tercary.shape}
                bg={theme.buttons.tercary.bgColor}
                color={theme.buttons.tercary.color}
                _hover={{
                  bg: theme.buttons.tercary.hoverBgColor,
                }}
                _active={{
                  bg: theme.buttons.tercary.pressedBgColor,
                }}
                style={{
                  width: "fit-content",
                  minWidth: "6.25rem",
                  padding: "0rem 1.25rem",
                }}
                className="gordita700"
              >
                Close
              </Button>
            
          </ModalFooter>
        </ModalContent>
      </Modal>
        </Box>
    );
  };
  
  export default Perks;
  