import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  HStack,
  Image,
  Text,
  VStack,
  Heading,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal,
  useDisclosure,
  Flex,
  Icon,
  List,
  ListItem,
  ListIcon,
  useToast,
  Input,
  Divider,
} from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { useNavigate, Navigate } from "react-router-dom";
import moment from "moment";
import Layout from "../../modules/layout/layout";
import { useMediaQuery } from "../../utils/useMediaQuery";
import { user } from "../../recoil/atoms/user";
import {
  RegistraionModalTypes,
  registration,
} from "../../recoil/atoms/registration";
import { formatDate, formatTime } from "../portalys/newsevents";
import { theme } from "../../styles/theme/base";
import axios from "../../utils/api/axios";
import TicketModal from "../myTickets/model";
import { FaApple,FaGoogleWallet, FaTrash, FaCheckCircle, FaInfoCircle, FaUser, FaEnvelope, FaPhone, FaLink, FaEdit } from 'react-icons/fa';
import appleWallet from "../../static/assets/images/US-UK_Add_to_Apple_Wallet_RGB_101421.svg";
import googleWallet from "../../static/assets/images/enUS_add_to_google_wallet_add-wallet-badge.svg";
import exportedBackground from '../../static/assets/images/exported-background.jpg';
import {QRAppComponentDesktop} from '../appPromo/downloadApp';
import secrets from "../../secrets";


function ResellDashboard(props) {
  const navigate = useNavigate();
  const toast = useToast();
  const isTablet = useMediaQuery("(max-width: 1192px)");
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isInstructionsOpen,
    onOpen: onInstructionsOpen,
    onClose: onInstructionsClose,
  } = useDisclosure();
  const [_, setUser] = useRecoilState(user);
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [isTicketsModalOpen, setIsTicketsModalOpen] = useState(false);
  const [_R, setRegistrationModal] = useRecoilState(registration);
  const [isStripeConnected, setIsStripeConnected] = useState(false);
  const [userBalance, setUserBalance] = useState({currentBalance: 0, allTimeBalance: 0});
  const [listings, setListings] = useState([]);
  const [offers, setOffers] = useState([]);
  const [selectedListing, setSelectedListing] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const {
    isOpen: isOfferModalOpen,
    onOpen: onOfferModalOpen,
    onClose: onOfferModalClose,
  } = useDisclosure();
  const {
    isOpen: isSellerDetailsOpen,
    onOpen: onSellerDetailsOpen,
    onClose: onSellerDetailsClose,
  } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  const [userData, setUserData] = useRecoilState(user);
  const [isEditingPrice, setIsEditingPrice] = useState(false);
  const [newPrice, setNewPrice] = useState("");

  useEffect(() => {
    // Function to check if the URL contains the payment_intent parameter
    const hasPaymentIntentParam = () => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.has('payment_intent');
    };
    const hasListedParam = () => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.has('listed');
    };
    if (hasPaymentIntentParam() || hasListedParam()) {
      onOpen2(); // Open the modal
    }
  }, [onOpen2]);

  const handleCloseDisclaimer2 = () => {
    onClose2();
    navigate('/resell-dashboard');
  };
  const handlePortalysDownloadClick = () => {
    window.open(secrets.portalysDownloadLink, '_blank');
  };


  const stripeCheck = async () => {
    const token = _?.token;

    try {
      const response = await axios.get("/api/reseller/onboarded", {
        headers: {
          "x-auth-token": "Bearer " + token,
        },
        params: {
          userId: _?.userData?.userId,
        },
      });

      // Redirect the user to the Stripe Connect onboarding
      setIsStripeConnected(response.data.status);
    } catch (error) {
      console.error("Error installing hubspot:", error);
      // Handle error and provide user feedback
    }
  };

  const getDashboardData = async () => {
    const token = _?.token;

    try {
      const response = await axios.get("/api/reseller/dashboard", {
        headers: {
          "x-auth-token": "Bearer " + token,
        },
        params: {
          userId: _?.userData?.userId,
        },
      });

      // Redirect the user to the Stripe Connect onboarding
      setUserBalance(response.data.balance);
      setListings(response.data.listings);
      setOffers(response.data.offers);
    } catch (error) {
      console.error("Error installing hubspot:", error);
      // Handle error and provide user feedback
    }
  };

  useEffect(() => {
    if (userData?.token) {
      stripeCheck();
      getDashboardData();
    }
  }, [userData?.token]);

  const handleStripe = async () => {
    const token = _?.token; // Make sure you have the correct token

    try {
      const response = await axios.get(
        "/api/reseller/stripe", // Note that we removed "/api" from the path
        {
          headers: {
            "x-auth-token": "Bearer " + token,
          },
          params: {
            userId: _?.userData?.userId,
          },
        }
      );

      // Redirect the user to the Stripe Connect onboarding
      window.location.href = response.data.redirectUrl;
    } catch (error) {
      console.error("Error setting up payments:", error);
      // Handle error and provide user feedback
    }
  };

  const getStatusTextListing = (sold, isDelivered) => {
    if (isDelivered === true) {
      return "Delivered";
    }
    if (sold === true) {
      return "Sold";
    }else{
      return "Listed";
    }
  };
  const getStatusColorListing = (sold, isDelivered) => {
    if (isDelivered === true) {
      return "#02F6AD";
    }
    if (sold === true) {
      return "#FF1B1B";
    }   else{
      return "rgba(255, 255, 255, 0.60)";
    }
   
  };

  const getStatusTextOffer = (bought, isReceived) => {
    if (isReceived === true) {
      return "Received";
    }
    if (bought === true) {
      return "Bought";
    }else{
      return "Offer Pending";
    }
  };    
  const getStatusColorOffer = (bought, isReceived) => {
    if (isReceived === true) {
      return "#02F6AD";
    }
    if (bought === true) {
      return "#FF1B1B";
    }else{
      return "rgba(255, 255, 255, 0.60)";
    }
  };


  const handleListingClick = (listing) => {
    setSelectedListing(listing);
    setIsEditingPrice(false);
    setNewPrice(listing.price);
    onOpen();
  };
  
  const handleOfferClick = (offer) => {
    setSelectedOffer(offer);
    onOfferModalOpen();
  };

  const handleDeleteListing = async () => {
    try{
   await axios.delete(`/api/reseller/deleteListing/${selectedListing.listingId}`);

    onClose();
    getDashboardData();
  }catch(error){
    console.error("Error deleting listing:", error);
  }
  };

  const handleMarkDelivered = async () => {
    try{
      await axios.post(`/api/reseller/markAsDelivered/${selectedListing.listingId}`);
      onClose();
      toast({
        title: "Listing marked as delivered",
        status: "success",
      });
      getDashboardData();
    }catch(error){
      console.error("Error marking as delivered:", error);
    }
  };

  const handleGoToListingEvent = () => {
    navigate(`/resell/event/${selectedListing.event.slug}`);
  };

  const handleGoToOfferEvent = () => {
    navigate(`/resell/event/${selectedOffer.event.slug}`);
  };

  const handleViewInstructions = () => {
    onInstructionsOpen();
  };

  const handleDeleteOffer = async () => {
    try {
      await axios.delete(`/api/reseller/deleteOffer/${selectedOffer.offerId}`);
      onOfferModalClose();
      toast({
        title: "Offer deleted successfully",
        status: "success",
      });
      // Refresh offers list
      getDashboardData();
    } catch (error) {
      console.error("Error deleting offer:", error);
      toast({
        title: "Error deleting offer",
        status: "error",
      });
    }
  };

  const handleViewSellerDetails = () => {
    onSellerDetailsOpen();
  };

  const handleMarkReceived = async () => {
    try {
      await axios.post(`/api/reseller/markAsReceived/${selectedOffer.offerId}`);
      onOfferModalClose();
      toast({
        title: "Offer marked as received",
        status: "success",
      });
      // Refresh offers list
      getDashboardData();
    } catch (error) {
      console.error("Error marking offer as received:", error);
      toast({
        title: "Error marking offer as received",
        status: "error",
      });
    }
  };

  // Add this function to handle login redirect
  const handleLoginRedirect = () => {
    setRegistrationModal((lp) => {
        return {
          ...lp,
          openModal: true,
          modalType: RegistraionModalTypes.SIGNUP,
        };
      });
  };

  // Add this function to handle navigation to contact-us page
  const handleNeedHelp = () => {
    navigate('/contact-us');
  };

  const handleEditListingPriceClick = () => {
    setIsEditingPrice(true);
  };

  const handlePriceSubmit = async () => {
    if (newPrice) {
      try {
       const response = await axios.put(`/api/editListingPrice/${selectedListing.listingId}`, {
          newPrice: newPrice,
        });
        onClose();
        toast({
          title: response.data.message,
          status: "success",
        });
        getDashboardData();
      } catch (error) {
        console.error("Error updating listing price:", error);
        toast({
          title: "Error updating listing price",
          status: "error",
        });
      }
    }
  };

  // If user is not logged in, show login prompt
  if (!userData?.token) {
    return (
      <Layout {...props} alignItems="center" justifyContent="center">
        <Box
          maxWidth="1220px"
          width="100%"
          margin={"0 auto 5.31rem"}
          padding={isTablet ? "0 1.25rem" : "0"}
          textAlign="center"
          justifyContent="center"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Text
            fontSize={isMobile ? "1.75rem" : "2.375rem"}
            className="gordita600"
            marginBottom="2rem"
          >
            Resale Dashboard
          </Text>
          <Text fontSize="1.25rem" marginBottom="2rem">
            Please log in to access your Resale Dashboard.
          </Text>
          <Button
            onClick={handleLoginRedirect}
            sx={theme.buttons.primary.shape}
            bg={theme.buttons.primary.default}
            color={theme.buttons.primary.color}
            _hover={{
              bg: theme.buttons.primary.hover,
              boxShadow: theme.buttons.primary.hoverBoxShadow,
            }}
            _active={{
              boxShadow: theme.buttons.primary.activeBoxShadow,
            }}
          >
            Log In
          </Button>
        </Box>
      </Layout>
    );
  }

  return (
    <Layout {...props} alignItems="center" justifyContent="center">
      <Box
        maxWidth="1220px"
        width="100%"
        margin={"0 auto 5.31rem"}
        padding={isTablet ? "0 1.25rem" : "0"}
        position="relative"
      >
        <Text
          textAlign={"center"}
          margin={isMobile ? "1.19rem auto" : "1.31rem auto"}
          fontSize={isMobile ? "1.75rem" : "2.375rem"}
          className="gordita600"
          lineHeight={"150%"}
          letterSpacing={"-0.02375rem"}
          textTransform={"capitalize"}
        >
          Resale Dashboard
        </Text>
        {isMobile && (
          <Button
            onClick={handleNeedHelp}
            marginTop="1rem"
            bg={theme.buttons.secondary.bgColor}
            color={theme.buttons.secondary.color}
            _hover={{
              borderColor: theme.buttons.secondary.hoverBorderColor,
            }}
            _active={{
              bg: theme.buttons.secondary.pressed,
            }}
            //zIndex="1000"

          >
            Need Help?
          </Button>
        )}
        {!isMobile && (
          <Button
            onClick={handleNeedHelp}
            position="absolute"
            right="1rem"
            bg={theme.buttons.secondary.bgColor}
            color={theme.buttons.secondary.color}
            _hover={{
              borderColor: theme.buttons.secondary.hoverBorderColor,
            }}
            _active={{
              bg: theme.buttons.secondary.pressed,
            }}
            
          >
            Need Help?
          </Button>
        )}
        {listings?.length>0 ? (
            <>
        <Box>
          <Text
            className="gordita600"
            fontSize={isMobile ? "1.4375rem" : "1.8125rem"}
            lineHeight={"150%"}
            letterSpacing={"-0.01813rem"}
            textTransform={"capitalize"}
            marginBottom={isMobile ? "0.94rem" : "1.87rem"}
          >
            My Earnings
          </Text>
          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            justifyContent="space-between"
            alignItems={isMobile ? "flex-start" : "center"}
            padding="1.5rem"
            borderRadius="0.5rem"
            backgroundColor="#000000"
            border="1px solid rgba(255, 255, 255, 0.20)"
            marginBottom="2rem"
          >
            <VStack align="flex-start" spacing="0.5rem" mb={isMobile ? "1rem" : "0"}>
              <Text fontSize="1rem" fontWeight="600" color="#FFFFFF">
                Current Balance
              </Text>
              <Text fontSize="1.5rem" fontWeight="700" color="#FFFFFF">
                ${userBalance?.currentBalance || 0.00}
              </Text>
            </VStack>
            <VStack align="flex-start" spacing="0.5rem" mb={isMobile ? "1rem" : "0"}>
              <Text fontSize="1rem" fontWeight="600" color="#FFFFFF">
                All-Time Balance
              </Text>
              <Text fontSize="1.5rem" fontWeight="700" color="#FFFFFF">
                ${userBalance?.allTimeBalance || 0.00}
              </Text>
            </VStack>
            <Button
              onClick={handleStripe}
              sx={theme.buttons.primary.shape}
              bg={theme.buttons.primary.default}
              color={theme.buttons.primary.color}
              _hover={{
                bg: theme.buttons.primary.hover,
                boxShadow: theme.buttons.primary.hoverBoxShadow,
              }}
              _active={{
                boxShadow: theme.buttons.primary.activeBoxShadow,
              }}
              css={{
                width: "auto",
              }}
            >
              {isStripeConnected ? "View Stripe Account" : "Connect Bank Account"}
            </Button>
          </Box>
        </Box>
        <Box>
          <Text
            className="gordita600"
            fontSize={isMobile ? "1.4375rem" : "1.8125rem"}
            lineHeight={"150%"}
            letterSpacing={"-0.01813rem"}
            textTransform={"capitalize"}
            marginTop={isMobile ? "2.81rem" : "6.25rem"}
            marginBottom={isMobile ? "0.94rem" : "1.87rem"}
          >
            My Listings
          </Text>

          
          <Box
              w="100%"
              display="flex"
              justifyContent={isMobile ? "center" : "flex-start"}
              alignItems="center"
              overflowX={isMobile ? "visible" : "scroll"}
              overflowY="hidden"
              gap={"0.62rem"}
              rowGap={isMobile ? "0.62rem" : "1.25rem"}
              columnGap={"1.25rem"}
              flexWrap={isMobile ? "wrap" : "nowrap"}
            >
              {listings?.map((listing, index) => {
                  let {
                    eventImage: image,
                    eventName: heading,
                    startDate: sdate,
                    startTime: time,
                    endTime: etime,
                    city,
                    country,
                    timezone,
                  } = listing.event;
                  const formattedDate = formatDate(sdate, timezone);
                  const formattedTime = formatTime(time);
                  const formattedETime = formatTime(etime);
                  return (
                    <Box
                      key={index}
                      sx={theme.card.primary.shape}
                      css={{
                        height: isMobile ? "auto" : "31.938rem",
                        display: "flex",
                        flexDirection: isMobile ? "column" : "column",
                        justifyContent: "flex-start",
                        alignItems: "stretch",
                        gap: "0.62rem",
                        padding: isMobile ? "0.31rem" : "0.62rem 0.62rem 1.56rem",
                        cursor: "pointer",
                      }}
                      onClick={() => handleListingClick(listing)}
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        pos="relative"
                        width="100%"
                      >
                        <Box
                          flexShrink="0"
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          pos="absolute"
                          left="1.25rem"
                          top="1.25rem"
                          zIndex="1"
                        >
                          <Box
                            px="1rem"
                            py="0.375rem"
                            fontSize="0.875rem"
                            w="max-content"
                            backgroundColor={getStatusColorListing(listing.sold, listing.isDelivered)}
                            borderRadius="6.25rem"
                            className="gordita600"
                            color="black.100"
                          >
                            {getStatusTextListing(listing.sold, listing.isDelivered)}
                          </Box>
                        </Box>
                        <Image
                          w="100%"
                          h={isMobile ? "10rem" : "22.5rem"}
                          objectFit="cover"
                          borderRadius="var(--radius-radius-big, 0.9375rem)"
                          src={image}
                        />
                      </Box>

                      <VStack
                        padding={isMobile ? "0.5rem" : "1.25rem 0.62rem"}
                        display="flex"
                        gap="0.5rem"
                        alignItems="flex-start"
                        width="100%"
                      >
                        <Heading
                          color="#FBFAFB"
                          className="gordita700"
                          fontSize={isMobile ? "1rem" : "1.4375rem"}
                          textTransform="capitalize"
                          style={{
                            width: "100%",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {heading}
                        </Heading>

                        <HStack
                          fontSize={{ base: 12, md: 14, "3xl": 16 }}
                          w="100%"
                          className="gordita400"
                          spacing={2}
                        >
                          <Text className="gordita600" whiteSpace="nowrap">
                            {formattedDate}
                          </Text>
                          <Text whiteSpace="nowrap">
                            {formattedTime + " - " + formattedETime}
                          </Text>
                        </HStack>

                        <HStack
                          fontSize={{ base: 12, md: 14, "3xl": 16 }}
                          w="100%"
                          className="gordita400"
                          spacing={2}
                        >
                          <Text
                            style={{
                              maxWidth: "50%",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            Portalys Resell
                          </Text>
                          <Text
                            color="rgba(255, 255, 255, 0.60)"
                            style={{
                              maxWidth: "50%",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {city || ""} {city && country ? ", " : ""} {country || ""}
                          </Text>
                        </HStack>
                      </VStack>
                    </Box>
                  );
                })}
            </Box>
          
        </Box>
        </>
        ):(
           <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
              <Text marginTop="4rem" fontSize="1.25rem" fontWeight="700" textAlign="center">
                You have not resold any tickets yet
              </Text>
              <Button
                marginTop="1.56rem"
                sx={theme.buttons.secondary.shape}
                bg={theme.buttons.secondary.bgColor}
                color={theme.buttons.secondary.color}
                border={theme.buttons.secondary.border}
                _hover={{
                  borderColor: theme.buttons.secondary.hoverBorderColor,
                }}
                _active={{
                  bg: theme.buttons.secondary.pressed,
                }}
                style={{
                  width: "16.75rem",
                }}
                onClick={() => navigate("/")}
              >
                Find Secondary Market Events!
              </Button>
            </Box>
        )}


        {offers?.length>0 && (
<Box>
          <Text
            className="gordita600"
            fontSize={isMobile ? "1.4375rem" : "1.8125rem"}
            lineHeight={"150%"}
            letterSpacing={"-0.01813rem"}
            textTransform={"capitalize"}
            marginTop={isMobile ? "2.81rem" : "6.25rem"}
            marginBottom={isMobile ? "0.94rem" : "1.87rem"}
          >
            My Offers
          </Text>

          
            <Box
              w="100%"
              display="flex"
              justifyContent={isMobile ? "center" : "flex-start"}
              alignItems="center"
              overflowX={isMobile ? "visible" : "scroll"}
              overflowY="hidden"
              gap={"0.62rem"}
              rowGap={isMobile ? "0.62rem" : "1.25rem"}
              columnGap={"1.25rem"}
              flexWrap={isMobile ? "wrap" : "nowrap"}
            >
              {offers?.map((offer, index) => {
                  let {
                    eventImage: image,
                    eventName: heading,
                    startDate: sdate,
                    startTime: time,
                    endTime: etime,
                    city,
                    country,
                    timezone,
                    platform,
                  } = offer.event;
                  const formattedDate = formatDate(sdate, timezone);
                  const formattedTime = formatTime(time);
                  const formattedETime = formatTime(etime);
                  return (
                    <Box
                      key={index}
                      sx={theme.card.primary.shape}
                      css={{
                        height: isMobile ? "auto" : "31.938rem",
                        display: "flex",
                        flexDirection: isMobile ? "column" : "column",
                        justifyContent: "flex-start",
                        alignItems: "stretch",
                        gap: "0.62rem",
                        padding: isMobile ? "0.31rem" : "0.62rem 0.62rem 1.56rem",
                        cursor: "pointer",
                      }}
                      onClick={() => handleOfferClick(offer)}
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        pos="relative"
                        width="100%"
                      >
                        <Box
                          flexShrink="0"
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          pos="absolute"
                          left="1.25rem"
                          top="1.25rem"
                          zIndex="1"
                        >
                          <Box
                            px="1rem"
                            py="0.375rem"
                            fontSize="0.875rem"
                            w="max-content"
                            backgroundColor={getStatusColorOffer(offer.bought, offer.isReceived)}
                            borderRadius="6.25rem"
                            className="gordita600"
                            color="black.100"
                          >
                            {getStatusTextOffer(offer.bought, offer.isReceived)}
                          </Box>
                        </Box>
                        <Image
                          w="100%"
                          h={isMobile ? "10rem" : "22.5rem"}
                          objectFit="cover"
                          borderRadius="var(--radius-radius-big, 0.9375rem)"
                          src={image}
                        />
                      </Box>

                      <VStack
                        padding={isMobile ? "0.5rem" : "1.25rem 0.62rem"}
                        display="flex"
                        gap="0.5rem"
                        alignItems="flex-start"
                        width="100%"
                      >
                        <Heading
                          color="#FBFAFB"
                          className="gordita700"
                          fontSize={isMobile ? "1rem" : "1.4375rem"}
                          textTransform="capitalize"
                          style={{
                            width: "100%",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {heading}
                        </Heading>

                        <HStack
                          fontSize={{ base: 12, md: 14, "3xl": 16 }}
                          w="100%"
                          className="gordita400"
                          spacing={2}
                        >
                          <Text className="gordita600" whiteSpace="nowrap">
                            {formattedDate}
                          </Text>
                          <Text whiteSpace="nowrap">
                            {formattedTime + " - " + formattedETime}
                          </Text>
                        </HStack>

                        <HStack
                          fontSize={{ base: 12, md: 14, "3xl": 16 }}
                          w="100%"
                          className="gordita400"
                          spacing={2}
                        >
                          <Text
                            style={{
                              maxWidth: "50%",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            Portalys Resell
                          </Text>
                          <Text
                            color="rgba(255, 255, 255, 0.60)"
                            style={{
                              maxWidth: "50%",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {city || ""} {city && country ? ", " : ""} {country || ""}
                          </Text>
                        </HStack>
                      </VStack>
                    </Box>
                  );
                })}
            </Box>
          
        </Box>
        )}
       
      </Box>

      
      

      



      <Modal isOpen={isOpen} onClose={onClose}       isCentered
      width="100%"
      margin={isMobile ? "1rem" : "auto"}>
        <ModalOverlay />
        <ModalContent   display="flex"
        margin={isMobile ? "1rem" : "auto"}
        padding="32px 24px 0 24px"
        flexDirection="column"
        alignItems="flex-start"
        borderRadius="32px"
        border="1px solid rgba(255, 255, 255, 0.24)"
        background="#000"
        overflow="auto" >
          <ModalHeader>Listing Options</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="stretch" width="100%" >
              <Button leftIcon={<Icon as={FaTrash} />} onClick={handleDeleteListing} disabled={selectedListing?.sold}>
                Delete Listing
              </Button>
              <Button leftIcon={<Icon as={FaInfoCircle} />} onClick={handleViewInstructions} disabled={!selectedListing?.sold}>
                View Sending Instructions
              </Button>
              <Button leftIcon={<Icon as={FaCheckCircle} />} onClick={handleMarkDelivered} disabled={!selectedListing?.sold || selectedListing?.isDelivered}>
                Mark as Delivered
              </Button>
              <Button leftIcon={<Icon as={FaLink} />} onClick={handleGoToListingEvent}>
                Go to Event
              </Button>
              {!isEditingPrice ? (
              <Button leftIcon={<Icon as={FaEdit} />} onClick={handleEditListingPriceClick} disabled={selectedListing?.sold}>
                Edit Listing Price
              </Button>
              ) : (
  <VStack spacing={2} align="stretch" width="100%">
    <Input
      placeholder="Enter new price"
      value={newPrice}
      type="number"
      onChange={(e) => setNewPrice(e.target.value)}
    />
    <Button onClick={handlePriceSubmit}>
      Submit
    </Button>
    <Button variant="outline" onClick={() => setIsEditingPrice(false)}>
      Cancel
    </Button>
  </VStack>
)}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isInstructionsOpen} onClose={onInstructionsClose} isCentered width="100%" margin={isMobile ? "1rem" : "auto"}>
        <ModalOverlay />
        <ModalContent
          display="flex"
          margin={isMobile ? "1rem" : "auto"}
          padding="32px 24px 0 24px"
          flexDirection="column"
          alignItems="flex-start"
          borderRadius="32px"
          border="1px solid rgba(255, 255, 255, 0.24)"
          background="#000"
          overflow="auto"
        >
          <ModalHeader>Sending Instructions</ModalHeader>
          <ModalCloseButton />
          <ModalBody width="100%">
            <VStack spacing={4} align="stretch" width="100%">
              <Text fontSize="lg" fontWeight="bold">Buyer Information:</Text>
              <List spacing={3}>
                <ListItem>
                  <ListIcon as={FaUser} color="green.500" />
                  Name: {selectedListing?.buyer?.firstName} {selectedListing?.buyer?.lastName}
                </ListItem>
                <ListItem>
                  <ListIcon as={FaEnvelope} color="green.500" />
                  Email: {selectedListing?.buyer?.email}
                </ListItem>
                <ListItem>
                  <ListIcon as={FaPhone} color="green.500" />
                  Phone: {selectedListing?.buyer?.phoneNumber}
                </ListItem>
              </List>
              <Text fontSize="md" mt={4}>
                Please use the above information to send the ticket to the buyer from the {selectedListing?.event?.platform||'platform'} app or by email.
              </Text>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" onClick={onInstructionsClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Offer Options Modal */}
      <Modal isOpen={isOfferModalOpen} onClose={onOfferModalClose} isCentered width="100%" margin="auto">
        <ModalOverlay />
        <ModalContent
          display="flex"
          margin={isMobile ? "1rem" : "auto"}          
          padding="32px 24px 0 24px"
          flexDirection="column"
          alignItems="flex-start"
          borderRadius="32px"
          border="1px solid rgba(255, 255, 255, 0.24)"
          background="#000"
          overflow="auto"
        >
          <ModalHeader>Offer Options</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="stretch" width="100%">
              <Button leftIcon={<Icon as={FaTrash} />} onClick={handleDeleteOffer} disabled={selectedOffer?.bought}>
                Delete Offer
              </Button>
              <Button leftIcon={<Icon as={FaInfoCircle} />} onClick={handleViewSellerDetails} disabled={!selectedOffer?.bought}>
                View Seller Details
              </Button>
              <Button leftIcon={<Icon as={FaCheckCircle} />} onClick={handleMarkReceived} disabled={!selectedOffer?.bought || selectedOffer?.isReceived}>
                Mark as Received
              </Button>
              <Button leftIcon={<Icon as={FaLink} />} onClick={handleGoToOfferEvent}>
                Go to Event
              </Button>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" onClick={onOfferModalClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Seller Details Modal */}
      <Modal isOpen={isSellerDetailsOpen} onClose={onSellerDetailsClose} isCentered width="100%" margin="auto">
        <ModalOverlay />
        <ModalContent
          display="flex"
          margin={isMobile ? "1rem" : "auto"}
          padding="32px 24px 0 24px"
          flexDirection="column"
          alignItems="flex-start"
          borderRadius="32px"
          border="1px solid rgba(255, 255, 255, 0.24)"
          background="#000"
          overflow="auto"
        >
          <ModalHeader>Seller Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody width="100%">
            <VStack spacing={4} align="stretch" width="100%">
              <Text fontSize="lg" fontWeight="bold">Seller Information:</Text>
              {selectedOffer?.sellers?.map((seller, index) => (
                <Box key={index}>
                  {index > 0 && <Divider my={4} />}
                  {selectedOffer?.sellers?.length > 1 && (
                    <Text fontSize="md" fontWeight="bold" mb={2}>Seller #{index + 1}</Text>
                  )}
                  <List spacing={3}>
                    <ListItem>
                      <ListIcon as={FaUser} color="green.500" />
                      Name: {seller.firstName} {seller.lastName}
                    </ListItem>
                    <ListItem>
                      <ListIcon as={FaEnvelope} color="green.500" />
                      Email: {seller.email}
                    </ListItem>
                    <ListItem>
                      <ListIcon as={FaPhone} color="green.500" />
                      Phone: {seller.phoneNumber}
                    </ListItem>
                  </List>
                </Box>
              ))}
              <Text fontSize="md" mt={4}>
                Please use the above information to contact the seller{selectedOffer?.sellers?.length > 1 ? 's' : ''} if you have any questions about the ticket.
              </Text>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" onClick={onSellerDetailsClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

              <Modal isOpen={isOpen2} onClose={handleCloseDisclaimer2} isCentered size="xl">
          <ModalOverlay />
          <ModalContent
            zIndex="999"
            maxW={isMobile ? "90%" : "42.4375rem"}
            maxHeight={"90%"}
            borderRadius={"2.125rem"}
            boxShadow={"0px 0px 100px 150px rgba(0, 0, 0, 0.30)"}
            backgroundColor={isMobile ? "#000000" : "rgba(0, 0, 0, 0.50)"}
            border={"1px solid rgba(255, 255, 255, 0.20)"}
            backdropFilter={"blur(75px)"}
            padding={"1rem 0.6rem"}
            style={{
              width: "42.4375rem !important",
            }}
          >
            <ModalHeader fontSize={isMobile ? "1.3125rem" : "1.5625rem"}
                className="gordita700"
                padding={"1.25rem"}>
              {new URLSearchParams(window.location.search).has('listed') 
                ? new URLSearchParams(window.location.search).get('listed')
                : "Payment Method Processed Successfully!"}
            </ModalHeader>
            <ModalCloseButton color="white" />
            <ModalBody marginTop={"1rem"}>
              {new URLSearchParams(window.location.search).has('listed') ? (
                <p>
                  {new URLSearchParams(window.location.search).get('listed') === 'Tickets Sold Successfully!'
                    ? `You have sold your tickets successfully! You will receive a confirmation email at ${_?.userData?.email || 'your address'}. You can see the sending instructions on this page.`
                    : 'We will notify you when we find a buyer for your tickets. You can manage your listings on this page.'}
                </p>
              ) : (
                <p>
                  You will receive a confirmation email at {_?.userData?.email || 'your address'}, and your offer will appear on this page.
                </p>
              )}
              <br />
            </ModalBody>
            <ModalFooter>
            <Button
                          variant="ghost"
                          onClick={handleCloseDisclaimer2}
                          sx={theme.buttons.secondary.shape}
                          bg={theme.buttons.secondary.bgColor}
                          color={theme.buttons.secondary.color}
                          css={{
                            height: "43px",
                            width: "fit-content",
                          }}
                          _hover={{
                            borderColor: theme.buttons.secondary.hoverBorderColor,
                          }}
                          _active={{
                            bg: theme.buttons.secondary.pressed,
                          }}
                          border={theme.buttons.secondary.border}
                        >
                          Close
                        </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>


    </Layout>
  );
}

export default ResellDashboard;
