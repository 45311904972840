import { atom } from "recoil";

export const filters = atom({
  key: "filters",
  default: {
    filters: {
      eventName: "",
      eventType: "",
    },
  },
});
