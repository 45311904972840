import {
  Box,
  Button,
  Divider,
  HStack,
  Text,
  VStack,
  Input,
  chakra,
  Image,
} from '@chakra-ui/react';
import { SignUpSchema } from '../../../utils/schema';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { google } from '../../../static/assets/images';
import {
  RegistraionModalTypes,
  registration,
} from '../../../recoil/atoms/registration';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { userRoles } from '../../../utils/constants';
import { useGoogleLogin } from '@react-oauth/google';
import { saveToken } from '../../../utils/handleTokens';
import { user } from '../../../recoil/atoms/user';
import { loginWithGoogle } from '../../../utils/actions/registration';
import { useCookies } from 'react-cookie';
import PhoneInput from 'react-phone-input-2/';
import 'react-phone-input-2/lib/bootstrap.css';
import axios from '../../../utils/api/axios';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { theme } from '../../../styles/theme/base';
import { useMediaQuery } from '../../../utils/useMediaQuery';

const initialValues = { phoneNumber: '', password: '', confirmPassword: '' };

/**
 * The function handles the sign up process using Google login and saves the user's token and data to
 * local storage and state.
 */
const SignUp = () => {
  const [_, setRegistrationModal] = useRecoilState(registration);
  const [u, setUser] = useRecoilState(user);
  const [cookies, setCookie] = useCookies(['x-auth-token']);
  const location = useLocation();

  const navigate = useNavigate();
  const userRole = userRoles.ATTENDEE;

  const signUpWithGoogle = useGoogleLogin({
    onSuccess: (res) => handleGoogleLogin(res),
    onError: (err) => console.log(err),
    prompt: 'select_account',
  });
  const [verificationStatus, setVerificationStatus] = useState('');
  const [verificationCheck, setVerificationCheck] = useState(false);

  const shouldShowContent = !location.pathname.includes('/event');

  const isMobile = useMediaQuery('(max-width: 768px)');

  const handleGoogleLogin = async (payload) => {
    const user = await loginWithGoogle({ ...payload, userRole });
    saveToken(user.accessToken, 'x-auth-token', 60 * 60, setCookie);
    saveToken(user.accessToken, 'accessToken', 60 * 60, setCookie);
    saveToken(user.refreshToken, 'refreshToken', 60 * 60 * 24 * 270, setCookie);
    localStorage.setItem('user_d', JSON.stringify(user.user));
    localStorage.setItem('x-auth-token', user.accessToken);
    setUser((lp) => {
      return {
        ...lp,
        token: user.accessToken,
        userData: user.user,
      };
    });
    if (!user.user.phoneNumber) {
      setRegistrationModal((lp) => {
        return {
          ...lp,
          openModal: true,
          modalType: RegistraionModalTypes.SIGNUP_VERIFICATION,
          userData: {},
          userRole: '',
        };
      });
    } else {
      setRegistrationModal((lp) => {
        return {
          ...lp,
          openModal: false,
          modalType: '',
          userData: {},
          userRole: '',
        };
      });
    }
  };

  const handleSendOTP = async (phoneNumber) => {
    try {
      const response = await axios.post('/api/sendOTP', { phoneNumber });
      if (response.data.success === true) {
        setVerificationStatus(response.data.message);
        console.log('aaaaaaaaachcekoiin');
        setVerificationCheck(true);
        return true;
      } else {
        setVerificationStatus(response.data.message);
        setVerificationCheck(false);
        return false;
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      setVerificationStatus('Failed to send OTP');
      return false;
    }
  };

  /* The code is rendering a sign-up form using Chakra UI components and Formik library. The form
  includes input fields for email, password, and confirm password, and buttons to sign up with email
  or Google. The form also includes validation using Yup schema and error messages for invalid
  input. When the form is submitted, it calls a function to set the registration modal state with
  the user's email and open the password modal for further registration. The code also includes a
  link to the login modal and saves the user's token and data to local storage and state after
  successful registration. */
  return (
    <Box w="100%">
      <VStack gap={20}>
        <Text className="gordita700" fontSize={{ base: 18, md: 24 }}>
          Enter Portalys!
        </Text>

        <Text
          style={{
            width: '22.0625rem',
            color: 'var(--text-t-primary, #FFF)',
            textAlign: 'center',
            fontFamily: 'Gordita',
            fontSize: '1.125rem',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '140%' /* 1.575rem */,
            letterSpacing: '-0.01125rem',
          }}
        >
          To Login or Sign up to Portalys and start buying tickets, please add
          your phone number.
        </Text>
        <Formik
          initialValues={initialValues}
          validationSchema={SignUpSchema}
          onSubmit={async (values) => {
            try {
              // Attempt to send OTP
              const response = await handleSendOTP('+' + values.phoneNumber);
              console.log('aaaa', verificationCheck);

              // Check if OTP sending was successful
              if (response) {
                // Proceed with setting the modal
                setRegistrationModal((lp) => ({
                  ...lp,
                  openModal: true,
                  modalType: RegistraionModalTypes.SIGNUP_VERIFICATION,
                  userRole,
                  userData: {
                    phoneNumber: values.phoneNumber,
                  },
                }));
              }
            } catch (error) {
              console.error('Error sending OTP:', error);
              setVerificationStatus('Failed to send OTP');
              setVerificationCheck(false);
            }
          }}
        >
          {(formik) => {
            const { handleSubmit, errors, touched, setFieldValue } = formik;

            const handlePhoneNumberChange = (value) => {
              // Check if the value is numeric or empty
              if (/^\d*$/.test(value) || value === '') {
                setFieldValue('phoneNumber', value);
              }
            };

            return (
              <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <VStack px={{ base: 0, md: 36 }} alignItems="flex-start">
                  <Text fontWeight={400} fontSize="0.75rem" pl={'1.5rem'}>
                    Phone Number
                  </Text>
                  <Field
                    type="phoneNumber"
                    name="phoneNumber"
                    id="phoneNumber"
                    render={({ field }) => (
                      <PhoneInput
                        inputProps={{
                          name: 'phoneNumber', // Specify the name for the input field
                          placeholder: 'Enter Your Phone Number',
                          style: {
                            width: '100%',
                            border: '1px solid #FFFFFF',
                            borderRadius: '6.25rem',
                            height: '3.375rem',
                            fontSize: '14px',
                            color: '#FFFFFF',
                            backgroundColor: 'transparent',
                          },
                        }}
                        style={{
                          marginTop: '0.30rem',
                        }}
                        dropdownStyle={{ backgroundColor: '#000000' }}
                        country={_?.country.toLowerCase() || 'us'} // Set your default country
                        value={field.value} // Set the value explicitly
                        onChange={handlePhoneNumberChange} // Handle input changes
                      />
                    )}
                  />

                  {errors.phoneNumber && touched.phoneNumber && (
                    <Text
                      color="red"
                      className="gordita400"
                      fontWeight={400}
                      fontSize={14}
                    >
                      {errors.phoneNumber}
                    </Text>
                  )}

                  <p
                    style={{
                      textAlign: 'center',
                      fontWeight: 700,
                      color: 'red',
                    }}
                  >
                    {verificationStatus}
                  </p>
                </VStack>

                <Box px={{ base: 0, md: 36 }} mt={24} w="100%">
                  <Button
                    type="submit"
                    className="gordita700"
                    sx={theme.buttons.primary.shape}
                    bg={theme.buttons.primary.default}
                    color={theme.buttons.primary.color}
                    css={{
                      width: '100%',
                    }}
                    _hover={{
                      bg: theme.buttons.primary.hover,
                      boxShadow: theme.buttons.primary.hoverBoxShadow,
                    }}
                    _active={{
                      boxShadow: theme.buttons.primary.activeBoxShadow,
                    }}
                  >
                    Receive SMS With The Code
                  </Button>
                </Box>

                {shouldShowContent && (
                  <>
                    <Box>
                      <HStack my={36} px={{ base: 0, md: 36 }}>
                        <Divider
                          orientation="horizontal"
                          bg="#A2A2A6"
                          w="100%"
                        />
                      </HStack>
                      <Box
                        className="gordita400"
                        fontSize={16}
                        px={{ base: 0, md: 36 }}
                        mt={24}
                        w="100%"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Text
                          style={{
                            width: '22.0625rem',
                            color: 'var(--text-t-primary, #FFF)',
                            textAlign: 'center',
                            fontFamily: 'Gordita',
                            fontSize: '1.25rem',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: '140%' /* 1.75rem */,
                            letterSpacing: '-0.0125rem',
                            textTransform: 'capitalize',
                          }}
                        >
                          Already have an account?
                        </Text>

                        <br />
                        <Button
                          sx={theme.buttons.primary.shape}
                          bg={theme.buttons.secondary.bgColor}
                          color={theme.buttons.secondary.color}
                          css={{
                            width: 'fit-content',
                          }}
                          _hover={{
                            borderColor:
                              theme.buttons.secondary.hoverBorderColor,
                          }}
                          _active={{
                            bg: theme.buttons.secondary.pressed,
                          }}
                          border={theme.buttons.secondary.border}
                          className="gordita700"
                          onClick={() =>
                            setRegistrationModal((lp) => ({
                              ...lp,
                              openModal: true,
                              modalType: RegistraionModalTypes.LOGIN_EMAIL,
                              userData: {},
                            }))
                          }
                        >
                          {' '}
                          Log In with Email
                        </Button>
                      </Box>
                    </Box>

                    {isMobile && (
                      <Box>
                        <Box
                          className="gordita400"
                          fontSize={16}
                          px={{ base: 0, md: 36 }}
                          mt={24}
                          w="100%"
                          pb={35}
                          textAlign="center"
                        >
                          Are you a verifier?
                          <chakra.span
                            color="white"
                            cursor="pointer"
                            fontWeight="700"
                            onClick={() =>
                              setRegistrationModal((lp) => {
                                return {
                                  ...lp,
                                  openModal: true,
                                  modalType: RegistraionModalTypes.VERIFIER,
                                };
                              })
                            }
                          >
                            {' '}
                            Log In As Verifier
                          </chakra.span>
                        </Box>
                      </Box>
                    )}
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
      </VStack>
    </Box>
  );
};

export default SignUp;
