import React, { useRef, useEffect, useCallback } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import secrets from "../secrets";

const GoogleMapsLoader = ({ children }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: secrets.googleMapApi, 
    version: "weekly",
    libraries: [], 
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  return isLoaded ? children : <div>Loading...</div>;
};

const containerStyle = {
  width: "100%",
  height: "100%",
  border: "none",
  borderRadius: "1.25rem",
  backgroundColor: "transparent",
};

const darkStyle = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "administrative.country",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#181818",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#1b1b1b",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#2c2c2c",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#8a8a8a",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#373737",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#3c3c3c",
      },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry",
    stylers: [
      {
        color: "#4e4e4e",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#0F1418",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#3d3d3d",
      },
    ],
  },
];

const MapComponent = ({ lat, lng, zoom, markers }) => {
  const navigate = useNavigate();
  const mapRef = useRef(null);
  const center = {
    lat: lat,
    lng: lng,
  };

  const onMarkerClick = useCallback(
    (event) => {
      navigate('/newevents/' + event?.uuid);
    },
    [navigate]
  );

  useEffect(() => {
    if (mapRef.current && window.google && window.google.maps) {
      const map = new window.google.maps.Map(mapRef.current, {
        center: center,
        zoom: zoom,
        styles: darkStyle,
      });

      if (markers && markers.length > 0) {
        markers.forEach((markerData) => {
          const marker = new window.google.maps.Marker({
            position: { lat: markerData?.latitude, lng: markerData?.longitude },
            map,
          });

          const infoWindowContent = `
          <div id='eventMarkers'>
            <h3 classname='gordita400' style="color:black;font-weight:700;text-align:center">${markerData?.eventName}</h3>
            <img src="${markerData?.eventImage}" alt="Custom Marker" style="width: 250px; height: 150px;cursor:pointer" />
          </div>
        `;

          const infoWindow = new window.google.maps.InfoWindow({
            content: infoWindowContent,
          });

          marker.addListener('click', () => {
            infoWindow.open(map, marker);
          });

          infoWindow.addListener('domready', () => {
            const imgElement = document.querySelector('#eventMarkers');
            imgElement.addEventListener('click', () => {
              onMarkerClick(markerData);
            });
          });
        });
      } else if (lat && lng) {
        new window.google.maps.Marker({
          position: { lat: lat, lng: lng },
          map,
        });
      }
    }
  }, [center, zoom, markers, lat, lng, onMarkerClick]);

  return <div ref={mapRef} style={containerStyle} />;
};

const Map = (props) => (
  <GoogleMapsLoader>
    <MapComponent {...props} />
  </GoogleMapsLoader>
);

export default Map;