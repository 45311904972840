import React, { useEffect, useRef, useState, useMemo } from 'react';
import {
  Box,
  Text,
  VStack,
  Image,
  Heading,
  Button,
  Flex,
  useToast,
  Input,
} from '@chakra-ui/react';
import '../../styles/portalys/portalys.css';
import {
  RegistraionModalTypes,
  registration,
} from '../../recoil/atoms/registration';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../../modules/layout/layout';
import { getEventsBySlug } from '../../utils/actions/event';
import { isEmpty } from 'lodash';
import moment from 'moment';
import 'moment/min/locales';
import { useRecoilState } from 'recoil';
import { ticket } from '../../recoil/atoms/tickets';
import { user } from '../../recoil/atoms/user';
import { theme } from '../../styles/theme/base';
import { useMediaQuery } from '../../utils/useMediaQuery';
import axios from '../../utils/api/axios';
import secrets from '../../secrets';
import { useLocation } from 'react-router-dom';
import chevron from '../../static/assets/images/chevron.svg';
import FAQsComponent from './faqs';
import './event-components/eventDetails.css';
import { hexToHSL } from '../../helpers/fucntions/getThemeColor';
import { NotificationComponent } from '../../modules/shared/notificationComponent';
import { useCookies } from 'react-cookie';
import { getCode } from 'iso-3166-1-alpha-2';
import i18n from 'i18next';
import translationEN from './locales/en.json';
import translationFR from './locales/fr.json';
import translationES from './locales/es.json';
import { WaveComponent } from './event-components/waveComponent';
import { TableComponent } from './event-components/tableComponent';
import { PerkComponent } from './event-components/perkComponent';
import {
  AddToWishlist,
  EnterGuestListButton,
  UnlockHidden,
} from './event-components/eventButtons';
import { GetYourTicketsButton } from './event-components/eventButtons';
import { TagsComponent } from './event-components/tags';
import { EventInfoComponent } from './event-components/eventInfo';
import { TipsComponent } from './event-components/tips';
import { MapComponent } from './event-components/mapComponent';
import { PageNotFound } from './event-components/pageNotFound';
import { MobileImageHeader } from './event-components/eventInfo';
import { EventImageComponent } from './event-components/eventInfo';
import { UnlockModal } from './event-components/modals';
import { DownloadAppComponent } from '../appPromo/downloadApp';
import {
  DecriptionComponent,
  LineupComponent,
  SpotifyComponent,
  TrailerComponent,
} from './event-components/eventSections';
import { EventCreatorComponent } from './event-components/eventCreator';
import {
  FeesComponentsDesktop,
  FeesComponentsMobile,
} from './event-components/total';

i18n.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
      translation: translationEN,
    },
    fr: {
      translation: translationFR,
    },
    es: {
      translation: translationES,
    },
  },
});
export default function EventDetail(props) {
  const [_R, setRegistrationModal] = useRecoilState(registration);
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [event, setEvent] = useState();
  const [_, setTicket] = useRecoilState(ticket);
  const [_U, setUser] = useRecoilState(user);
  const toast = useToast();
  const isTablet = useMediaQuery('(max-width: 991px)');
  const isMobile = useMediaQuery('(max-width: 760px)');
  const [isChecked, setIsChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [websocket, setWebsocket] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const [password, setPassword] = useState('');
  const [cookies, setCookie] = useCookies([`${event?.eventName}-password`]);
  const [openSite, setOpenSite] = useState(false);
  const [error, setError] = useState(false);
  const [seePerks, setSeePerks] = useState(false);
  const [sawPerks, setSawPerks] = useState(false);
  const [pageNotFound, setPageNotFound] = useState(false);
  const [language, setLanguage] = useState(null);
  const is24HourFormat = moment.locale() !== 'en';
  const TimeFormat = is24HourFormat ? 'HH:mm' : 'h:mm A';
  const [enteredTicketPassword, setEnteredTicketPassword] = useState('');
  const [unlockedTicketIds, setUnlockedTicketIds] = useState([]);

  const handleTicketPasswordSubmit = () => {
    const unlockedTicketIds = event.ticketTypes
      .filter((wave) => wave.ticketPassword === enteredTicketPassword)
      .map((wave) => wave.ticketId);

    // Unlock the ticket waves that match the password
    setUnlockedTicketIds((prev) => [...prev, ...unlockedTicketIds]);
    setIsModalOpen(false);
  };

  const hasPasswordProtectedTickets = event?.ticketTypes?.some(
    (wave) => wave.ticketPassword && !unlockedTicketIds.includes(wave.ticketId)
  );

  const maxRetries = 10; // Maximum number of websocket connection retries before reloading page

  //connect websocket
  const connectWebSocket = () => {
    // Extract the part after http:// or https://
    const apiUrlWithoutProtocol = secrets.apiBaseUrl.replace(
      /^https?:\/\//,
      ''
    );

    // Determine the WebSocket protocol
    const wsProtocol = secrets.apiBaseUrl.startsWith('https') ? 'wss' : 'ws';

    // Construct the WebSocket URL
    const wsUrl = `${wsProtocol}://${apiUrlWithoutProtocol}`;

    // Now you can use wsUrl to create your WebSocket connection
    const ws = new WebSocket(wsUrl);

    setWebsocket(ws);

    ws.onopen = () => {
      setRetryCount(0); // Reset retry count on successful connection
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);

      switch (data.message) {
        case 'pong':
          break;

        case 'EVENT_UPDATED':
          if (data.slug === id) {
            reloadData();
          }
          break;

        case 'EVENT_DELETED':
          if (data.slug === id) {
            // Redirect to home page if event is deleted
            window.location.href = '/';
          }
          break;

        default:
          console.log('Received unknown message type:', data.message);
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.onclose = (event) => {
      console.log('WebSocket disconnected:', event.reason);
      if (!event.wasClean && retryCount < maxRetries) {
        setRetryCount(retryCount + 1);
        setTimeout(connectWebSocket, 2000); // Retry after 2 seconds
      } else {
        window.location.reload(); // Reload page if max retries exceeded
      }
    };
  };

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (websocket) {
        websocket.close();
      }
    };
  }, [retryCount]);

  const reloadData = async () => {
    if (id) {
      try {
        const val = await getEventsBySlug(id, trackingId);
        if (!isEmpty(val)) {
          setEvent(val);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  //extracted colors
  let filteredColors = event?.extractedColors;

  if (Array.isArray(filteredColors)) {
    filteredColors = filteredColors
      .sort((a, b) => b.area - a.area)
      .filter((color) => {
        // Count the number of "f"s in the color string
        let fCount = (color.hex.match(/f/g) || []).length;
        // Count the number of "0"s in the color string
        let zeroCount = (color.hex.match(/0/g) || []).length;

        // Keep the color if it has at most two "f"s
        return fCount <= 2 && zeroCount <= 3;
      });
  } else {
    filteredColors = [];
  }

  const { bgColor, accentColor } = hexToHSL(filteredColors?.[0]?.hex);

  //donation stuff
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const [donationAmount, setDonationAmount] = useState(0);
  const handleDonationChange = (e) => {
    setDonationAmount(parseFloat(e.target.value));
  };

  //ticket selected variable
  const [ticketSelected, setTicketSelected] = useState([]);
  var ticketsTotal = ticketSelected.reduce((accumulator, ticket) => {
    const ticketTotal = parseFloat(ticket.total);
    return isNaN(ticketTotal) ? accumulator : accumulator + ticketTotal;
  }, 0);
  const [noOfTickets, setNoOfTickets] = useState(0);

  //Tickets increase/ decrease functions and helper functions
  const handleIncreaseTicket = (ticketId) => {
    const existingTicket = ticketSelected.find(
      (ticket) => ticket.ticketId === ticketId
    );
    setNoOfTickets(noOfTickets + 1);
    if (existingTicket) {
      setTicketSelected((prev) =>
        prev.map((ticket) => {
          if (ticket.ticketId === ticketId) {
            return {
              ...ticket,
              quantity: ticket.quantity + 1,
              total: Number(ticket.total) + Number(ticket.price),
              instagram: ticket.instagram,
              limitQuantity: ticket.limitQuantity,
            };
          }
          return ticket;
        })
      );
    } else {
      const ticket = findTicketById(ticketId);
      if (ticket) {
        setTicketSelected((prev) => [
          ...prev,
          {
            ticketId,
            quantity: 1,
            total: Number(ticket.ticketPrice),
            price: ticket.ticketPrice,
            type: ticket.ticketName,
            item: 'ticket',
            instagram: ticket.instagram,
            limitQuantity: ticket.limitQuantity,
          },
        ]);
      }
    }
  };

  const handleDecreaseTicket = (ticketId) => {
    const existingTicket = ticketSelected.find(
      (ticket) => ticket.ticketId === ticketId
    );

    setNoOfTickets(noOfTickets - 1);
    if (existingTicket) {
      if (existingTicket.quantity > 1) {
        setTicketSelected((prev) =>
          prev.map((ticket) => {
            if (ticket.ticketId === ticketId) {
              return {
                ...ticket,
                quantity: ticket.quantity - 1,
                total: Number(ticket.total) - Number(ticket.price),
                instagram: ticket.instagram,
                limitQuantity: ticket.limitQuantity,
              };
            }
            return ticket;
          })
        );
      } else {
        setTicketSelected((prev) =>
          prev.filter((ticket) => ticket.ticketId !== ticketId)
        );
      }
    }
  };

  const findTicketById = (ticketId) => {
    for (const ticket of event?.ticketTypes) {
      if (ticket.ticketId === ticketId) return ticket;
    }
    return null;
  };

  //perks
  const [perkSelected, setPerkSelected] = useState([]);

  const perksTotal = perkSelected.reduce((accumulator, perk) => {
    const perkTotal = parseFloat(perk.total);
    return isNaN(perkTotal) ? accumulator : accumulator + perkTotal;
  }, 0);

  const [noOfperks, setNoOfPerks] = useState(0);

  //perks increase/decrease
  const handleIncreasePerk = (perkId) => {
    const existingPerk = perkSelected.find((perk) => perk.perkId === perkId);
    setNoOfPerks(noOfperks + 1);
    if (existingPerk) {
      setPerkSelected((prev) =>
        prev.map((perk) => {
          if (perk.perkId === perkId) {
            return {
              ...perk,
              quantity: perk.quantity + 1,
              total: Number(perk.total) + Number(perk.price),
            };
          }
          return perk;
        })
      );
    } else {
      const perk = findPerkById(perkId);
      if (perk) {
        setPerkSelected((prev) => [
          ...prev,
          {
            perkId,
            quantity: 1,
            total: Number(perk.perkPrice),
            price: perk.perkPrice,
            type: perk.perkName,
            item: 'perk',
          },
        ]);
      }
    }
  };

  const handleDecreasePerk = (perkId) => {
    const existingPerk = perkSelected.find((perk) => perk.perkId === perkId);

    setNoOfPerks(noOfperks - 1);
    if (existingPerk) {
      if (existingPerk.quantity > 1) {
        setPerkSelected((prev) =>
          prev.map((perk) => {
            if (perk.perkId === perkId) {
              return {
                ...perk,
                quantity: perk.quantity - 1,
                total: Number(perk.total) - Number(perk.price),
              };
            }
            return perk;
          })
        );
      } else {
        setPerkSelected((prev) =>
          prev.filter((perk) => perk.perkId !== perkId)
        );
      }
    }
  };

  const findPerkById = (perkId) => {
    for (const list of event.perkLists) {
      const perk = list.perks.find((p) => p.perkId === perkId);
      if (perk) return perk;
    }
    return null;
  };

  const isGetYourTicketsButtonDisabled = noOfTickets === 0 && noOfperks === 0;

  //tracking data
  const trackingId = searchParams.get('tracking') || ''; // Get trackingId from URL

  const init = async () => {
    if (id) {
      try {
        const val = await getEventsBySlug(id, trackingId);
        if (!isEmpty(val)) {
          setEvent(val);
          setLanguage(val.language);
          i18n.changeLanguage(val.language);
          moment.locale(val.language);
          if (val?.isMintedCompletely) {
            setIsChecked(true);
            setDonationAmount(1);
          }
          if (!val?.eventPassword) {
            setOpenSite(true);
          }
          if (val?.perkLists.length > 0) {
            setSeePerks(true);
          }
          axios.post('/api/pageView', {
            pageName: location.pathname,
            organizer_id: val.organizedBy?.opId,
            page_type: 'eventPage',
          });
        }
      } catch (error) {
        setPageNotFound(true);
        console.error(error);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    init();
    if (_?.perks) {
      setSawPerks(true);
      setTicket((lp) => ({
        ...lp,
        perks: false,
      }));
    }
  }, [id]);

  const handleGetTickets = () => {
    if (!isEmpty(_U?.userData)) {
      const xnor = (a, b) => (a && b) || (!a && !b);
      if (xnor(seePerks, sawPerks)) {
        setTicket((lp) => {
          return {
            ...lp,
            uuid: event.uuid,
            quantity: noOfTickets || 1,
            total: ticketsTotal,
            ticketSelected: ticketSelected,
            selectedEvent: { ...event },
            currency: event.currency,
            item: ticketSelected[0]?.item || 'ticket',
            taxi: isChecked,
            participant_fee_percentage: event.participant_fee_percentage / 100,
            participant_fee_fixed: event.participant_fee_fixed,
            bgColor: bgColor,
            accentColor,
            promoterSlug: trackingId,
            donationAmount: donationAmount,
            perksTotal: perksTotal || 0,
            perkSelected: perkSelected,
            language: language,
            cryptoPayments: event?.cryptoPayments,
          };
        });
        if (ticketsTotal === 0 && perksTotal === 0) {
          navigate('/booking/rsvp');
        } else {
          navigate('/booking/detail');
        }
      } else {
        setSawPerks(true);
        window.scrollTo(0, 0);
      }
    } else {
      setRegistrationModal((lp) => {
        return {
          ...lp,
          openModal: true,
          modalType: RegistraionModalTypes.SIGNUP,
          country: getCode(event?.country),
        };
      });
    }
  };
  const handleNavigate = () => {
    // Get the current location and append "/guestlist"
    const currentPath = window.location.pathname;
    const newPath = `${currentPath}/guestlist`;

    // Navigate to the new path
    navigate(newPath);
  };

  const getYourTicketButton = (
    <GetYourTicketsButton
      handleFunction={handleGetTickets}
      text={i18n.t('enterGuestList')}
      isGetYourTicketsButtonDisabled={isGetYourTicketsButtonDisabled}
      noOfTickets={noOfTickets}
      noOfperks={noOfperks}
      isPublished={event?.isPublished}
      seePerks={seePerks}
      sawPerks={sawPerks}
      perkLists={event?.perkLists}
      continueText={i18n.t('continue')}
      checkout={i18n.t('checkout')}
      getYourTickets={i18n.t('getYourTickets')}
      isMobile={isMobile}
    />
  );

  const enterGuestListButton = (
    <EnterGuestListButton
      handleFunction={handleNavigate}
      text={i18n.t('enterGuestList')}
    />
  );

  const handleLikeEvent = async () => {
    if (!isEmpty(_U?.userData) && _U.token) {
      await axios
        .post('/api/likeEvent/' + event?.uuid, {
          headers: {
            'x-auth-token': _U?.token,
          },
        })
        .then((res) => {
          init();
          const data = res.data;
          toast({
            position: 'top-right',
            duration: 4000,
            render: ({ onClose }) => (
              <NotificationComponent
                title={
                  data?.message === 'Event Liked'
                    ? `${i18n.t('wishlistAdded')}`
                    : `${i18n.t('wishlistRemoved')}`
                }
                status={data.status ? 'success' : 'error'}
                onClose={onClose}
              />
            ),
          });
        })
        .catch((err) => {
          toast({
            position: 'top-right',
            duration: 4000,
            render: ({ onClose }) => (
              <NotificationComponent
                title={'Something went wrong!'}
                status={'error'}
                onClose={onClose}
              />
            ),
          });
          console.log({ err });
        });
    } else {
      setRegistrationModal((lp) => {
        return {
          ...lp,
          openModal: true,
          modalType: RegistraionModalTypes.SIGNUP,
          language: event?.language,
        };
      });
    }
  };

  const handleX = (e) => {
    e.stopPropagation();
    navigate('/');
  };

  const handleCopy = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(window.location.href);
    toast({
      title: 'Link copied!',
      isClosable: true,
      duration: 4000,
      status: 'success',
      position: 'top-right',
    });
  };

  const handleLike = (e) => {
    e.stopPropagation();
    handleLikeEvent();
  };

  const handleProfileClick = () => {
    navigate('/creator/' + event.organizedBy?.slug + '?lng=' + language);
  };

  const typeMinTicketIds = {};

  //arranging the ticket types per type
  event?.ticketTypes?.forEach((ticket) => {
    const typeName = ticket.typeName;
    const ticketId = ticket.ticketId;

    if (
      !(typeName in typeMinTicketIds) ||
      ticketId < typeMinTicketIds[typeName]
    ) {
      typeMinTicketIds[typeName] = ticketId;
    }
  });

  const TicketDetails = (
    <div>
      <Box width={'100%'} pos="relative">
        {!(seePerks && sawPerks) && (
          <EventInfoComponent
            eventName={event?.eventName}
            accentColor={accentColor}
            startTime={event?.startTime}
            endTime={event?.endTime}
            startDate={event?.startDate}
            city={event?.city}
            location={event?.location}
            totalTickets={event?.totalTickets}
            available={event?.available}
            hideLikes={event?.hideLikes}
            seePerks={event?.seePerks}
            sawPerks={event?.sawPerks}
            isMobile={isMobile}
            moment={moment}
            TimeFormat={TimeFormat}
            people={i18n.t('people')}
            by={i18n.t('by')}
          />
        )}
        {!isMobile && !isTablet && !(seePerks && sawPerks) && (
          <TagsComponent eventType={event?.eventType} />
        )}
      </Box>

      {!(seePerks && sawPerks) && <Box className="div-separator" />}

      {!event?.isOver ? (
        <Box width={'100%'}>
          <VStack width={'100%'} margin="0 auto" className="regularcmards">
            <Box
              display="block"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
              className="darkcard"
            >
              {!(seePerks && sawPerks) ? (
                <div>
                  {event?.ticketTypes?.length > 0 && (
                    <Text
                      fontSize={'1.3125rem'}
                      className="gordita600"
                      lineHeight={'160%'}
                      color={'#FFFFFF'}
                      marginBottom={'0.75rem'}
                    >
                      {i18n.t('tickets')}
                    </Text>
                  )}

                  <Box display={'flex'} flexDir={'column'} gap={'0.94rem'}>
                    {event?.ticketTypes
                      ?.sort((a, b) => {
                        return (
                          typeMinTicketIds[a.typeName] -
                          typeMinTicketIds[b.typeName]
                        );
                      })
                      ?.map((wave, index) => {
                        let isPreviousWaveSoldOut;
                        if (index > 0) {
                          const previousWave = event?.ticketTypes[index - 1];
                          // Check if the previous wave is in the same ticket type (typeName)
                          if (previousWave.typeName === wave.typeName) {
                            let endFormatted;
                            if (previousWave.end) {
                              endFormatted = new Date(previousWave.end);
                            }
                            const now = new Date().toLocaleString('en-US', {
                              timeZone: event.timezone,
                            });
                            const currentDateInTimezone = new Date(now);
                            const isPreviousWaveOver =
                              endFormatted &&
                              endFormatted < currentDateInTimezone;
                            const selectedTicket = ticketSelected.find(
                              (ticket) =>
                                ticket.ticketId === previousWave.ticketId
                            );
                            const isPreviousWaveSoldOutX =
                              Number(previousWave.ticketRemainingSupply) -
                                Number(selectedTicket?.quantity || 0) <=
                              0;
                            if (isPreviousWaveSoldOutX || isPreviousWaveOver) {
                              isPreviousWaveSoldOut = true;
                            } else {
                              isPreviousWaveSoldOut = false;
                            }
                          } else {
                            // If the previous wave is in a different ticket type, consider it sold out
                            isPreviousWaveSoldOut = true;
                          }
                        } else {
                          // If it's the first wave, consider the previous wave as sold out
                          isPreviousWaveSoldOut = true;
                        }

                        if (wave.typeName === 'default') {
                          isPreviousWaveSoldOut = true;
                        }
                        const isPasswordProtected = !!wave.ticketPassword;
                        if (
                          isPasswordProtected &&
                          !unlockedTicketIds.includes(wave.ticketId)
                        ) {
                          return null;
                        }

                        return (
                          <WaveComponent
                            key={index}
                            eventName={wave.ticketName}
                            ticketPrice={
                              wave.ticketPrice === 0 ? 0 : wave.ticketPrice
                            }
                            noOfRmainingTickets={wave.ticketRemainingSupply}
                            waveType={wave.ticketId}
                            description={wave.ticketDescription}
                            isPreviousWaveSoldOut={isPreviousWaveSoldOut}
                            startDate={wave.start}
                            endDate={wave.end}
                            timezone={event.timezone}
                            currency={event.currency}
                            text={i18n.t('free')}
                            ticketSelected={ticketSelected}
                            language={event.language}
                            decreaseFunction={handleDecreaseTicket}
                            increaseFunction={handleIncreaseTicket}
                            noOfTickets={noOfTickets}
                            accentColor={accentColor}
                            limit={wave.limitQuantity}
                          />
                        );
                      })}
                    {hasPasswordProtectedTickets && (
                      <UnlockHidden
                        handleFunction={() => {
                          setIsModalOpen(true);
                          setEnteredTicketPassword('');
                        }}
                      />
                    )}
                    {isModalOpen && (
                      <UnlockModal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        enteredTicketPassword={enteredTicketPassword}
                        setEnteredTicketPassword={setEnteredTicketPassword}
                        handleTicketPasswordSubmit={handleTicketPasswordSubmit}
                        isMobile={isMobile}
                      />
                    )}
                  </Box>
                  {(event?.tableLink || event?.tableDescription) && (
                    <>
                      <div>
                        <Box className="div-separator" />
                        <Text
                          fontSize={'1.3125rem'}
                          className="gordita600"
                          lineHeight={'160%'}
                          color={'#FFFFFF'}
                          marginBottom={'0.75rem'}
                        >
                          {i18n.t('tables')}
                        </Text>
                      </div>

                      <Box display={'flex'} flexDir={'column'} gap={'0.94rem'}>
                        <TableComponent
                          tableDescription={event.tableDescription}
                          tableLink={event.tableLink}
                          accentColor={accentColor}
                        />
                      </Box>
                    </>
                  )}
                  {event?.tableMap && (
                    <div
                      style={{
                        borderRadius: '25px',
                        marginTop: '20px',
                        //padding: '20px',
                      }}
                    >
                      <Image
                        style={{
                          width: '100%',
                          height: '100%',
                          borderRadius: '1.5625rem',
                        }}
                        src={event?.tableMap}
                        alt="icon"
                      />
                    </div>
                  )}

                  {event?.isMintedCompletely && (
                    <TipsComponent
                      isChecked={isChecked}
                      handleCheckboxChange={handleCheckboxChange}
                      donationAmount={donationAmount}
                      handleDonationChange={handleDonationChange}
                    />
                  )}
                </div>
              ) : (
                <div>
                  <VStack alignItems="flex-start" marginBottom={'2.44rem'}>
                    <Text
                      fontSize="1.125rem"
                      className="gordita500"
                      lineHeight="160%"
                      color="#FFFFFF"
                      fontWeight={'500'}
                    >
                      {i18n.t('add')} Perks
                    </Text>

                    <Text
                      fontSize="1rem"
                      className="gordita400"
                      lineHeight="160%"
                      fontWeight={'400'}
                      color="var(--Colors-S---W---60, rgba(255, 255, 255, 0.60))"
                    >
                      {i18n.t('addPerks')}
                    </Text>
                  </VStack>

                  <Box display={'flex'} flexDir={'column'} gap={'0.94rem'}>
                    {event?.perkLists?.map(
                      (perkList, index) =>
                        perkList.perks && // Check if perkList.perk exists
                        perkList.perks.map(
                          (
                            perk,
                            innerIndex // Use a different variable name for the inner index
                          ) => (
                            <PerkComponent
                              key={innerIndex} // Use innerIndex as the key
                              perkName={perk.perkName}
                              perkPrice={
                                perk.perkPrice === 0 ? 0 : perk.perkPrice
                              }
                              noOfRmainingPerks={perk.perkRemainingSupply} // Corrected the prop name
                              description={perk.perkDescription}
                              perkImage={perk.perkImage}
                              waveType={perk.perkId}
                              currency={event.currency}
                              perkSelected={perkSelected}
                              decreaseFunction={handleDecreasePerk}
                              increaseFunction={handleIncreasePerk}
                              accentColor={accentColor}
                            />
                          )
                        )
                    )}
                  </Box>
                </div>
              )}
            </Box>
          </VStack>

          {!isMobile && !isTablet && (
            <>
              <FeesComponentsDesktop
                noOfTickets={noOfTickets}
                noOfperks={noOfperks}
                serviceFeesText={i18n.t('serviceFees')}
                currency={event?.currency}
                ticketsTotal={ticketsTotal}
                perksTotal={perksTotal}
                ticketSelected={ticketSelected}
                perkSelected={perkSelected}
                participant_fee_percentage={event?.participant_fee_percentage}
                participant_fee_fixed={event?.participant_fee_fixed}
                totalText={i18n.t('total')}
                accentColor={accentColor}
                isChecked={isChecked}
                donationAmount={donationAmount}
              />
              {!isMobile &&
                !isTablet &&
                event?.ticketTypes?.length > 0 &&
                getYourTicketButton}
              {!(seePerks && sawPerks) && (
                <div>
                  <AddToWishlist
                    handleFunction={handleLikeEvent}
                    isLiked={event?.isLiked}
                    removeFrom={i18n.t('removeFrom')}
                    addTo={i18n.t('addToWishlist')}
                  />

                  {event?.showGuestList && enterGuestListButton}
                  <DownloadAppComponent isTablet={isTablet} isMobile={isMobile} />
                </div>
              )}
            </>
          )}
          {(isMobile || isTablet) && !(seePerks && sawPerks) && (
            <div>
              <Box className="div-separator" />
              {event?.showGuestList && enterGuestListButton}
              <DownloadAppComponent isTablet={isTablet} isMobile={isMobile} />
            </div>
          )}
          {(isMobile || isTablet) &&
            !isEmpty(event?.eventType) &&
            !(seePerks && sawPerks) && (
              <Box justifyContent="center">
                <Box className="darkcard">
                  <Flex
                    flexWrap="wrap" // Allow items to wrap to a new line
                    gap="2%"
                    justifyContent="flex-start" // Center items horizontally
                    alignItems="center" // Center items vertically
                    marginTop="25px"
                  >
                    {<TagsComponent eventType={event?.eventType} />}
                  </Flex>
                </Box>

                <Box className="div-separator" />
              </Box>
            )}
        </Box>
      ) : (
        <Box>
          <Text color="red" fontWeight="700">
            NO TICKETS AVAILABLE. EVENT IS OVER
          </Text>
        </Box>
      )}
    </div>
  );

  const memoizedComponent = useMemo(
    () => (
      <MapComponent
        location={event?.location}
        latitude={event?.latitude}
        longitude={event?.longitude}
        accentColor={accentColor}
        locationText={i18n.t('location')}
      />
    ),
    [event, accentColor]
  );

  //spotify track
  const match = event?.spotifyId?.match(/\/track\/(\w+)/);
  const trackId = match ? match[1] : null;

  return (
    <Box bg={bgColor}>
      <Layout {...props} bgColor={bgColor}>
        {event !== undefined ? (
          pageNotFound ? (
            <PageNotFound />
          ) : (
            <div>
              {openSite ||
              (cookies && cookies[`${event?.eventName}-password`]) ? (
                <div>
                  <Box className={'event-details-wrapper'}>
                    <Box className={'event-left-components'}>
                      {(isMobile || isTablet) && !(seePerks && sawPerks) && (
                        <MobileImageHeader
                          handleCopy={handleCopy}
                          handleLike={handleLike}
                          handleX={handleX}
                        />
                      )}

                      {!(seePerks && sawPerks) && (
                        <EventImageComponent
                          extraPictures={event?.extraPictures}
                          isTablet={isTablet}
                          eventImage={event?.eventImage}
                        />
                      )}
                      {/* for when user is on perks page */}
                      {seePerks && sawPerks && (
                        <div
                          style={{
                            position: 'relative',
                            flexDirection: 'column',
                          }}
                        >
                          <img
                            src={chevron}
                            alt="chevron"
                            style={{
                              position: 'absolute',
                              top: '0.65rem',
                              left: '0.65rem',
                              zIndex: 1,
                              cursor: 'pointer', // Add cursor pointer to indicate it's clickable
                            }}
                            onClick={() => {
                              setSawPerks(false);
                              //setPerkSelected([]);
                            }}
                          />
                          <img
                            src={event?.eventImage}
                            style={{
                              borderRadius: '1.61519rem',
                              border: '1.034px solid rgba(255, 255, 255, 0.10)',
                              display: 'block',
                              height: '15.63475rem',
                              width: '100%',
                              objectFit: 'cover',
                              maxWidth: '100%',
                              objectPosition: 'top',
                            }}
                            alt={'event-image'}
                          />

                          {!(isMobile || isTablet) && (
                            <EventInfoComponent
                              eventName={event?.eventName}
                              accentColor={accentColor}
                              startTime={event?.startTime}
                              endTime={event?.endTime}
                              startDate={event?.startDate}
                              city={event?.city}
                              location={event?.location}
                              totalTickets={event?.totalTickets}
                              available={event?.available}
                              hideLikes={true}
                              seePerks={event?.seePerks}
                              sawPerks={event?.sawPerks}
                              isMobile={isMobile}
                              moment={moment}
                              TimeFormat={TimeFormat}
                              margtop={'1.94rem'}
                              people={i18n.t('people')}
                              by={i18n.t('by')}
                            />
                          )}
                        </div>
                      )}

                      {(isMobile || isTablet) && (
                        <>
                          <Box margin={'1.88rem 0'}>{TicketDetails}</Box>
                          {seePerks && sawPerks && <Box height="100px"> </Box>}
                        </>
                      )}

                      {!(seePerks && sawPerks) && (
                        <Box>
                          {event?.description && (
                            <DecriptionComponent
                              about={i18n.t('about')}
                              description={event?.description}
                            />
                          )}
                          <Box className="div-separator" />
                        </Box>
                      )}
                      {!(seePerks && sawPerks) && (
                        <div>
                          {event?.trailer && ( // Check if event?.trailer exists and is not empty
                            <TrailerComponent
                              trailerText={i18n.t('trailer')}
                              trailer={event?.trailer}
                            />
                          )}

                          {!isEmpty(event?.lineup) && (
                            <Box justifyContent="center">
                              <LineupComponent
                                performers={i18n.t('performers')}
                                lineup={event?.lineup}
                                isTablet={isTablet}
                              />
                              <Box className="div-separator" />
                            </Box>
                          )}

                          {!isEmpty(event?.spotifyId) && (
                            <SpotifyComponent
                              bestTrack={i18n.t('bestTrack')}
                              trackId={trackId}
                            />
                          )}

                          {memoizedComponent}

                          {event?.organizedBy && (
                            <EventCreatorComponent
                              eventCreator={i18n.t('eventCreator')}
                              handleProfileClick={handleProfileClick}
                              organizedBy={event?.organizedBy}
                              followers={i18n.t('followers')}
                              isMobile={isMobile}
                              isTablet={isTablet}
                            />
                          )}
                        </div>
                      )}
                    </Box>

                    <Box className={'event-separator'} />

                    {!isMobile && !isTablet && (
                      <Box
                        marginBottom="100px"
                        className={'event-right-components'}
                      >
                        {TicketDetails}
                      </Box>
                    )}
                  </Box>
                  {!(seePerks && sawPerks) && (
                    <Box
                      margin={
                        isTablet ? '0 auto 4.3rem' : '15.31rem auto 10rem'
                      }
                      padding={'0 1.25rem'}
                    >
                      <FAQsComponent />
                    </Box>
                  )}
                </div>
              ) : (
                <Box
                  height="100vh"
                  bg={bgColor}
                  overflow="hidden"
                  display="flex"
                  alignItems="center"
                  margin={'0 20px'}
                >
                  <Box
                    w="100%"
                    bg={bgColor}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box w="100%" maxW="350px">
                      <Text color="white.100" className="gordita400" mb={10}>
                        Event Password
                      </Text>
                      <Input
                        w="100%"
                        h="56px"
                        className="gordita400"
                        borderRadius="0.625rem"
                        bg="#000000"
                        border="1px solid rgba(255, 255, 255, 0.20)"
                        borderColor={
                          error ? '#FF6B6B' : 'rgba(255, 255, 255, 0.20)'
                        }
                        color="#FFFFFF"
                        placeholder={'Enter Event Password'}
                        focusBorderColor={error ? '#FF6B6B' : '#FFFFFF'}
                        _hover={{
                          borderColor: error ? '#FF6B6B' : '#FFFFFF !important',
                        }}
                        _placeholder={{
                          color: 'rgba(255, 255, 255, 0.40)',
                        }}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                      />
                      {error && password !== event?.eventPassword && (
                        <Text color="red" className="gordita400" mb={10}>
                          Incorrect Password
                        </Text>
                      )}
                      <Box mt={12}>
                        <Button
                          onClick={() => {
                            if (password === event?.eventPassword) {
                              setOpenSite(true);

                              // Construct the path with or without the tracking query parameter based on trackingId
                              const pathWithTracking = trackingId
                                ? `/event/${event?.slug}?tracking=${trackingId}`
                                : `/event/${event?.slug}`;

                              // Set the cookie with the constructed path
                              setCookie(
                                `${event?.eventName}-password`,
                                password,
                                {
                                  path: '/',
                                }
                              );
                            } else {
                              setError(true);
                            }
                          }}
                          fontWeight="700"
                          textAlign="center"
                          fontSize="1rem"
                          borderRadius="40px"
                          outline="none"
                          sx={theme.buttons.primary.shape}
                          bg={theme.buttons.primary.default}
                          color={theme.buttons.primary.color}
                          className="gordita700"
                          css={{
                            width: '100%',
                          }}
                          _hover={{
                            bg:
                              !isGetYourTicketsButtonDisabled &&
                              theme.buttons.primary.hover,
                            boxShadow:
                              !isGetYourTicketsButtonDisabled &&
                              theme.buttons.primary.hoverBoxShadow,
                            cursor: !isGetYourTicketsButtonDisabled
                              ? 'pointer'
                              : 'default',
                          }}
                          _active={{
                            boxShadow:
                              !isGetYourTicketsButtonDisabled &&
                              theme.buttons.primary.activeBoxShadow,
                          }}
                          _disabled={{
                            bg: theme.buttons.primary.disabled,
                            color: theme.buttons.primary.colorDisabled,
                          }}
                          disabled={!password}
                          cursor={'pointer'}
                        >
                          Access
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </div>
          )
        ) : (
          <div
            style={{
              margin: '0 auto',
              fontSize: '2rem',
              fontWeight: '700',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '90vh',
            }}
          >
            Loading...
          </div>
        )}
      </Layout>
      {(isTablet || isMobile) &&
        (openSite || (cookies && cookies[`${event?.eventName}-password`])) &&
        event?.ticketTypes?.length > 0 && (
          <Box
            position={'sticky'}
            bottom={'0'}
            width={'100%'}
            maxW={'1200px'}
            display={'flex'}
            gap={'0.62rem'}
            flexDir={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            borderRadius={'40px 40px 0 0'}
            bg={bgColor}
            padding={'1.25rem'}
            borderTop="1px solid rgba(255, 255, 255, 0.20)"
            backdropFilter={'blur(75px)'}
          >
            {!(noOfTickets === 0 && noOfperks === 0) && (
              <FeesComponentsMobile
                noOfTickets={noOfTickets}
                noOfperks={noOfperks}
                serviceFeesText={i18n.t('serviceFees')}
                currency={event?.currency}
                ticketsTotal={ticketsTotal}
                perksTotal={perksTotal}
                ticketSelected={ticketSelected}
                perkSelected={perkSelected}
                participant_fee_percentage={event?.participant_fee_percentage}
                participant_fee_fixed={event?.participant_fee_fixed}
                totalText={i18n.t('total')}
                accentColor={accentColor}
                isChecked={isChecked}
                donationAmount={donationAmount}
              />
            )}
            {getYourTicketButton}
          </Box>
        )}
    </Box>
  );
}
