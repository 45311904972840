/**
 * This is a React component that renders the EventsComponent within a DashboardLayout and requires
 * authentication for certain user roles.
 * @param props - props is an object that contains all the properties passed to the Events component.
 * These properties can include data, functions, and other components that are used within the
 * component.
 * @returns The code is returning a React functional component named "Events". This component is
 * wrapped with the "withAuth" higher-order component, which takes two arguments: the "Events"
 * component and an array of user roles. The "Events" component returns a JSX element that renders the
 * "DashboardLayout" component with props and a child component named "EventsComponent". The
 * "DashboardLayout" component is a custom
 */
import EventLayout from "../../modules/layout/event";
import ResellEventsComponent from "../../modules/pages/Events/resell-events";
import { userRoles } from "../../utils/constants";
import withAuth from "../../utils/withAuth";
const ResellEvents = (props) => {
  return (
    <EventLayout {...props} dashboardName="Resell Events">
      <ResellEventsComponent />
    </EventLayout>
  );
};

export default withAuth(ResellEvents, [
  userRoles.ADMIN,
]);
