import {
    Box,
    Flex,
    HStack,
    Text,
    VStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    useDisclosure,
    Img,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Image,
    IconButton,
    AccordionIcon,
  } from "@chakra-ui/react";
  import {
    DateBox,
    InputBox,
    SelectBox,
    SwitchBox,
    TextBox,
  } from "./SharedModules";
  import RangeSlider from "../../Events/Create/rangeslider";
  import { user } from "../../../../recoil/atoms/user";
  import { useRecoilState } from "recoil";
  import { userRoles } from "../../../../utils/constants";
  import { useEffect, useState, useRef } from "react";
  import { theme } from "../../../../styles/theme/base";
  import { useMediaQuery } from "../../../../utils/useMediaQuery";
  import deleteIcon from "../../../../static/assets/images/deleteIcon.svg";
  import { DeleteIcon } from "@chakra-ui/icons";
  import editIcon from "../../../../static/assets/images/editIcon.svg";
  import dollarIcon from "../../../../static/assets/images/dollarIcon.svg";
  import { Select } from "antd";
  import NFTMeta from "./NFTMetaData";
  import "./styles.css";
  import axios from "../../../../utils/api/axios";
  import secrets from "../../../../secrets";
  import { useLocation } from "react-router-dom";
  import frame3 from "../../../../static/assets/images/Frame 3.png";
  import getSymbolFromCurrency from "currency-symbol-map";
  
  
  
  const TableMap = (props) => {
    const {
      handleBlur,
      handleChange,
      values,
      setFieldValue,
      isEditMode,
    } = props;
  
    const isMobile = useMediaQuery("(max-width: 768px)");
    const isTablet = useMediaQuery("(max-width: 1024px)");
  
    const [editMode, setEditMode] = useState(false); // Edit mode state
    const [tableTypes, setTableTypes] = useState([]);
    const [editMode2, setEditMode2] = useState(false); // Edit mode state
    const [editingTableIndex, setEditingTableIndex] = useState(null);
    const [waveCounter, setWaveCounter] = useState(0);
    const [wave, setWave] = useState({ isEditable: false, counter: null });
    const [table, setTable] = useState({ isEditable: false, counter: null });
    const [tables, setTables] = useState([]);
    const location = useLocation();
  
    const shouldShowContent = location.pathname.includes("/edit");
  
    const [soldoutIndex, setSoldoutIndex] = useState(null);
    const [tableOut, setTableOutIndex] = useState(null);
  
    const [dragging, setDragging] = useState(false);
    const [loading, setLoading] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const dropRef = useRef(null);
    const [errorFields, setErrorFields] = useState();
  
    const handleDragEnter = (e) => {
      e.preventDefault();
    };
  
    const handleDragOver = (e) => {
      e.preventDefault();
    };
  
    const handleDrop = (e) => {
      e.preventDefault();
      if (e.dataTransfer.files) uploadEventImage(e.dataTransfer.files[0]);
    };
  
    const handleMouseDown = (e) => {
      setDragging(true);
      const initialX = e.clientX - position.x;
      const initialY = e.clientY - position.y;
  
      const handleMouseMove = (e) => {
        setPosition({
          x: e.clientX - initialX,
          y: e.clientY - initialY,
        });
      };
  
      document.addEventListener("mousemove", handleMouseMove);
  
      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
        setDragging(false);
      };
  
      document.addEventListener("mouseup", handleMouseUp);
    };
  
    const uploadEventImage = async (image) => {
      const fd = new FormData();
      fd.append("image", image);
      fd.append("type", "event");
      setLoading(true);
      await axios
        .post("/api/uploadImage", fd,{
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.status) {
            if (res.data.link) {
              setFieldValue("tableMap", res.data.link);
            }
          } else {
            toast({
              title: "Cover Uploading Err",
              status: "error",
              isClosable: true,
              duration: 4000,
              position: "top-right",
            });
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast({
            title: "Cover Uploading Err",
            status: "error",
            isClosable: true,
            duration: 4000,
            position: "top-right",
          });
        });
    };

  
    useEffect(() => {
      if (isEditMode && values?.tableTypes) {
        setTableTypes([...values?.tableTypes]);
      }
    }, [values.tableTypes]);
  
    useEffect(() => {
      if (!isEditMode) {
        setTableTypes([]);
      }
    }, [isEditMode]);
  
    const addTableType = (
      name,
      description,
      quantity,
      price,
      fullPrice,
      minPerson,
      maxPerson,
      chargePP
    ) => {
      const newTableType = {
        name: name,
        description: description,
        quantity: quantity,
        price: price,
        fullPrice:fullPrice,
        minPerson: minPerson,
        maxPerson: maxPerson,
        chargePP: chargePP,
        tableRemainingSupply: Number(quantity),
      };
      setTableTypes([...tableTypes, newTableType]);
      setFieldValue("tableTypes", [...tableTypes, newTableType]);
    };
  
    useEffect(() => {
      if (tableOut !== null) {
        const updatedTableTypes = [...tableTypes];
        updatedTableTypes[tableOut].tableRemainingSupply = "0";
  
        // Update the state
        setTableTypes(updatedTableTypes);
        setFieldValue("tableTypes", updatedTableTypes);
        setSoldoutIndex(null);
      }
    }, [tableOut, tableTypes, setFieldValue]);
  
   
  
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
      isOpen: isOpen2,
      onOpen: onOpen2,
      onClose: onClose2,
    } = useDisclosure();
    const {
      isOpen: isOpen3,
      onOpen: onOpen3,
      onClose: onClose3,
    } = useDisclosure();

  
  
    
  

  
    return (
      <Box w="100%">

        <Box w={"100%"}>
          <Text
            className="gordita600"
            fontSize={"1.3125rem"}
            textAlign="left"
            w="100%"
            color="#FFFFFF"
            pb={"1.87rem"}
          >
            Event Layout
          </Text>
          <VStack width={"100%"} gap={"1.56rem"}>
            <HStack w="100%">
              <Box
                ref={dropRef}
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                w="100%"
              >
  
                <Box
                  onMouseDown={handleMouseDown}
                  w="100%"
                  h="100%"
                  bg="#000000"
                  borderRadius="40px"
                  border="1px solid #FFFFFF33"
                  p="15px"
                >
                  {!values?.tableMap && (
                    <Box
                      w="100%"
                      minH="320px"
                      display="flex"
                      justifyContent="end"
                      alignItems="end"
                      h="100%"
                    >
                      <input
                        style={{ display: "none" }}
                        id="tableMap"
                        type="file"
                        accept=".jpg,.jpeg,.png"
                        onChange={(e) => {
                          uploadEventImage(e.target.files[0]);
                        }}
                      />
                      <label htmlFor="tableMap">
                        <Box
                          border={"1px solid #FFFFFF33"}
                          width={"164px"}
                          height={"50px"}
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          borderRadius={"50px"}
                          cursor={"pointer"}
                          _hover={{
                            borderColor: "#FFFFFF",
                          }}
                          _active={{
                            bg: "rgba(255, 255, 255, 0.05)",
                          }}
                        >
                          Upload Layout
                        </Box>
                      </label>
                    </Box>
                  )}
                  {values?.tableMap && (
                    <Box>
                      <Box w="100%" bg="#141414" borderRadius="8px" p="20px">
                        <Box
                          w="100%"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          h="100%"
                          borderRadius="8px"
                        >
                          <Box position="relative" w="100%">
                            <Box w="100%">
                              {values?.tableMap && (
                                <Image w="100%" h="100%" src={values.tableMap} />
                              )}
                            </Box>
                            <IconButton
                              icon={<DeleteIcon />}
                              aria-label="Close video"
                              position="absolute"
                              color="red"
                              top={2}
                              right={2}
                              zIndex={1}
                              onClick={() => setFieldValue("tableMap", "")}
                              size="lg"
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </HStack>
            </VStack>
            </Box>
           
      </Box>
    );
  };
  
  export default TableMap;
  