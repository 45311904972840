import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  Image,
  useToast,
  Icon,
} from "@chakra-ui/react";
import { InputBox } from "./SharedModules";
import {
  upload,
  lineup1,
  lineup3,
  lineup4,
} from "../../../../static/assets/images/dashboard/sidebar";
import { useState } from "react";
import uuid from "react-uuid";
import isEmpty from "lodash/isEmpty";
import axios from "../../../../utils/api/axios";
import secrets from "../../../../secrets";
import { CloseIcon, DeleteIcon } from "@chakra-ui/icons";
import { theme } from "../../../../styles/theme/base";
import { TypeAdd } from "../../../../static/assets/images/dashboard/sidebar";
import deleteIcon from "../../../../static/assets/images/deleteIcon.svg";
import checkIcon from "../../../../static/assets/images/checkIcon.svg";
import imageIcon from "../../../../static/assets/images/imageIcon.svg";
import editIcon from "../../../../static/assets/images/editIcon.svg";
import lineupPerformer from "../../../../static/assets/images/lineupPerformer.png";
import { useMediaQuery } from "../../../../utils/useMediaQuery";
/**
 * The ArtistLineup function is a React component that allows users to add and display tracks and
 * artists for a music event, including uploading images for each.
 * @param props - props is an object that contains properties passed down to the component from its
 * parent component. It includes the handleBlur, values, and setFieldValue functions, which are used to
 * handle form input and state management. It also includes the toast function from the Chakra UI
 * library, which is used to display notifications
 * @returns The `ArtistLineup` component is being returned.
 */
const ArtistLineup = (props) => {
  const { handleBlur, values, setFieldValue } = props;
  const initialTrack = {
    trackName: "",
    artistName: "",
    image: "",
  };
  const initialLineup = {
    performerName: "",
    performerImage: "",
  };
  const [track, setTrack] = useState(initialTrack);
  const [lineup, setLineUp] = useState(initialLineup);
  const [showLineUpForm, setShowLineUpForm] = useState(true);
  const isMobile = useMediaQuery("(max-width: 992px)");

  const toast = useToast();

  const uploadTrackImage = async (image, type) => {
    const fd = new FormData();
    fd.append("image", image);
    fd.append("type", type);
    axios
      .post("/api/uploadImage", fd,{
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status) {
          if (type === "lineup") {
            setLineUp({
              ...lineup,
              image: res.data?.link,
              image: res.data?.link,
            });
          } else {
            setTrack({
              ...track,
              image: res.data?.link,
              image: res.data?.link,
            });
          }
        } else {
          toast({
            title: "Image Uploading Err",
            status: "error",
            isClosable: true,
            duration: 4000,
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        toast({
          title: "Image Uploading Err",
          status: "error",
          isClosable: true,
          duration: 4000,
          position: "top-right",
        });
      });
  };
  return (
    <VStack w="100%">
      <VStack w="100%">
        <Text
          className="gordita700"
          fontSize={"1.25rem"}
          textAlign="left"
          // mb={"1.25rem"}
          w="100%"
          color="#FFFFFF"
        >
          Line Up 
        </Text>
        {!isEmpty(values.lineup) && (
          <VStack
            gap={"0.63rem"}
            width={"100%"}
            margin={"1.25rem auto !important"}
          >
            {!isEmpty(values.lineup) &&
              Object.values(values.lineup).map(
                ({ id, image, trackName, performerName }) => (
                  <Box
                    key={id}
                    w="100%"
                    p={"0.31rem"}
                    borderRadius="6.25rem;"
                    border={"1px solid rgba(255, 255, 255, 0.10)"}
                    h="5rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      width={"100%"}
                      gap={"10px"}
                    >
                      <Image
                        borderRadius={"4.375rem"}
                        objectFit={"fill"}
                        h="4.375rem"
                        w="4.375rem"
                        alt=""
                        src={image ?? lineupPerformer}
                      />
                      <Text
                        className="gordita600"
                        fontSize={"1.4375rem"}
                        color="#FFFFFF"
                        lineHeight={"150%;"}
                        letterSpacing={"-0.01438rem"}
                      >
                        {performerName}
                      </Text>
                    </Box>
                    <Box
                      display={"flex"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      width={"100%"}
                      gap={"10px"}
                    >
                      <Button
                        sx={theme.buttons.secondary.shape}
                        bg={theme.buttons.secondary.default}
                        color={theme.buttons.secondary.color}
                        _hover={{
                          borderColor: theme.buttons.secondary.hoverBorderColor,
                        }}
                        _active={{
                          bg: theme.buttons.secondary.pressed,
                        }}
                        style={{
                          width: "3.4375rem",
                          height: "3.4375rem",
                          borderRadius: "50%",
                        }}
                        border={"1px solid #FFFFFF33"}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the click event from propagating
                          if (values.lineup.hasOwnProperty(id)) {
                            setFieldValue(`lineup.${id}`, undefined);
                          }
                        }}
                      >
                        <img
                          src={deleteIcon}
                          alt={"delete-icon"}
                          style={{
                            width: "44px",
                            height: "44px",
                            fontSize: "40px",
                          }}
                        />
                      </Button>
                      {/* <Button
                        sx={theme.buttons.secondary.shape}
                        bg={theme.buttons.secondary.default}
                        color={theme.buttons.secondary.color}
                        _hover={{
                          borderColor: theme.buttons.secondary.hoverBorderColor,
                        }}
                        _active={{
                          bg: theme.buttons.secondary.pressed,
                        }}
                        style={{
                          width: "3.4375rem",
                          height: "3.4375rem",
                          borderRadius: "50%",
                        }}
                        border={"1px solid #FFFFFF33"}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the click event from propagating
                          // if (values.lineup.hasOwnProperty(id)) {
                          //   setFieldValue(`lineup.${id}`, undefined);
                          // }
                        }}
                      >
                        <img
                          src={editIcon}
                          alt={"edit-icon"}
                          style={{
                            width: "44px",
                            height: "44px",
                            fontSize: "40px",
                          }}
                        />
                        </Button>*/}
                    </Box>
                  </Box>
                )
              )}
          </VStack>
        )}
        <VStack width={"100%"} gap={"1.56rem"}>
          {showLineUpForm && (
            <HStack alignItems="center" flex={1} w="100%" gap={"10px"}>
              <Box
                width={isMobile ? "6.5rem" : "8.5rem"}
                padding="0.31rem"
                border={"1px solid rgba(255, 255, 255, 0.20)"}
                borderRadius={"6.25rem"}
                cursor={"pointer"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <input
                  style={{ display: "none" }}
                  id="lineupImage"
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  onChange={(e) => {
                    uploadTrackImage(e.target.files[0], "lineup");
                  }}
                />

                <label
                  htmlFor="lineupImage"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.63rem",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    width={"2.8125rem"}
                    height={"2.8125rem"}
                    borderRadius={"50%"}
                    backgroundColor={"rgba(255, 255, 255, 0.05)"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <img
                      src={lineup.image || imageIcon}
                      alt={"image-icon"}
                      style={{ width: "1.5rem", height: "1.5rem" }}
                    />
                  </Box>
                  {!isMobile && <Text color="#FFFFFF">Image</Text>}
                </label>
              </Box>
              <InputBox
                values={values}
                handleBlur={handleBlur}
                handleChange={(val) =>
                  setLineUp({
                    ...lineup,
                    performerName: val.target.value,
                  })
                }
                placeholder="Enter Performer's Name"
                label=""
                name="performerName"
                maxW="25.1875rem"
              />

              <Button
                sx={theme.buttons.secondary.shape}
                bg={theme.buttons.secondary.default}
                color={theme.buttons.secondary.color}
                _hover={{
                  borderColor: theme.buttons.secondary.hoverBorderColor,
                }}
                _active={{
                  bg: theme.buttons.secondary.pressed,
                }}
                style={{
                  width: "3.4375rem",
                  height: "3.4375rem",
                  minWidth: "3.4375rem",
                  minHeight: "3.4375rem",
                  borderRadius: "50%",
                }}
                disabled={!lineup.performerName}
                border={"1px solid #FFFFFF33"}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent the click event from propagating
                  const id = uuid();
                  setFieldValue(`lineup.${id}`, { ...lineup, id });
                  setLineUp(initialLineup);
                  setShowLineUpForm(false);
                }}
              >
                <img
                  src={checkIcon}
                  alt={"check-icon"}
                  style={{ width: "44px", height: "44px", fontSize: "40px" }}
                />
              </Button>
            </HStack>
          )}

          <Button
            sx={theme.buttons.secondary.shape}
            bg={theme.buttons.secondary.border}
            color={theme.buttons.secondary.color}
            border={theme.buttons.secondary.border}
            style={{
              width: "100%",
            }}
            className="gordita700"

            _hover={{
              borderColor: theme.buttons.secondary.hoverBorderColor,
            }}
            onClick={() => {
              // const id = uuid();
              // setFieldValue(`lineup.${id}`, { ...lineup, id });
              setLineUp(initialLineup);
              setShowLineUpForm(true);
            }}
          >
            Add Performer
          </Button>
        </VStack>
      </VStack>
    </VStack>
  );
};

export default ArtistLineup;
