/**
 * These are functions that make API calls to create, fetch, and manipulate events in a web
 * application.
 * @param payload - The data to be sent in the request body for adding an event.
 * @returns These are functions that return promises that make HTTP requests to different endpoints of
 * an API using the Axios library. The specific data that is being returned depends on the endpoint
 * being accessed and the parameters being passed in.
 */
import axios from "./axios";

export const addEvent = async (payload,token) => {
  return axios.post("/api/create", payload,{
    headers: {
      "x-auth-token": `Bearer ${token}`,
    },
});
};

export const editEvent = async (payload,token) => {
  return axios.post("/api/editEvent", payload,{
    headers: {
      "x-auth-token": `Bearer ${token}`,
    },
});
};

export const addResellEvent = async (payload,token) => {
  return axios.post("/api/create-resell-event", payload,{
    headers: {
      "x-auth-token": `Bearer ${token}`,
    },
});
};

export const editResellEvent = async (payload,token) => {
  return axios.post("/api/edit-resell-event", payload,{
    headers: {
      "x-auth-token": `Bearer ${token}`,
    },
});
};

export const fetchEvent = async (params,token) => {
  return axios.get("/api/events/organizer/" + params,{
    headers: {
      "x-auth-token": `Bearer ${token}`,
    },
});
};

export const fetchVerifierEvent = async (params) => {
  return axios.get("/api/events/verifier/" + params);
};


export const fetchAllEventLiked = async (params,token) => {
  return axios.get("/api/allevents/liked/" + params,{
    headers: {
      "x-auth-token": `Bearer ${token}`,
    },
});
};

export const fetchEventByUUID = async (id) => {
  return axios.get("/api/events/" + id);
};

export const fetchEventBySlug = async (id, trackingId) => {
  const url = `/api/event/${id}${trackingId ? `?tracking=${trackingId}` : ''}`;
  return axios.get(url);
};
export const fetchResellEventBySlug = async (id) => {
  return axios.get(`/api/resellEvent/${id}`);
}

