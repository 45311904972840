/**
 * This is a React component for a dashboard layout with a sidebar, navbar, and main content area.
 * @returns The `DashboardLayout` component is being returned.
 */
import { Box, useColorModeValue } from "@chakra-ui/react";
import { node, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { MainWrapper, Child } from "../../../styles/layout/dashboard";
import Sidebar from "../dashboard/sidebar";
import { theme } from "../../../styles/theme/base";
import Navbar from "./navbar";
/**
 * This is a React component for a dashboard layout with a navbar, sidebar, and main content area.
 * @returns This code exports a functional component called `DashboardLayout`. It takes in `children`
 * and `dashboardName` as props, and returns a layout component that includes a `Navbar`, `Sidebar`,
 * and a `Box` that wraps around the `children` prop. The `Navbar` and `Sidebar` components are passed
 * some props, and the `Sidebar` component also receives a `setSidebarWidth
 */
const EventLayout = ({ children, dashboardName, ...rest }) => {
  const [sidebarWidth, setSidebarWidth] = useState("200px");
  const value = useColorModeValue(
    theme.colors.white[100],
    theme.colors.black[100]
  );

  return (
    <MainWrapper background={value}>
      <Box w="100%" display={"flex"}>
        <Sidebar
          sidebarWidth={sidebarWidth}
          setSidebarWidth={setSidebarWidth}
        />
        <Box w={"100%"}>
          <Child id="main">{children}</Child>
        </Box>
      </Box>
    </MainWrapper>
  );
};

EventLayout.propTypes = {
  children: node,
  dashboardName: string,
};

export default EventLayout;
