import { atom } from "recoil";
import { isTokenExpired } from "../../utils/handleTokens";


/**
 * Retrieves the default user state from localStorage.
 * 
 * This function fetches the user data and access token from localStorage. It then checks if the 
 * access token is valid by calling the `isTokenExpired` function. If the token is valid, it sets 
 * the user state with the user data and token from localStorage. If the token is invalid or 
 * expired, it sets the user state to empty values.
 * 
 * @returns {Object} - An object containing the user data, token, and login status.
 */

const getDefaultUserState = () => {
  const userData = localStorage.getItem('user_d')
  const accessToken = localStorage.getItem('x-auth-token')
  let token = ""
  let user = {}
  const isValidToken = accessToken && !isTokenExpired(accessToken);

  if (isValidToken) {
    token = accessToken
    user = JSON.parse(userData)
  }
  else {
    token = ""
    user = {}
  }
  return {
    userData: user ? user : {},
    token: token || "",
    isLoggedIn: isValidToken,
  };
};

/**
 * Recoil atom to manage the user state in the application.
 * 
 * This atom stores the user data, token, and login status. It uses the `getDefaultUserState`
 * function to initialize the default state based on the values stored in localStorage.
 * 
 * @type {RecoilState<Object>}
 */
export const user = atom({
  key: "user",
  default: getDefaultUserState()
  // {
  //   userData: {},
  //   token: "",
  // },
});
