/**
 * This is a React component that renders a dashboard layout with a create event page, and requires
 * authentication for certain user roles.
 * @param props - props is an object that contains all the properties passed down to the CreateEvents
 * component. These properties can include data, functions, and other components that are used within
 * the component.
 * @returns The CreateEvents component wrapped with the withAuth higher-order component, which takes in
 * the CreateEvents component and an array of user roles as arguments. The CreateEvents component
 * returns a DashboardLayout component with props passed down and a CreateEvent component nested
 * inside.
 */

import EventLayout from "../../modules/layout/event";
import EmbedEventComponent from "../../modules/pages/Events/embed";
import { userRoles } from "../../utils/constants";
import withAuth from "../../utils/withAuth";
const EmbedEvent = (props) => {
  return (
    <EventLayout {...props} dashboardName="Events">
      <EmbedEventComponent {...props} />
    </EventLayout>
  );
};

export default withAuth(EmbedEvent, [
  userRoles.ORGANIZER,
  userRoles.PREMIUM_ORGANIZER,
  userRoles.ADMIN,
]);
