import { React, useState, useRef, useEffect } from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Button,
  HStack,
  VStack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { user } from "../../recoil/atoms/user";
import axios from "../../utils/api/axios";
import { theme } from "../../styles/theme/base";
import { useLocation } from "react-router-dom";

import { useParams } from "react-router-dom"; // Import useParams if you're using React Router
import { useNavigate } from "react-router-dom";
import { userRoles } from "../../utils/constants";
import withAuth from "../../utils/withAuth";

const Validate = () => {
  const [_, setUser] = useRecoilState(user);
  const [tickets, setTickets] = useState([]);
  const token = localStorage.getItem("x-auth-token");
  const params = useParams();
  const navigate = useNavigate();
  const { walletAddress, eventuuid } = params;

  const fetchNFTs = async (walletAddress, eventuuid) => {
    if (token) {
      try {
        const response = await axios.get("/api/getNFTsByEvent", {
          headers: {
            "x-auth-token": token,
          },
          params: {
            eventUuid: eventuuid,
            walletAddress: walletAddress,
          },
        });
        console.log("tickets", response.data.nfts);
        setTickets(response.data.nfts);
      } catch (error) {
        console.error("Error fetching NFTs:", error);
      }
    }
  };

  useEffect(() => {
    const { walletAddress, eventuuid } = params;

    fetchNFTs(walletAddress, eventuuid);
  }, [token]);

  const validateNFT = async (ticketIds) => {
    try {
      if (token) {
        const headers = {
          "x-auth-token": token,
        };

        const data = {
          ticketIds: ticketIds,
        };

        const response = await axios.post("/api/validateNFT", data, {
          headers,
        });

        if (response.data.success) {
          return response.data.message;
        } else {
          throw new Error(response.data.message);
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const toast = useToast();

  const handleValidateClick = async (ticketIds) => {
    try {
      const message = await validateNFT(ticketIds);
      toast({
        title: "Validation Successful",
        description: message,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      fetchNFTs(walletAddress, eventuuid);
    } catch (error) {
      console.error("Validation failed:", error.message);
      toast({
        title: "Validation Failed",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };
  return (
    <Box // Container to center the VStack vertically
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh" // Make the container take the full viewport height
      bg="#000000"
    >
      <VStack spacing={4} alignItems="stretch">
        <Box>
          {tickets.length ? (
            tickets.map((ticket) => (
              <Box
                key={ticket.ticketId}
                sx={theme.Header.shape}
                style={{ cursor: "pointer" }}
              >
                <HStack width="100%" justifyContent="space-between">
                  <Text
                    fontFamily="Gordita"
                    fontSize="1rem"
                    fontWeight="400"
                    color={"rgba(255, 255, 255, 0.60)"}
                  >
                    {ticket.quantity || "1"} {ticket.type} Ticket
                  </Text>
                  {ticket.isVerified ? (
                    <Button
                      sx={theme.buttons.tercary.shape}
                      bg={theme.buttons.secondary.border} // Change to the desired color for verified tickets
                      color="#959597" // Change to the desired text color for verified tickets
                      border="#FF1B1B"
                      onClick={() =>
                        handleValidateClick([ticket.ticketId])
                      }
                    >
                      Verified
                    </Button>
                  ) : (
                    <Button
                      sx={theme.buttons.tercary.shape}
                      bg={theme.buttons.secondary.border} // Change to the desired color for unverified tickets
                      color={theme.buttons.secondary.color} // Change to the desired text color for unverified tickets
                      border={theme.buttons.secondary.border}
                      onClick={() =>
                        handleValidateClick([ticket.ticketId])
                      }
                    >
                      Validate
                    </Button>
                  )}
                </HStack>
              </Box>
            ))
          ) : (
            <>
              <Text
                marginTop="4rem"
                fontFamily="Gordita"
                fontSize="1.25rem"
                fontWeight="700"
              >
                No tickets for this event
              </Text>
            </>
          )}
        </Box>
        <Button
          sx={{
            borderRadius: `100px`,
            display: `flex`,
            isolation: `isolate`,
            flexDirection: `row`,
            justifyContent: `center`,
            alignItems: `center`,
            padding: `15px 30px`,
            boxSizing: `border-box`,
            overflow: `hidden`,
            height: `43px`,
            width: `180px`,
          }}
          bg={theme.buttons.primary.default}
          color={theme.buttons.primary.color}
          _hover={{
            bg: theme.buttons.primary.hover,
            boxShadow: theme.buttons.primary.hoverBoxShadow,
          }}
          _active={{
            boxShadow: theme.buttons.primary.activeBoxShadow,
          }}
          position="fixed"
          bottom="4"
          right="4"
          onClick={() => {
            // Pass the selected event object to the next screen
            navigate(`/verifier/scanner/${eventuuid}`);
          }}
        >
          Back to Scanning
        </Button>
      </VStack>
    </Box>
  );
};

export default withAuth(Validate, [userRoles.VERIFIER]);
