import cookie from "cookie";
import _intersection from "lodash/intersection";
import _isEmpty from "lodash/isEmpty";
import React, { Component, useEffect, useState } from "react";
import { userRoles } from "./constants.js";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { user } from "../recoil/atoms/user.js";
import { Box, Spinner } from "@chakra-ui/react";

/**
 * This is a higher-order function that returns a component that checks if the user has the required
 * role and redirects them if not.
 * @param WrappedComponent - This is a component that is being wrapped by the withAuth higher-order
 * component. It is the component that requires authentication before it can be accessed.
 * @param [requiredRole] - This is a parameter that specifies the role(s) required to access the
 * wrapped component. It is an optional parameter with a default value of [userRoles.ATTENDEE].
 * @param [bypassRedirect=false] - The `bypassRedirect` parameter is a boolean value that determines
 * whether or not to redirect the user to the homepage if their role does not match the required role.
 * If `bypassRedirect` is set to `true`, the user will not be redirected and the component will be
 * rendered with the loading
 * @returns The `withAuth` function returns a higher-order component that takes in a `WrappedComponent`
 * and returns a new component that checks if the user has the required role to access the
 * `WrappedComponent`. If the user has the required role, the `WrappedComponent` is rendered with the
 * `props` passed to it. If the user does not have the required role, a loading spinner is displayed
 * until
 */
const withAuth =
  (WrappedComponent, requiredRole = [userRoles.ATTENDEE], bypassRedirect = false) =>
  (props) => {
    const [_, setUser] = useRecoilState(user);

    useEffect(() => {
      const user = localStorage.getItem("user_d");
      if (user) {
        const userP = JSON.parse(user);
        if (!requiredRole.includes(userP?.userRole)) {
          window.location.href = "/";
        }
      } else {
        window.location.href = "/";
      }
    }, []);
    return (
      <>
        {requiredRole.includes(_?.userData?.userRole) ? (
          <WrappedComponent {...props} />
        ) : (
          <>
            <Box w="100%" h="100vh" display="flex" justifyContent="center" alignItems="center">
            {"Unauthorized, Please Close This Page"} 
            </Box>
          </>
        )}
      </>
    );
  };

export default withAuth;
