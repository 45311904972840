import { Box, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import axios from "../../../../../utils/api/axios";
import { theme } from "../../../../../styles/theme/base";

const ViewContactsModal = ({ isOpen, onClose, listId }) => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    // Fetch the subscriber list details when the modal is opened
    const fetchListDetails = async () => {
      try {
        setLoading(true);
        const data = await getSubscriberListDetailsApi(listId);
        setContacts(data.contacts); // Set the contacts data
      } catch (error) {
        setError("Failed to fetch list details.");
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchListDetails(); // Only fetch data when the modal is open
    }
  }, [isOpen, listId]);



// Function to fetch subscriber list details by listId
const getSubscriberListDetailsApi = async (listId) => {
  try {
    const response = await axios.get(`/api/list/${listId}`);
    return response.data; // Return the fetched data
  } catch (error) {
    console.error("Error fetching subscriber list details:", error);
    throw error; // Re-throw the error so it can be handled in the component
  }
};


  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent
        bg="black"
        border="1px solid #333333"
        borderColor="#333333"
        borderRadius="1.25rem"
        overflow="hidden" // Prevents overflowing content from breaking the layout
      >
        <ModalHeader>Subscriber List Contacts</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          overflowY="auto" // Enables scrolling if content exceeds the modal height
          maxH="60vh" // Sets a maximum height for the content area
        >
          {loading ? (
            <Text>Loading...</Text>
          ) : error ? (
            <Text color="red.500">{error}</Text>
          ) : (
            <VStack spacing={3} align="flex-start">
              {contacts.length > 0 ? (
                contacts.map((contact) => (
                  <Box
                    key={contact.id}
                    border="1px solid #333333"
                    borderRadius="8px"
                    p="3"
                    w="100%"
                  >
                    <Text fontWeight="bold">{contact.name}</Text>
                    <Text>{contact.email}</Text>
                    <Text>{contact.phone}</Text>
                  </Box>
                ))
              ) : (
                <Text>No contacts found in this list.</Text>
              )}
            </VStack>
          )}
        </ModalBody>
        <ModalFooter>
        <Button
                onClick={
                    onClose
                }
                sx={theme.buttons.tercary.shape}
                bg={theme.buttons.tercary.bgColor}
                color={theme.buttons.tercary.color}
                _hover={{ bg: "red.500" }}
                border="1px solid rgba(255, 255, 255, 0.20)"
                css={{
                width: "fit-content !important",
                padding: "1.25rem",
                marginRight: "10px",
                }}
            >
                Close
            </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ViewContactsModal;
