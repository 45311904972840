import { BackTop } from "antd";

export const theme = {
  components: {
    Toast: {
      // Customize the styles here
      baseStyle: {
        container: {
          backgroundColor: "black",
          background: "blue",
          color: "white",
          // Your custom container styles
        },
        content: {
          // Your custom content (text) styles
        },
        body: {
          // Your custom styles for the toast body
          background: "blue",
          color: "white",
        },
      },
    },
  },
  colors: {
    primary: {
      100: "#FF5C00",
    },
    black: {
      100: "#000000",
      200: "#252526",
      300: "#1C1C1C",
      400: "#141414",
    },
    white: {
      100: "#ffffff",
      200: "#FBFAFB",
    },
    blue: {
      100: "#0364FF",
    },
    orange: {
      100: "#FF5C00",
    },
    purple: {
      100: "#A6A0BB",
    },
  },
  breakpoints: {
    sm: "32.5em",
    md: "48em",
    lg: "85em",
    "3xl": "125rem",
  },
  config: {
    initialColorMode: "dark",
    useSystemColorMode: false,
  },
  typography: {
    h1: {
      mobile: {
        fontSize: "22px",
        lineHeight: "28px",
        fontWeight: "800",
      },
      desktop: {
        fontSize: "40px",
        lineHeight: "48px",
        fontWeight: "800",
      },
    },
    h2: {
      mobile: {
        fontSize: "28px",
        lineHeight: "32px",
        fontWeight: "700",
      },
      desktop: {
        fontSize: "36px",
        lineHeight: "39px",
        fontWeight: "700",
      },
    },
    h4: {
      mobile: {
        fontSize: "18px",
        lineHeight: "32px",
        fontWeight: "700",
      },
      desktop: {
        fontSize: "22px",
        lineHeight: "39px",
        fontWeight: "700",
      },
    },
    body: {
      mobile: {
        fontSize: "14px",
        lineHeight: "21px",
        fontWeight: "500",
      },
      desktop: {
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: "500",
      },
    },
    text: {
      mobile: {
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: "500",
      },
      desktop: {
        fontSize: "16px",
        lineHeight: "23px",
        fontWeight: "500",
      },
    },
    footerText: {
      mobile: {
        fontSize: "12px",
        lineHeight: "14px",
        fontWeight: "400",
      },
      desktop: {
        fontSize: "14px",
        lineHeight: "16px",
        fontWeight: "400",
      },
    },
    footerHeading: {
      mobile: {
        fontSize: "14px",
        lineHeight: "17px",
        fontWeight: "700",
      },
      desktop: {
        fontSize: "18px",
        lineHeight: "19px",
        fontWeight: "700",
      },
    },
    btnText: {
      mobile: {
        fontSize: "12px",
        lineHeight: "14px",
        fontWeight: "700",
      },
      desktop: {
        fontSize: "15px",
        lineHeight: "16px",
        fontWeight: "700",
      },
    },
  },
  fontSizes: {
    16: "16px",
    20: "20px",
    32: "32px",
    36: "36px",
    40: "40px",
  },
  fontWeights: {
    400: 400,
    700: 700,
  },
  lineHeights: {
    24: "24px",
    38: "38px",
    48: "48px",
  },
  space: {
    8: "8px",
    10: "10px",
    12: "12px",
    14: "14",
    16: "16px",
    20: "20px",
    24: "24px",
    28: "28px",
    36: "36px",
    44: "44px",
    56: "56px",
    60: "60px",
    71: "71px",
    84: "84px",
    100: "100px",
    108: "108px",
  },

  card: {
    primary: {
      default: "#FF5C00",
      pressed: "#FF5C00",
      disabled: "#929295",
      hover: "#FFFFFF",
      color: "#FFFFFF",
      shape: {
        maxWidth: "23.75rem",
        height: "37.5rem",
        width: "100%",
        flexShrink: 0,
        borderRadius: "1.5625rem",
        border: "1px solid rgba(255, 255, 255, 0.20)",
        background: "rgba(0, 0, 0, 0.50)",
        backdropFilter: "blur(150px)",
        padding:
          "var(--spacing-spacing-none, 0.625rem) var(--spacing-spacing-none, 0.625rem) var(--spacing-spacing-none, 1.25rem) var(--spacing-spacing-none, 0.625rem)",
        _hover: {
          borderColor: "#fff",
          // Add any other styles for the hover state
        },
      },
    },
    picture: {
      shape: {
        width: "19.0625rem",
        height: "12.6875rem",
        flexShrink: 0,
        borderRadius: "var(--radius-radius-big, 1.25rem)",
      },
    },
  },

  Tablelist: {
    shape: {
      backgroundColor: `rgba(0, 0, 0, 1)`,
      border: `1px solid rgba(146, 146, 149, 1)`,
      boxSizing: `border-box`,
      borderRadius: `100px`,
      display: `flex`,
      position: `relative`,
      isolation: `isolate`,
      flexDirection: `row`,
      width: `1126px`,
      height: `74px`,
      justifyContent: `flex-start`,
      alignItems: `flex-start`,
      padding: `0px`,
      overflow: `hidden`,
    },
  },
  fixedButtons: {
    primary: {
      default: "#FFFFFF",
      pressed: "#FFFFFF",
      disabled: "#FFFFFF1A",
      hover: "#FFFFFF",
      color: "#000000",
      colorDisabled: "#FFFFFF66",
      hoverBoxShadow: "0px 0px 50px 0px rgba(255, 255, 255, 0.25)",
      activeBoxShadow: "0px 0px 50px 0px rgba(255, 255, 255, 0.40)",
      shape: {
        borderRadius: `100px`,
        display: `flex`,
        position: `relative`,
        isolation: `isolate`,
        flexDirection: `row`,
        justifyContent: `center`,
        alignItems: `center`,
        boxSizing: `border-box`,
        overflow: `hidden`,
        height: `43px`,
        width: `180px`,
        padding: "15px 30px",
      },
    },
    secondary: {
      default: "#000000",
      pressed: "#FFFFFF0D",
      disabled: "#FFFFFF1A",
      hover: "#000000",
      border: "1px solid #FFFFFF33",
      color: "#FFFFFF",
      hoverBorderColor: "#FFF",
      shape: {
        borderRadius: `100px`,
        display: `flex`,
        position: `relative`,
        isolation: `isolate`,
        flexDirection: `row`,
        justifyContent: `center`,
        alignItems: `center`,
        boxSizing: `border-box`,
        overflow: `hidden`,
        height: `44px`,
        width: `44px`,
        padding: "10px",
      },
    },
  },
  buttons: {
    primary: {
      default: "#FFFFFF",
      pressed: "#FFFFFF",
      disabled: "rgba(255, 255, 255, 0.10)",
      hover: "#FFFFFF",
      color: "#000000",
      colorDisabled: "rgba(255, 255, 255, 0.40)",
      hoverBoxShadow: "0px 0px 50px 0px rgba(255, 255, 255, 0.25)",
      activeBoxShadow: "0px 0px 50px 0px rgba(255, 255, 255, 0.40)",
      shape: {
        borderRadius: `100px`,
        display: `flex`,
        position: `relative`,
        isolation: `isolate`,
        flexDirection: `row`,
        justifyContent: `center`,
        alignItems: `center`,
        boxSizing: `border-box`,
        overflow: `hidden`,
        height: `3.125rem`,
        width: `180px`,
      },
    },
    secondary: {
      default: "#000000",
      pressed: "rgba(255, 255, 255, 0.05)",
      disabled: "#FFFFFF1A",
      hover: "#FFFFFF",
      border: "1px solid #FFFFFF33",
      color: "#FFFFFF",
      bgColor: "trasparent",
      hoverBorderColor: "#FFFFFF",
      disabledColor: "rgba(255, 255, 255, 0.40)",
      disabledBorderColor: "rgba(255, 255, 255, 0.10)",
      shape: {
        borderRadius: `3.125rem`,
        border: "1px solid rgba(255, 255, 255, 0.20)",
        display: `flex`,
        position: `relative`,
        isolation: `isolate`,
        flexDirection: `row`,
        justifyContent: `center`,
        alignItems: `center`,
        boxSizing: `border-box`,
        overflow: `hidden`,
        height: `3.125rem`,
        width: `44px`,
      },
    },
    tercary: {
      hover: "#FFFFFF0D",
      pressed: "#FFFFFF1A",
      color: "#FFFFFF",
      bgColor: "transparent",
      hoverBgColor: "rgba(255, 255, 255, 0.05)",
      pressedBgColor: "rgba(255, 255, 255, 0.10)",
      disabledBgColor: "transparent",
      shape: {
        borderRadius: `100px`,
        display: `flex`,
        position: `relative`,
        isolation: `isolate`,
        flexDirection: `row`,
        justifyContent: `center`,
        alignItems: `center`,
        padding: `10px`,
        boxSizing: `border-box`,
        overflow: `hidden`,
        height: `3.125rem`,
        width: ["auto", "15.75rem"],
      },
    },

    mobile: {
      shape: {
        borderRadius: `6.25rem`,
        display: `flex`,
        position: `relative`,
        isolation: `isolate`,
        flexDirection: `row`,
        justifyContent: `center`,
        alignItems: `center`,
        padding: `15px 30px`,
        boxSizing: `border-box`,
        overflow: `hidden`,
        height: `3.375rem`,
        width: `100%`,
        fontFamily: `Gordita`,
      },
    },
  },

  sidenav: {
    primary: {
      default: "#00000",
      pressed: "#FF5C00",
      disabled: "#929295",
      hover: "#FF7629",
      color: "#FFFFFF",

      shape: {
       // borderRadius: `0.9375rem`,
        display: `flex`,
        position: `relative`,
        isolation: `isolate`,
        flexDirection: `row`,
        width: `200px`,
        justifyContent: `flex-start`,
        alignItems: `center`,
        padding: `15px`,
        boxSizing: `border-box`,
        overflow: `hidden`,
        height: `54px`,
      //  borderTop: `1px solid rgba(255, 255, 255, 0.20)`,
      //  borderRight: `var(--spacing-spacing-none, 1px) solid rgba(255, 255, 255, 0.20)`,
      //  borderBottom: `var(--spacing-spacing-none, 1px) solid rgba(255, 255, 255, 0.20)`,
       // borderLeft: `var(--spacing-spacing-none, 1px) solid rgba(255, 255, 255, 0.20)`,
      },
      collapsed: {
        borderRadius: `100px`,
        display: `flex`,
        position: `relative`,
        isolation: `isolate`,
        flexDirection: `row`,
        justifyContent: `center`,
        alignItems: `center`,
        padding: `10px`,
        boxSizing: `border-box`,
        overflow: `hidden`,
        height: `54px`,
        width: `54px`,
      },
    },
  },

  accountButton: {
    primary: {
      default: "#00000",
      pressed: "#FF5C00",
      disabled: "#929295",
      hover: "#FFFFFF",
      color: "#FFFFFF",

      shape: {
        border: `1px solid rgba(255, 255, 255, 0.20)`,
        boxSizing: `border-box`,
        borderRadius: `100px`,
        display: `flex`,
        position: `relative`,
        isolation: `isolate`,
        flexDirection: `row`,
        height: `44px`,
        justifyContent: `center`,
        alignItems: `center`,
        overflow: `hidden`,
        width: `91px`,
      },
    },
  },

  popoverContent: {
    layout: {
      shape: {
        textAlign: "center",
        width: "13.875rem",
        bg: "#000000",
        color: "#FFFFFF",
        borderRadius: "1.25rem",
        boxShadow:
          "0px 0px 100px 20px rgba(0, 0, 0, 0.90), -1px -1px 0px 0px rgba(255, 255, 255, 0.20) inset, 1px 1px 0px 0px rgba(255, 255, 255, 0.50) inset",
      },
    },
    elements: {
      shape: {
        cursor: "pointer",
        colorScheme: "blue",
        fontSize: "1rem",
        fontWeight: 700,
        _hover: { bg: "rgba(255, 255, 255, 0.07)" },
      },
    },
  },

  menu: {
    layout: {
      shape: {
        bg: "#000000",
        color: "#FFFFFF",
        borderRadius: "1.5625rem",
        border: "1px solid #FFFFFF",
        boxShadow: "0px 0px 200px 0px #000",
        width: "198px",
        marginBottom: "10rem",
      },
    },
    menuItems: {
      shape: {
        margin: "0",
        cursor: "pointer",
        fontSize: "1rem",
        fontWeight: 700,
        backgroundColor: "#000000",
        _hover: { bg: "rgba(255, 255, 255, 0.05)" },
        _active: { bg: "rgba(255, 255, 255, 0.10)" },
        overflow: "hidden",
        borderRadius: "100px",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textTransform: "capitalize",
      },
      selectedShape: {
        margin: "0",
        cursor: "pointer",
        fontSize: "1rem",
        fontWeight: 700,
        color: "#000000",
        backgroundColor: "#FFFFFF",
        _hover: {
          boxShadow: "0px 0px 50px 0px rgba(255, 255, 255, 0.25)",
        },
        _active: {
          boxShadow: "0px 0px 50px 0px rgba(255, 255, 255, 0.40)",
        },
        overflow: "hidden",
        borderRadius: "100px",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  },

  backtop: {
    shape: {
      height: "40px",
      width: "40px",
      borderRadius: "50%",
      border: " 1px solid #FFFFFF",
      borderColor: "#FFFFFF",
      backgroundColor: " #000000",
      color: "#FFFFFF",
      fontSize: "14px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },

  Header: {
    shape: {
      display: "flex",
      width: "90%",
      height: "6rem",
      padding:
        "var(--spacing-spacing-s, 1.25rem) 1.25rem var(--spacing-spacing-s, 1.25rem) var(--spacing-spacing-ll, 1.25rem)",
      justifyContent: "center",
      alignItems: "center",
      gap: "8.375rem",
      flexShrink: "0",
      borderBottom: "1px solid rgba(255, 255, 255, 0.20)",
    },
  },
};
