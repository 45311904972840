import React, { useState } from "react";
import { Box, Heading, useColorModeValue } from "@chakra-ui/react";
import Layout from "../../modules/layout/layout";
import FAQsComponent from "../portalys/faqs";
import "../../styles/faq/faq.css";

/**
 * The FaqEventPlanner function renders a frequently asked questions page with toggles for organizer
 * and participant views.
 * @param props - props is an object containing any additional properties passed down to the
 * FaqEventPlanner component from its parent component. These could include things like styling
 * information, data to be displayed, or functions to be called.
 * @returns This code exports a React functional component named FaqEventPlanner. It returns a layout
 * with a list of frequently asked questions and their answers, along with a toggle button to switch
 * between "Organizer" and "Participant" views. The data for the questions and answers is stored in an
 * array named "data".
 */
export default function FaqEventPlanner(props) {
  const [home, setHome] = useState(1);
  const homeHandle = (event) => {
    setHome(event);
  };
  const value = useColorModeValue("black.100", "#D7D7D7");

  const faqsList = [
    {
      key: "1",
      label: "How to access my event after buying a ticket?",
      children:
        "Log In using your account’s phone number to see your Event Wallet QR code and show it to the event organizer. Or request it on your confirmation email to receive a link by text message. By SMS, the QR code changes every 30 seconds for screenshot protection; no screenshots work 😕 . No connection? No problem, say your name and present an ID at the door, and you’re all set.",
    },
    {
      key: "2",
      label: "What happens if I buy tickets for my friends?",
      children: `All your tickets will be inside your QR code. If you need to share a ticket, you can do so easily on the "My Tickets" page. Simply enter the event, select the ticket you want to share, and choose the recipient.`,
    },
    {
      key: "3",
      label: "Can someone steal/replicate my tickets?",
      children:
        "Your tickets cannot be stolen, duplicated, or hacked. We use blockchain for the good, making it easy for everyone. Get your tickets with peace of mind. Portalys is 100% free of fraud.",
    },
    {
      key: "4",
      label: "What if I don’t see my ticket(s)?",
      children:
        "Most commonly, failed payments. Your bank did not process a payment to us.However, if you don’t see your tickets on your “My Account” page, please email hello@portalys.io with your first and last name and phone number.",
    },
    {
      key: "5",
      label: "How can I get assistance when needed?",
      children:
        "Send us a DM on our social accounts; Instagram, Twitter, and LinkedIn. Or send us an email at hello@portalys.io.",
    },
  ];

  return (
    <Layout {...props}>
      <Box padding="70px 0px">
        <Box className="faqplannerhero">
          <Heading
            fontSize="40px"
            fontWeight="800"
            textAlign="center"
            className="faqheader"
            color={value}
          >
            Frequently Asked Questions
          </Heading>

          <Box padding="0 1.25rem">
            <FAQsComponent faqsList={faqsList} />
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}
