import {
    Box,
    HStack,
    useColorModeValue,
    VStack,
    useToast,
    Spinner,
    Text,
    Heading,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    Collapse,
    Flex,
    Icon,
  } from '@chakra-ui/react';
  import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
  
  import { useEffect, useState, useRef } from 'react';
  import { theme } from '../../../../styles/theme/base';
  import { Formik } from 'formik';
  import BasicEvents from './BasicEvents';
  import { EventBar } from './SharedModules';
  import Ticketing from './Ticketing';
  import TableMap from './TableMap';
  import ArtistLineup from './Artist';
  import { useNavigate, useParams, useLocation } from 'react-router-dom';
  import Promotion from './Promotion';
  import Summary from './Summary';
  import { postResellEvent, modifyResellEvent } from '../../../../utils/actions/event';
  import NFTMeta from './NFTMetaData';
  import { createEventSchema, updateEventSchema } from '../../../../utils/schema';
  import { BsArrowRight, BsChevronLeft } from 'react-icons/bs';
  import GuestList from './GuestList';
  import Music from './Music';
  import Trailer from './Trailer';
  import PromoCodes from './PromoCodes';
  import { getResellEventsBySlug } from '../../../../utils/actions/event';
  import { isEmpty, values } from 'lodash';
  import Admin from './Admin';
  import ExtraPictures from './ExtraPictures';
  import EventPassword from './EventPassword';
  import Perks from './Perks';
  import TableDetails from './TableDetails';
  import Tags from './Tags';
  import Language from './Language';
  import { useRecoilState } from 'recoil';
  import { user } from '../../../../recoil/atoms/user';
  
  import { useMediaQuery } from '../../../../utils/useMediaQuery';

  

  
  const CreateResellEvent = ({ login, provider, paySolFee }) => {
    const params = useParams();
    const { id } = params;
    const location = useLocation();
    const { isEditMode } = location.state || {};
    const formikRef = useRef(); // reference to formik handleSubmit func, since the submit button is outside the Formik component (in the header)
    const shouldShowContent = location.pathname.includes('/edit');
    const [event, setEvent] = useState();
    const [slug, setSlug] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const toast = useToast();
    const isMobile = useMediaQuery('(max-width: 768px)');
    const [_U, setUser] = useRecoilState(user);
  

    const initialValues = {
      eventName: '',
      eventType: '',
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      location: '',
      description: '',
      lineup: {},
      eventImage: '',
      currency: 'USD',
    };
  
    useEffect(() => {
      window.scrollTo(0, 0);
      const init = async () => {
        if (id) {
          try {
            const val = await getResellEventsBySlug(id);
            if (!isEmpty(val)) {
              setEvent(val);
              initialValues.eventName = val.eventName;
              initialValues.eventType = JSON.parse(val.eventType);
              initialValues.startDate = val.startDate;
              initialValues.endDate = val.endDate;
              initialValues.startTime = val.startTime;
              initialValues.endTime = val.endTime;
              initialValues.location = val.location;
              initialValues.description = val.description;
              initialValues.longitude = val.longitude;
              initialValues.latitude = val.latitude;
              initialValues.city = val.city;
              initialValues.country = val.country;
              initialValues.ticketTypes = Array.isArray(val.ticketTypes) ? val.ticketTypes : JSON.parse(val.ticketTypes);
              initialValues.lineup = val.lineup;
              initialValues.currency = val.currency;
              initialValues.eventImage = val.eventImage;
              initialValues.spotifyId = val.spotifyId;
              initialValues.fee_percentage = val.fee_percentage;
              initialValues.fee_fixed = val.fee_fixed;
              initialValues.participant_fee_percentage = val.participant_fee_percentage;
              initialValues.participant_fee_fixed = val.participant_fee_fixed;  
              initialValues.platform = val.platform;
            }
          } catch (error) {
            console.log({ error });
          }
        }
      };
      if (id && isEditMode) {
        init();
      } else {
        console.log('No id, resetting form');
        setEvent();
        formikRef.current?.resetForm({ values: initialValues });
      }
    }, [id]);
    const token = localStorage.getItem('x-auth-token');
  

    const handleCreateEvent = async (values) => {
          try {
            setIsLoading(true);

            await postResellEvent(
              {
                ...values,
              },
              token
            )
              .then((ne) => {
                if (ne.status) {
                  toast({
                    title: 'Resell Event Created Succesfully',
                    status: 'success',
                    isClosable: true,
                    duration: 4000,
                    position: 'bottom',
                  });
                  navigate('/resell-events');
                } else {  
                  toast({
                    title: 'ResellEvent Creation',
                    description: ne?.errors[0]?.message,
                    status: 'error',
                    isClosable: true,
                    duration: 8000,
                    position: 'bottom',
                  });
                }
              })
              .catch((err) => {
                toast({
                  title: 'Event Create',
                  description: err.message,
                  status: 'error',
                  isClosable: true,
                  duration: 8000,
                  position: 'bottom',
                });
              });
            setIsLoading(false);
          } catch (err) {
            setIsLoading(false);
            toast({
              title: 'Event Create',
              description: err.message,
              status: 'error',
              isClosable: true,
              duration: 8000,
              position: 'bottom',
            });
          }
        }
  
    const handleEditEvent = async (values) => {
      try {
        setIsLoading(true);
        await modifyResellEvent(
          {
            ...values,
            slug: id,
          },
          token
        )
          .then((ne) => {
            if (ne?.status) {
              toast({
                title: 'Resell Event Modified Successfully',
                status: 'success',
                isClosable: true,
                duration: 4000,
                position: 'bottom',
              });
              navigate('/resell-events');
            } else {
              console.log('aaaa', ne?.errors[0]?.message);
              toast({
                title: 'Resell Event Creation',
                description: ne?.errors[0]?.message,
                status: 'error',
                isClosable: true,
                duration: 4000,
                position: 'bottom',
              });
            }
          })
          .catch((err) => {
            console.log('bbbb', { err });
            toast({
              title: 'Resell Event Create',
              description: err.message,
              status: 'error',
              isClosable: true,
              duration: 4000,
              position: 'bottom',
            });
          });
        setIsLoading(false);
      } catch (err) {
        console.log({ err });
        setIsLoading(false);
        toast({
          title: 'Missing Field',
          description: err.message,
          status: 'error',
          isClosable: true,
          duration: 4000,
          position: 'bottom',
        });
      }
    };
  


  
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const handleSubmit = () => {
        if (formikRef.current) {
          formikRef.current.handleSubmit();
        }
      };

    return (
      <Box
        style={{
          width: '100%',
          height: '100vh',
          overflow: 'scroll',
        }}
      >
        <Heading
          color="#FBFAFB"
          fontSize="2rem"
          pos={'sticky'}
          top={0}
          zIndex={40}
          w="100%"
          fontWeight={700}
          mb={'3.12rem'}
          bg={'rgba(0, 0, 0, 0.50)'}
          backdropFilter={'blur(150px)'}
          borderBottom={'1px solid rgba(255, 255, 255, 0.10)'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box
            maxW={'1200px'}
            width={'100%'}
            display={'flex'}
            justifyContent={'space-between'}
            flexWrap={'wrap'}
            alignItems={'center'}
            margin={'0 auto'}
            padding={'1.75rem 30px'}
            gap={'10px'}
          >
            <Box w={'fit-content'} display={'flex'}>
              <Box>{shouldShowContent ? 'Edit Event' : 'Resell Event'}</Box>
            </Box>
  
            <Box display="flex" width={'fit-content'}>
              <Button
                isLoading={isLoading}
                loadingText={id && isEditMode ? 'Updating' : 'Saving'}
                className="gordita700"
                onClick={handleSubmit}
                sx={theme.buttons.primary.shape}
                bg={theme.buttons.primary.default}
                color={theme.buttons.primary.color}
                style={{ width: 'fit-content', padding: '0 1.5rem' }}
                _hover={{
                  bg: theme.buttons.primary.hover,
                  boxShadow: theme.buttons.primary.hoverBoxShadow,
                }}
                _active={{
                  boxShadow: theme.buttons.primary.activeBoxShadow,
                }}
                disabled={isButtonDisabled}
              >
                {id && isEditMode ? `Update Event` : `Save As Draft`}
              </Button>
            </Box>
          </Box>
        </Heading>
        <Box
          maxW={'39.625rem;'}
          margin={'0 auto'}
          p={'0 20px'}
          marginBottom={'80px'}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {

                if (id && isEditMode) {
                  handleEditEvent(values);
                } else {
                  handleCreateEvent(values);
                }
              }
            }
            innerRef={formikRef}
          >
            {(formik) => {
              const {
                values,
                handleChange,
                handleSubmit,
                handleBlur,
                resetForm,
                setFieldValue,
              } = formik;
  
              useEffect(() => {
                const requiredFields = [
                  values.eventName,
                  // values.eventType,
                  // values.ticketTypes,
                  // values.description,
                  values.endDate,
                  values.eventImage,
                  values.location,
                  values.startDate,
                  values.startTime,
                  values.platform,

                ];
  
                const isAnyFieldEmpty = requiredFields.some((field) => !field);
  
                setIsButtonDisabled(isAnyFieldEmpty);
              }, [
                values.eventName,
                values.eventType,
                values.ticketTypes,
                values.description,
                values.endDate,
                values.eventImage,
                values.location,
                values.startDate,
                values.startTime,
                values.platform,
              ]);
  
              return (
                <VStack
                  w="100%"
                  gap={'2rem'}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Promotion
                    {...{
                      values,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                    }}
                  />
  
                  <BasicEvents
                    {...{
                      values,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                    }}
                  />
                  <Box className="div-separator" />
  
                  {/* <NFTMeta
                    {...{
                      values,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                    }}
                  />*/}
  
                  <Ticketing
                    {...{
                      values,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                      isEditMode,
                    }}
                  />
                  <Box className="div-separator" />
                  <Tags
                    {...{
                      values,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                    }}
                  />
                  <Box className="div-separator" />
  
                  <Music
                    {...{
                      values,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                    }}
                  />
  
                  <Box className="div-separator" />
                  <ArtistLineup
                    {...{
                      values,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                    }}
                  />
                  {_U?.userData?.userRole === 'ADMIN' && shouldShowContent && (
                    <Admin
                      {...{
                        values,
                        handleBlur,
                        handleChange,
                        setFieldValue,
                      }}
                    />
                  )}
                </VStack>
              );
            }}
          </Formik>
        </Box>
      </Box>
    );
  };
  
  export default CreateResellEvent;
  