export const navLinks = [
  { text: "Marketplace", link: "/marketplace" },
  { text: "Our Vision", link: "/ourvision" },
];
export const userRoles = {
  ADMIN: "ADMIN",
  ORGANIZER: "ORGANIZER",
  PREMIUM_ORGANIZER: "PREMIUM_ORGANIZER",
  ATTENDEE: "ATTENDEE",
  VERIFIER:"VERIFIER",
};

export const cities = [
  {
    label: "Boston",
    value: "boston",
    longitude: -71.05783386046194,
    latitude: 42.36000125863117,
  },
  {
    label: "New York",
    value: "new york",
    longitude: -73.99823091579137,
    latitude: 40.712986793933304,
  },
  {
    label: "Miami",
    value: "miami",
    longitude: -80.19444268046931,
    latitude: 25.76290650671431,
  },
  {
    label: "Paris",
    value: "paris",
    longitude: 2.349092663043262,
    latitude: 48.856566664280805,
  },
];
