/**
 * This is a React component that renders a dashboard layout with a tickets component, and requires
 * authentication for certain user roles.
 * @param props - props is an object that contains all the properties passed to the Orders component.
 * These properties can be accessed using dot notation, for example, props.someProperty.
 * @returns The Orders component is being returned, which includes the TicketsComponent wrapped in a
 * DashboardLayout component. The Orders component is also wrapped in the withAuth higher-order
 * component, which checks if the user has the required roles to access the component.
 */
import EventLayout from "../../modules/layout/event";
import PromoCodes from "../../modules/pages/Admin/PromoCodes";
import { userRoles } from "../../utils/constants";
import withAuth from "../../utils/withAuth";
const Promo = (props) => {
  return (
    <EventLayout {...props} dashboardName="Tracking Links">
      <PromoCodes />
    </EventLayout>
  );
};

export default withAuth(Promo, [
  userRoles.ADMIN,
  userRoles.PREMIUM_ORGANIZER,
  userRoles.ORGANIZER,
]);
