import {
    Box,
    Button,
    Divider,
    HStack,
    Text,
    VStack,
    Input,
    chakra,
    useToast,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
  } from "@chakra-ui/react";
  import { LoginSchema } from "../../../utils/schema";
  import { Formik, Field, Form } from "formik";
  import {
    RegistraionModalTypes,
    registration,
  } from "../../../recoil/atoms/registration";
  import { user } from "../../../recoil/atoms/user";
  import { useRecoilState } from "recoil";
  import { Navigate, useNavigate } from "react-router-dom";
  import { useState } from "react";
  import { useMediaQuery } from "../../../utils/useMediaQuery";
  import { useCookies } from "react-cookie";
  import PhoneInput from "react-phone-input-2/";
  import "react-phone-input-2/lib/bootstrap.css";
  import { userRoles } from "../../../utils/constants";
  import { theme } from "../../../styles/theme/base";
  import axios from "../../../utils/api/axios";
  import backgroundPicture from "../../../static/assets/images/portalysvisionhero1.png";
  import { LightLogo } from "../../../static/assets/images";
  import LoginVerification from "../LoginVerification";
  import SignUpVerification from "../SignUpVerification";
  import LoginByEmail from "../LoginByEmail";
import LoginVerificationByEmail from "../LoginByEmailVerification";
  
  const initialValues = { phoneNumber: "", password: "" };
  
  const CustomToast = chakra("div", {
    baseStyle: {
      zIndex: "modal",
    },
  });
  /**
   * The Login function is a React component that handles user login, including email and password
   * authentication as well as Google login.
   * @returns The Login component is being returned.
   */
  
  
  const LoginOrganizer = () => {
    const [_, setRegistrationModal] = useRecoilState(registration);
    const [u, setUser] = useRecoilState(user);
    const toast = useToast();
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(["x-auth-token"]);
    const isMobile = useMediaQuery("(max-width: 768px)");
    const [verificationStatus, setVerificationStatus] = useState("");
    const [verificationCheck, setVerificationCheck] = useState(false);
    const userRole = userRoles.ATTENDEE;
    const renderModal = () => {
        switch (_.modalType) {
          case RegistraionModalTypes.LOGIN:
            return <Login />;
          case RegistraionModalTypes.SIGNUP_VERIFICATION:
            return <SignUpVerification />;
          case RegistraionModalTypes.LOGIN_VERIFICATION:
            return <LoginVerification />;
            case RegistraionModalTypes.LOGIN_EMAIL:
            return <LoginByEmail />;
            case RegistraionModalTypes.LOGIN_VERIFICATION_EMAIL:
              return <LoginVerificationByEmail />;
        }
      };
    const handleSendOTP = async (phoneNumber) => {
      try {
        const response = await axios.post("/api/sendOTP", { phoneNumber });
        if (response.data.success === true) {
          setVerificationStatus(response.data.message);
          console.log("aaaaaaaaachcekoiin");
          setVerificationCheck(true);
          return true;
        } else {
          setVerificationStatus(response.data.message);
          setVerificationCheck(false);
          return false;
        }
      } catch (error) {
        console.error("Error sending OTP:", error);
        setVerificationStatus("Failed to send OTP");
        return false;
      }
    };
  
    return (
        <Box
          style={{
            background: `url(${backgroundPicture}), lightgray 50% / cover no-repeat`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            // backgroundColor: "#000000",
            width: "100vw",
            height: "100vh",
            flexShrink: 0,
          }}
        >
          <Box
            pos="relative"
            left={isMobile ? 0 : "7%"}
            display={"flex"}
            flexDir={"column"}
            justifyContent={"center"}
            alignItems={"flex-start"}
            width={isMobile ? "100%" : "25rem"}
            height={"100vh"}
            p={isMobile ? 20 : 0}
          >
            <Image
              cursor="pointer"
              onClick={() => navigate("/")}
              alt=""
              src={LightLogo}
              flex={1}
              width={120}
              height={70}
              margin={isMobile ? "-20px auto 20px" : 0}
            />
            <HStack flex={4} spacing={0} w="100%">
              <Box w="100vh" h="100%">
                <VStack>
                  <Text
                    className="gordita700"
                    fontSize={{ base: 20, md: 24 }}
                    textAlign="center"
                    mb={isMobile ? 30 : 30}
                  >
                    Log In to Portalys!
                  </Text>
                  <Formik
          initialValues={initialValues}
          validationSchema={LoginSchema}
          onSubmit={async (values) => {
            try {
              // Attempt to send OTP
              const response = await handleSendOTP("+" + values.phoneNumber);
              console.log("aaaa", verificationCheck);

              // Check if OTP sending was successful
              if (response) {
                // Proceed with setting the modal
                setRegistrationModal((lp) => ({
                  ...lp,
                  openModal: true,
                  modalType: RegistraionModalTypes.LOGIN_VERIFICATION,
                  userRole,
                  userData: {
                    phoneNumber: values.phoneNumber,
                  },
                }));
              }
            } catch (error) {
              console.error("Error sending OTP:", error);
              setVerificationStatus("Failed to send OTP");
              setVerificationCheck(false);
            }
          }}
        >
          {(formik) => {
            const { handleSubmit, errors, touched, setFieldValue } = formik;

            const handlePhoneNumberChange = (value) => {
              // Check if the value is numeric or empty
              if (/^\d*$/.test(value) || value === "") {
                setFieldValue("phoneNumber", value);
              }
            };

            return (
              <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <VStack px={{ base: 0, md: 36 }} alignItems="flex-start">
                  <Text fontWeight={400} fontSize="0.75rem" pl={"1.5rem"}>
                    Phone Number
                  </Text>
                  <Field
                    type="phoneNumber"
                    name="phoneNumber"
                    id="phoneNumber"
                    render={({ field }) => (
                      <PhoneInput
                        inputProps={{
                          name: "phoneNumber", // Specify the name for the input field
                          placeholder: "Enter Your Phone Number",
                          style: {
                            width: "100%",
                            border: "1px solid #FFFFFF",
                            borderRadius: "6.25rem",
                            height: "3.375rem",
                            fontSize: "14px",
                            color: "#FFFFFF",
                            backgroundColor: "transparent",
                          },
                        }}
                        style={{
                          marginTop: "0.30rem",
                        }}
                        dropdownStyle={{ backgroundColor: "#000000" }}
                        country={"us"} // Set your default country
                        value={field.value} // Set the value explicitly
                        onChange={handlePhoneNumberChange} // Handle input changes
                      />
                    )}
                  />

                  {errors.phoneNumber && touched.phoneNumber && (
                    <Text
                      color="red"
                      className="gordita400"
                      fontWeight={400}
                      fontSize={14}
                    >
                      {errors.phoneNumber}
                    </Text>
                  )}

                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: 700,
                      color: "red",
                    }}
                  >
                    {verificationStatus}
                  </p>
                </VStack>

                <Box px={{ base: 0, md: 36 }} mt={24} w="100%">
                  <Button
                    type="submit"
                    className="gordita700"
                    sx={theme.buttons.primary.shape}
                    bg={theme.buttons.primary.default}
                    color={theme.buttons.primary.color}
                    css={{
                      width: "100%",
                    }}
                    _hover={{
                      bg: theme.buttons.primary.hover,
                      boxShadow: theme.buttons.primary.hoverBoxShadow,
                    }}
                    _active={{
                      boxShadow: theme.buttons.primary.activeBoxShadow,
                    }}
                  >
                    Receive SMS With The Code
                  </Button>
                </Box>

                <Box
                  className="gordita400"
                  fontSize={16}
                  px={{ base: 0, md: 36 }}
                  mt={24}
                  w="100%"
                  textAlign="center"
                >
                 
                  <chakra.span
                    color="white"
                    cursor="pointer"
                    fontWeight="700"
                    onClick={() =>
                      setRegistrationModal((lp) => ({
                        ...lp,
                        openModal: true,
                        modalType: RegistraionModalTypes.LOGIN_EMAIL,
                        userData: {
                        },
                      }))
                    }
                  >
                    {" "}
                    Log in with Email
                  </chakra.span>
                </Box>

                <Box
                  className="gordita400"
                  fontSize={16}
                  px={{ base: 0, md: 36 }}
                  mt={24}
                  w="100%"
                  textAlign="center"
                >
                  Don't have an account?
                  <chakra.span
                    color="white"
                    cursor="pointer"
                    fontWeight="700"
                    onClick={() =>
                      navigate('/create-event')
                    }
                  >
                    {" "}
                    Sign Up
                  </chakra.span>
                </Box>

                {isMobile && (
                  <Box>
                    <HStack my={36} px={{ base: 0, md: 36 }}>
                      <Divider orientation="horizontal" bg="#A2A2A6" w="100%" />
                    </HStack>
                    <Box
                      className="gordita400"
                      fontSize={16}
                      px={{ base: 0, md: 36 }}
                      mt={24}
                      w="100%"
                      pb={35}
                      textAlign="center"
                    >
                      Are you a verifier?
                      <chakra.span
                        color="white"
                        cursor="pointer"
                        fontWeight="700"
                        onClick={() =>
                        navigate('/?verifier=true')
                        }
                      >
                        {" "}
                        Log In As Verifier
                      </chakra.span>
                    </Box>
                  </Box>
                )}
              </Form>
            );
          }}
        </Formik>
                </VStack>
              </Box>
            </HStack>
          </Box>
    
          <div color="#FFFFFF" zIndex="100"></div>
          <Modal
            closeOnOverlayClick={false}
            isOpen={_.openModal}
            onOverlayClick={() => {
              setRegistrationModal((lp) => {
                return { ...lp, openModal: false, userRole: "" };
              });
            }}
            onClose={() => {
              setRegistrationModal((lp) => {
                return { ...lp, openModal: false, userRole: "" };
              });
            }}
            size={isMobile ? "full" : { base: "sm", md: "md", lg: "lg" }} // Set the size conditionally
            isCentered={true}
          >
            <ModalOverlay />
            <ModalContent
              mx={0}
              color="white.100"
              borderRadius="2.5rem"
              boxShadow={
                isMobile
                  ? "none"
                  : "0px 0px 100px 20px rgba(0, 0, 0, 0.90), -1px -1px 0px 0px rgba(255, 255, 255, 0.20) inset, 1px 1px 0px 0px rgba(255, 255, 255, 0.50) inset"
              }
              bg={"rgba(0, 0, 0, 0.50)"}
              backdropFilter={"blur(150px)"}
            >
              <ModalCloseButton />
              <ModalBody
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                p={isMobile ? 15 : "60px 10px"}
              >
                {renderModal()}
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      );
  };
  
  export default LoginOrganizer;
  