import "./App.css";
import "./column-system.css";
import {
  Box,
  Button,
  ChakraProvider,
  color,
  Input,
  Text,
} from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import { theme } from "./styles/theme/base";
import Routes from "./routes";
import { GoogleOAuthProvider } from "@react-oauth/google";
import secrets from "./secrets";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useRecoilState } from "recoil";
import { SignClient } from "@walletconnect/sign-client";
import { Web3Modal as Web3Modal1 } from "@web3modal/standalone";
import { CrossmintPaymentElement } from "@crossmint/client-sdk-react-ui";
import { CrossmintNFTCollectionView } from "@crossmint/client-sdk-react-ui";

// Plugins
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./dayjs.config";
import { Image } from "@chakra-ui/react";
import DivisorSvg from "./static/assets/images/Divisor.svg";
import Divisor1Svg from "./static/assets/images/Divisor1.svg";
import Group558SVG from "./static/assets/images/Group558.svg";
import Group548SVG from "./static/assets/images/Group 548.svg";

// Adapters
import { fetchUserData } from "./utils/actions/registration";
import { user } from "./recoil/atoms/user";
import { userRoles } from "./utils/constants";
import { HStack, Link } from "@chakra-ui/react";
import {
  InstagramOutlined,
  LinkedinOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import { useMediaQuery } from "@chakra-ui/react";
const newTheme = extendTheme(theme);

const web3Modal = new Web3Modal1({
  projectId: "191dd3d4e36d94ef89d652e8c5de4f39",
  standaloneChains: ["eip155:137"],
  themeColor: "blackWhite",
});

function App() {
  const sitePassword = secrets.sitePassword;
  const [openSite, setOpenSite] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [userChecked, setUserChecked] = useState(false);
  const [cookies, setCookie] = useCookies(["site-password"]);
  const [_, setUser] = useRecoilState(user);

  const [provider, setProvider] = useState(null);
  const [signClient, setSignClient] = useState();
  const [sessions, setSessions] = useState([]);

  async function createClient() {
    try {
      const client = await SignClient.init({
        projectId: "191dd3d4e36d94ef89d652e8c5de4f39",
      });
      setSignClient(client);
      await subscribeToEvents(client);
    } catch (e) {
      console.log(e);
    }
  }

  async function login() {
    if (!signClient) throw Error("Cannot connect. Sign Client is not created");
    try {
      // dapp is going to send a proposal namespace
      const proposalNamespace = {
        eip155: {
          chains: ["eip155:137"],
          methods: ["eth_sendTransaction"],
          events: ["connect", "disconnect"],
        },
      };

      const { uri, approval } = await signClient.connect({
        requiredNamespaces: proposalNamespace,
      });

      if (uri) {
        web3Modal.openModal({ uri });
        const sessionNamespace = await approval();
        onSessionConnect(sessionNamespace);
        web3Modal.closeModal();
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function onSessionConnect(session) {
    if (!session) throw Error("session doesn't exist");
    try {
      localStorage.setItem("provider", JSON.stringify(session));
      setSessions(session);
      setProvider(session);
    } catch (e) {
      console.log(e);
    }
  }

  async function logout() {
    try {
      await signClient.disconnect({
        topic: sessions.topic,
        code: 6000,
        message: "User disconnected",
      });
      reset();
      localStorage.removeItem("provider");
    } catch (e) {
      console.log(e);
    }
  }

  async function subscribeToEvents(client) {
    if (!client)
      throw Error("No events to subscribe to b/c the client does not exist");

    try {
      client.on("session_delete", () => {
        reset();
      });
    } catch (e) {
      console.log(e);
    }
  }

  const reset = () => {
    setSessions([]);
    setProvider(null);
  };

  useEffect(() => {
    if (!signClient) {
      createClient();
    }
  }, [signClient]);

  const getUser = async () => {
    const token = localStorage.getItem("x-auth-token");
    if (token) {
      await fetchUserData(token)
        .then((user) => {
          if (user?.user) {
            setUser((lp) => {
              return {
                ...lp,
                token,
                userData: user?.user,
              };
            });
            setUserChecked(true);
          }
        })
        .catch((err) => {
          setUser((lp) => {
            return {
              ...lp,
              token: "",
              userData: null,
            };
          });
          setUserChecked(true);
        });
    } else {
      setUser((lp) => {
        return {
          ...lp,
          token: "",
          userData: null,
        };
      });
      setUserChecked(false);
    }
  };
  useEffect(() => {
    setOpenSite(true);
    setCookie("site-password", password, {
      path: "/",
    });
    getUser();
  }, []);
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const [isMonitor] = useMediaQuery("(min-width: 1540px)");

  /*function onEvent(event) {
    switch (event.type) {
      case "quote:status.changed":
        const { quote } = event.payload;
        console.log("quote", quote);
      case "payment:preparation.succeeded":
        const { totalQuote } = event.payload;
        console.log("totalQuote", totalQuote);
        break;
      case "payment:process.started":
        console.log("payment:process.started");
        break;
      case "payment:process.succeeded":
        const { orderIdentifier } = event.payload;
        console.log("orderIdentifier", orderIdentifier);
        break;
      default:
        break;
    }
  }

  const wallets = [
    {
      "chain": "ethereum",
      "publicKey": "0xe3d4cEf6F650dea3fd19AA8d9a89F13bC4273468"
    },
    {
      "chain": "polygon",
      "publicKey": "0xe3d4cEf6F650dea3fd19AA8d9a89F13bC4273468"
    },
    {
      "chain": "bsc",
      "publicKey": "0xe3d4cEf6F650dea3fd19AA8d9a89F13bC4273468"
    },
    {
      "chain": "optimism",
      "publicKey": "0xe3d4cEf6F650dea3fd19AA8d9a89F13bC4273468"
    },
    {
      "chain": "arbitrum",
      "publicKey": "0xe3d4cEf6F650dea3fd19AA8d9a89F13bC4273468"
    },
    {
      "chain": "base",
      "publicKey": "0xe3d4cEf6F650dea3fd19AA8d9a89F13bC4273468"
    }
  ];





  return (
    <div style={{ height: "100vh" }}>

<CrossmintPaymentElement
        clientId="7ad322a7-f675-4c07-b2a1-ba8e5d6e3222"
        projectId="6f3fb382-305f-47d1-90d9-5da160f952dc"


        environment="staging"
        recipient={{
          email: "issamhaddad@portalys.io",
          //wallet: "0xc3bac53CD5d7Cc2220586c271947F063B836446F"
          wallet:"0xe3d4cEf6F650dea3fd19AA8d9a89F13bC4273468"
        }}
        currency="USD"
        locale="en-US"
        mintConfig={{
          "type":"erc-1155"
          ,"totalPrice":"0.001"
          ,"id":"1"
          ,"quantity":"1",
          "PriceToPay":"1"}}
        onEvent={onEvent}
      />
   

   <CrossmintNFTCollectionView wallets={wallets} environment="staging" />


    </div>
               
  ); */

  return (
    <ChakraProvider portalZIndex={40} theme={newTheme}>
      <GoogleOAuthProvider clientId={secrets.GoogleClientId}>
        {openSite || (cookies && cookies["site-password"]) ? (
          <Box bg="#000000">
            {_?.userData?.userRole === userRoles.ORGANIZER &&
              _?.userData?.isNew && <OnboardingForm />}
            <Routes
              {...{
                logout,
                login,
                provider,
                userChecked,
                getUser,
              }}
            />
          </Box>
        ) : (
          <Box height="100vh" bg="black" overflow="hidden"></Box>
        )}
      </GoogleOAuthProvider>
    </ChakraProvider>
  );
}

export default App;
