import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "../../../utils/api/axios";
import {
  RegistraionModalTypes,
  registration,
} from "../../../recoil/atoms/registration";
import { Box, Button, VStack } from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { theme } from "../../../styles/theme/base";
function EmailVerification() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [verificationStatus, setVerificationStatus] = useState("loading");
  const [message, setMessage] = useState("");
  const [_, setRegistrationModal] = useRecoilState(registration);

  useEffect(() => {
    const token = searchParams.get("tokenVerify");

    async function verifyEmail() {
      try {
        await axios.get(`api/verify/${token}`);
        setVerificationStatus("success");
      } catch (error) {
        setVerificationStatus("error");
      }
    }

    verifyEmail();
  }, []);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {verificationStatus === "loading" && <p>Verifying your email...</p>}
      {verificationStatus === "success" && (
        <VStack spacing="30">
          <p style={{ fontWeight: 700 }}>Email verified!</p>
          <Button
            sx={theme.buttons.primary.shape}
            bg={theme.buttons.primary.default}
            color={theme.buttons.primary.color}
            _hover={{
              bg: theme.buttons.primary.hover,
              boxShadow: theme.buttons.primary.hoverBoxShadow,
            }}
            _active={{
              boxShadow: theme.buttons.primary.activeBoxShadow,
            }}
            onClick={() => {
              setRegistrationModal((lp) => ({
                ...lp,
                openModal: true,
                modalType: RegistraionModalTypes.LOGIN,
                userData: {},
                userRole: "",
              }));
            }}
          >
            {" "}
            Log In
          </Button>
        </VStack>
      )}
      {verificationStatus === "error" && (
        <p>Email verification failed. Please try again or contact support.</p>
      )}
    </Box>
  );
}

export default EmailVerification;
