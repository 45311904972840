/* This code is defining a Recoil atom called `dashboard` which has a default value of an object with
two properties: `expanded` (a boolean set to `false`) and `countries` (an array of objects
representing different cities with their labels, values, and icons). The `import` statements are
importing images of the flags of France and the USA from a specific file path. */

import { atom } from "recoil";
import { france, usa } from "../../static/assets/images/index";
export const dashboard = atom({
  key: "dashboard",
  default: {
    dashboard: {
      expanded: true,
      countries: [
        { label: "Boston", value: "boston", icon: usa },
        { label: "New York", value: "new york", icon: usa },
        { label: "Miami", value: "miami", icon: usa },
        { label: "Paris", value: "Paris", icon: france },
      ],
    },
  },
});
