/* This code is importing the `atom` function from the `recoil` library and using it to create a
`ticket` atom. An atom is a unit of state in Recoil, a state management library for React. The
`ticket` atom has a default value that includes properties for the type of ticket, quantity, price,
total, selected seats, name, phone, and selected event. This atom can be used to store and update
the state of a ticket in a React application. */
import { atom } from "recoil";

export const ticket = atom({
  key: "ticket",
  default: {
    type: "",
    quantity: "",
    price: "",
    total: "",
    selectedSeats: [],
    name: "",
    phone: "",
    selectedEvent: {},
  },
});
