/**
 * The function saves a token with a specified expiration time as a cookie.
 * @param token - The token is a string that represents a user's authentication or authorization
 * credentials. It is typically generated by a server and sent to the client as a response to a
 * successful login or authentication request. The token is then stored on the client-side (e.g. in a
 * cookie or local storage) and sent
 * @param type - The type parameter is a string that represents the type of token being saved. It could
 * be a JWT token, an access token, a refresh token, or any other type of token used for authentication
 * or authorization purposes.
 * @param time - The `time` parameter is the duration of the token validity in seconds. It is used to
 * calculate the expiration time of the cookie.
 * @param setCookie - `setCookie` is a function that is used to set a cookie in the browser. It is
 * likely a function provided by a third-party library such as `react-cookie` or `js-cookie`. The
 * function takes three parameters: the name of the cookie (`type`), the value of the cookie
 */
export const saveToken = (token, type, time, setCookie) => {
  let expires = new Date();
  expires.setTime(expires.getTime() + time * 1000);
  setCookie(type, token, {
    path: "/",
    expires,
  });
};


export const isTokenExpired = (token) => {
  if (!token) return true;

  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );

  const { exp } = JSON.parse(jsonPayload);
  return Date.now() >= exp * 1000;
};