import styled from "@emotion/styled";

export const TimeBoxWrapper = styled.div`
  width: 100%;
  input[type="time"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }

  .ant-picker-suffix {
    width: 2.8125rem;
    height: 2.8125rem;
    display: flex;
    justify-content: center;
    align-itens: center;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.05);
  }

  .ant-picker-input > input:placeholder-shown {
    font-weight: bold;
    color: #fff !important;
  }

  .ant-picker-input input::placeholder {
    color: #ffffff !important;
    font-size: 16px;
  }

  .ant-picker-input > input {
    color: #ffffff !important;
    margin-left: 1rem;
    width: 100%;
  }

  .ant-picker-clear {
    display: none;
  }

  .ant-picker .ant-picker-input span.ant-picker-suffix,
  .ant-picker.ant-picker-range span.ant-picker-suffix {
    margin-left: 0px;
    margin-right: 0px;
    order: -1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
